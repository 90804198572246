<div
  class="timeline-event d-flex p-2 align-items-start align-self-stretch rounded"
  (click)="handleClick()"
  [class.selected]="selected"
>
  <div
    class="icon justify-content-center d-flex align-items-center rounded-circle flex-shrink-0"
    [ngClass]="{
      flow: !log.captureId && !log.deliverableId,
      change: log.captureId || log.deliverableId,
    }"
  >
    <span
      class="material-symbols-outlined"
      *ngIf="!log.captureId && !log.deliverableId && log.displayEventName != 'NoteDeleted'"
      >flowsheet</span
    >
    <span
      class="material-symbols-outlined"
      *ngIf="log.captureId || log.deliverableId || log.displayEventName == 'NoteDeleted'"
      >heat_pump_balance</span
    >
  </div>
  <div class="body d-flex w-100">
    <div class="flex-grow-1">
      <h5 class="title">{{ 'missionsService::' + log.displayEventName | abpLocalization }}</h5>
      <div class="light-text">
        <span
          >{{ log.date + 'Z' | date: 'H:mm' }} | {{ log.date + 'Z' | date: 'MM/dd/yyyy' }}
          {{ log.eventUserName ? ' • ' + log.eventUserName : '' }}</span
        >
      </div>
    </div>
  </div>
</div>
