import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetNoteInput,
  NotesCreateDto,
  NotesDto,
  NotesExcelDownloadDto,
  NotesUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { MissionNoteDataDto } from 'projects/flyguys/src/app/pilot-sourcing/models/mission-note-data-dto';
import { MissionsNoteFilters } from 'projects/flyguys/src/app/pilot-sourcing/models/missions-note-filters';
import { UserNoteDto } from 'projects/flyguys/src/app/pilot-sourcing/models/user-note-dto';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  apiName = 'missionsService';

  filter = (missionId: string, input: MissionsNoteFilters, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionNoteDataDto>>(
      {
        method: 'POST',
        url: `/api/missions-service/mission-notes/mission/${missionId}/filter`,
        body: {
          categories: input.categoryId,
          departments: input.atentionTo,
          submittedBy: input.submittedBy,
          filterText: input.filterText,
          pageSize: input.pageSize,
          skipCount: input.skipCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getUsersByMission = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<UserNoteDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-notes/mission/${missionId}/users`,
      },
      { apiName: this.apiName, ...config },
    );

  create = (input: NotesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/note',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/note/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/note/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/note/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: NotesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/note/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetNoteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<NotesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/note',
        params: {
          filterText: input.filterText,
          subject: input.subject,
          body: input.body,
          categoryId: input.categoryId,
          departamentId: input.departamentId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: NotesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotesDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/note/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  removeFromMission = (missionId: string, noteId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission-notes/mission/${missionId}/note/${noteId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getListUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<NotesDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/note/notes-user/${userId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAllowed = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotesDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-notes/allowed-notes`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
