import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Observable,
  map,
  catchError,
  of,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  finalize,
} from 'rxjs';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PagedResultDto } from '@abp/ng.core';

import {
  CustomersDto,
  enumState,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';

export type CustomerData = { customerId: String; customerName: String };

@Component({
  selector: 'app-customer-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class CustomerAutocompleteComponent implements OnInit {
  @Input() omittedCustomers: string[] = [];
  @Input() preloadedCustomer?: CustomersDto;
  @Input() required?: boolean = false;
  @Output() customerSelected: EventEmitter<CustomerData | null>;
  filteredOptions: Observable<CustomersDto[]>;
  search: FormGroup;
  loading: boolean = false;

  constructor(private customerService: CustomersService) {
    this.customerSelected = new EventEmitter();
  }

  ngOnInit(): void {
    this.search = new FormGroup({
      term: new FormControl<string>(this.preloadedCustomer?.name || ''),
    });

    if (this.required) {
      this.search.get('term').addValidators(Validators.required);
    }

    this.filteredOptions = this.search?.get('term').valueChanges.pipe(
      debounceTime(333),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term?.length) {
          return this.searchCustomer(term.toLowerCase());
        } else if (term?.length === 0) {
          this.emitCustomer(null);
        }

        return of([]);
      }),
    );
  }

  /**
   * Handles customer selection on the autocomplete
   * @param event MatAutocompleteSelectedEvent
   */
  handleCustomerSelection(event: MatAutocompleteSelectedEvent) {
    const customer: CustomersDto = event.option.value;
    this.emitCustomer({ customerId: customer.id, customerName: customer.name });
  }

  /**
   * Formats the name of the customer to display
   * @param customer any
   * @returns string
   */
  formatCustomer(customer: any): string {
    return customer?.name || customer;
  }

  /**
   * Clears the search query
   */
  clear() {
    this.search.get('term').setValue('', { emitEvent: false });
  }

  /**
   * Emits the passed value to consumers
   * @param data CustomerData | null
   */
  private emitCustomer(data: CustomerData | null) {
    this.customerSelected.emit(data);
  }

  /**
   * Searches for customers by filtering using the name
   * @param name string
   * @returns Observable<CustomersDto[]>
   */
  private searchCustomer(name: string): Observable<CustomersDto[]> {
    const input: GetCustomerInput = {
      filterText: name,
      name: name,
      skipCount: 0,
      maxResultCount: 100,
      isPaginated: true,
      parentId: '',
      state: enumState.Enabled,
      isCompany: true,
    };

    this.loading = true;

    return this.customerService.getList(input).pipe(
      map((data: PagedResultDto<CustomersDto>) => {
        let customers: CustomersDto[] = [];

        if (data?.totalCount > 0) {
          this.search.get('term').setErrors(null);

          customers = data.items
            .filter((c: CustomersDto) => !this.omittedCustomers.includes(c.id))
            .map((c: CustomersDto) => c);
        } else if (data?.totalCount === 0) {
          this.search.get('term').setErrors({ notFound: true });
        }

        return customers;
      }),
      catchError(e => {
        console.error('Error while fetching customers', e);
        return of([]);
      }),
      finalize(() => (this.loading = false)),
    );
  }
}
