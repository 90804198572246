import { Pipe, PipeTransform } from '@angular/core';
import { OrderFormDeliverableFieldModel } from '../components/orders/model/order-form-deliverable-field.model';
import { KmlDataInfoPackages } from '../shared/deliverable-attributes-list/deliverable-attributes-list.component';

@Pipe({
  name: 'kmlFieldSameConfigPipe',
})
export class KmlFieldSameConfigPipe implements PipeTransform {
  transform(kmlData: KmlDataInfoPackages[], delvIndex: number): OrderFormDeliverableFieldModel[] {
    if (kmlData.length > 0) {
      let kmlDataInfo: OrderFormDeliverableFieldModel[] = [];
      let delV = kmlData.filter(x => x.index == delvIndex);
      if (delV) {
        delV.forEach(x => {
          if (Array.isArray(x.kmlData)) {
            kmlDataInfo = [...x.kmlData];
          }
        });
      }
      return kmlDataInfo;
    }
  }
}
