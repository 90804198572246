<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
  <h2 class="mb-0">Notifications</h2>
</div>

<mat-card class="mb-25 tagus-card">
  <mat-card-content>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="webNotifications">
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef class="text-start">Message</th>
          <td mat-cell *matCellDef="let element">
            <div
              [routerLink]="[generateMissionDetailPath(element)]"
              class="d-flex align-items-center name-info gray-color cursor-pointer"
              [ngClass]="{ 'message-style': element.dismissed }"
            >
              <a href="javascript:void(0)" title="Dismiss">
                <i
                  class="fas fa-solid fa-thumbtack pin-notification"
                  *ngIf="!element.dismissed"
                  (click)="handleRemovePin(element)"
                ></i> </a
              >&nbsp;
              {{ element.message }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="text-end">Date</th>
          <td mat-cell *matCellDef="let element" class="text-end">{{ element.date | date }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalPageSize"
        showFirstLastButtons
        (page)="handleOnPage($event)"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
