<abp-page>
  <div class="d-flex mb-2 align-items-center justify-content-between">
    <h1 class="mb-0">Order List</h1>
    <button
      mat-button
      class="common-button"
      type="button"
      color="primary"
      [routerLink]="['/orders/new']"
      *abpPermission="'missionsService.Mission.Create && General.Common.Create'"
    >
      <mat-icon fontIcon="add"></mat-icon>
      New Order
    </button>
  </div>
  <mat-tab-group [(selectedIndex)]="tabSelected" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Missions">
      <orders-missions></orders-missions>
    </mat-tab>

    <!--<mat-tab label="Projects"
          ><br />
          <orders-projects></orders-projects
        ></mat-tab>-->
    <mat-tab label="Portfolios">
      <ng-template matTabContent>
        <orders-portfolio></orders-portfolio>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</abp-page>
