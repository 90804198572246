import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Badge } from '../../models/badges.enum';
import { PilotSourcingDto } from '../../models/pilot-sourcing-dto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MissionPilotsService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { MissionPilotsCreateDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import {
  enumState,
  EnumStateOptions,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/shared';
import {
  ActionOrderService,
  AvailabilityService,
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  MissionPilotRequestStatusService,
  PilotExperienceLevelesService,
  PilotStatesService,
  SensorTypesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  ActionOrderConfiguredDto,
  AvailabilityDto,
  GetAvailabilitiesInput,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetMissionPilotRequestStateInput,
  GetPilotExperienceLevelInput,
  GetPilotStateInput,
  GetSensorTypesInput,
  MissionPilotRequestStatusDto,
  PilotExperienceLevelesDto,
  SensorTypesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  ABP,
  ConfigStateService,
  ListService,
  LocalizationService,
  PagedResultDto,
  EnvironmentService,
} from '@abp/ng.core';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { GetPilotsForSourcingInput } from '../../models/pilot-for-sorucing-request';
import { firstValueFrom, Observable } from 'rxjs';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { LoadingOverlayService } from '../../../services/loading/loading.service';
import { CaptureWithDeliverablesDto } from '../../models/capture-with-deliverables-dto';
import { ChatPilotModalComponent } from '../chat-pilot-modal/chat-pilot-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import {
  BadgesDto,
  EquipmentsDto,
  type GetBadgeInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  BadgesService,
  CapturesService,
  EquipmentsService,  
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import { PilotForSourcingFilterColumns } from '../../models/pilot-for-sourcing-filter-columns';
import {
  GetPilotSourcingExtraFilters,
  GetPilotSourcingFilters,
} from '../../models/get-pilot-sourcing-filters-dto';
import { PaginationSortingAndGlobalSearchDto } from '../../models/pagination-sorting-and-global-search-dto';
import { PilotSourcingSettingsForLocalStorage } from '../../models/pilot-sourcing-settings-for-local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { PilotInfoComponent } from '../pilot-info/pilot-info.component';
import { format } from 'date-fns';
import { NotificationsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { SmsNotificationsMultipleCreateDto } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';
import { AssignBadgeModalComponent } from '../assign-badge-modal/assign-badge-modal.component';
import { BadgePilotData } from '../assign-badge-modal/models/badge-pilot-data';
import { BadgeSelectionDto } from '../assign-badge-modal/models/badge-selection-dto';
import { PilotBadgeService } from '../../../../../../pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import { PilotBadgeDto } from '../../../../../../pilots-service/src/lib/proxy/pilots-service/relationals';
import { RevokeBadgeModalComponent } from '../revoke-badge-modal/revoke-badge-modal.component';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OrderRequestModel } from '../../models/order-request-model';
import { DistanceModalComponent } from '../distance-modal/distance-modal.component';
import { SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { ConversationService } from 'projects/chat/src/lib/services/conversation.service';
import { ProgressionLevel } from '../assign-badge-modal/models/badge-progression-enum';

@Component({
  selector: 'app-pilot-sourcing-sourcing',
  templateUrl: './pilot-sourcing-sourcing.component.html',
  styleUrls: ['./pilot-sourcing-sourcing.component.scss'],
  providers: [ListService],
})
export class PilotSourcingSourcingComponent implements OnInit {
  @Input() titleKey: string | null = null;
  @Input() missionId: string | null = null;
  @Input() missionStatusId: string | null = null;
  @Input() missionName: string | null = null;
  @Input() jobId: string | null = null;
  @Input() captureId: string | null = null;
  @Input() capture: CaptureWithDeliverablesDto | null = null;
  @Input() missionDetails: boolean = false;
  @Input() customerId: string | null = null;

  @Output() captureBreadcrumbClicked = new EventEmitter();

  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';
  readonly maxDistance: number = 500;
  filters = { isPaginated: true } as GetPilotsForSourcingInput;

  pilotsForSourcingData: PagedResultDto<PilotSourcingDto> = {
    items: [],
    totalCount: 0,
  };

  states = EnumStateOptions;
  experienceLevels: PagedResultDto<PilotExperienceLevelesDto> = {
    items: [],
    totalCount: 0,
  };
  requestStatuses: PagedResultDto<MissionPilotRequestStatusDto> = {
    items: [],
    totalCount: 0,
  };
  availabilities: PagedResultDto<AvailabilityDto> = {
    items: [],
    totalCount: 0,
  };
  sensorTypes: PagedResultDto<SensorTypesDto> = {
    items: [],
    totalCount: 0,
  };

  equipments: EquipmentsDto[];
  filteredEquipments: EquipmentsDto[];

  form: FormGroup;

  searchForm: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  notificationForm: FormGroup;

  pilotsForSMS: PilotSourcingDto[] = [];

  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  isSMSModalBusy = false;
  isSMSModalOpen = false;
  statusOptions: any[] = [];
  selectedPilot: PilotSourcingDto;

  public page = 1;
  public totalCount = 0;
  public sorting = '';
  valuesMaxResultCount = [];
  MaxResultCount = 100;
  captureDateLocal: string;
  captureDateLocalTime: string;

  clientMaxResultCount = 10;
  pageNumber = 0;

  public readonly CHAT_WITH_PILOT = 'chat_with_pilot';
  allowChatWithPilot: boolean = false;

  public readonly ASSIGN_BADGE = 'assign_badge';
  allowAssignBadge: boolean = false;

  public readonly REVOKE_BADGE = 'revoke_badge';
  allowRevokeBadge: boolean = false;

  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;

  pilotForSourcingGridFilters: GetPilotSourcingFilters;

  pilotForSourcingGridExtraFilters: GetPilotSourcingExtraFilters = {
    infoChecked: true,
    isFromMissionDetailsRequested: true,
    sendSMS: false,
  };
  filterConfig: FilterConfig = PilotForSourcingFilterColumns;

  // Default paging values
  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearchDto = {
    skipCount: 0,
    maxResultCount: 10,
  };

  currentUserId: string;

  callingApi: boolean = false;

  selectedPilots: PilotSourcingDto[] = [];
  changingPageMaxResoultCount: boolean = false;
  SelectionType = SelectionType;
  @ViewChild('dataTable', { static: false }) dataTable: DatatableComponent;

  orderRequest?: OrderRequestModel = null;

  availabilitiesDict: { [id: string]: string } = {};
  sensorTypesDict: { [id: string]: string } = {};

  showRanking: boolean = false;

  constructor(
    private fb: FormBuilder,
    public readonly pilotSourcingService: PilotSourcingService,
    private missionPilotsService: MissionPilotsService,
    private availabilityService: AvailabilityService,
    private requestStatusService: MissionPilotRequestStatusService,
    private pilotExperienceLevelService: PilotExperienceLevelesService,
    public readonly list: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    public loadingService: LoadingOverlayService,
    private pilotService: PilotService,
    private usersService: IdentityUserService,
    private dialog: MatDialog,
    private confirmation: ConfirmationService,
    private stateService: ConfigStateService,
    private equipmentsService: EquipmentsService,
    private readonly actionOrderService: ActionOrderService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private spinner: NgxSpinnerService,
    private readonly pilotStateService: PilotStatesService,
    private notificationBroadcastService: NotificationBroadcastService,
    private localizationService: LocalizationService,
    private captureService: CapturesService,
    private readonly notificationService: NotificationsService,
    private missionFlowService: MissionFlowService,
    private readonly httpClient: HttpClient,
    private readonly _environmentService: EnvironmentService,
    private pilotBadgeService: PilotBadgeService,
    private toasterService: ToasterService,
    private conversationService: ConversationService,
    private badgeService: BadgesService,
    private sensorTypesService: SensorTypesService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      status: [''],
    });

    this.loadDefaultFilters();
    this.getOrderRequest();
    this.getPageAttributeValues();
    this.getMaxCountAttribute();
    this.applyStoredFilterSettings();
    this.getPilotExperienceLevelValues();
    this.getRequestStatusValues();
    this.getAvailabilityValues();
    this.getPilotBadges();
    this.getActionConfiguration();
    this.getEquipments();
    this.getPilotStateValues();
    this.updateContactOptions();
    this.getSensorTypesValues();
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
      if (
        notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionFinderStatus
      ) {
        if (this.missionId == notif.itemId) {
          this.callApiWithFilters();
        }
      }
    });

    if (this.capture.captureDate && this.capture.captureTime) {
      const newDate: Date = new Date(`${this.capture.captureDate} ${this.capture.captureTime}Z`);
      this.captureDateLocal = format(newDate, 'MM/dd/yyyy');
      this.captureDateLocalTime = format(newDate, 'HH:mm');
    } else if (this.capture.captureDate && !this.capture.captureTime) {
      const newDate: Date = new Date(`${this.capture.captureDate} 00:00Z`);
      this.captureDateLocal = format(newDate, 'MM/dd/yyyy');
      this.captureDateLocalTime = '-';
    }
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.pilotForSourcingGridFilters[updatedCondition.column as keyof GetPilotSourcingFilters] =
          updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  loadDefaultFilters() {
    this.pilotForSourcingGridFilters = {
      distanceSelected: ['150'],
      isPreferred: ['true'],
    };

    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues:
          this.pilotForSourcingGridFilters[condition.column as keyof GetPilotSourcingFilters] ?? [],
      })),
    };
  }

  onInfoCheckedTurn() {
    if (!this.pilotForSourcingGridExtraFilters.infoChecked)
      this.pilotForSourcingGridExtraFilters.infoChecked = null;
    this.callApiWithFilters();
  }

  callApiWithFilters() {
    if (this.callingApi) {
      return;
    }

    this.callingApi = true;

    this.spinner.show();
    this.getPilotSourcingValues().subscribe({
      next: (response: PagedResultDto<PilotSourcingDto>) => {
        this.pilotsForSourcingData = response;
        this.spinner.hide();
        this.callingApi = false;
        this.showRankingColumn(response);
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  private getPilotSourcingValues(): Observable<PagedResultDto<PilotSourcingDto>> {
    this.pilotForSourcingGridFilters.missionId = [this.missionId];
    this.pilotForSourcingGridFilters.captureId = [this.captureId];

    if (this.customerId) {
      this.pilotForSourcingGridExtraFilters.customerId = this.customerId;
    }

    return this.pilotSourcingService.getPilotSourcingData(
      this.pilotForSourcingGridFilters,
      this.pilotForSourcingGridExtraFilters,
      this.paginationSortingAndGlobalSearch,
    );
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    // Clear the specific filter
    this.pilotForSourcingGridFilters[column as keyof GetPilotSourcingFilters] = [];

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    // Reset filters
    for (let condition of this.filterConfig.conditions) {
      this.pilotForSourcingGridFilters[condition.column as keyof GetPilotSourcingFilters] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.searchForm.get('searchInput')?.setValue('');
    this.paginationSortingAndGlobalSearch.filter = '';

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    } else if (condition.type === FilterType.Boolean) {
      return value?.toLowerCase() == 'true' ? 'Yes' : 'No';
    }

    // Return the value itself for other types
    return value;
  }

  setPage(pageInfo: any) {
    setTimeout(() => {
      this.paginationSortingAndGlobalSearch = {
        ...this.paginationSortingAndGlobalSearch,
        skipCount: pageInfo.offset * pageInfo.pageSize,
        maxResultCount: pageInfo.pageSize,
      };
      this.storeFilterSettings();
      this.callApiWithFilters();
    }, 30);
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  onGlobalSearch() {
    this.paginationSortingAndGlobalSearch.filter = this.searchForm.get('searchInput')?.value.trim();

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  // Local Storage Filters section
  private storeFilterSettings() {
    const filterSettings: PilotSourcingSettingsForLocalStorage = {
      pilotForSourcingFilters: this.pilotForSourcingGridFilters,
      paginationSortingAndGlobalSearch: this.paginationSortingAndGlobalSearch,
    };
    localStorage.setItem(
      `pilotfilterSettings_${this.currentUserId}`,
      JSON.stringify(filterSettings),
    );
  }

  private applyStoredFilterSettings() {
    const storedSettings = localStorage.getItem(`pilotfilterSettings_${this.currentUserId}`);
    if (storedSettings) {
      const { missionGridFilters, paginationSortingAndGlobalSearch } = JSON.parse(storedSettings);

      // Variables to call the API
      this.pilotForSourcingGridFilters = missionGridFilters || this.pilotForSourcingGridFilters;
      this.paginationSortingAndGlobalSearch =
        paginationSortingAndGlobalSearch || this.paginationSortingAndGlobalSearch;

      // Variables to update ngx-datatable UI
      if (
        paginationSortingAndGlobalSearch.skipCount !== undefined &&
        paginationSortingAndGlobalSearch.maxResultCount
      ) {
        this.pageNumber =
          paginationSortingAndGlobalSearch.skipCount /
          paginationSortingAndGlobalSearch.maxResultCount;
        this.clientMaxResultCount = paginationSortingAndGlobalSearch.maxResultCount;
      }

      // Variables to update the filter chips and sidenav filter summary UI
      this.filterConfig.conditions.forEach(condition => {
        const storedValue =
          this.pilotForSourcingGridFilters[condition.column as keyof GetPilotSourcingFilters];
        if (storedValue) {
          condition.existingValues = storedValue;
        }
      });

      // Update the global search input in the form
      if (paginationSortingAndGlobalSearch && paginationSortingAndGlobalSearch.filter) {
        this.form.get('searchInput')?.setValue(paginationSortingAndGlobalSearch.filter);
      }

      this.refreshFilterConfig();
    }

    this.callApiWithFilters();
  }

  // We need this to ensure that the component app-grid-filters is re-rendered.
  // Otherwise we get weird behaviour, displaying previous filters in the summary.
  private refreshFilterConfig() {
    this.filterConfig = { ...this.filterConfig };
  }

  private updateExperienceLevelOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotExperienceLevelId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  private updateRequestStatusOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'requestStatusId');
    if (condition) {
      condition.options = data.map(item => ({
        id: item.id,
        description: this.getRequestStatusDescription(item.description),
      }));
    }
  }

  private updateAvailabilitiesOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'availabilityId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  private updateSensorTypesOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'sensorsType');
    if (condition) {
      condition.options = data.map(item => ({
        id: item.description,
        description: item.description,
      }));
    }
  }

  private updateEquipmentsOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'equipmentId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private updatePilotStateOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotStateId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  private updateBadgeOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotBadgesId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  getEquipments() {
    this.equipmentsService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.equipments = r.items;
      this.updateEquipmentsOptions(r.items);
      this.filteredEquipments = this.equipments.slice();
    });
  }

  filterEquipments(value: string) {
    this.filteredEquipments = this.equipments.filter(eq =>
      eq.name.toLowerCase().includes(value.toLowerCase()),
    );
  }

  getMessageByIdToActionData(id: number): string {
    //0, request, 1 assign
    switch (id) {
      case 0: {
        return this.localizationService.instant('missionsService::UpdatePaymentPilot');
      }
      case 1: {
        return this.localizationService.instant('missionsService::UpdatePaymentPilot');
      }
      default:
        return '';
    }
  }

  getActionData(id: number, action: string) {
    const message = this.getMessageByIdToActionData(id);
    const uniqueDeliverables = [
      ...new Set(this.capture.deliverables.map(deliverable => deliverable.name)),
    ];

    let pilotsId = '';

    if (this.selectedPilots.length > 0) {
      pilotsId = this.selectedPilots
        .map(function (x) {
          return x.id;
        })
        .join(',');
    }
    return {
      captureDate: this.capture.fixedCaptureDate || this.captureDateLocal,
      captureTime: this.capture.fixedCaptureTime || this.captureDateLocalTime || '-',
      captureNumber: this.capture.captureNumber,
      deliverables: uniqueDeliverables.join(', '),
      pilotPayDef: this.capture.pilotPay || 0,
      pilotDateDef: this.capture.pilotPayDate || '',
      captureId: this.capture.captureId,
      message: message,
      pilots: pilotsId,
      action: action,
    };
  }

  private getPilotExperienceLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const experienceLevelFilter = { state: enumState.Enabled } as GetPilotExperienceLevelInput;

    this.pilotExperienceLevelService
      .getList({
        ...query,
        ...experienceLevelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.experienceLevels = res;
        this.updateExperienceLevelOptions(res.items);
      });
  }

  private getRequestStatusValues(): void {
    const query = {} as ABP.PageQueryParams;
    const requestStatusFilter = { state: enumState.Enabled } as GetMissionPilotRequestStateInput;

    this.requestStatusService
      .getList({
        ...query,
        ...requestStatusFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.requestStatuses = res;
        this.updateRequestStatusOptions(res.items);
      });
  }

  private getAvailabilityValues(): void {
    const query = {} as ABP.PageQueryParams;
    const availabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;

    this.availabilityService
      .getList({
        ...query,
        ...availabilityFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.availabilities = res;
        this.updateAvailabilitiesOptions(res.items);
        for (let dep of this.availabilities.items) {
          this.availabilitiesDict[dep.id] = dep.description;
        }
      });
  }

  private getPilotStateValues(): void {
    const query = {} as ABP.PageQueryParams;
    const pilotStateFilter = { state: enumState.Enabled } as GetPilotStateInput;

    this.pilotStateService
      .getList({
        ...query,
        ...pilotStateFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.updatePilotStateOptions(res.items);
      });
  }

  private getSensorTypesValues(): void {
    const query = {} as ABP.PageQueryParams;
    const sensorTypeFilter = { state: enumState.Enabled } as GetSensorTypesInput;

    this.sensorTypesService
      .getList({
        ...query,
        ...sensorTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.sensorTypes = res;
        this.updateSensorTypesOptions(res.items);
        for (let dep of this.sensorTypes.items) {
          this.sensorTypesDict[dep.id] = dep.description;
        }
      });
  }
  
  private getPilotBadges() {
    const input: GetBadgeInput = {
      maxResultCount: 1000,
      state: enumState.Enabled,
    };

    this.badgeService.getList(input).subscribe({
      next: (response: PagedResultDto<BadgesDto>) => {
        const activeBadges = response.items.filter(badge => badge.isActive);
        this.updateBadgeOptions(activeBadges);
      },
      error: err => console.log(err),
    });
  }

  assignPilots() {
    this.pilotSourcingCommunicationService.callReloadFunction();
    const pilotsName = this.selectedPilots
      .map(function (x) {
        return x.name;
      })
      .reduce((prev, curr) => prev + ', &nbsp;' + curr);

    const assignToText = this.localizationService.instant('missionsService::AssignToThisMission');

    const confirmationMessage = `${assignToText} <div class="m-2 h5"><b>${pilotsName}</b></div>`;

    const cancelText = this.localizationService.instant('AbpUi::Cancel');
    const assignText = this.localizationService.instant('missionsService::Assign');

    const options: Partial<Confirmation.Options> = {
      hideCancelBtn: false,
      hideYesBtn: false,
      dismissible: false,
      cancelText: cancelText,
      yesText: assignText,
      titleLocalizationParams: [],
    };

    const assignPilotsText = this.localizationService.instant('missionsService::AssignPilot') + 's';

    this.confirmation.warn(confirmationMessage, assignPilotsText, options).subscribe(status => {
      if (status === Confirmation.Status.confirm) {
        this.loadingService.showOverlay();
        const assignedStatus = this.requestStatuses.items.find(
          status => status.description.trim().toLowerCase() === 'assigned',
        );
        if (!assignedStatus) {
          this.loadingService.hideOverlay();
          alert('"Assigned" status not found. Check MissionPilotRequestStatus in Core Service');
          return;
        }

        const pilotApiCalls = this.selectedPilots.map(pilot => {
          const createDto: MissionPilotsCreateDto = {
            pilotId: pilot.id,
            missionId: this.missionId,
            assigned: true,
            state: enumState.Enabled,
            assignedDate: new Date().toISOString(),
            distance: 0,
            requestStatusId: assignedStatus.id,
            descriptionMission: 'mission description',
            requestStatusDesc: assignedStatus.description,
            pilotName: pilot.name,
          };
          return createDto;
        });
        this.missionPilotsService.createList(pilotApiCalls).subscribe({
          next: response => {
            this.loadingService.hideOverlay();
            this.setCapturePilotPay();
            this.getPilotSourcingValues().subscribe(d => {
              this.pilotsForSourcingData = d;
              this.selectedPilots = [];
              this.dataTable.selected = [];
              this.dataTable.recalculate();
              this.showRankingColumn(d);
            });

            if (this.pilotForSourcingGridExtraFilters.sendSMS) {
              let formValue = {
                users: [],
                description: '',
                message: '',
                phoneNumbers: [],
              } as SmsNotificationsMultipleCreateDto;
              const _enviroment = this._environmentService.getEnvironment();

              formValue.description = 'SMS from Assign Pilot';
              formValue.message = this.localizationService
                .instant('missionsService::MessageSMSAssignPilot')
                .replace('{0}', this.getShortMissionName(this.missionName, 100))
                .replace('{1}', this.jobId);

              if (this.selectedPilots.length > 0) {
                this.selectedPilots.forEach(pilot => {
                  formValue.users = [];
                  formValue.phoneNumbers = [];

                  const _return = this.usersService.get(pilot.userId).subscribe(res => {
                    if (res.extraProperties) {
                      if (res.extraProperties.enableSMSReception) {
                        formValue.users.push(pilot.userId);

                        if (pilot.phone != null && pilot.phone.trim() !== '') {
                          formValue.phoneNumbers.push(pilot.phone);
                        }

                        const request = this.notificationService
                          .createMultipleOptions(formValue)
                          .subscribe({
                            next: () => {},
                            error: error => {
                              console.log(
                                'An error was generated in the sending of the SMS.',
                                error,
                              );
                            },
                          });
                      } else {
                        this.toasterService.error(
                          this.localizationService
                            .instant('IdentityService::SMSNotificationValidationMessage')
                            .replace('pilot', pilot.name),
                        );
                      }
                    } else {
                      this.toasterService.error(
                        this.localizationService
                          .instant('IdentityService::SMSNotificationValidationMessage')
                          .replace('pilot', pilot.name),
                      );
                    }
                  });
                });
              }
            }
          },
          error: err => {
            this.loadingService.hideOverlay();
            console.error(err);
          },
        });
      }
    });
  }

  requestPilots() {
    this.pilotSourcingCommunicationService.callReloadFunction();
    const isSinglePilot = this.selectedPilots.length === 1;
    const pilotCountText = isSinglePilot
      ? this.localizationService.instant('missionsService::PilotId')
      : this.localizationService.instant('missionsService::PilotId') + 's';
    const descPilots = `${this.selectedPilots.length} ${pilotCountText}`;

    const confirmationMessage = this.localizationService.instant(
      'missionsService::RequestMessagePilots',
      descPilots,
    );

    const resPilots = this.localizationService.instant('missionsService::RequestPilots');

    this.confirmation.warn(confirmationMessage, resPilots).subscribe(status => {
      if (status === Confirmation.Status.confirm) {
        const awaitingResponseStatus = this.requestStatuses.items.find(
          status => status.description.trim().toLowerCase() === 'awaiting response',
        );
        if (!awaitingResponseStatus) {
          alert(
            '"Awaiting Response" status not found. Check MissionPilotRequestStatus in Core Service',
          );
          return;
        }
        const awaitingResponseStatusId = awaitingResponseStatus.id;

        const missionPilotApiCalls = this.selectedPilots.map(pilot => {
          const createDto: MissionPilotsCreateDto = {
            pilotId: pilot.id,
            missionId: this.missionId,
            assigned: false,
            state: enumState.Enabled,
            distance: 0,
            requestStatusId: awaitingResponseStatusId,
            descriptionMission: 'mission description',
            requestStatusDesc: awaitingResponseStatus.description,
            pilotName: pilot.name,
          };
          return createDto;
        });
        this.missionPilotsService.createList(missionPilotApiCalls).subscribe({
          next: response => {
            this.setCapturePilotPay();
            this.getPilotSourcingValues().subscribe(d => {
              this.pilotsForSourcingData = d;
              this.selectedPilots = [];
              this.dataTable.selected = [];
              this.dataTable.recalculate();
              this.showRankingColumn(d);
            });

            let formValue = {
              users: [],
              description: '',
              message: '',
              phoneNumbers: [],
            } as SmsNotificationsMultipleCreateDto;
            
            const _enviroment = this._environmentService.getEnvironment();

            formValue.description = 'Request pilot notification';
            formValue.message = this.localizationService
              .instant('missionsService::MessageSMSRequestPilot')
              .replace('{0}', this.getShortMissionName(this.missionName, 98))
              .replace('{1}', this.jobId);

            if (this.selectedPilots.length > 0) {
              this.selectedPilots.forEach(pilot => {
                formValue.users = [];
                formValue.phoneNumbers = [];

                const _return = this.usersService.get(pilot.userId).subscribe(res => {
                  if (res.extraProperties) {
                    if (res.extraProperties.enableSMSReception) {
                      formValue.users.push(pilot.userId);

                      if (pilot.phone != null && pilot.phone.trim() !== '') {
                        formValue.phoneNumbers.push(pilot.phone);
                      }

                      const request = this.notificationService
                        .createMultipleOptions(formValue)
                        .subscribe({
                          next: () => {},
                          error: error => {
                            console.log('An error was generated in the sending of the SMS.', error);
                          },
                        });
                    } else {
                      this.toasterService.error(
                        this.localizationService
                          .instant('IdentityService::SMSNotificationValidationMessage')
                          .replace('pilot', pilot.name),
                      );
                    }
                  } else {
                    this.toasterService.error(
                      this.localizationService
                        .instant('IdentityService::SMSNotificationValidationMessage')
                        .replace('pilot', pilot.name),
                    );
                  }
                });
              });
            }
          },
          error: err => console.error(err),
        });
      }
    });
  }

  requestPilot(row: any) {
    this.pilotSourcingCommunicationService.callReloadFunction();
    const confirmationMessage = this.localizationService.instant(
      'missionsService::RequestMessagePilots',
      row.name,
    );

    const resPilots = this.localizationService.instant('missionsService::RequestPilots');

    this.confirmation.warn(confirmationMessage, resPilots).subscribe(status => {
      if (status === Confirmation.Status.confirm) {
        const awaitingResponseStatus = this.requestStatuses.items.find(
          status => status.description.trim().toLowerCase() === 'awaiting response',
        );
        if (!awaitingResponseStatus) {
          alert(
            '"Awaiting Response" status not found. Check MissionPilotRequestStatus in Core Service',
          );
          return;
        }
        const awaitingResponseStatusId = awaitingResponseStatus.id;

        const createDto: MissionPilotsCreateDto = {
          pilotId: row.id,
          missionId: this.missionId,
          assigned: false,
          state: enumState.Enabled,
          distance: 0,
          requestStatusId: awaitingResponseStatusId,
          descriptionMission: 'mission description',
          requestStatusDesc: awaitingResponseStatus.description,
          pilotName: row.name,
        };

        this.missionPilotsService.create(createDto).subscribe({
          next: response => {
            this.getPilotSourcingValues().subscribe(d => {
              this.pilotsForSourcingData = d;
              this.showRankingColumn(d);
            });
            this.selectedPilots = [];
            this.dataTable.selected = [];
            this.dataTable.recalculate();
            this.isModalOpen = false;
          },
          error: err => {
            console.log(err);
            this.isModalOpen = false;
          },
        });
      }
    });
  }

  openUpdateRequestStatusModal(row: PilotSourcingDto) {
    this.selectedPilot = row;
    this.isModalOpen = true;
  }

  submitUpdateRequestStatus() {
    const selectedStatusId = this.form.get('status')?.value;

    const selectedStatus = this.requestStatuses.items.find(
      status => status.id === selectedStatusId,
    );
    const isAssigned = selectedStatus?.description.trim().toLowerCase() === 'assigned';
    // TODO: Add logic to calculate the distance
    const calculatedDistance = 0;

    if (!this.missionId) {
      alert('MissionId is required to perform this action!');
      return;
    }

    const createDto: MissionPilotsCreateDto = {
      pilotId: this.selectedPilot.id,
      missionId: this.missionId,
      assigned: isAssigned,
      state: enumState.Enabled,
      assignedDate: new Date().toISOString(),
      distance: calculatedDistance,
      requestStatusId: selectedStatusId,
      descriptionMission: 'mission description',
      requestStatusDesc: selectedStatus?.description,
      pilotName: this.selectedPilot.name,
    };

    this.missionPilotsService.create(createDto).subscribe({
      next: response => {
        this.setCapturePilotPay();
        this.isModalOpen = false;
        this.getPilotSourcingValues().subscribe(d => {
          this.pilotsForSourcingData = d;
          this.showRankingColumn(d);
        });
      },
      error: err => {
        console.log(err);
        this.isModalOpen = false;
      },
    });
  }

  openBadges(row: any) {}

  makeCall(row: any) {}

  getStars(rating: number): string[] {
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const halfStars = roundedRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    for (let i = 0; i < fullStars; i++) {
      stars.push('filled');
    }

    if (halfStars) {
      stars.push('half');
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push('empty');
    }

    return stars;
  }

  getBadgeString(badge: Badge): string {
    switch (badge) {
      case Badge.BADGE_ONE:
        return 'new_releases';
      case Badge.BADGE_TWO:
        return 'co_present';
      case Badge.BADGE_THREE:
        return 'cameraswitch';
      case Badge.BADGE_FOUR:
        return 'military_tech';
      default:
        return '';
    }
  }

  getRequestStatusDescription(description: string): string {
    if (!description) return '';

    let translationKey = description.replace(/\s/g, '').trim().toLowerCase();

    if (!translationKey) return '';

    return this.localizationService.instant(`missionsService::Request_Status_${translationKey}`);
  }

  changePageSize() {
    this.list.maxResultCount = this.clientMaxResultCount;
    this.pageNumber = 0;
  }

  private getPageAttributeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
      });
  }

  private getMaxCountAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.MaxResultCount = parseInt(attribute.description);
      });
  }

  navigateToCaptures() {
    this.captureBreadcrumbClicked.emit();
  }

  chatwithPilot(row: PilotSourcingDto): void {
    this.pilotService.get(row.id).subscribe(pilotResponse => this.openDialog(pilotResponse));
  }

  openDialog(pilot: PilotDto): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');

    this.usersService.get(pilot.userId).subscribe(user => {
      const dialogRef = this.dialog.open(ChatPilotModalComponent, {
        width: '825px',
        height: '715px',
        disableClose: true,
        data: {
          userId: user.id,
          name: user.name,
          surname: user.surname,
          username: user.userName,
          missionDetails: this.missionDetails,
          firstMessage: `Hi, I'm ${user.name} ${user.surname} ${this.currentUserRoles} of the mission ${this.missionName}`,
        },
      });
    });
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  private configureActionsVisibility(): void {
    this.allowChatWithPilot = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.CHAT_WITH_PILOT &&
        ((this.missionStatusId && t.statusId == this.missionStatusId) ||
          this.missionDetails === false),
    );

    this.allowAssignBadge = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.ASSIGN_BADGE &&
        ((this.missionStatusId && t.statusId == this.missionStatusId) ||
          this.missionDetails === false),
    );

    this.allowRevokeBadge = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.REVOKE_BADGE &&
        ((this.missionStatusId && t.statusId == this.missionStatusId) ||
          this.missionDetails === false),
    );
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;
      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.storeFilterSettings();
      this.callApiWithFilters();
    }
  }

  /**
   * Shows an embeded version of the pilot profile through a Material Dialog
   * @param id string
   * @returns void
   */
  displayPilotProfile(id: string, userId: string): void {
    const ref = this.dialog.open(PilotInfoComponent, {
      data: { id, userId },
      width: '90%',
      height: '90%',
      disableClose: true,
      position: { top: '50px' },
    });

    ref.afterClosed().subscribe({
      next: () => {
        //update current pilots
        this.callApiWithFilters();
      },
    });
  }

  setCapturePilotPay() {
    this.captureService.get(this.capture.captureId).subscribe(c => {
      this.capture.pilotPay = c.pilotPay;
    });
  }

  sendSms(pilot: PilotSourcingDto) {
    this.pilotsForSMS = [];

    const _return = this.usersService.get(pilot.userId).subscribe(res => {
      if (res.extraProperties) {
        if (res.extraProperties.enableSMSReception) {
          this.pilotsForSMS.push(pilot);
          this.showForm();
        } else {
          this.toasterService.error(
            this.localizationService
              .instant('IdentityService::SMSNotificationValidationMessage')
              .replace('pilot', pilot.name),
          );
        }
      } else {
        this.toasterService.error(
          this.localizationService
            .instant('IdentityService::SMSNotificationValidationMessage')
            .replace('pilot', pilot.name),
        );
      }
    });
  }

  showForm() {
    this.buildNotificationForm();
    this.isSMSModalOpen = true;
  }

  hideForm() {
    this.isSMSModalOpen = false;
    this.notificationForm.reset();
    this.pilotsForSMS = [];
  }

  buildNotificationForm() {
    this.notificationForm = this.fb.group({
      phoneNumbers: [[], []],
      roles: [[], []],
      users: [[], []],
      selectedUser: [[], []],
      message: [null, [Validators.required, Validators.maxLength(320)]],
      description: ['SMS from Pilot Sourcing', []],
    });
  }

  submitNotificationForm() {
    if (this.notificationForm.invalid) return;

    let formValue = this.notificationForm.value;

    if(this.pilotsForSMS.length > 0){
      formValue.users = this.pilotsForSMS.map(x => x.userId);

      const _phoneNumbers = this.pilotsForSMS.map(x => x.phone);
  
      if (_phoneNumbers != null && _phoneNumbers.length > 0) {
        formValue.phoneNumbers = _phoneNumbers;
      }
  
      const _enviroment = this._environmentService.getEnvironment();

      formValue.message =
        'Message:\n' +
        formValue.message +
        '\n' +
        this.localizationService
          .instant('missionsService::MessageSMSPilotSourcing')
          .replace('{0}', this.missionName)
          .replace(
            '{1}',
            `${_enviroment.apis.PilotsApp.url}/mission-details/${this.missionId}/${this.capture.captureId}/00000000-0000-0000-0000-000000000000/1`,
          );

      const request = this.notificationService.createMultipleOptions(formValue);
  
      this.isSMSModalBusy = true;
  
      request
        .pipe(
          finalize(() => (this.isSMSModalBusy = false)),
          tap(() => this.hideForm()),
        )
        .subscribe();
  
      this.pilotsForSMS.map(x => x.userId).forEach(element => {
        this.send(formValue.message,element);
      });      
    }
  }

  send(message:string,userId:any) {

    message = message.replace(/\\n/g, '<br/>');
    message = message.replace('||','<br/>');

    this.conversationService
      .sendMessageByInput({ message: message, targetUserId: userId })
      .subscribe();
  }
  
  onSelect({ selected }) {
    this.selectedPilots.splice(0, this.selectedPilots.length);
    this.selectedPilots.push(...selected.filter(x => this.checkSelectable(x)));
  }

  checkSelectable(event) {
    return event.requestStatusDescription?.toLowerCase().trim() !== 'assigned';
  }

  private getOrderRequest() {
    this.missionFlowService.getOrderRequestData(this.missionId).subscribe(res => {
      this.orderRequest = res;
    });
  }
  showDrivingDistance(pilot: PilotSourcingDto) {
    var destLat = Number.parseFloat(this.orderRequest.location.latitude);
    var destLng = Number.parseFloat(this.orderRequest.location.longitude);

    if (!(destLat && destLng)) {
      this.toasterService.error('Mission has no correct coordinates.');
      return;
    }

    if (!(pilot.addressLat && pilot.addressLng)) {
      this.toasterService.error('Pilot has no correct coordinates.');
      return;
    }

    var destination = new google.maps.LatLng(destLat, destLng);
    var origin = new google.maps.LatLng(pilot.addressLat, pilot.addressLng);

    var matrix = new google.maps.DistanceMatrixService();
    matrix.getDistanceMatrix(
      {
        destinations: [destination],
        origins: [origin],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        language: 'en-US',
      },
      (res, status) => this.showDistanceDialog(res, status, pilot),
    );
  }

  showDistanceDialog(
    response: google.maps.DistanceMatrixResponse,
    status: google.maps.DistanceMatrixStatus,
    pilot: PilotSourcingDto,
  ) {
    const firstResult = response.rows[0].elements[0];

    this.dialog.open(DistanceModalComponent, {
      data: {
        localizableEntityName: 'pilotsService::Pilot',
        name: pilot.name,
        distance: firstResult.distance,
        duration: firstResult.duration,
      },
      width: '400px',
    });
  }

  async openAssignBadgeModal(row: PilotSourcingDto) {
    try {
      this.loadingService.showOverlay();
      const badgePilotData = await this.fetchPilotDetails(row.id);
      this.loadingService.hideOverlay();

      const dialogRef = this.dialog.open(AssignBadgeModalComponent, {
        disableClose: true,
        width: '900px',
        panelClass: 'custom-modalbox',
        data: badgePilotData,
      });

      dialogRef.afterClosed().subscribe({
        next: (selectedBadges: BadgeSelectionDto[]) => {
          if (selectedBadges && selectedBadges.length > 0) {
            this.pilotBadgeService.assignMultipleBadges(selectedBadges).subscribe({
              next: (assignedBadges: PilotBadgeDto[]) => {
                  this.callApiWithFilters();
                  const pilotName = selectedBadges[0].pilotName;

                this.toasterService.success(
                  `Badge assignments for ${pilotName} have been successfully updated.`,
                );
              },
              error: () =>
                this.toasterService.error(
                  'An error occurred while assigning the badges to the pilot.',
                ),
            });
          } else {
            this.toasterService.info('No badges were selected for assignment.');
          }
        },
        error: error => console.error('Error in dialog:', error),
      });
    } catch (error) {
      this.loadingService.hideOverlay();
      this.toasterService.error(
        'An error occurred while fetching pilot details. Please try again.',
      );
    }
  }

  async openRevokeBadgeModal(row: PilotSourcingDto) {
    try {
      this.loadingService.showOverlay();
      const badgePilotData = await this.fetchPilotDetails(row.id);
      this.loadingService.hideOverlay();

      const dialogRef = this.dialog.open(RevokeBadgeModalComponent, {
        disableClose: true,
        width: '900px',
        panelClass: 'custom-modalbox',
        data: badgePilotData,
      });

      dialogRef.afterClosed().subscribe({
        next: (revokedBadgeIds: string[]) => {
          if (revokedBadgeIds && revokedBadgeIds.length > 0) {
            this.pilotBadgeService.revokeBadges(row.id, revokedBadgeIds).subscribe({
              next: () => {
                this.callApiWithFilters();
                this.toasterService.success(
                  `Successfully revoked ${revokedBadgeIds.length} badge(s) from pilot ${row.name}.`,
                );
              },
              error: error => {
                console.error('Error revoking badges:', error);
                this.toasterService.error(
                  'An error occurred while revoking badges. Please try again.',
                );
              },
            });
          }
        },
        error: error => console.error('Error in dialog:', error),
      });
    } catch (error) {
      this.loadingService.hideOverlay();
      this.toasterService.error(
        'An error occurred while fetching pilot details. Please try again.',
      );
    }
  }

  private async fetchPilotDetails(pilotId: string): Promise<BadgePilotData> {
    try {
      const pilotDetails = await firstValueFrom(this.pilotService.get(pilotId));

      const experienceLevel = await this.fetchExperienceLevel(
        pilotDetails.pilotExperienceLevelId,
        pilotId,
      );
      const availability = await this.fetchAvailability(pilotDetails.availabilityId, pilotId);

      return {
        pilotId: pilotId,
        pilotName: `${pilotDetails.firstName} ${pilotDetails.lastName}`,
        pilotExperience: experienceLevel,
        pilotStatus: pilotDetails.infoChecked ? 'Active' : 'Inactive',
        availability: availability,
      };
    } catch (error) {
      console.error('Error fetching pilot details:', error);
      throw new Error('Failed to fetch pilot details');
    }
  }

  private async fetchExperienceLevel(experienceLevelId: string, pilotId: string): Promise<string> {
    try {
      const expLevel = await firstValueFrom(
        this.pilotExperienceLevelService.get(experienceLevelId),
      );
      return expLevel.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }

  private async fetchAvailability(availabilityId: string | null, pilotId: string): Promise<string> {
    if (!availabilityId) {
      return '-';
    }

    try {
      const availabilityData = await firstValueFrom(this.availabilityService.get(availabilityId));
      return availabilityData.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }

  private updateContactOptions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'preferredContactStyle');
    if (condition) {
      condition.options = [
        { id: 'Email', description: 'Email' },
        { id: 'Text', description: 'Text' },
        { id: 'Phone Call', description: 'Phone Call' },
        { id: 'In-app Notifications', description: 'In-app Notifications' },
        { id: 'No Preference', description: 'No Preference' },
      ];
    }
  }

  getNameForSelectCheckbox() {
    return this.selectedPilots?.length > 0
      ? this.localizationService.instant('missionsService::DeselectAllMissionsTooltip')
      : this.localizationService.instant('missionsService::SelectAllMissionsTooltip');
  }

  getBadgeText(row: PilotSourcingDto): string {
    let badgeText = row.lastBadgeDescription || '';

    if (row.lastBadgeProgressionLevel !== undefined && row.lastBadgeProgressionLevel !== null) {
      const adjustedLevel = row.lastBadgeProgressionLevel + 1;
      badgeText = `(${adjustedLevel}) ${badgeText} - ${
        ProgressionLevel[row.lastBadgeProgressionLevel]
      }`;
    }

    return badgeText;
  }

  showRankingColumn(pilotsForSourcingData: PagedResultDto<PilotSourcingDto>) {
    if (pilotsForSourcingData.totalCount > 0) {
      if (pilotsForSourcingData.items[0].totalRanking != -1) {
        this.showRanking = true;
      }
    }
  }

  getShortMissionName(missionName: string, maxLength: number): string {
    if (missionName.length > maxLength) {
      return missionName.substring(0, maxLength - 3) + '...';
    }
    return missionName;
  }
}
