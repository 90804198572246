export enum MissionStatus {
  LogisticsCoordination = 'LCO',
  Cancelled = 'CL',
  PilotAssigned = 'PA',
  ReadyForProcessing = 'RFP',
  OnHold = 'OH',
  PilotOnSite = 'POS',
  DataUploading = 'DU',
  PilotLeftSite = 'PLS',
  CustomerRequest = 'CRQ',
  PilotCheckedIn = 'PCI',
  MediaDelivered = 'MD',
  AwaitingQAQCAssignment = 'AQAQC',
  InQAQC = 'IQAQC',
  DataProcessing = 'DP',
  AwaitingFlight = 'AF',
  AwaitingMissionCoordinatorAssignment = 'AMCA',
  ReadyForSourcing = 'RFS',
  PilotSourcing = 'PS',
  RequestRefly = 'RR',
  Dead = 'DEAD',
  ClientInvoiced = 'CI',
  UnderMCReview = 'UMCR',
  AirspacePendingPilotRequest = 'AIRPEN',
  AirspaceRequested = 'AIREQ',
  AirspaceApproved = 'AIRAPP',
  Shipping = 'SP',
}
