import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  DeliverablesDto,
  GetDeliverableInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { PagedResultDto } from '@abp/ng.core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { DeliverableAttributesComponent } from '../deliverable-attributes/deliverable-attributes.component';

import { OrderFormModel } from '../../components/orders/model/order-form.model';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { DeliverableAttribute } from '../deliverable-attributes-list/deliverable-attribute.interface';

@Component({
  selector: 'app-deliverable-list',
  templateUrl: './deliverables-list.component.html',
  styleUrls: ['./deliverables-list.component.scss'],
})
export class DeliverableListComponent implements OnInit, OnDestroy {
  kmlUploaded: OrderFormDeliverableFieldModel[] = [];
  selected: any;
  selectedList: any = [];
  filteredOptions: any[] = [];
  valuesFound: any[] = [];
  displayedColumns: string[] = ['name', 'standardPrice', 'actions'];
  filters = { isPaginated: true } as GetDeliverableInput;
  dataDeliverable: PagedResultDto<DeliverablesDto> = {
    items: [],
    totalCount: 0,
  };
  totalPageSize: number;
  pageSizeOptions = [10, 25, 50];
  itemsToAdd: number = 10;
  itemsDisplayed: number = 10;
  currentPage: number = 1;
  pageIndex: number = 0;
  kmlDataArray: KmlDataInfo[] = [];

  @Input() model: OrderFormModel;

  private subscriptions = new Subscription();

  incompleteConfiguration: boolean;
  missingRequiredConfiguration: boolean;

  constructor(private dialogService: MatDialog) { }

  ngOnInit() {
    this.updateFilteredOptions();
    // Copy Mission: Update the selected array with the selected products from the model
    this.selected = this.model.orderDetail.productsSelected;
    this.updateProductQuantityAndStatus();
  }

  updateFilteredOptions() {
    const startIndex = this.filteredOptions.length;
    const endIndex = startIndex + this.itemsDisplayed;
    const slicedProducts = this.model.productsbyIndustry.slice(startIndex, endIndex);

    // Find the selected products that are not in the current slice
    const selectedProductsOutsideSlice = this.model.orderDetail.productsSelected.filter(
      selectedProduct =>
        !slicedProducts.some(product => product.productId === selectedProduct.productId),
    );

    // Combine the sliced products and the selected products outside the slice
    this.filteredOptions = this.filteredOptions.concat(
      slicedProducts,
      selectedProductsOutsideSlice,
    );

    // Update the quantity and selected status of the products in the filteredOptions array
    this.updateProductQuantityAndStatus();
  }

  nextPage() {
    this.updateFilteredOptions();
  }

  updateProductQuantityAndStatus() {
    this.filteredOptions.forEach(product => {
      const selectedProduct = this.model.orderDetail.productsSelected.find(
        p => p.productId === product.productId,
      );
      if (selectedProduct) {
        product.selected = true;
        product.quantity = selectedProduct.quantity;
      }
    });
  }

  onSearch(searchTerm: string) {
    if (searchTerm) searchTerm = searchTerm.toLocaleLowerCase();

    this.valuesFound = [];
    this.filteredOptions = this.model.productsbyIndustry.filter(
      item =>
        item.productName.toLowerCase().includes(searchTerm) ||
        item.productDescription.toLowerCase().includes(searchTerm),
    );
    for (let dl of this.filteredOptions) {
      this.valuesFound.push(dl);
    }
  }

  quantityUpdated(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  selectionChange(event: any) {
    const productIdSelected = event.options[0]?.value?.productId;

    if (!productIdSelected) return;

    const productSelected = this.filteredOptions.find(x => x.productId === productIdSelected);

    productSelected.selected = event.options[0].selected;

    if (productSelected.selected) {
      productSelected.subTotal = productSelected.quantity * productSelected.standardPrice;
      productSelected.subTotal = Math.abs(Number(productSelected.subTotal.toFixed(2)));
      this.model.orderDetail.productsSelected.push(productSelected);
      this.editAttributes(productSelected, false);
    } else {
      this.unSelectProduct(productIdSelected);
    }

    this.model.buildDetails();

    this.validateConfigurations();
    this.validateRequiredConfigurations(productSelected);
  }

  updatePrice(data: OrderFormDeliverableModel) {
    const indexEdit = this.model.orderDetail.productsSelected.findIndex(
      item => item.packageId === data.packageId,
    );

    if (indexEdit !== -1) {
      const productUpdated = this.model.orderDetail.productsSelected[indexEdit];
      productUpdated.standardPrice = data.standardPrice;
      data.editingPrice = false;
      this.onQuantityChange(data);
    }
  }

  onQuantityChange(data: OrderFormDeliverableModel) {
    const productUpdated = this.model.orderDetail.productsSelected.find(
      item => item.productId === data.productId,
    );

    if (!productUpdated) return;

    productUpdated.subTotal = productUpdated.standardPrice * productUpdated.quantity;

    this.model.buildDetails();
    this.validateConfigurations();
    this.validateRequiredConfigurations(productUpdated);
  }

  onQuantityBlur(data: OrderFormDeliverableModel) {
    if (!data.quantity) {
      data.quantity = 1;
    }
  }

  editAttributes(
    deliverable: OrderFormDeliverableModel,
    isEdition: boolean,
    isAddAttribute: boolean = false,
  ) {
    let kmlDataInfo: KmlDataInfo[] = [];
    if (isAddAttribute == true && this.kmlDataArray.length > 0) {
      this.kmlDataArray.forEach(x => {
        if (x?.deliverableId == deliverable?.deliverableId) {
          kmlDataInfo.push(x);
        }
      });
    }

    let attributesData: DeliverableAttribute;
    const dialogData = {
      title: 'Add Deliverable Attributes',
      deliverableData: deliverable,
      readonly: false,
      isEdition: isEdition,
      kmlDataInfo: kmlDataInfo,
    };

    const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
      data: dialogData,
      disableClose: true,
      width: '65%',
    });
    const dialogSub = dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          deliverableAttribute: DeliverableAttribute;
          fieldUpload: OrderFormDeliverableFieldModel[];
          kmlFieldData: OrderFormDeliverableFieldModel[];
        }) => {
          if (result) {
            attributesData = result.deliverableAttribute;
            this.kmlUploaded = result.fieldUpload;

            let existingData = this.kmlDataArray.find(
              item => item.deliverableId === result.deliverableAttribute.deliverableId,
            );

            if (existingData) {
              existingData.kmlField = [...result.kmlFieldData, ...result.fieldUpload];
            } else {
              let data: KmlDataInfo = {
                deliverableId: result.deliverableAttribute.deliverableId,
                kmlField: [...result.kmlFieldData, ...result.fieldUpload],
              };
              this.kmlDataArray.push(data);
            }
          }

          if (!result) {
            this.validateRequiredConfigurations(deliverable);
            this.validateConfigurations();
            return;
          }

          let productUpdated = this.model.orderDetail.productsSelected.find(
            item =>
              item.deliverableId === attributesData.deliverableId &&
              item.productId === attributesData.productId,
          );

          productUpdated.sameConfiguration = attributesData.sameConfiguration;
          deliverable.fields = [...attributesData.fields];

          let detailedAttributes = attributesData.detailedAttributes.map(x => [...x]);

          deliverable.detailedAttributes = [...detailedAttributes];

          this.validateRequiredConfigurations(deliverable);
          this.validateConfigurations();
        },
      );
    this.subscriptions.add(dialogSub);
  }

  addAttributes(
    event: MouseEvent,
    deliverable: OrderFormDeliverableModel,
    selected: boolean,
  ): void {
    event.stopPropagation();
    event.preventDefault();
    let isAddAttribute = true;

    if (!deliverable.selected) return;

    this.editAttributes(deliverable, true, true);
    deliverable.incompleteConfiguration = false;
    this.incompleteConfiguration = !!this.model.productsbyIndustry.find(
      x => x.incompleteConfiguration,
    );
  }

  clearAttributes(d: OrderFormDeliverableModel) {
    d.fields.forEach((f: OrderFormDeliverableFieldModel) => {
      f.numericValue = 0;
      f.stringValue = '';
    });
  }

  unSelectProduct(productId: string) {
    const indexRemove = this.model.orderDetail.productsSelected.findIndex(
      item => item.productId === productId,
    );
    if (indexRemove !== -1) {
      this.model.orderDetail.productsSelected.splice(indexRemove, 1);
    }

    const productToUpdate = this.model.productsbyIndustry.find(
      item => item.productId === productId,
    );

    if (productToUpdate) {
      this.clearAttributes(productToUpdate);
    }

    const selectedIndex = this.selected.findIndex(
      selectedItem => selectedItem.productId === productId,
    );

    if (selectedIndex > 0) {
      this.selected.splice(selectedIndex, 1);
      this.selected = [...this.selected];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private validateConfigurations(): void {
    this.incompleteConfiguration = false;

    for (let deliverable of this.model.productsbyIndustry) {
      deliverable.incompleteConfiguration = false;
    }

    for (let deliverable of this.model.orderDetail.productsSelected) {
      deliverable.incompleteConfiguration = false;
    }

    for (let deliverable of this.model.orderDetail.productsSelected) {
      if (deliverable.sameConfiguration) continue;

      this.incompleteConfiguration =
        deliverable.quantity > deliverable.detailedAttributes?.length ||
        this.incompleteConfiguration;

      deliverable.incompleteConfiguration =
        deliverable.quantity > deliverable.detailedAttributes?.length;
    }
  }

  private validateRequiredConfigurations(deliverable: OrderFormDeliverableModel): void {
    if (deliverable.sameConfiguration) {
      let requiredAttributes = deliverable.fields?.filter(x => x.isRequired) || [];

      deliverable.requiredConfigurationIncomplete = !!requiredAttributes.find(
        x =>
          (x.stringValue == undefined || x.stringValue == '') &&
          x.numericValue == undefined &&
          x.boolValue == undefined,
      );
    } else {
      if (!deliverable.detailedAttributes) deliverable.detailedAttributes = [];

      let hasRequiredAttr = !!deliverable.fields?.find(x => x.isRequired);

      if (hasRequiredAttr && deliverable.quantity > deliverable.detailedAttributes.length) {
        deliverable.requiredConfigurationIncomplete = true;
        this.missingRequiredConfiguration = !!this.model.orderDetail.productsSelected.find(
          x => x.requiredConfigurationIncomplete,
        );
        return;
      }

      for (let listOfAttributes of deliverable.detailedAttributes) {
        let requiredAttributes = listOfAttributes?.filter(x => x.isRequired) || [];

        deliverable.requiredConfigurationIncomplete = !!requiredAttributes.find(
          x =>
            (x.stringValue == undefined || x.stringValue == '') &&
            x.numericValue == undefined &&
            x.boolValue == undefined,
        );

        if (deliverable.requiredConfigurationIncomplete) break;
      }
    }

    this.missingRequiredConfiguration = !!this.model.orderDetail.productsSelected.find(
      x => x.requiredConfigurationIncomplete,
    );
  }

  protected readonly JSON = JSON;
}

export interface KmlDataInfo {
  deliverableId: string;
  kmlField: OrderFormDeliverableFieldModel[];
}
