import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { finalize, switchMap } from 'rxjs';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import { FileDescriptorService, FileInfoDto } from '@volo/abp.ng.file-management/proxy';
import { downloadBlob } from '@abp/ng.core';
import { DeliverableAttribute } from '../deliverable-attributes-list/deliverable-attribute.interface';
import { AttributeTypesEnum } from '../../pilot-sourcing/models/attribute-types.enum';
import { NgForm } from '@angular/forms';
import { CaptureDeliverableAttributeValidation } from './capture-deliverable-attribute-validation.interface';
import { AttributeValidationDefinitionService } from '../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/attribute-validation-definition.service';
import { AttributeValidationService } from '../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/attribute-validation.service';
import type {
  AttributeValidationCreateDto,
  AttributeValidationDto,
} from '../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import { ToleranceTypeEnum } from '../../../../../missions-service/src/lib/proxy/missions-service/basics/tolerance-type.enum';
import { KmlDataInfo } from '../deliverable-list/deliverables-list.component';

@Component({
  selector: 'app-deliverable-attributes',
  templateUrl: './deliverable-attributes.component.html',
  styleUrls: ['./deliverable-attributes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliverableAttributesComponent implements OnInit {
  public readonlyMode: boolean;
  maxFileSizeFiles: number = 200 * 1024 * 1024;

  readonly DEFAULT_MAX_LENGTH = 100;

  filesInfo: FilesInfo[] = [];
  deliverableAttribute: DeliverableAttribute;
  fileUpdated: boolean;
  hasAttributesAutomation: boolean;
  selectedAttributeValidationDefinitionIds: string[] = [];
  attributeValidationDefinitions: CaptureDeliverableAttributeValidation[] = [];
  fieldUpload: OrderFormDeliverableFieldModel[] = [];
  kmlData: OrderFormDeliverableFieldModel[] = [];
  kmlDataCollection: OrderFormDeliverableFieldModel[][] = [];
  deliverableKmlData: DeliverableAttribute;
  isFourthStep: boolean = false;
  selectedFiles: File[] = [];
  disabled: boolean = false;
  formUpdate: boolean = false;

  public get attributeTypes(): typeof AttributeTypesEnum {
    return AttributeTypesEnum;
  }

  constructor(
    public dialogRef: MatDialogRef<DeliverableAttributesComponent>,
    public readonly fileDescriptorService: FileDescriptorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      deliverableData: OrderFormDeliverableModel;
      readonly: boolean;
      isEdition: boolean;
      fromMissionDetail?: boolean;
      fromMissionSummary?: boolean;
      kmlDataInfo: KmlDataInfo[];
    },
    public dialogService: MatDialog,
    public loadingService: LoadingOverlayService,
    public readonly attributeValidationDefinitionService: AttributeValidationDefinitionService,
    public readonly attributeValidationService: AttributeValidationService,
  ) {
    this.readonlyMode = data.readonly;
  }

  ngOnInit() {
    if (this.data.isEdition && this.data.fromMissionSummary) {
      this.dataFieldsFilter(this.data.deliverableData);
    }
    this.createInitialModel();
  }

  private dataFieldsFilter(data: OrderFormDeliverableModel) {
    if (data) {
      const uniqueValues = new Set<string>();

      const kmlDataFields = data.fields.filter(f => {
        if (f.typeCode.toLowerCase().includes('kml')) {
          if (!uniqueValues.has(f.stringValue)) {
            uniqueValues.add(f.stringValue);
            return true;
          }
          return false;
        }
        return true;
      });

      data.fields = kmlDataFields;
    }
  }

  private createInitialModel(): void {
    if (!this.data.deliverableData.fields) this.data.deliverableData.fields = [];
    if (!this.data?.kmlDataInfo) {
      this.data.kmlDataInfo = [];
    }
    let deliverableAttribute = <DeliverableAttribute>{
      deliverableId: this.data.deliverableData.deliverableId,
      productId: this.data.deliverableData.productId,
      deliverableName: this.data.deliverableData.deliverableName,
      quantity: this.data.deliverableData.quantity,
      sameConfiguration: this.data.isEdition ? this.data.deliverableData?.sameConfiguration : true,
      fields: this.data.deliverableData.fields.map(x => {
        let attr = { ...x };

        attr.fieldControlName = `ctrl-${attr.id}-${this.data.deliverableData.productId}`;

        if (this.data.isEdition) {
          attr.stringValue = x.stringValue;

          if (attr.typeCode == AttributeTypesEnum.Bool)
            attr.boolValue = attr.stringValue == true.toString();

          if (attr.typeCode == AttributeTypesEnum.Number)
            attr.numericValue = new Number(attr.stringValue);

          if (attr.typeCode == AttributeTypesEnum.File || attr.typeCode == AttributeTypesEnum.Kml) {
            attr.loadingFile = true;
            this.fileDescriptorService.get(attr.stringValue).subscribe({
              next: res => {
                attr.fileValue = res.name;
                attr.loadingFile = false;
              },
              error: () => {
                attr.loadingFile = false;
              },
            });
          }
          attr.numericValue = new Number(attr.stringValue);
        } else {
          if (attr.defaultValue) {
            if (attr.typeCode == AttributeTypesEnum.Bool)
              attr.boolValue = attr.defaultValue == true.toString();
            else if (attr.typeCode == AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.defaultValue);
            else attr.stringValue = x.defaultValue;
          }
        }

        return attr;
      }),
    };

    deliverableAttribute.detailedAttributes = [];

    for (let i = 0; i < deliverableAttribute.quantity; i++) {
      let fieldsCopy = deliverableAttribute.fields;
      const withoutKML = fieldsCopy.filter(field => field.typeCode !== 'KML');
      const kmlField = fieldsCopy.filter(field => field.typeCode === 'KML');
      deliverableAttribute.fields = [...withoutKML, ...kmlField];

      if (this.data.isEdition) {
        if (
          this.data.deliverableData.detailedAttributes &&
          this.data.deliverableData.detailedAttributes.length > i
        ) {
          let fields = [...this.data.deliverableData.detailedAttributes[i]];

          let fieldsCopy = fields.map(x => x);

          deliverableAttribute.detailedAttributes.push(fieldsCopy);
        } else {
          let newArray = deliverableAttribute.fields.map(x => {
            let attr = { ...x };
            attr.fieldControlName = `ctrl-${i}-${x.id}-${this.data.deliverableData.productId}`;
            attr.stringValue = '';
            attr.loadingFile = false;

            if (attr.defaultValue) {
              if (attr.typeCode == AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue == true.toString();
              else if (attr.typeCode == AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else attr.stringValue = x.defaultValue;
            }

            return attr;
          });

          deliverableAttribute.detailedAttributes.push(newArray);
        }
      } else {
        let newArray = deliverableAttribute.fields.map(x => {
          let attr = { ...x };
          attr.fieldControlName = `ctrl-${i}-${x.id}-${this.data.deliverableData.productId}`;
          attr.stringValue = '';

          if (attr.defaultValue) {
            if (attr.typeCode == AttributeTypesEnum.Bool)
              attr.boolValue = attr.defaultValue == true.toString();
            else if (attr.typeCode == AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.defaultValue);
            else attr.stringValue = x.defaultValue;
          }
          return attr;
        });

        deliverableAttribute.detailedAttributes.push(newArray);
      }
    }

    this.deliverableAttribute = deliverableAttribute;

    if (this.data.isEdition) {
      this.deliverableKmlData = { ...this.deliverableAttribute };

      let kmlDataInfo = this.deliverableKmlData.fields.filter(
        x => x.typeCode.toLowerCase() == 'kml',
      );
      if (this.data?.kmlDataInfo.length > 0) {
        this.data?.kmlDataInfo.forEach(x => {
          this.kmlData = x?.kmlField;
        });
      } else {
        this.kmlData = kmlDataInfo;
      }

      if (kmlDataInfo.length > 1) {
        this.deliverableKmlData.fields = this.deliverableKmlData.fields.filter(
          x => x.typeCode.toLowerCase() !== 'kml',
        );
        this.deliverableKmlData.fields.push(kmlDataInfo[0]);
      }
    }

    if (this.data.fromMissionSummary) {
      this.disabled = true;
      if (
        !this.data.deliverableData.sameConfiguration &&
        this.data.deliverableData.sameConfiguration != undefined
      ) {
        let quantity = this.data.deliverableData.quantity;
        for (let i = 0; i < quantity; i++) {
          let controlName = `ctrl-${i}`;
          let dataWithSameCode = this.data?.deliverableData?.fields.filter(
            x =>
              x.fieldControlName && x.fieldControlName.includes(controlName) && x.typeCode == 'KML',
          );
          if (dataWithSameCode) {
            this.kmlDataCollection.push(dataWithSameCode);
          }
        }
      } else {
        this.data.deliverableData.sameConfiguration = true;
      }
    }
  }
  public isControlInvalid(form: NgForm, controlName: string): boolean {
    return form?.controls[controlName]?.invalid ?? false;
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverable(): void {
    let boolAttribute = this.deliverableAttribute.fields.filter(
      x => x.typeCode == AttributeTypesEnum.Bool,
    );
    boolAttribute.forEach(attr => {
      attr.stringValue = attr.boolValue.toString();
    });

    let numericAttributes = this.deliverableAttribute.fields.filter(
      x => x.typeCode == AttributeTypesEnum.Number,
    );
    numericAttributes.forEach(attr => {
      if (attr.numericValue !== null && attr.numericValue !== undefined)
        attr.stringValue = attr.numericValue.toString();
      else attr.stringValue = '0';
    });

    this.deliverableAttribute.detailedAttributes.forEach(fields => {
      let boolAttribute = fields.filter(x => x.typeCode == AttributeTypesEnum.Bool);
      boolAttribute.forEach(attr => {
        attr.stringValue = attr.boolValue.toString();
      });

      let numericAttributes = fields.filter(x => x.typeCode == AttributeTypesEnum.Number);
      numericAttributes.forEach(attr => {
        if (attr.numericValue !== null && attr.numericValue !== undefined)
          attr.stringValue = attr.numericValue.toString();
        else attr.stringValue = '0';
      });
    });

    this.createAttributeValidations();

    this.dialogRef.close({
      deliverableAttribute: this.deliverableAttribute,
      fieldUpload: this.fieldUpload,
      kmlFieldData: this.kmlData,
    });
  }

  private createAttributeValidations() {
    if (
      this.selectedAttributeValidationDefinitionIds &&
      this.selectedAttributeValidationDefinitionIds.length > 0
    ) {
      const selectedAttributeMap = new Map<string, string>();
      this.attributeValidationDefinitions
        .filter(def =>
          this.selectedAttributeValidationDefinitionIds.includes(
            def.attributeValidationDefinitionId,
          ),
        )
        .forEach(def =>
          selectedAttributeMap.set(def.attributeId, def.attributeValidationDefinitionId),
        );

      this.deliverableAttribute.fields
        .filter(field => selectedAttributeMap.has(field.attributeId))
        .forEach(field => {
          const attributeId = field.attributeId;
          const captureDeliverableId = field.captureDeliverableId;
          const attributeValidationDefinitionId = selectedAttributeMap.get(attributeId);

          if (attributeValidationDefinitionId) {
            const createDto: AttributeValidationCreateDto = {
              attributeValidationDefinitionId: attributeValidationDefinitionId,
              captureDeliverableAttributeId: captureDeliverableId,
            };

            this.attributeValidationService.create(createDto).subscribe({
              next: response => console.log('Attribute Validation created with ID: ', response.id),
              error: error => console.error('Error creating attribute validation: ', error),
            });
          }
        });
    }
  }

  onFileSelected(files: File[], field: OrderFormDeliverableFieldModel, index: number) {
    this.fileUpdated = true;
    if (field.typeCode == 'KML') {
      let newFileInfo: FilesInfo = {
        index: index,
        selectedFiles: files,
      };
      this.filesInfo.push(newFileInfo);
    }
    this.selectedFiles = files;
    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles,
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      if (field.typeCode.toLowerCase() == 'KML'.toLocaleLowerCase()) {
        this.uploadKML(files, field);
      } else {
        this.uploadFile(files[0], field);
      }
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  uploadKML(files: File[], field: OrderFormDeliverableFieldModel): void {
    if (!files) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    field.loadingFile = true;
    const formData = new FormData();

    files.forEach(file => {
      formData.append('fileList', file);
    });

    this.fileDescriptorService
      .uploadAttachMentsFolder(
        'Deliverables_Attributes',
        'Attribute_' + field.fieldControlName,
        formData,
      )
      .subscribe(
        (res: FileInfoDto[]) => {
          res.forEach((fileInfo: FileInfoDto) => {
            if (fileInfo?.id && fileInfo?.fileAttachmentUrl) {
              const newFieldCopy = { ...field };
              newFieldCopy.stringValue = fileInfo.id;
              newFieldCopy.fileValue = fileInfo.name;
              newFieldCopy.loadingFile = false;
              const existingFile = this.fieldUpload?.find(
                x =>
                  x.stringValue == newFieldCopy.stringValue &&
                  x.fileValue == newFieldCopy.fileValue,
              );
              if (!existingFile) {
                this.fieldUpload.push(newFieldCopy);
              }
            }
          });
          field.loadingFile = false;
        },
        error => {
          field.loadingFile = false;
        },
      );
  }

  uploadFile(file: File, field: OrderFormDeliverableFieldModel): void {
    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    field.loadingFile = true;
    const formData = new FormData();
    formData.append('fileList', file);

    this.fileDescriptorService
      .uploadAttachMentsFolder(
        'Deliverables_Attributes',
        'Attribute_' + field.fieldControlName,
        formData,
      )
      .subscribe(
        (res: FileInfoDto[]) => {
          if (res[0]?.id && res[0]?.fileAttachmentUrl) {
            field.stringValue = res[0].id;
            field.fileValue = res[0].name;
          }

          field.loadingFile = false;
        },
        error => {
          field.loadingFile = false;
        },
      );
  }

  downloadDocument(field: OrderFormDeliverableFieldModel) {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(field.stringValue)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(field.stringValue, token)),
        finalize(() => this.loadingService.hideOverlay()),
      )
      .subscribe(result => {
        downloadBlob(result, field.fileValue);
      });
  }

  onFileRemoved(field: OrderFormDeliverableFieldModel): void {
    if (field) {
      field.stringValue = '';
      field.fileValue = '';
      this.formUpdate = true;
    }
  }

  onFileKmlRemoved(file: any, field: OrderFormDeliverableFieldModel): void {
    this.fileUpdated = true;
    const newKmlList = this.kmlData?.filter(x => x.fileValue !== file.name);
    const newFileUpload = this.fieldUpload?.filter(x => x.fileValue !== file.name);

    this.fieldUpload = newFileUpload;
    this.kmlData = newKmlList;
    this.deliverableAttribute?.fields.find(x => {
      if (x.fileValue == file.name) {
        x.stringValue = '';
        x.fileValue = '';
      }
    });
  }

  public thereAreMissingFiles(): boolean {
    let missingValues = false;

    if (this.deliverableAttribute.sameConfiguration) {
      missingValues = !!this.deliverableAttribute.fields.find(
        x =>
          (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
          x.isRequired &&
          !x.stringValue,
      );
    } else {
      for (let fields of this.deliverableAttribute.detailedAttributes) {
        missingValues = !!fields.find(
          x =>
            (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
            x.isRequired &&
            !x.stringValue,
        );

        if (missingValues) break;
      }
    }

    return missingValues;
  }

  onAttributeValidationChange(attributeValidationDefinitionId: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedAttributeValidationDefinitionIds.push(attributeValidationDefinitionId);
    } else {
      const index = this.selectedAttributeValidationDefinitionIds.indexOf(
        attributeValidationDefinitionId,
      );
      if (index > -1) {
        this.selectedAttributeValidationDefinitionIds.splice(index, 1);
      }
    }
  }

  getToleranceTypeLabel(value: ToleranceTypeEnum): string {
    switch (value) {
      case ToleranceTypeEnum.Value:
        return 'Value';
      case ToleranceTypeEnum.Percentage:
        return 'Percentage';
      case ToleranceTypeEnum.Custom:
        return 'Custom';
      default:
        return 'Unknown';
    }
  }

  onCheckboxChange(isChecked: boolean) {
    this.deliverableAttribute.sameConfiguration = isChecked;
  }
}

export interface FilesInfo {
  index: number;
  selectedFiles: File[];
}

export interface FilesInfoPkg extends FilesInfo {
  deliverableIndex: number;
}
