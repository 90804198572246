<h5 mat-dialog-title class="flg-modal flg-modal__title">Edit Order Details</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="formMissionDetails" skipValidation>
        <mat-form-field class="w-48 me-2">
          <mat-label>{{ 'missionsService::SLAProfile' | abpLocalization }}</mat-label>
          <mat-select formControlName="priorityId" required (ngModelChange)="changeSla($event)">
            <mat-option *ngFor="let dp of SLAOptions.items" [value]="dp.id" title="{{ dp.name }}">{{
              dp.name
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formMissionDetails.get('priorityId').invalid">{{
            'missionsService::PleaseEnterValue' | abpLocalization
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-50">
          <mat-label>{{ 'missionsService::OrderIndustry' | abpLocalization }}</mat-label>
          <mat-select formControlName="industryId" required>
            <mat-option
              *ngFor="let di of dataIndustries.items"
              [value]="di.id"
              title="{{ di.description }}"
              >{{ di.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="formMissionDetails.get('industryId').invalid">{{
            'missionsService::PleaseEnterValue' | abpLocalization
          }}</mat-error>
        </mat-form-field>

        <app-order-custom-sla
          *ngIf="slaSelected?.isCustom"
          [slas]="slaGenerated"
        ></app-order-custom-sla>

        <mat-form-field class="w-100">
          <mat-label>Mission Name</mat-label>
          <input matInput placeholder="Mission Name" formControlName="missionName" required />
          <mat-error *ngIf="formMissionDetails.get('missionName').hasError('maxlength')">
            Please enter a shorter value (less than 385 characters)
          </mat-error>
        </mat-form-field>

        <flg-rich-text-input
          [control]="formMissionDetails.get('description')"
          placeholder=""
          label="{{ 'missionsService::MissionSummary' | abpLocalization }}"
          class="mb-3"
        ></flg-rich-text-input>

        <mat-form-field class="w-100">
          <mat-label>{{
            'missionsService::CustomerRequestedCaptureDate' | abpLocalization
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="captureDate"
            placeholder="MM/DD/YYYY"
          />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>
            {{ 'missionsService::CustomerRequestedCaptureTime' | abpLocalization }}
          </mat-label>

          <input
            matInput
            type="time"
            class="time-input"
            placeholder="00:00 PM"
            formControlName="captureTime"
          />
        </mat-form-field>
        <mat-form-field class="w-100" subscriptSizing="dynamic">
          <mat-label>
            {{ 'missionsService::CaptureDateMustBe' | abpLocalization }}
          </mat-label>
          <mat-select name="captureDateMustId" formControlName="captureDateMustId">
            <mat-option *ngFor="let data of dataCaptureMust" [value]="data.value">
              {{ data.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="my-1">
          <mat-checkbox formControlName="manualAirspaceWaiverRequired">
            Airspace Waiver Required
          </mat-checkbox>
        </div>

        <mat-form-field class="w-100">
          <mat-label> {{ 'missionsService::AssetOrder' | abpLocalization }}</mat-label>
          <input matInput placeholder="Asset / Order Number" formControlName="missionAssetOrder" />
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      {{ 'missionsService::Cancel' | abpLocalization }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveMissionDetails()"
      [disabled]="!formMissionDetails.valid"
      [class.disabled-button]="!formMissionDetails.valid"
    >
      {{ 'missionsService::Save' | abpLocalization }}
    </button>
  </div>
</mat-dialog-actions>
