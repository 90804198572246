import { FormGroup, Validators } from '@angular/forms';
import { forkJoin, map, Subscription } from 'rxjs';
import { addMonths } from 'date-fns';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';

import {
  AirSpaceClassificationsDto,
  ContactTypesDto,
  CountriesDto,
  GetAirSpaceClassificationInput,
  GetContactTypeInput,
  GetCountryInput,
  GetStateInput,
  SLADto,
  StatesDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups/models';
import { OrderFormSiteModel } from '../../model/order-form-site.model';

import {
  AirSpaceClassificationsService,
  ContactTypesService,
  CountriesService,
  StatesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  ContactsService,
  LocationsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';

import { OrderRequestMissionDTO } from '../../dto/order-request-mission.dto';
import {
  AddressesCreateDto,
  CapturesCreateDto,
  ContactsCreateDto,
  ContactsDto,
  ContactsUpdateDto,
  CustomerLocationDto,
  DeliverableAspectCreateDto,
  GetCustomerLocationsInput,
  LocationsCreateDto,
  LocationWithAddressDto,
  MissionsCreateDto,
  MissionSLADto,
  PortfolioMissionsCreateDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../../../../missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { FileDescriptorService, FileInfoDto } from 'projects/file-management/proxy/src/lib';
import { OrderFormSiteFileModel } from '../../model/order-form-site-file.model';
import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';

import { MatDialog } from '@angular/material/dialog';
import { RecurrenceComponent } from '../../recurrence/recurrence.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RecurrenceForm } from '../../recurrence/model/recurrence-form.model';
import { OrderRequestMissionProjectDTO } from '../../dto/order-request-mission-project.dto';
import { OrderRequestMissionFileDTO } from '../../dto/order-request-mission-file.dto';
import { MapVisibilityService } from '../../services/map-visibility.service';
import { MapReloadService } from '../../services/map-reload.service';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { CustomerLocationService } from '../../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics/customer-location.service';
import {
  CustomerContactsCreateDto,
  CustomerContactsDto,
  enumState,
} from '../../../../../../../customers-service/src/lib/proxy/customers-service/basics';
import { OrderFormContactModel } from '../../model/order-form-contact.model';
import { OrderContactComponent } from '../../order-contact/order-contact.component';
import { ConfirmDialogComponent } from '../../../common/confirm-dialog/confirm.dialog.component';
import { CustomerContactsService } from '../../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics';
import { Location, LocationOrigin, LocationSource } from '@flyguys/forms';
import { FlyguysLocationFormComponent } from '@flyguys/forms';
import { ToasterService } from '@abp/ng.theme.shared';
import { getPendingControls } from '@flyguys/components';
import { convert } from 'geo-coordinates-parser';
import { PortfolioMissionDTO } from '../../dto/portfolio-mission.dto';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { format } from 'date-fns';
const requiredControlsNames = {
  missionName: 'Mission Name',
  missionSummary: 'Mission Summary',
  captureDate: 'Capture Date',
  locSiteName: 'Site Name',
  locAirSpaceClasfId: 'Airspace Classification',
  lat: 'Latitude',
  lng: 'Longitude',
  projectName: 'Project Name',
  address: 'Address',
  countryId: 'Country',
  stateId: 'State',
  city: 'City',
  zip: 'Zip Code',
  deliveryDate: 'Deliverable Due Date',
  externalLink: 'External Link URL',
  externalLinkName: 'External Link Name',
};

@Component({
  selector: 'app-order-site',
  templateUrl: './order-site.component.html',
  styleUrls: ['./order-site.component.scss'],
})
export class OrderSiteComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @ViewChild(FlyguysLocationFormComponent) locationForm: FlyguysLocationFormComponent;
  @Output() removesite = new EventEmitter<OrderFormSiteModel>();
  @Output() contactsUpdatedChange = new EventEmitter();
  @Input() customerId: string;
  @Input() slaId: string;
  @Input() contactsUpdated: boolean;
  @Input() site: OrderFormSiteModel;
  @Input() hideHeader: boolean;
  @Input() sLASelected: SLADto;
  @Output() slaUpdatedChange = new EventEmitter<MissionSLADto[]>();
  formOrderSite: FormGroup;
  // Stores the RecurrenceForm raw output to preserve state between uses
  previousRecurrence: any;
  // Prevents selection of dates before today
  minDate: Date;
  private readonly defaultRecurrence = recurrenceEnum.NoRepeat;
  maxFileSizeFiles: number = 200 * 1024 * 1024;

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: recurrenceEnumDisplayNames[recurrenceEnum[key]],
    }));
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  dataAirspace: PagedResultDto<AirSpaceClassificationsDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterCountries = {} as GetCountryInput;
  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  contactTypes: PagedResultDto<ContactTypesDto> = {
    items: [],
    totalCount: 0,
  };

  mapLoaded: boolean = false;

  private subscriptions: Subscription[] = [];

  public mapMarkers: FlyguysMapMarker[] = [];

  selectedKML: OrderFormSiteFileModel;
  selectedFileNames: String[] = [];

  locations: LocationWithAddressDto[] = [];
  filteredLocations: LocationWithAddressDto[] = [];
  selectedLocation: LocationWithAddressDto;

  showDeletebutton: boolean;

  temporalFolderId: string = new Date().getTime().toString();
  notSelectedItemAirspace: AirSpaceClassificationsDto = {
    id: this.emptyGuid,
    description: '[Not Selected]',
    state: enumState.Enabled,
    migrated: false,
  };

  showUploadLink = false;

  anyTimeBeforeValue = CaptureMustBeEnum.AnytimeBefore.toString();

  public getRecurrenceEnum(): typeof recurrenceEnum {
    return recurrenceEnum;
  }

  constructor(
    public readonly list: ListService,
    public readonly airspaceService: AirSpaceClassificationsService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly contactsService: ContactsService,
    private datePipe: DatePipe,
    public readonly fileDescriptorService: FileDescriptorService,
    public loadingService: LoadingOverlayService,
    private dialog: MatDialog,
    public readonly contactTypesService: ContactTypesService,
    public readonly mapVisibilityService: MapVisibilityService,
    private mapreloadService: MapReloadService,
    public dialogService: MatDialog,
    public locationService: LocationsService,
    public customerLocationService: CustomerLocationService,
    public customerContactsService: CustomerContactsService,
    private toaster: ToasterService,
    private readonly slaService: SlaService,
  ) {
    this.minDate = new Date();
    this.formOrderSite = FormHelpers.buildValidatorSite();
  }

  ngOnInit() {
    this.slaService.recalculateSLASubject.subscribe(slaId => {
      this.slaId = slaId;
      this.calculationSLA(true);
    });
    this.getAirSpaceClasifications();
    this.getCountries();
    this.getStates();
    this.getContactTypes();
    this.showDeletebutton = Number(this.site.number) > 1;

    this.populateAddressForm();

    if (this.site.loclatitude || this.site.captureDate) {
      this.performActionsAfterComponentIsVisible();
    }

    this.formOrderSite
      .get('location.address')
      .valueChanges.subscribe({ next: v => (this.site.locAddress = v) });

    this.formOrderSite
      .get('location.lat')
      .valueChanges.subscribe({ next: v => (this.site.loclatitude = v) });

    this.formOrderSite
      .get('location.lng')
      .valueChanges.subscribe({ next: v => (this.site.loclongitude = v) });

    this.formOrderSite
      .get('location.zip')
      .valueChanges.subscribe({ next: v => (this.site.locZipCode = v) });

    this.formOrderSite
      .get('manualAirspaceWaiverRequired')
      .valueChanges.subscribe({ next: v => (this.site.manualAirspaceWaiverRequired = v) });

    this.formOrderSite.get('UploadDataLocation').valueChanges.subscribe({
      next: v => {
        this.site.uploadDataLocation = v;

        const isExternalPortal = v === 'External Portal';
        this.formOrderSite
          .get('externalLinkName')
          .setValidators(isExternalPortal ? [Validators.required] : []);
        this.formOrderSite
          .get('externalLink')
          .setValidators(
            isExternalPortal ? [Validators.required, Validators.pattern('https?://.+')] : [],
          );
        this.showUploadLink = isExternalPortal;

        ['externalLink', 'externalLinkName'].forEach(control =>
          this.formOrderSite.get(control).updateValueAndValidity(),
        );
      },
    });

    this.formOrderSite
      .get('location.city')
      .valueChanges.subscribe({ next: v => (this.site.locCity = v) });

    this.formOrderSite
      .get('locAirSpaceClasfId')
      .valueChanges.subscribe({ next: v => (this.site.locAirSpaceClasfId = v) });

    this.formOrderSite.get('locAirSpaceClasfId').setValue(this.notSelectedItemAirspace.id);

    this.formOrderSite
      .get('locAirSpaceClasfDescription')
      ?.setValue(this.notSelectedItemAirspace.description);

    this.formOrderSite.get('location.countryId').valueChanges.subscribe({
      next: id => {
        this.site.locCountryId = id;
        this.site.locCountryDescription =
          this.dataCountries.items?.find(c => c.id === id)?.description || '';
      },
    });

    this.formOrderSite.get('location.stateId').valueChanges.subscribe({
      next: id => {
        this.site.locStateId = id;
        this.site.locStateDescription =
          this.dataStates.items?.find(c => c.id === id)?.description || '';
      },
    });

    // We need this when we're adding a new site and we already have a customer id.
    if (this.customerId) {
      this.getLocationsWithAddress();
      this.getContacts();
    }

    this.slaService.customSlaChange.subscribe(newSla => {
      this.recalculateSLA(newSla);
    });
  }

  // We need this for the first site. On page load we still don't have a customerId
  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerId && changes.customerId.currentValue) {
      this.getLocationsWithAddress();
      this.getContacts();
    }

    if (changes.contactsUpdated) {
      this.getContacts();
    }

    if (changes.site && this.map) {
      this.setupMapSubscriptions();
    }

    if (changes.slaId) {
      this.calculateCapture();
    }

    if (changes.sLASelected && this.sLASelected?.isCustom) {
      this.formOrderSite.get('frequencyId').disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    if (this.map) {
      this.setupMapSubscriptions();
    }
  }

  private setupMapSubscriptions() {
    this.formOrderSite.get('location.lat').valueChanges.subscribe(sLat => {
      const lat = sLat;
      const lng = this.formOrderSite.get('location.lng').value;
      this.reactToLatLngChange({ lat, lng, id: 1 });
    });
    this.formOrderSite.get('location.lng').valueChanges.subscribe(sLng => {
      const lng = sLng;
      const lat = this.formOrderSite.get('location.lat').value;
      this.reactToLatLngChange({ lat, lng, id: 1 });
    });
  }

  /**
   * Handles the Map Component load event
   * @param status boolean
   */
  handleMapLoad(status: boolean) {
    this.mapLoaded = status;
    this.setupMapSubscriptions();
    this.mapVisibilityService.setMapInstance(this.map);
  }

  updateProjectNameValidation() {
    const projectNameControl = this.formOrderSite.get('projectName');
    if (this.getEnumFromById(this.site.frequencyId, recurrenceEnum) !== recurrenceEnum.NoRepeat) {
      projectNameControl.setValidators([Validators.required]);
    } else {
      projectNameControl.clearValidators();
    }
    projectNameControl.updateValueAndValidity();
  }

  getAirSpaceClasifications() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetAirSpaceClassificationInput;

    this.site.locAirSpaceClasfId = this.notSelectedItemAirspace.id;
    this.site.locAirSpaceClasfDescription = this.notSelectedItemAirspace.description;
    const subsairspace = this.airspaceService.getList(request).subscribe(
      (data: PagedResultDto<AirSpaceClassificationsDto>) => {
        if (data?.totalCount != 0) {
          this.dataAirspace = data;
          this.setDescriptionFromId(
            this.notSelectedItemAirspace.id,
            this.dataAirspace.items,
            'locAirSpaceClasfDescription',
            'description',
          );
        }
      },
      error => {
        console.log('Error on getting Air Space classfications: ' + JSON.stringify(error));
      },
    );
    this.subscriptions.push(subsairspace);
  }

  setDescriptionFromId(
    id: string,
    items: any[],
    fieldtoSet: string,
    fieldName: string,
    isEnum: Boolean = false,
  ) {
    let item = {};
    if (isEnum) {
      item = items.find(x => x.value === Number(id?.toString()));
    } else {
      item = items.find(x => x.id === id?.toString());
    }
    this.site[fieldtoSet] = item?.[fieldName];
    if (this.site['captureDateMustDescription'] != null && this.site['captureDate'] != null) {
      this.calculationSLA();
    }

    if (id == this.anyTimeBeforeValue) {
      this.site.frequencyId = recurrenceEnum.NoRepeat.toString();
      this.formOrderSite.controls.frequencyId.setValue(recurrenceEnum.NoRepeat.toString());
      this.site.recurrenceDescription = null;
      this.previousRecurrence = null;
    }
  }

  frequencyChange(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    this.setDescriptionFromId(id, items, fieldtoSet, fieldName, true);
    this.updateProjectNameValidation();
  }

  getCountries() {
    const subscountries = this.countriesService.getList(this.filterCountries).subscribe(
      (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount != 0) {
          this.dataCountries = data;
        }
      },
      error => {
        console.log('Error on getting Countries: ' + JSON.stringify(error));
      },
    );
    this.subscriptions.push(subscountries);
  }

  getStates() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;

    const subsstates = this.statesService.getList(request).subscribe({
      next: (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount != 0) {
          this.dataStates = data;
        }
      },
      error: error => {
        console.log('Error on getting States : ' + JSON.stringify(error));
      },
    });
    this.subscriptions.push(subsstates);
  }

  private generateFormData(file: File | null) {
    if (!file) {
      return null;
    }
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  onkmlSelected(files: File[]) {
    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles,
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      this.uploadKmlFile(files[0]);
    }
  }

  onkmlRemoved(file: File) {
    this.site.kmlFiles.forEach((kmlModel: OrderFormSiteFileModel) => {
      if (kmlModel.name.trim() == file.name.trim()) {
        this.removeKmlFile(kmlModel);
      }
    });
  }

  async onfilesSelected(files: File[] | null) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > this.maxFileSizeFiles) {
        this.dialogService.open(MessageSucessComponent, {
          data: {
            title: 'Maximum File exceeded',
            message: `File ${files[i].name} exceeds the maximum allowed size (${this.formatFileSize(
              this.maxFileSizeFiles,
            )}).`,
          },
          disableClose: true,
          width: '475px',
        });
        const indexFile = files.indexOf(files[i]);
        if (indexFile >= 0) {
          files.splice(indexFile, 1);
        }
      }
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= this.maxFileSizeFiles) {
        formData.append('fileList', files[i]);
      }
    }

    this.site.filesAttachments = [];
    if (formData.has('fileList')) {
      // Hide and show to prevent multiple overlays when copying a mission
      this.loadingService.hideOverlay();
      this.loadingService.showOverlay();

      const subFiles = this.fileDescriptorService
        .uploadAttachMentsFolder(
          'Order_Missions',
          'Mission_Files_' + this.temporalFolderId,
          formData,
        )
        .subscribe(
          (data: FileInfoDto[]) => {
            if (data?.length != 0) {
              this.site.missionFolderId = data[0]?.folderId;
              this.site.orderFolderId = data[0]?.parentFolderId;
              for (let i = 0; i < data.length; i++) {
                if (data[i].fileAttachmentUrl.trim() != '') {
                  const newFileModel: OrderFormSiteFileModel = {
                    fileId: data[i].id,
                    path: data[i].fileAttachmentUrl,
                    name: data[i].name,
                  };
                  this.site.filesAttachments.push(newFileModel);
                }
              }
            }
            this.loadingService.hideOverlay();
          },
          error => {
            this.loadingService.hideOverlay();
            console.log('Error on Upload Files: ' + JSON.stringify(error));
          },
        );
      this.subscriptions.push(subFiles);
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  onfileRemoved(file: File) {
    // Check if the file exists in the filesAttachments array
    const fileModel = this.site.filesAttachments.find(f => f.name.trim() === file.name.trim());

    if (fileModel && fileModel.fileId) {
      // File exists and has a fileId, so it's an existing file
      const index = this.site.filesAttachments.indexOf(fileModel);
      if (index >= 0) {
        this.site.filesAttachments.splice(index, 1);
      }
    } else {
      // File doesn't have a fileId, so it's a newly added file
      const index = this.site.filesAttachments.findIndex(f => f.name.trim() === file.name.trim());
      if (index >= 0) {
        this.site.filesAttachments.splice(index, 1);
      }
    }
  }

  uploadKmlFile(file: File): void {
    if (file) {
      // Hide and show to prevent multiple overlays when copying a mission
      this.loadingService.hideOverlay();
      this.loadingService.showOverlay();
      const formData = new FormData();
      formData.append('fileList', file);
      const request = this.fileDescriptorService.uploadAttachMentsFolder(
        'Order_Missions',
        'Mission_Files_' + this.temporalFolderId,
        formData,
      );

      this.subscriptions.push(
        request.subscribe(
          (res: FileInfoDto[]) => {
            if (res[0]?.id) {
              if (res[0]?.fileAttachmentUrl.trim() != '') {
                this.site.kmlFiles = [];
                this.site.missionFolderId = res[0]?.folderId;
                this.site.orderFolderId = res[0]?.parentFolderId;
                const newKmlModel: OrderFormSiteFileModel = {
                  fileId: res[0]?.id,
                  path: res[0]?.fileAttachmentUrl,
                  name: res[0]?.name,
                };
                this.site.kmlFiles.push(newKmlModel);
                if (this.map) {
                  this.renderKML();
                }
                this.loadingService.hideOverlay();
              }
            }
          },
          error => {
            this.loadingService.hideOverlay();
            console.error('Error Uploading file', error);
          },
        ),
      );
    } else {
      console.error('Error Uploading file.No file selected');
    }
  }

  renderKML() {
    const [kml] = this.site.kmlFiles;
    this.selectedKML = kml;
    this.map.loadKMLLayer(this.selectedKML.path);
    this.triggerReload();
  }

  removeKmlFile(file: OrderFormSiteFileModel): void {
    let latitude: string;
    let longitude: string;
    const request = this.fileDescriptorService.delete(file.fileId);
    this.subscriptions.push(
      request.subscribe(
        () => {
          this.map.removeKMLLayer();
          if (!this.site.locAddress) {
            latitude = '4.626137636370072';
            longitude = '-74.06811384764599';
          } else {
            latitude = this.site.loclatitude;
            longitude = this.site.loclongitude;
          }
          this.map.kmlLayerToggle;
          this.map.clearMarkers();
          this.map.options = {
            center: { lat: Number(latitude), lng: Number(longitude) },
          };
          this.map.centerMap();
          this.site.kmlFiles = [];
          this.triggerReload();
        },
        error => {
          console.error('Error Removing kml file', error);
        },
      ),
    );
  }

  removeFile(file: OrderFormSiteFileModel): void {
    this.fileDescriptorService.delete(file.fileId);
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {
    this.site.loclatitude = marker.lat.toString();
    this.site.loclongitude = marker.lng.toString();
  }

  buildOrderRequestMissionDto(
    customerId: string,
    customerName: string,
    priorityId: string,
    priorityName: string,
  ): OrderRequestMissionDTO {
    return {
      mission: this.buildMissionDto(customerId, customerName, priorityId, priorityName),
      project: this.buildOrderRequestProjectDto(),
      capture: this.buildCaptureDto(),
      locationId: this.site.locLocationId,
      location: this.buildLocationDto(),
      address: this.buildAddressDto(),
      deliverableAspect: this.buildDeliveryAspectsDto(),
      locationContact: this.buildLocationContactDto(),
      filesAttachments: this.buildFileAttachmentsDto(),
      missionFolderId: this.site.missionFolderId,
      orderFolderId: this.site.orderFolderId,
      sla: this.site.sla,
      manualAirspaceWaiverRequired: this.site.manualAirspaceWaiverRequired,
    };
  }

  buildOrderRequestForPortfolioDto(portfolioId: string): PortfolioMissionDTO {
    return {
      mission: this.buildMissionForPortfolioDto(),
      project: this.buildOrderRequestProjectDto(),
      capture: this.buildCaptureDto(),
      locationId: this.site.locLocationId,
      location: this.buildLocationDto(),
      address: this.buildAddressDto(),
      deliverableAspect: this.buildDeliveryAspectsDto(),
      locationContact: this.buildLocationContactDto(),
      filesAttachments: this.buildFileAttachmentsDto(),
      missionFolderId: this.site.missionFolderId,
      orderFolderId: this.site.orderFolderId,
      portfolioId: portfolioId,
      sla: this.site.sla,
    };
  }

  buildOrderRequestProjectDto(): OrderRequestMissionProjectDTO {
    return {
      projectName: this.getProjectName(),
      recurrence: this.getEnumFromById(this.site.frequencyId, recurrenceEnum),
      repeatsOn: this.site.recurrence?.repeatsOn,
      beginDate: this.datePipe.transform(this.site.captureDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.site.recurrence?.endDate, 'yyyy-MM-dd'),
      repetitions: this.site.recurrence?.repetitions,
    };
  }

  getEnumFromById<T>(id: string, enumType: T) {
    for (const key of Object.keys(enumType)) {
      if (enumType[key] === Number(id)) {
        return enumType[key];
      }
    }
  }

  buildMissionDto(
    pcustomerId: string,
    pcustomerName: string,
    ppriorityId: string,
    ppriorityName: string,
  ): MissionsCreateDto {
    return {
      name: this.site.missionName,
      summary: this.site.missionSummary,
      customerId: pcustomerId,
      customerName: pcustomerName,
      state: enumState.Enabled,
      additionalNotes: this.site.aditionalNotes,
      priorityId: ppriorityId,
      priorityDescription: ppriorityName,
      kmlFilePath: this.site.kmlFiles?.[0]?.path,
      kmlFileId: this.site.kmlFiles?.[0]?.fileId,
      externalLink: this.site.externalLink,
      externalLinkName: this.site.externalLinkName,
      uploadDataLocation: this.site.uploadDataLocation,
      uploadDataLocationId: this.site.uploadDataLocationId,
    };
  }

  buildMissionForPortfolioDto(): PortfolioMissionsCreateDto {
    return {
      name: this.site.missionName,
      summary: this.site.missionSummary,
      state: enumState.Enabled,
      additionalNotes: this.site.aditionalNotes,
      kmlFilePath: this.site.kmlFiles?.[0]?.path,
      kmlFileId: this.site.kmlFiles?.[0]?.fileId,
    };
  }

  buildFileAttachmentsDto(): OrderRequestMissionFileDTO[] {
    let fileslist: OrderRequestMissionFileDTO[] = [];

    if (!this.site || !this.site.filesAttachments) {
      return fileslist;
    }

    this.site.filesAttachments.forEach((fileModel: OrderFormSiteFileModel) => {
      fileslist.push({
        fileId: fileModel.fileId,
        name: fileModel.name,
      });
    });
    return fileslist;
  }

  buildCaptureDto(): CapturesCreateDto {
    return {
      date: this.datePipe.transform(this.site.captureDate, 'yyyy-MM-dd'),
      time: this.site.captureTime,
      // Default to 'True' (flexible) if captureTime is null or empty, false otherwise
      flexibleCaptureTime: this.site.captureTime == null || this.site.captureTime === '',
      captureDateMust: this.getEnumFromById(this.site.captureDateMustId, CaptureMustBeEnum),
      state: enumState.Enabled,
      fixedDate: this.datePipe.transform(this.site.captureDate, 'MM/dd/yyyy'),
      fixedTime: this.getFixedHourFormat(this.site.captureTime) || null,
    };
  }

  buildLocationDto(): LocationsCreateDto {
    return {
      gpsCoordinates: this.site.loclatitude + ',' + this.site.loclongitude,
      geoFenceRadio: 'geoFenceRadio', //TODO: Get Geofence Radio
      airSpaceClasificationId: this.site.locAirSpaceClasfId,
      airspaceClassificationName: this.site.locAirSpaceClasfDescription,
      siteName: this.site.locSiteName,
      state: enumState.Enabled,
    };
  }

  buildAddressDto(): AddressesCreateDto {
    return {
      streetAddress: this.site.locAddress,
      countryId: this.site.locCountryId,
      countryName: this.site.locCountryDescription,
      stateId: this.site.locStateId,
      stateName: this.site.locStateDescription,
      city: this.site.locCity,
      zipCode: this.site.locZipCode,
      state: enumState.Enabled,
    };
  }

  buildDeliveryAspectsDto(): DeliverableAspectCreateDto {
    return {
      deliverableDueDate: this.datePipe.transform(this.site.deliveryDate, 'yyyy-MM-dd', 'UTC'),
      notes: this.site.deliveryNotes,
      instructions: this.site.uploadingDataInstruction,
      deliveryAllSameTime: false,
      state: enumState.Enabled,
      fixedDeliverableDueDate: this.datePipe.transform(this.site.deliveryDate, 'MM/dd/yyyy'),
    };
  }

  buildLocationContactDto(): ContactsCreateDto[] {
    if (!this.site || !this.site.siteContacts || this.site.siteContacts.length === 0) {
      return [];
    }

    const externalContactType = this.contactTypes.items.find(
      contactType => contactType.code == contactTypeEnum.External,
    );

    return this.site.siteContacts.map(contact => ({
      contactId: contact.contactId,
      contactTypeId: externalContactType?.id,
      contactTypeDescription: externalContactType?.description,
      firstName: contact.name,
      lastName: contact.lastname,
      phone: contact.number,
      email: contact.email,
      shareData: contact.shareData,
      levelCommunicationId: contact.levelCommunicationId,
      levelCommunicationDescription: contact.levelCommunicationDescription,
      contactMethodId: contact.preferredContactMethodId,
      contactMethodDescription: contact.preferredContactMethodDescription,
      state: enumState.Enabled,
    }));
  }

  getProjectName(): string | undefined {
    if (this.getEnumFromById(this.site.frequencyId, recurrenceEnum) == recurrenceEnum.NoRepeat) {
      return this.site.missionName;
    } else {
      return this.site.projectName;
    }
  }

  removeSite(site: OrderFormSiteModel) {
    this.removesite.emit(site);
  }

  /**
   * Adds a marker
   * @param location Location
   * @returns void
   */
  private addMarker(location: Location): void {
    const siteMarker: FlyguysMapMarker = {
      id: 1,
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
    };

    this.map.clearMarkers();
    this.map.addMarker(siteMarker);
    this.triggerReload();
  }

  /**
   * Creates a new marker with updated coordinates
   * @param updatedMarker FlyguysMapMarker
   */
  private reactToLatLngChange(updatedMarker: FlyguysMapMarker) {
    this.map.clearMarkers();
    if (updatedMarker.lat && updatedMarker.lng) {
      this.map.addMarker(updatedMarker);

      const transformedCoords = convert(`${updatedMarker.lat} ${updatedMarker.lng}`);
      this.site.loclatitude = String(transformedCoords.decimalLatitude);
      this.site.loclongitude = String(transformedCoords.decimalLongitude);
    }

    this.triggerReload();
  }

  /**
   * Handles the activation of the Recurrence field
   * @param MatDatepickerInputEvent<Date>
   * @returns void
   */
  handleSelectCaptureDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value && !this.sLASelected?.isCustom) {
      this.formOrderSite.get('frequencyId').enable();
    } else {
      this.formOrderSite.get('frequencyId').disable();
    }
    this.calculationSLA();

    if (this.sLASelected?.isCustom && this.site?.sla?.length > 0) {
      this.customSlaModifyCaptureDate(event.value);
    }
  }

  /**
   * Handles the selection of a Recurrence
   * @param value recurrenceEnum
   * @returns void
   */
  handleRecurrencySelect(value: recurrenceEnum) {
    if (value !== recurrenceEnum.NoRepeat) {
      const ref = this.dialog.open(RecurrenceComponent, {
        data: {
          type: value,
          captureDate: this.site.captureDate,
          previousRecurrence: this.previousRecurrence,
        },
        width: '360px',
      });

      ref.afterClosed().subscribe({
        next: (recurrence: RecurrenceForm) => {
          if (recurrence) {
            this.site.recurrence = recurrence.toDTO();
            this.previousRecurrence = {
              type: recurrence.type,
              ...recurrence.getRawValue(),
            };

            let originalDescription = recurrence.getRecurrence();

            if (value === recurrenceEnum.Biweekly) {
              originalDescription = originalDescription.replace(/(Every)/i, '$1 other');
            }

            this.site.recurrenceDescription = originalDescription;
          } else {
            this.formOrderSite
              .get('frequencyId')
              .patchValue(this.previousRecurrence?.type || this.defaultRecurrence);
          }
        },
      });
    } else {
      this.site.recurrenceDescription = null;
      this.previousRecurrence = null;
    }
  }

  /**
   * Handles the edition of a previously selected recurrence.
   */
  handleEditRecurrence() {
    this.handleRecurrencySelect(this.previousRecurrence.type);
  }

  /**
   * Helps the Recurrence Select compare its objects
   *
   * @param o1 number
   * @param o2 string
   * @returns boolean
   */
  compareRecurrence(o1: number, o2: string): boolean {
    return o1 === parseInt(o2);
  }

  private getContactTypes() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetContactTypeInput;

    this.contactTypesService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactTypes = res;
      });
  }

  /**
   * Reacts to changes in the location form
   * @param location Location | null
   */
  handleLocationChange(location: Location | null) {
    if (location) {
      this.site.loclatitude = location.lat;
      this.site.loclongitude = location.lng;
      this.site.loclatitude = location.lat;
      this.site.locCity = location.city;
      this.site.locZipCode = location.zip;
      this.site.locAddress = location.name;
      this.site.locCountryId = location.countryId;
      this.site.locStateId = location.stateId;
      this.site.locCountryDescription =
        this.dataCountries.items?.find(c => c.id === location.countryId)?.description || '';
      this.site.locStateDescription =
        this.dataStates.items?.find(s => s.id === location.stateId)?.description || '';

      this.addMarker(location);
    } else {
      this.site.locCity = '';
      this.site.locZipCode = '';
      this.site.locCountryId = '';
      this.site.locStateId = '';
      this.site.loclatitude = '';
      this.site.loclongitude = '';
      this.site.locCountryDescription = '';
      this.site.locStateDescription = '';
    }
  }

  triggerReload(): void {
    this.mapreloadService.reloadMap();
  }

  private getLocationsWithAddress() {
    const customerId = this.customerId;

    const getCustomerLocationsInput: GetCustomerLocationsInput = {
      customerId: customerId,
      maxResultCount: 1000,
    };
    this.customerLocationService.getList(getCustomerLocationsInput).subscribe({
      next: (response: PagedResultDto<CustomerLocationDto>) => {
        const customerLocations: string[] = response.items.map(item => item.locationId);

        this.locationService.getLocationsWithAddress(customerLocations, customerId).subscribe({
          next: data => (this.locations = data),
          error: err => console.error('Error fetching locations:', err),
        });
      },
      error: err => console.log(err),
    });

    this.filteredLocations = this.locations;

    this.formOrderSite.get('locSiteName').valueChanges.subscribe(val => {
      if (typeof val === 'string') {
        this.filteredLocations = this.locations.filter(
          location =>
            location.siteName.toLowerCase().includes(val.toLowerCase()) ||
            location.streetAddress.toLowerCase().includes(val.toLowerCase()),
        );

        if (this.filteredLocations.length === 0 || val.trim() === '') {
          if (this.locationForm) {
            this.locationForm.clear();
          }
          this.filteredLocations = [];
        }
      } else {
        this.selectedLocation = val;
      }
    });
  }

  onLocationSelected(event: MatAutocompleteSelectedEvent) {
    const selectedLocation: LocationWithAddressDto = event.option.value;

    this.site.locLocationId = selectedLocation.locationId;
    this.site.locSiteName = selectedLocation.siteName;
    this.site.loclatitude = selectedLocation.latitude;
    this.site.loclongitude = selectedLocation.longitude;
    this.site.locCity = selectedLocation.city;
    this.site.locZipCode = selectedLocation.zipCode;
    this.site.locCountryId = selectedLocation.countryId;
    this.site.locStateId = selectedLocation.stateId;
    this.site.locAddress = selectedLocation.streetAddress;

    this.formOrderSite.get('locSiteName').patchValue(selectedLocation.siteName);

    this.locationForm.setData(
      {
        name: this.site.locAddress,
        lat: this.site.loclatitude,
        lng: this.site.loclongitude,
        zip: this.site.locZipCode,
        city: this.site.locCity,
        stateId: this.site.locStateId,
        countryId: this.site.locCountryId,
      },
      LocationSource.SITE,
    );
  }

  // Site Contacts related logic
  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { prop: 'levelCommunicationDescription', name: 'Level of Coordination' },
    { prop: 'contactMethodDescription', name: 'Preferred Contact Method' },
    { name: 'Actions' },
  ];

  handleAddContact(): void {
    let contact = new OrderFormContactModel();

    const dialogRef = this.dialogService.open(OrderContactComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: {
        contact,
        displayContactMethodAndLevelOfCoordination: true,
        addingContact: true,
        title: 'Add New Contact',
        actions: {
          confirm: 'Add',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const contactCreateDto: ContactsCreateDto = {
            contactTypeId: data.contactFromId,
            contactTypeDescription: data.contactFromDescription,
            firstName: data.name,
            lastName: data.lastname,
            phone: data.number,
            email: data.email,
            levelCommunicationId: data.levelCommunicationId,
            levelCommunicationDescription: data.levelCommunicationDescription,
            shareData: data.shareData,
            contactMethodId: data.preferredContactMethodId,
            contactMethodDescription: data.preferredContactMethodDescription,
            state: enumState.Enabled,
          };

          this.contactsService.create(contactCreateDto).subscribe({
            next: (createdContact: ContactsDto) => {
              const contactId = createdContact.id;

              const customerContactCreateDto: CustomerContactsCreateDto = {
                contactId: contactId,
                customerId: this.customerId,
                state: enumState.Enabled,
              };

              this.customerContactsService.create(customerContactCreateDto).subscribe({
                next: (createdCustomerContact: CustomerContactsDto) => {
                  if (createdCustomerContact) {
                    this.getContacts();
                    data.contactId = contactId;
                    const contacts: OrderFormContactModel[] = [...this.site.siteContacts, data];
                    this.site.siteContacts = contacts;

                    this.toaster.success(
                      `Contact '${data.name} ${data.lastname}' created successfully.`,
                    );
                  }
                },
                error: err => console.log(err),
              });
            },
            error: err => console.log(err),
          });
        }
      }),
    );
  }

  handleRemoveContact(row: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((removeContact: Boolean) => {
        if (removeContact) {
          const indexToRemove = this.site.siteContacts.indexOf(row);

          if (indexToRemove !== -1) {
            this.site.siteContacts.splice(indexToRemove, 1);
          }

          this.site.siteContacts = [...this.site.siteContacts];
        }
      }),
    );
  }

  handleEditContact(contact: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(OrderContactComponent, {
      width: '900px',
      data: {
        contact,
        displayContactMethodAndLevelOfCoordination: true,
        addingContact: false,
        title: 'Edit Contact',
        actions: {
          confirm: 'Edit',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          // Edit the contact in the database
          const contactsUpdateDto: ContactsUpdateDto = {
            contactTypeId: data.contactFromId,
            contactTypeDescription: data.contactFromDescription,
            firstName: data.name,
            lastName: data.lastname,
            phone: data.number,
            email: data.email,
            levelCommunicationId: data.levelCommunicationId,
            levelCommunicationDescription: data.levelCommunicationDescription,
            contactMethodId: data.preferredContactMethodId,
            contactMethodDescription: data.preferredContactMethodDescription,
            state: enumState.Enabled,
          };

          this.contactsService.update(data.contactId, contactsUpdateDto).subscribe({
            next: (updatedContact: ContactsDto) => {
              this.toaster.success(
                `Contact '${updatedContact.firstName} ${updatedContact.lastName}' updated successfully.`,
              );

              const indexToEdit = this.site.siteContacts.findIndex(
                contact => contact.contactId === updatedContact.id,
              );

              if (indexToEdit !== -1) {
                this.site.siteContacts[indexToEdit] = {
                  ...this.site.siteContacts[indexToEdit],
                  contactFromId: data.contactFromId,
                  contactFromDescription: data.contactFromDescription,
                  email: data.email,
                  name: data.name,
                  lastname: data.lastname,
                  number: data.number,
                  shareData: data.shareData,
                  levelCommunicationId: data.levelCommunicationId,
                  levelCommunicationDescription: data.levelCommunicationDescription,
                  preferredContactMethodId: data.preferredContactMethodId,
                  preferredContactMethodDescription: data.preferredContactMethodDescription,
                };
              }

              this.site.siteContacts = [...this.site.siteContacts];
              this.contactsUpdatedChange.emit();
            },
            error: err => console.log('Error updating contact: ', err),
          });
        }
      }),
    );
  }

  filteredContacts: ContactsDto[] = [];
  contacts: ContactsDto[] = [];

  private getContacts() {
    if (!this.customerId) return;

    this.customerContactsService.getCustomerContacts(this.customerId).subscribe({
      next: (response: PagedResultDto<CustomerContactsDto>) => {
        const customerContactsList = response.items;

        const contactIds = customerContactsList.map(contact => contact.contactId);

        this.contactsService.getContactsByListOfIds(contactIds).subscribe({
          next: (data: ContactsDto[]) => {
            this.contacts = data;

            this.contacts.forEach(contactDto => {
              const index = this.site?.siteContacts?.findIndex(
                siteContact => siteContact.contactId === contactDto.id,
              );
              if (index > -1) {
                const siteContact = this.site.siteContacts[index];
                siteContact.email = contactDto.email || siteContact.email;
                siteContact.name = contactDto.firstName || siteContact.name;
                siteContact.lastname = contactDto.lastName || siteContact.lastname;
                siteContact.number = contactDto.phone || siteContact.number;
                siteContact.levelCommunicationId =
                  contactDto.levelCommunicationId || siteContact.levelCommunicationId;
                siteContact.levelCommunicationDescription =
                  contactDto.levelCommunicationDescription ||
                  siteContact.levelCommunicationDescription;
                siteContact.preferredContactMethodId =
                  contactDto.contactMethodId || siteContact.preferredContactMethodId;
                siteContact.preferredContactMethodDescription =
                  contactDto.contactMethodDescription ||
                  siteContact.preferredContactMethodDescription;
                siteContact.contactFromId = contactDto.contactTypeId || siteContact.contactFromId;
                siteContact.contactFromDescription =
                  contactDto.contactTypeDescription || siteContact.contactFromDescription;

                this.site.siteContacts[index] = siteContact;
              }
            });
          },
          error: err => console.error('Error fetching contacts:', err),
        });
      },
      error: err => console.log(err),
    });

    this.filteredContacts = [...this.contacts];

    this.formOrderSite.get('siteContactSearch').valueChanges.subscribe(val => {
      if (typeof val === 'string') {
        this.filteredContacts = this.contacts.filter(
          contact =>
            (contact.firstName && contact.firstName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.lastName && contact.lastName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.phone && contact.phone.toLowerCase().includes(val.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(val.toLowerCase())) ||
            (
              (contact.firstName === null ? '' : contact.firstName).toLowerCase() +
              ' ' +
              (contact.lastName === null ? '' : contact.lastName).toLowerCase()
            ).includes(val.toLowerCase()),
        );
      }
    });
  }

  openContactsAutocompletePanel(autocompleteTrigger: MatAutocompleteTrigger) {
    if (!autocompleteTrigger.panelOpen) {
      setTimeout(() => {
        this.filteredContacts = [...this.contacts];
        autocompleteTrigger.openPanel();
      }, 500);
    }
  }

  onContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    // Check if the contact already exists in the siteContacts
    if (this.site.siteContacts.some(contact => contact.contactId === selectedContact.id)) {
      this.toaster.warn('This contact is already added.');
      this.formOrderSite.get('siteContactSearch').setValue('');
      return;
    }

    const contactToAdd: OrderFormContactModel = {
      contactId: selectedContact.id || '',
      contactFromId: selectedContact.contactTypeId || '',
      contactFromDescription: selectedContact.contactTypeDescription || '',
      email: selectedContact.email || '',
      name: selectedContact.firstName,
      lastname: selectedContact.lastName,
      number: selectedContact.phone || '',
      shareData: false,
      preferredContactMethodId: selectedContact.contactMethodId || '',
      preferredContactMethodDescription: selectedContact.contactMethodDescription || '',
      levelCommunicationId: selectedContact.levelCommunicationId || '',
      levelCommunicationDescription: selectedContact.levelCommunicationDescription || '',
      customerContactDescription: '',
      customerId: '',
      userId: selectedContact.userId,
    };

    const contacts: OrderFormContactModel[] = [...this.site.siteContacts, contactToAdd];
    this.site.siteContacts = contacts;

    this.formOrderSite.get('siteContactSearch').setValue('');
    this.toaster.success(
      `Contact '${contactToAdd.name} ${contactToAdd.lastname}' added successfully.`,
    );
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    const pendingLocationControls = getPendingControls(
      this.formOrderSite.get('location') as FormGroup,
      requiredControlsNames,
      `Site ${this.site.number}: `,
    );

    const pendingSiteControls = getPendingControls(
      this.formOrderSite,
      requiredControlsNames,
      `Site ${this.site.number}: `,
    );

    return [...pendingSiteControls, ...pendingLocationControls];
  }

  private getFixedHourFormat(fixedHour?: string) {
    if (
      !fixedHour ||
      (!fixedHour.toLowerCase().includes('am') && !fixedHour.toLowerCase().includes('pm'))
    )
      return fixedHour;

    const [time, period] = fixedHour.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour);

    if (period === 'PM') {
      formattedHour += 12;
    }

    return `${formattedHour}:${minute}`;
  }

  private populateAddressForm() {
    const origin =
      (this.site.locAddress === '' &&
        this.site.loclatitude === '' &&
        this.site.loclatitude === '') ||
      this.site.locAddress
        ? LocationOrigin.ADDRESS
        : LocationOrigin.COORDINATES;

    if (this.site) {
      this.formOrderSite.patchValue({
        locSiteName: this.site.locSiteName,
        locAirSpaceClasfId: this.site.locAirSpaceClasfId,
        location: {
          address: this.site.locAddress,
          origin,
          lat: this.site.loclatitude,
          lng: this.site.loclongitude,
          zip: this.site.locZipCode,
          city: this.site.locCity,
          countryId: this.site.locCountryId,
          stateId: this.site.locStateId,
        },
      });
    }
  }

  private performActionsAfterComponentIsVisible() {
    this.subscriptions.push(
      this.mapVisibilityService.state$.subscribe(visible => {
        if (visible) {
          this.mapVisibilityService.mapInstance$.subscribe(map => {
            setTimeout(() => {
              // enable the frequency dropdown if the capture date is selected
              if (this.site.captureDate) {
                this.formOrderSite.get('frequencyId').enable();
              }
              if (this.sLASelected?.isCustom) {
                this.formOrderSite.get('frequencyId').disable();
              }
              // Center the map based on the coordinates
              if (this.map) {
                this.formOrderSite.get('location.lat')?.updateValueAndValidity({ onlySelf: true });
                this.formOrderSite.get('location.lng')?.updateValueAndValidity({ onlySelf: true });

                if (this.site.loclatitude && this.site.loclongitude) {
                  this.site.loclatitude = String(this.site.loclatitude);
                  this.site.loclongitude = String(this.site.loclongitude);
                  this.centerMapOnSiteCoordinates();
                  if (this.site.kmlFiles && this.site.kmlFiles.length > 0) {
                    this.renderKML();
                  }
                }
              }
            }, 1000); // This is a workaround because I need to wait for the google map to completely load before setting the coordinates. Improve on technical debt ticket.
          });
        }
      }),
    );
  }

  private centerMapOnSiteCoordinates() {
    if (this.site.loclatitude && this.site.loclongitude) {
      const siteMarker: FlyguysMapMarker = {
        id: 1,
        lat: parseFloat(this.site.loclatitude),
        lng: parseFloat(this.site.loclongitude),
        description: this.site.locAddress,
      };

      this.reactToLatLngChange(siteMarker);
    }
  }

  calculateCapture() {
    if (!this.slaId) return;

    const mustbe = this.formOrderSite.get('captureDateMustId').value;

    if (CaptureMustBeEnum.AnytimeBefore != mustbe) return;

    const newDate = addMonths(new Date(), 2);

    const slaInfo = {
      captureDate: newDate.toLocaleDateString('en-CA'),
      captureMustBe: mustbe,
      deliverableDate: new Date('0001-01-01T00:00:00Z'),
      slaId: this.slaId,
    };

    forkJoin({
      slaResult: this.slaService.getList({ maxResultCount: 100 }),
      calcResult: this.slaService.calculationSLA(slaInfo),
    }).subscribe(r => {
      const sla = r.slaResult.items.find(q => q.id == this.slaId);

      if (!sla) return;

      const milesonte = sla.slaMilestones.find(q => q.isMatchWithCaptureDate);
      if (!milesonte) return;

      if (r.calcResult.totalCount > 0) {
        const captureData = r.calcResult.items.find(q => q.slaMilestoneId == milesonte.id);

        if (captureData) {
          const dueDate: Date =
            captureData.dueDate instanceof Date
              ? captureData.dueDate
              : new Date(captureData.dueDate);
          this.formOrderSite.get('captureDate').setValue(dueDate);
          this.calculationSLA();
        }
      }
    });
  }

  calculationSLA(force: boolean = false) {
    if (
      !force &&
      (!this.slaId ||
        ((!this.sLASelected || this.sLASelected?.isCustom) && this.site?.sla?.length > 0))
    )
      return;
    const captureDate = this.formOrderSite.get('captureDate').value;

    // If the capture date is not a valid date at this point, we should not try to call the API
    if (!(captureDate instanceof Date) || isNaN(captureDate.getTime())) {
      return;
    }

    const mustbe = this.formOrderSite.get('captureDateMustId').value;
    let localDateString = captureDate.toLocaleDateString('en-CA'); // Formato 'YYYY-MM-DD'
    if (this.slaId) {
      const slaInfo = {
        captureDate: localDateString,
        captureMustBe: mustbe.toString() == '' ? CaptureMustBeEnum.OnSelectedDate : mustbe,
        deliverableDate: new Date('0001-01-01T00:00:00Z'),
        slaId: this.slaId,
      };

      this.slaService.calculationSLA(slaInfo).subscribe(d => {
        if (d.totalCount > 0) {
          this.recalculateSLA(d.items);
          this.slaUpdatedChange.emit(this.site.sla);
        }
      });
    }
  }

  onDeliverableChange(newDeliverable) {
    if (this.site?.sla?.length > 0) {
      let indexSlaSelected = this.site.sla.findIndex(s => s.isMatchWithDueDate);
      let slaSelected = this.site.sla.find(s => s.isMatchWithDueDate);
      if (!slaSelected) {
        slaSelected = this.site.sla[this.site?.sla?.length - 1];
      }

      this.modifyDateMilestone(slaSelected, newDeliverable.value, indexSlaSelected);
    }
  }

  calculateBusinessDaysDifference(start: Date, dueDate: Date): number {
    if (!this.isValidDate(start) || !this.isValidDate(dueDate)) {
      return 0;
    }
    const startDate = new Date(start);
    const targetDate = new Date(dueDate);

    // Set hours, minutes, seconds, and milliseconds to zero for both dates
    startDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    // If the dates are the same, return 0
    if (startDate.getTime() === targetDate.getTime()) {
      return 1;
    }

    let count = 0;
    let increment = startDate < targetDate ? 1 : -1;
    let currentDate = new Date(startDate);

    // Move the currentDate in the correct direction to start counting the difference
    while (currentDate.getTime() !== targetDate.getTime()) {
      currentDate.setDate(currentDate.getDate() + increment);

      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // Skip weekends
        count += increment;
      }
    }

    return count + 1;
  }

  isValidDate(date: any): boolean {
    const parsedDate = new Date(date);
    return (
      parsedDate instanceof Date &&
      !isNaN(parsedDate.getTime()) &&
      parsedDate.getTime() !== new Date('0001-01-01T00:00:00').getTime()
    );
  }

  recalculateSLA(sla: MissionSLADto[]) {
    this.site.sla = [...sla];
    const matchDeliverableDueDate = this.site.sla.find(s => s.isMatchWithDueDate);
    const matchCaptureDate = this.site.sla.find(s => s.isMatchWithCaptureDate);
    let deliverableDueDate;
    if (matchDeliverableDueDate) {
      deliverableDueDate = matchDeliverableDueDate.dueDate;
    } else {
      deliverableDueDate = this.site.sla
        .sort((a, b) => a.order - b.order)
        [sla.length - 1].dueDate.toString();
    }
    this.site.deliveryDate = deliverableDueDate;
    this.formOrderSite.get('deliveryDate').setValue(this.site.deliveryDate);

    if (matchCaptureDate && this.sLASelected?.isCustom) {
      this.site.captureDate = new Date(matchCaptureDate.dueDate);
      this.formOrderSite.get('captureDate').setValue(new Date(matchCaptureDate.dueDate));
    }
  }

  addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  customSlaModifyCaptureDate(captureDateNew) {
    const milestone = this.site.sla.findIndex(s => s.isMatchWithCaptureDate);
    this.modifyDateMilestone(this.site.sla[milestone], captureDateNew, milestone);
  }

  modifyDateMilestone(slaSelected: MissionSLADto, newDate, index: number) {
    slaSelected.dueDate = format(newDate, 'yyyy-MM-dd') + 'T00:00:00';
    slaSelected.isCustom = true;
    slaSelected.days = this.calculateBusinessDaysDifference(
      slaSelected.startDate,
      slaSelected.dueDate,
    );
    this.site.sla = [...this.site.sla];

    const nextSla = this.site.sla[index + 1];
    if (nextSla) {
      const dueDate = new Date(slaSelected.dueDate);
      const startDate = this.addDays(dueDate, 1);
      nextSla.startDate = format(startDate, 'yyyy-MM-dd') + 'T00:00:00';
      nextSla.days = this.calculateBusinessDaysDifference(nextSla.startDate, nextSla.dueDate);
    }
  }
}
