import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { OrdersGridRefreshService } from '../services/orders-grid-refresh.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersListReuseStrategy extends RouteReuseStrategy {
  private readonly path = 'list';
  private handle: DetachedRouteHandle = null;

  constructor(private ordersRefreshService: OrdersGridRefreshService) {
    super();

    // If the service gets called we invalidate the cache, making the
    // grid list component go through its lifecycle again.
    this.ordersRefreshService.listenForGridChanges().subscribe({
      next: _ => {
        this.handle = null;
      },
    });
  }

  // Indicates if the route should be stored
  // orders doesn't have a path, so we track it using the data.preserve key
  // True signals that the component should be preserved
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data?.preserve || false;
  }

  // Saves the component information
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.routeConfig.path === this.path && route.data?.preserve) {
      this.handle = handle;
    }
  }

  // Indicates if the component should be retrieved, calls retrieve()
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig.path === this.path && route.data?.preserve && this.handle !== null;
  }

  // Return the stored data
  retrieve(_: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.handle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
