import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe } from './file-size.pipe';
import { KmlFieldPipe } from './kml-field-pipe';
import { KmlFieldSameConfigPipe } from './kml-field-sameconfig.pipe';

@NgModule({
  declarations: [FileSizePipe, KmlFieldPipe, KmlFieldSameConfigPipe],
  imports: [CommonModule],
  exports: [FileSizePipe, KmlFieldPipe, KmlFieldSameConfigPipe],
})
export class PipesModule {}
