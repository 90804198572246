import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomerAddressesCreateDto, CustomerAddressesDto, CustomerAddressesExcelDownloadDto, CustomerAddressesUpdateDto, GetCustomerAddressInput } from '../../relationals/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerAddressesService {
  apiName = 'customersService';
  

  create = (input: CustomerAddressesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAddressesDto>({
      method: 'POST',
      url: '/api/customers-service/customer-address',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/customers-service/customer-address/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAddressesDto>({
      method: 'GET',
      url: `/api/customers-service/customer-address/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/customers-service/customer-address/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetCustomerAddressInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerAddressesDto>>({
      method: 'GET',
      url: '/api/customers-service/customer-address',
      params: { filterText: input.filterText, addressId: input.addressId, customerId: input.customerId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: CustomerAddressesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/customers-service/customer-address/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: CustomerAddressesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAddressesDto>({
      method: 'PUT',
      url: `/api/customers-service/customer-address/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
