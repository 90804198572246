import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AttributeValidationCreateDto,
  AttributeValidationDto,
  AttributeValidationUpdateDto,
  AutomaticQaQcResultDto,
  ExifDataResultsDto,
  GetAttributeValidationsInput,
} from '../../relationals/models';

@Injectable({
  providedIn: 'root',
})
export class AttributeValidationService {
  apiName = 'missionsService';

  create = (input: AttributeValidationCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeValidationDto>(
      {
        method: 'POST',
        url: '/api/missions-service/attribute-validations',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/attribute-validations/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeValidationDto>(
      {
        method: 'GET',
        url: `/api/missions-service/attribute-validations/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetAttributeValidationsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AttributeValidationDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/attribute-validations',
        params: {
          filterText: input.filterText,
          attributeId: input.captureDeliverableAttributeId,
          attributeValidationDefinitionId: input.attributeValidationDefinitionId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getResults = (orderDetailId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AutomaticQaQcResultDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/automatic-qa-qc-results',
        params: {
          orderDetailId: orderDetailId,
          maxResultCount: 1000,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: AttributeValidationUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeValidationDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/attribute-validations/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  checkExistingValidations = (
    attributeValidationDefinitionIds: string[],
    captureDeliverableId: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, AttributeValidationDto[]>(
      {
        method: 'POST',
        url: '/api/missions-service/attribute-validations/check-existing',
        body: {
          attributeValidationDefinitionIds,
          captureDeliverableId,
        },
      },
      { apiName: this.apiName, ...config },
    );

  performAutomaticQa = (
    validations: AttributeValidationDto[],
    exifData: ExifDataResultsDto[],
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, ExifDataResultsDto[]>(
      {
        method: 'POST',
        url: '/api/missions-service/attribute-validations/perform-automatic-qa',
        body: {
          validations,
          exifData,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
