import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetWebNotificationContentInput,
  WebNotificationContentsCreateDto,
  WebNotificationContentsDto,
  WebNotificationContentsExcelDownloadDto,
  WebNotificationDismiss,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebNotificationContentsService {
  apiName = 'notificationsService';
  notificationDismissed$ = new Subject<WebNotificationContentsDto>();
  refreshNotifications$ = new Subject<void>();

  create = (input: WebNotificationContentsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, WebNotificationContentsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/web',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  backgroundCreate = (input: WebNotificationContentsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, WebNotificationContentsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/web/background',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getUserNotifications = (input: GetWebNotificationContentInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WebNotificationContentsDto>>(
      {
        method: 'GET',
        url: '/api/notification-service/web/user',
        params: {
          userId: input.userId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          excludeDismissed: input.excludeDismissed,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/notification-service/web/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetWebNotificationContentInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WebNotificationContentsDto>>(
      {
        method: 'GET',
        url: '/api/notification-service/web',
        params: {
          filterText: input.filterText,
          notificationId: input.notificationId,
          userId: input.userId,
          message: input.message,
          status: input.status,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (
    input: WebNotificationContentsExcelDownloadDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/notification-service/web/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  dismissWebNotification = (
    userId: string,
    webNotificationId: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/notification-service/web/user/${userId}/notification/${webNotificationId}/dismiss`,
      },
      { apiName: this.apiName, ...config },
    );

  dismissAllWebNotification = (input: WebNotificationDismiss, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: '/api/notification-service/web/dismissAll',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
