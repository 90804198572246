import { Component, OnInit } from '@angular/core';
import { ToasterService } from '@abp/ng.theme.shared';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent implements OnInit {
  requestTrackerId: string = '';

  constructor(
    private toaster: ToasterService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.requestTrackerId = this.route.snapshot.queryParamMap.get('correlationId');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.requestTrackerId);
    this.toaster.success('Request tracker id copied!');
  }
}
