import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetMissionFinancialExpensesInput,
  MissionFinancialExpenseCreateDto,
  MissionFinancialExpenseDto,
  MissionFinancialExpenseExcelDownloadDto,
  MissionFinancialExpenseUpdateDto,
} from '../../relational/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionFinancialExpenseService {
  apiName = 'missionsService';

  create = (input: MissionFinancialExpenseCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialExpenseDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission-financial-expenses',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission-financial-expenses/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialExpenseDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-financial-expenses/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-financial-expenses/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetMissionFinancialExpensesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionFinancialExpenseDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-financial-expenses',
        params: {
          filterText: input.filterText,
          expenseTypeId: input.expenseTypeId,
          description: input.description,
          unitsMin: input.unitsMin,
          unitsMax: input.unitsMax,
          uomId: input.uomId,
          itemCostMin: input.itemCostMin,
          itemCostMax: input.itemCostMax,
          totalItemCostMin: input.totalItemCostMin,
          totalItemCostMax: input.totalItemCostMax,
          expenseDateMin: input.expenseDateMin,
          expenseDateMax: input.expenseDateMax,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          missionId: input.missionId,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (
    input: MissionFinancialExpenseExcelDownloadDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/mission-financial-expenses/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: MissionFinancialExpenseUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialExpenseDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission-financial-expenses/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  generateDataqb = (input: any, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/missions-service/qb/generate-reconciliation-data-qb',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
