import { mapEnumToOptions } from '@abp/ng.core';

export enum enumDeviceType {
    Other = 0,
    wns = 1,
    Apns = 2,
    mpns = 3,
    Fcm = 4
}

export const EnumDeviceTypeOptions = mapEnumToOptions(enumDeviceType);
