<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      [filterConfig]="filterConfig"
      [filterTitle]="'Filter Activity'"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="filter-section">
      <h3>{{ 'missionsService::TimelineTitle' | abpLocalization }}</h3>

      <div class="d-flex align-items-center">
        <span class="fg-subtitle me-2">
          {{ 'missionsService::ShowMeActivity' | abpLocalization }}
        </span>
        <div class="d-flex align-items-center">
          <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
            <ng-container *ngIf="condition.existingValues.length">
              <app-filter-chip
                class="me-2"
                [label]="condition.columnDisplayName"
                [value]="getFilterDisplayValue(condition)"
                (removeEvent)="removeFilter(condition.column)"
              ></app-filter-chip>
            </ng-container>
          </ng-container>
        </div>

        <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
          add_circle
        </span>

        <span
          class="d-inline-block main-color fw-medium cursor-pointer"
          (click)="removeAllFilters()"
          >{{ 'missionsService::ClearFilterTimeline' | abpLocalization }}</span
        >
      </div>
    </div>

    <ng-container *ngIf="logs.length > 0" class="border rounded m-0">
      <div class="timeline-container">
        <div class="timeline-master">
          <h4 class="title">{{ 'missionsService::Activity' | abpLocalization }}</h4>
          <ng-scrollbar visibility="hover" style="height: 70vh">
            <app-timeline-event
              *ngFor="let log of logs; let isLast = last"
              [log]="log"
              [selected]="log.id === logSelected?.id"
              (logSelected)="showDetails($event)"
            ></app-timeline-event>
          </ng-scrollbar>
        </div>
        <div class="timeline-detail">
          <app-timeline-detail [detail]="logSelected"></app-timeline-detail>
        </div>
      </div>
    </ng-container>

    <div class="d-flex align-items-center" *ngIf="logs.length === 0">
      <h4>{{ 'missionsService::NoActivityFound' | abpLocalization }}</h4>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
