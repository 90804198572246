export enum enumMissionStatusMapping {
  CRQ = 0,
  RFS = 1,
  PS = 2,
  LCO = 3,
  AF = 4,
  PCI = 5,
  POS = 6,
  PLS = 7,
  DU = 8,
  DP = 9,
  IQAQC = 10,
  MD = 11,
  RR = 12,
  OH = 13,
  CL = 14,
  // Pilot pending = 15
  PA = 16,
  // QAQC Pending = 17
  // QAQC Approved = 18
  // QAQC NOT Approved = 19
  AMCA = 20,
  AQAQC = 21,
  DEAD = 22,
  CI = 23,
  UMCR = 24,
  AIRPEN = 25,
  AIREQ = 26,
  AIRAPP = 27,
}
