import { ConfigStateService } from '@abp/ng.core';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CustomizerSettingsService } from '../tagus/customizer-settings/customizer-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { WebNotificationContentsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  GetWebNotificationContentInput,
  WebNotificationContentsDto,
} from 'projects/notifications-service/src/lib/proxy/notifications-service/basics/models';
import { Subscription } from 'rxjs';

export interface NotificationElement {
  date: string;
  message: string;
}

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['message', 'date'];
  dataSource = new MatTableDataSource<NotificationElement>([]);

  webNotifications: WebNotificationContentsDto[];

  filter = <GetWebNotificationContentInput>{};

  totalPageSize: number;

  pageSizeOptions = [10, 25, 50];

  private subscription = new Subscription();

  private readonly missionDetailPath = '/pilot-sourcing/missions/';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public themeService: CustomizerSettingsService,
    readonly dialog: MatDialog,
    private notificationService: WebNotificationContentsService,
    private configService: ConfigStateService
  ) {}

  ngOnInit(): void {
    this.filter.userId = this.configService.getDeep('currentUser.userName');
    this.filter.maxResultCount = this.pageSizeOptions[0];
    this.filter.skipCount = 0;

    this.loadNotificationData();

    this.subscription.add(
      this.notificationService.refreshNotifications$.subscribe(() => this.loadNotificationData())
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  handleOnPage(event) {
    this.filter.maxResultCount = event.pageSize;
    this.filter.skipCount = event.pageSize * event.pageIndex;

    this.loadNotificationData();
  }

  handleRemovePin(element: WebNotificationContentsDto) {
    this.notificationService
      .dismissWebNotification(this.filter.userId, element.id)
      .subscribe(res => {
        element.dismissed = true;
        this.notificationService.notificationDismissed$.next(element);
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadNotificationData(): void {
    this.notificationService.getUserNotifications(this.filter).subscribe(res => {
      this.webNotifications = res.items;
      this.totalPageSize = res.totalCount;
    });
  }

  /**
   * Generates a valid redirect path for a notification
   * @param notification WebNotificationContentsDto
   * @returns string
   */
  generateMissionDetailPath(notification: WebNotificationContentsDto): string {
    return `${this.missionDetailPath}${notification.missionId}`;
  }
}
