import { FilterOption } from 'projects/flyguys/src/app/shared/grid-filters/models/filter-option.model';
import { FilterConfig } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { FilterType } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-type.enum';
import { enumNotificationStatus } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared';
import { enumNotificationType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-type';
import { enumNotificationTriggerType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-trigger-type';
import { enumNotificationCategoryType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-category-type';
export const NotificationFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'statuses',
      columnDisplayName: 'Status',
      type: FilterType.Dropdown,
      existingValues: [],
      options: enumToFilterOptions(enumNotificationStatus),
    },
    {
      column: 'types',
      columnDisplayName: 'Type',
      type: FilterType.Dropdown,
      existingValues: [],
      options: enumToFilterOptions(enumNotificationType),
    },
    {
      column: 'triggerTypes',
      columnDisplayName: 'Trigger Type',
      type: FilterType.Dropdown,
      existingValues: [],
      options: enumToFilterOptions(enumNotificationTriggerType),
    },
    {
      column: 'categoryTypes',
      columnDisplayName: 'Category Type',
      type: FilterType.Dropdown,
      existingValues: [],
      options: enumToFilterOptions(enumNotificationCategoryType),
    },
    {
      column: 'emails',
      columnDisplayName: 'Email',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'tags',
      columnDisplayName: 'Tags',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'creationDates',
      columnDisplayName: 'Creation Date',
      type: FilterType.Date,
      existingValues: [],
    },
  ],
};

function enumToFilterOptions<T>(enumObj: T): FilterOption[] {
  return Object.keys(enumObj)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      id: (enumObj as any)[key].toString(),
      description: key,
    }));
}
