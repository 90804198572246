import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ShipmentCreationDto } from '../models/shipment-creation.dto';
import { ShipmentDto } from '../models/shipment.dto';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  apiName = 'missionsService';

  create = (input: ShipmentCreationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ShipmentDto>(
      {
        method: 'POST',
        url: '/api/missions-service/shipments',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
