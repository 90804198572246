import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/recurrency.enum';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/capture-mustbe.enum';
import { Router } from '@angular/router';
import { ABP, ConfigStateService, LocalizationService } from '@abp/ng.core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MissionsViewGridFilterModel } from '../models/missions-view-grid-filter.model';
import { CustomViewDataModel } from '../models/custom-view-data.model';
import { FilterType } from 'projects/flyguys/src/app/shared/grid-filters/models/filter-type.enum';
import { MissionsFilterColumns } from '../models/missions-filter-columns';
import {
  GetPriorityInput,
  enumState,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { StatusMissionOrderService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/status-mission-order.service';
import {
  TimeframeFilterEnum,
  TimeframeFilterEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/timeframes-filter.enum';
import { StatusAndAssignedPermissions } from 'projects/flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import {
  assignedFilterDisplayNames,
  assignedFilterEnum,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/assignedFilter.enum';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { map } from 'rxjs';
import {
  enumNotificationCategoryType,
  notificationCategoriesDisplayNames,
} from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-category-type';
import {
  enumNotificationTriggerType,
  notificationTriggerDisplayNames,
} from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-trigger-type';
import {
  enumNotificationType,
  notificationTypeDisplayNames,
} from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-type';
import { CustomNotificationViewDataModel } from '../models/custom-notification-view-data.model';
import { NotificationViewFilters } from '../models/notification-view-filters';
import {
  NotificationFilterColumns,
  NotificationFilterManagerColumns,
} from '../models/notification-filters-columns';

@Component({
  selector: 'lib-custom-view-notification-modal-component',
  templateUrl: './custom-view-notification-modal-component.component.html',
  styleUrls: ['./custom-view-notification-modal-component.component.scss'],
})
export class CustomViewNotificationModalComponent {
  newViewForm: FormGroup;

  currentFilters: NotificationViewFilters;
  availableColumns: string[];
  selectedColumns: string[];

  isEdition: boolean;

  allFilters = NotificationFilterColumns;

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: recurrenceEnumDisplayNames[recurrenceEnum[key]],
    }));

  categories = Object.keys(enumNotificationCategoryType)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: notificationCategoriesDisplayNames[enumNotificationCategoryType[key]],
    }));

  triggers = Object.keys(enumNotificationTriggerType)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: notificationTriggerDisplayNames[enumNotificationTriggerType[key]],
    }));

  notificationTypes = Object.keys(enumNotificationType)
    .filter(key => typeof notificationTypeDisplayNames[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: notificationTriggerDisplayNames[enumNotificationType[key]],
    }));

  currentUserId: string = '';
  publicView: boolean;
  isAdminUser: boolean;

  constructor(
    public dialogRef: MatDialogRef<CustomViewNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomNotificationViewDataModel,
    private formBuilder: FormBuilder,
    private router: Router,
    private stateService: ConfigStateService,
    public readonly localizationService: LocalizationService,
    public readonly slaService: SlaService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
  ) {
    this.currentFilters = { ...data.data };

    this.createForm();

    this.isEdition = data.isEdition ?? false;
  }

  ngOnInit() {
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];
    this.isAdminUser = currentRoles.includes('admin');

    this.loadAvailableColumns();

    this.loadPreselectedColumns();

    this.updateTimeframeOptions();

    if (this.data.isManager) {
      this.loadManagerData();

      this.availableColumns = [
        'types',
        'triggerTypes',
        'categoryTypes',
        'tags',
        'creationDates',
        'audience',
        'name',
        'summary',
        'status',
      ];

      this.selectedColumns = this.availableColumns;
    }
  }

  loadManagerData() {
    this.allFilters = NotificationFilterManagerColumns;
  }

  areEmptyFilters(): boolean {
    return (
      !this.currentFilters ||
      (!this.currentFilters.categoryTypes?.length &&
        !this.currentFilters.creationDates?.length &&
        !this.currentFilters.emails?.length &&
        !this.currentFilters.filter?.length &&
        !this.currentFilters.statuses?.length &&
        !this.currentFilters.tags?.length &&
        !this.currentFilters.triggerTypes?.length &&
        !this.currentFilters.types?.length)
    );
  }

  getBaseViewSelected(): string {
    return assignedFilterDisplayNames[assignedFilterEnum.All];
  }

  removeBaseViewSelected() {}

  isArray(property: any): boolean {
    return property instanceof Array;
  }

  isCondition(propertyName: string): boolean {
    let condition = this.allFilters.conditions.find(x => x.column == propertyName);

    return propertyName == 'filter' || !!condition;
  }

  getDisplayValue(propertyName: string, value: string): string {
    let condition = this.allFilters.conditions.find(x => x.column == propertyName);

    if (!condition) return '-';

    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown || condition.type === FilterType.DateWithOptions) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  getDisplayName(propertyName: string): string {
    if (propertyName == 'filter') return 'Search Text';

    if (propertyName == 'sorting') return 'Search Text';

    let condition = this.allFilters.conditions.find(x => x.column == propertyName);

    if (!condition) return propertyName;

    return condition.columnDisplayName;
  }

  getSortingDisplayName(sortingValue: string): string {
    if (!sortingValue) return '';

    let values = sortingValue.split(' ');

    return this.getDisplayName(values[0].trim());
  }

  getSortingCriteria(sortingValue: string): string {
    if (!sortingValue) return '';

    let values = sortingValue.split(' ');

    return values[1].trim();
  }

  removeCondition(propertyName: string, index: number): void {
    this.currentFilters[propertyName].splice(index, 1);
  }

  removeSingleCondition(propertyName: string): void {
    this.currentFilters[propertyName] = '';
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  createForm() {
    this.newViewForm = this.formBuilder.group({
      viewName: [this.currentFilters.viewName, Validators.required],
      general: [!this.currentFilters.privateView],
    });
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  handleOnSave(): void {
    if (!this.isValid()) return;

    const formValue = this.newViewForm.getRawValue();

    this.currentFilters.columns = this.selectedColumns;
    this.currentFilters.viewName = formValue.viewName;
    this.currentFilters.privateView = !formValue.general;

    this.dialogRef.close(this.currentFilters);
  }

  isValid(): boolean {
    return this.newViewForm.valid && this.selectedColumns.length > 0;
  }

  private updateTimeframeOptions(): void {
    const dataTimeframesFilter = Object.keys(TimeframeFilterEnum)
      .filter(key => typeof TimeframeFilterEnum[key] === 'number')
      .map(key => ({
        id: TimeframeFilterEnum[key],
        description: this.localizationService.instant(
          TimeframeFilterEnumDisplayNames[TimeframeFilterEnum[key]],
        ),
      }));

    const conditionDateTime = this.allFilters.conditions.find(c => c.column === 'creationDates');

    if (conditionDateTime) {
      conditionDateTime.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }
  }

  private loadAvailableColumns(): void {
    this.availableColumns = [
      'statuses',
      'types',
      'triggerTypes',
      'categoryTypes',
      'emails',
      'tags',
      'creationDates',
      'audience',
    ];
  }

  private loadPreselectedColumns(): void {
    this.selectedColumns = [
      'statuses',
      'types',
      'triggerTypes',
      'categoryTypes',
      'emails',
      'tags',
      'creationDates',
      'audience',
    ];
  }
}
