import { ModuleWithProviders, NgModule } from '@angular/core';
import { CORE_SERVICE_ROUTE_PROVIDERS } from './providers/route.provider';
import { LOOKUPS_CONTACT_TYPES_ROUTE_PROVIDER } from './providers/contact-types-route.provider';
import { LOOKUPS_CATEGORIES_ROUTE_PROVIDER } from './providers/categories-route.provider';
import { LOOKUPS_AIR_SPACE_CLASSIFICATIONS_ROUTE_PROVIDER } from './providers/air-space-classifications-route.provider';
import { LOOKUPS_CAPTURE_TYPES_ROUTE_PROVIDER } from './providers/capture-types-route.provider';
import { LOOKUPS_CONFIGURATION_TYPES_ROUTE_PROVIDER } from './providers/configuration-types-route.provider';
import { LOOKUPS_CONTACT_METHODS_ROUTE_PROVIDER } from './providers/contact-methods-route.provider';
import { LOOKUPS_CUSTOMER_NOTIFICATION_TYPES_ROUTE_PROVIDER } from './providers/customer-notification-types-route.provider';
import { LOOKUPS_DEPARTAMENTS_ROUTE_PROVIDER } from './providers/departaments-route.provider';
import { LOOKUPS_INDUSTRIES_ROUTE_PROVIDER } from './providers/industries-route.provider';
import { LOOKUPS_LEVEL_COMMUNICATIONS_ROUTE_PROVIDER } from './providers/level-communications-route.provider';
import { LOOKUPS_NOTIFICATION_TYPES_ROUTE_PROVIDER } from './providers/notification-types-route.provider';
import { LOOKUPS_PILOT_EXPERIENCE_LEVELES_ROUTE_PROVIDER } from './providers/pilot-experience-leveles-route.provider';
import { LOOKUPS_PILOT_STATES_ROUTE_PROVIDER } from './providers/pilot-states-route.provider';
import { LOOKUPS_PRIORITIES_ROUTE_PROVIDER } from './providers/priorities-route.provider';
import { LOOKUPS_REPORT_CATEGORIES_ROUTE_PROVIDER } from './providers/report-categories-route.provider';
import { LOOKUPS_TYPE_EQUIPMENTS_ROUTE_PROVIDER } from './providers/type-equipments-route.provider';
import { LOOKUPS_QUESTION_DATA_TYPES_ROUTE_PROVIDER } from './providers/question-data-types-route.provider';
import { LOOKUPS_QUESTION_TYPES_ROUTE_PROVIDER } from './providers/question-types-route.provider';
import { LOOKUPS_RESPONSE_TYPES_ROUTE_PROVIDER } from './providers/response-types-route.provider';
import { LOOKUPS_CONFIGURATION_ATTRIBUTE_TYPES_ROUTE_PROVIDER } from './providers/configuration-attribute-types-route.provider';
import { LOOKUPS_CUSTOMER_STATES_ROUTE_PROVIDER } from './providers/customer-states-route.provider';
import { LOOKUPS_LANGUAGES_ROUTE_PROVIDER } from './providers/languages-route.provider';
import { LOOKUPS_LEVELES_ROUTE_PROVIDER } from './providers/leveles-route.provider';
import { LOOKUPS_ADDRESS_TYPES_ROUTE_PROVIDER } from './providers/address-types-route.provider';
import { LOOKUPS_COUNTRIES_ROUTE_PROVIDER } from './providers/countries-route.provider';
import { LOOKUPS_STATES_ROUTE_PROVIDER } from './providers/states-route.provider';
import { LOOKUPS_EXPORTS_ROUTE_PROVIDER } from './providers/exports-route.provider';
import { LOOKUPS_PUSH_NOTIFICATIONS_STATES_ROUTE_PROVIDER } from './providers/push-notifications-states-route.provider';
import { LOOKUPS_DEVICE_TYPES_ROUTE_PROVIDER } from './providers/device-types-route.provider';
import { LOOKUPS_EMAIL_NOTIFICATIONS_STATES_ROUTE_PROVIDER } from './providers/email-notifications-states-route.provider';
import { LOOKUPS_REPORT_COLUMN_TYPES_ROUTE_PROVIDER } from './providers/report-column-types-route.provider';
import { LOOKUPS_REPORT_PARAMETER_TYPES_ROUTE_PROVIDER } from './providers/report-parameter-types-route.provider';
import { LOOKUPS_MISSION_PILOT_REQUEST_STATUS_ROUTE_PROVIDER } from './providers/mission-pilot-request-status-route.provider';
import { LOOKUPS_PRODUCT_TYPES_ROUTE_PROVIDER } from './providers/product-types-route.provider';
import { LOOKUPS_AVAILABILITY_ROUTE_PROVIDER } from './providers/availability-route.provider';
import { LOOKUPS_EQUIPMENT_TYPE_ROUTE_PROVIDER } from './providers/equipment-type-route.provider';
import { LOOKUPS_REVENUE_TYPE_ROUTE_PROVIDER } from './providers/revenue-type-route.provider';
import { LOOKUPS_EXPENSE_TYPE_ROUTE_PROVIDER } from './providers/expense-type-route.provider';
import { LOOKUPS_SERVICE_TYPE_ROUTE_PROVIDER } from './providers/service-type-route.provider';
import { LOOKUPS_PAYMENT_TYPE_ROUTE_PROVIDER } from './providers/payment-type-route.provider';
import { LOOKUPS_SUBSCRIPTIONS_TYPE_ROUTE_PROVIDER } from './providers/subcriptions-type-route.provider';
import { LOOKUPS_MISSIONS_TYPE_ROUTE_PROVIDER } from './providers/missions-type-route.provider';
import { LOOKUPS_INDUSTRIES_TYPE_ROUTE_PROVIDER } from './providers/industries-type-route.provider';
import { LOOKUPS_CUSTOMER_ATTRIBUTE_TYPE_ROUTE_PROVIDER } from './providers/customer-attribute-types-route.provider';
import { LOOKUPS_STATUS_ROUTE_PROVIDER } from './providers/status-route.provider';
import { LOOKUPS_ACTION_ROUTE_PROVIDER } from './providers/action-route.provider';
import { LOOKUPS_ACTION_ORDER_ROUTE_PROVIDER } from './providers/action-order-route.provider';
import { LOOKUPS_CONTROL_TYPES_ROUTE_PROVIDER } from './providers/control-types-route.provider';
import { LOOKUPS_EQUIPMENT_MANUFACTURES_ROUTE_PROVIDER } from './providers/equipment-manufactures-route.provider';
import { LOOKUPS_UOM_ROUTE_PROVIDER } from './providers/uom-route.provider';
import { LOOKUPS_REASONS_ROUTE_PROVIDER } from './providers/reasons-route.provider';
import { LOOKUPS_MISSIONS_STATUS_ROUTE_PROVIDER } from './providers/missions-status-route.provider';
import { LOOKUPS_MISSION_STATUS_ROLE_ROUTE_PROVIDER } from './providers/mission-status-role-route.provider';
import { LOOKUPS_REASON_CATEGORIES_ROUTE_PROVIDER } from './providers/reason-categories-route.provider';
import { LOOKUPS_ETHNICITY_ROUTE_PROVIDER } from './providers/ethnicity-route.provider';
import { LOOKUPS_GENDER_ROUTE_PROVIDER } from './providers/gender-route.provider';
import { LOOKUPS_SENSOR_TYPES_ROUTE_PROVIDER } from './providers/sensor-types-route.provider';
import { LOOKUPS_STATUS_EQUIPMENTS_ROUTE_PROVIDER } from './providers/status-equipments-route.provider';
import { LOOKUPS_FILE_TYPES_ROUTE_PROVIDER } from './providers/file-types-route.provider';
import { LOOKUPS_DELIVERABLE_CATEGORIES_ROUTE_PROVIDER } from './providers/deliverable-categories-route.provider';
import { LOOKUPS_CUSTOMER_VERTICAL_ROUTE_PROVIDER } from './providers/customer-vertical-route.provider';
import { LOOKUPS_AUTOMATIC_ASSIGNMENT_ROUTE_PROVIDER } from './providers/automatic-assignment-route.provider';
import { LOOKUPS_SLAS_ROUTE_PROVIDER } from './providers/slas-route.provider';
import { LOOKUPS_PAYMENT_STATUS_ROUTE_PROVIDER } from './providers/payment-status-route.provider';
import { LOOKUPS_CUSTOMER_ACTION_ORDER_ROUTE_PROVIDER } from './providers/customer-action-order-route.provider';

@NgModule()
export class CoreServiceConfigModule {
  static forRoot(): ModuleWithProviders<CoreServiceConfigModule> {
    return {
      ngModule: CoreServiceConfigModule,
      providers: [
        CORE_SERVICE_ROUTE_PROVIDERS,
        LOOKUPS_CONTACT_TYPES_ROUTE_PROVIDER,
        LOOKUPS_CATEGORIES_ROUTE_PROVIDER,
        LOOKUPS_AIR_SPACE_CLASSIFICATIONS_ROUTE_PROVIDER,
        LOOKUPS_CAPTURE_TYPES_ROUTE_PROVIDER,
        LOOKUPS_CONFIGURATION_TYPES_ROUTE_PROVIDER,
        LOOKUPS_CONTACT_METHODS_ROUTE_PROVIDER,
        LOOKUPS_CUSTOMER_NOTIFICATION_TYPES_ROUTE_PROVIDER,
        LOOKUPS_DEPARTAMENTS_ROUTE_PROVIDER,
        LOOKUPS_INDUSTRIES_ROUTE_PROVIDER,
        LOOKUPS_LEVEL_COMMUNICATIONS_ROUTE_PROVIDER,
        LOOKUPS_NOTIFICATION_TYPES_ROUTE_PROVIDER,
        LOOKUPS_PILOT_EXPERIENCE_LEVELES_ROUTE_PROVIDER,
        LOOKUPS_PILOT_STATES_ROUTE_PROVIDER,
        LOOKUPS_PRIORITIES_ROUTE_PROVIDER,
        LOOKUPS_REPORT_CATEGORIES_ROUTE_PROVIDER,
        LOOKUPS_TYPE_EQUIPMENTS_ROUTE_PROVIDER,
        LOOKUPS_QUESTION_DATA_TYPES_ROUTE_PROVIDER,
        LOOKUPS_QUESTION_TYPES_ROUTE_PROVIDER,
        LOOKUPS_RESPONSE_TYPES_ROUTE_PROVIDER,
        LOOKUPS_CONFIGURATION_ATTRIBUTE_TYPES_ROUTE_PROVIDER,
        LOOKUPS_CUSTOMER_STATES_ROUTE_PROVIDER,
        LOOKUPS_LANGUAGES_ROUTE_PROVIDER,
        LOOKUPS_LEVELES_ROUTE_PROVIDER,
        LOOKUPS_ADDRESS_TYPES_ROUTE_PROVIDER,
        LOOKUPS_COUNTRIES_ROUTE_PROVIDER,
        LOOKUPS_STATES_ROUTE_PROVIDER,
        LOOKUPS_EXPORTS_ROUTE_PROVIDER,
        LOOKUPS_PUSH_NOTIFICATIONS_STATES_ROUTE_PROVIDER,
        LOOKUPS_DEVICE_TYPES_ROUTE_PROVIDER,
        LOOKUPS_EMAIL_NOTIFICATIONS_STATES_ROUTE_PROVIDER,
        LOOKUPS_REPORT_COLUMN_TYPES_ROUTE_PROVIDER,
        LOOKUPS_REPORT_PARAMETER_TYPES_ROUTE_PROVIDER,
        LOOKUPS_MISSION_PILOT_REQUEST_STATUS_ROUTE_PROVIDER,
        LOOKUPS_PRODUCT_TYPES_ROUTE_PROVIDER,
        LOOKUPS_AVAILABILITY_ROUTE_PROVIDER,
        LOOKUPS_EQUIPMENT_TYPE_ROUTE_PROVIDER,
        LOOKUPS_REVENUE_TYPE_ROUTE_PROVIDER,
        LOOKUPS_EXPENSE_TYPE_ROUTE_PROVIDER,
        LOOKUPS_SERVICE_TYPE_ROUTE_PROVIDER,
        LOOKUPS_PAYMENT_TYPE_ROUTE_PROVIDER,
        LOOKUPS_SUBSCRIPTIONS_TYPE_ROUTE_PROVIDER,
        LOOKUPS_MISSIONS_TYPE_ROUTE_PROVIDER,
        LOOKUPS_INDUSTRIES_TYPE_ROUTE_PROVIDER,
        LOOKUPS_CUSTOMER_ATTRIBUTE_TYPE_ROUTE_PROVIDER,
        LOOKUPS_STATUS_ROUTE_PROVIDER,
        LOOKUPS_ACTION_ROUTE_PROVIDER,
        LOOKUPS_ACTION_ORDER_ROUTE_PROVIDER,
        LOOKUPS_CONTROL_TYPES_ROUTE_PROVIDER,
        LOOKUPS_EQUIPMENT_MANUFACTURES_ROUTE_PROVIDER,
        LOOKUPS_UOM_ROUTE_PROVIDER,
        LOOKUPS_REASONS_ROUTE_PROVIDER,
        LOOKUPS_MISSIONS_STATUS_ROUTE_PROVIDER,
        LOOKUPS_MISSION_STATUS_ROLE_ROUTE_PROVIDER,
        LOOKUPS_REASON_CATEGORIES_ROUTE_PROVIDER,
        LOOKUPS_ETHNICITY_ROUTE_PROVIDER,
        LOOKUPS_GENDER_ROUTE_PROVIDER,
        LOOKUPS_SENSOR_TYPES_ROUTE_PROVIDER,
        LOOKUPS_STATUS_EQUIPMENTS_ROUTE_PROVIDER,
        LOOKUPS_FILE_TYPES_ROUTE_PROVIDER,
        LOOKUPS_DELIVERABLE_CATEGORIES_ROUTE_PROVIDER,
        LOOKUPS_CUSTOMER_VERTICAL_ROUTE_PROVIDER,
        LOOKUPS_AUTOMATIC_ASSIGNMENT_ROUTE_PROVIDER,
        LOOKUPS_SLAS_ROUTE_PROVIDER,
        LOOKUPS_PAYMENT_STATUS_ROUTE_PROVIDER,
        LOOKUPS_CUSTOMER_ACTION_ORDER_ROUTE_PROVIDER,
      ],
    };
  }
}
