import { ConfigStateService } from '@abp/ng.core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  Output,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { WebNotificationDismiss } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';
import { WebNotificationContentsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';

@Component({
  selector: 'flg-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [animate(200), style({ opacity: 1 })]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class FlyguysDrawerComponent implements OnChanges {
  @ViewChild(MatMenu) menu: MatMenu;
  @Input() matMenu: MatMenu;
  @Input() notifications: any[];
  @Input() title: string = '';
  @Input() viewAllUrl: string = '';
  @Input() itemTemplate: TemplateRef<any>;
  @Input() mode: string = 'Notifications';
  @Output() clearedNotification = new EventEmitter<boolean>();
  notificationsMode: string = 'Notifications';

  cleared: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notifications?.previousValue?.length < changes.notifications?.currentValue?.length) {
      this.cleared = false;
    }
  }
  /**
   * Notifies subscribers that the notes have been read
   */
  markAsRead() {
    this.cleared = true;
    if (this.notificationsMode == this.mode) {
      this.clearedNotification.emit(this.cleared);
    }
  }
}
