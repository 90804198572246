<div class="custom-modalbox form-container">
  <mat-icon
    class="close-modal-action"
    aria-hidden="false"
    aria-label="{{ 'shipmentService::CloseIcon' | abpLocalization }}"
    fontIcon="close"
    mat-dialog-close
    (click)="onClickClose()"
  ></mat-icon>

  <div class="groups row">
    <div class="col-sm-12 formTitle">
      <div class="label-container-main">
        <mat-label class="label-container-title">
          {{ 'shipmentService::MissionName' | abpLocalization }}:
        </mat-label>
        <mat-label class="label-container-value">{{ data.missionName }}</mat-label>
      </div>
    </div>

    <div class="col-sm-4 left-side mission-details">
      <div class="title-container">
        <h3 class="left-side-title">
          {{ 'shipmentService::ReferenceInformation' | abpLocalization }}
        </h3>
      </div>

      <app-left-side-item
        label="{{ 'shipmentService::CaptureDate' | abpLocalization }}"
        [content]="data.captureDate"
      ></app-left-side-item>

      <app-left-side-item
        label="{{ 'shipmentService::CaptureTime' | abpLocalization }}"
        [content]="data.captureTime"
      ></app-left-side-item>

      <app-left-side-item
        label="{{ 'shipmentService::CustomerName' | abpLocalization }}"
        [content]="data.customerName"
      ></app-left-side-item>

      <app-left-side-item
        label="{{ 'shipmentService::Location' | abpLocalization }}"
        [content]="data.location"
      ></app-left-side-item>

      <app-left-side-item
        label="{{ 'shipmentService::Pilot' | abpLocalization }}"
        [content]="data.pilot"
      ></app-left-side-item>
    </div>

    <div class="col-sm-8 right-side">
      <div class="title-container">
        <h3 class="title">{{ 'shipmentService::AddShipment' | abpLocalization }}</h3>
      </div>

      <form [formGroup]="form">
        <div>
          <mat-form-field class="d-flex mb-3 w-100" subscriptSizing="dynamic">
            <mat-label>{{ 'shipmentService::TrackingNumber' | abpLocalization }}</mat-label>
            <input matInput formControlName="trackingNumber" required />
          </mat-form-field>

          <mat-form-field class="d-flex mb-3 w-100" subscriptSizing="dynamic">
            <mat-label>{{ 'shipmentService::Carrier' | abpLocalization }}</mat-label>
            <mat-select formControlName="carrierId" required>
              <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
                {{ carrier.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="d-flex mb-3 w-100" subscriptSizing="dynamic">
            <mat-label>{{ 'shipmentService::EstimatedArrivalDate' | abpLocalization }}</mat-label>
            <input
              matInput
              [matDatepicker]="arrivalDatePicker"
              formControlName="estimatedArrivalDate"
            />
            <mat-datepicker-toggle matSuffix [for]="arrivalDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #arrivalDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="d-flex mb-3 w-100" subscriptSizing="dynamic">
            <mat-label>{{ 'shipmentService::Note' | abpLocalization }}</mat-label>
            <textarea matInput formControlName="note" rows="4"></textarea>
          </mat-form-field>
        </div>
      </form>

      <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
        <div class="d-flex justify-content-end">
          <button
            mat-flat-button
            class="outline-button me-2 badge-action-button"
            type="button"
            (click)="onClickClose()"
          >
            {{ 'shipmentService::Cancel' | abpLocalization }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button badge-action-button"
            type="button"
            (click)="onClickSave()"
            [disabled]="!form.valid"
          >
            {{ 'shipmentService::Save' | abpLocalization }}
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>
