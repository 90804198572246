import { mapEnumToOptions } from '@abp/ng.core';

export enum enumNotificationStatus {
    Sent = 0,
    Incomplete = 1,
    Failed = 2,
    Queued = 3
}

export const EnumNotificationStatusOptions = mapEnumToOptions(enumNotificationStatus);
