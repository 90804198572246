import { ConfigStateService, HttpWaitService, trackBy } from '@abp/ng.core';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ChatConfigService, ChatMessage } from '@volo/abp.ng.chat/config';
import { concat, fromEvent, Subject, Subscription, switchMap } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { ChatMessageSide } from '../enums/chat-message-side';
import { ChatContactDto } from '../models/chat-contact-dto';
import { ChatMessageDto } from '../models/chat-message-dto';
import { ConversationService } from '../services/conversation.service';
import { ChatContactsComponent } from './chat-contacts.component';
import { NotificationsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';

import { CustomizerSettingsService } from '../../../../flyguys/src/app/components/tagus/customizer-settings/customizer-settings.service';
import { format } from 'date-fns';
import { SmsNotificationsMultipleCreateDto } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics/models';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import { MatDialog } from '@angular/material/dialog';

import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import { FileDescriptorService, FileInfoDto } from '@volo/abp.ng.file-management/proxy';
import { downloadBlob } from '@abp/ng.core';
import { ChatImageDto } from '../models';
import { ChatImageViewerComponent } from './chat-image-viewer/chat-image-viewer.component';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();

@Component({
  selector: 'abp-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements AfterViewInit, OnDestroy {
  selectedContact = {} as ChatContactDto;
  unreadMessageCount = 0;
  userMessages = new Map<string, ChatMessageDto[]>();
  chatMessageSide = ChatMessageSide;
  message: string;
  message1: string;
  sendOnEnter: boolean;
  sendAsSMS: boolean = false;
  loading: boolean;
  pagingLoading: boolean;
  allMessagesLoaded: boolean;
  clickedStartMessage: boolean = false;

  @ViewChild('chatBox', { static: false })
  chatBoxRef: ElementRef<HTMLDivElement>;

  @ViewChild(ChatContactsComponent, { static: false })
  chatContactsComponent: ChatContactsComponent;

  trackByMessageDate = trackBy<ChatMessageDto>('messageDate');

  destroy$ = new Subject();

  get selectedContactMessages(): ChatMessageDto[] {
    return this.userMessages.get(this.selectedContact.userId) || [];
  }

  fileUpdated: boolean = false;
  loadingFile: boolean = false;
  stringValue?: string = null;
  fileValue?: string = null;
  imageMessage?: string = null;
  maxFileSizeFiles: number = 200 * 1024 * 1024;

  constructor(
    private conversationService: ConversationService,
    private chatConfigService: ChatConfigService,
    private configState: ConfigStateService,
    private httpWaitService: HttpWaitService,
    public themeService: CustomizerSettingsService,
    private notificationService: NotificationsService,
    public dialogService: MatDialog,
    public loadingService: LoadingOverlayService,
    public readonly fileDescriptorService: FileDescriptorService,
  ) {
    this.httpWaitService.addFilter([
      {
        method: 'POST',
        endpoint: '/api/chat/conversation/send-message',
      },
      {
        method: 'GET',
        endpoint: '/api/chat/contact/contacts',
      },
    ]);
  }

  private listenToChatBoxScroll() {
    if (this.chatBoxRef) {
      fromEvent(this.chatBoxRef.nativeElement, 'scroll')
        .pipe(debounceTime(150), takeUntil(this.destroy$))
        .subscribe(this.onScroll);
    }
  }

  private listenToNewMessages() {
    this.chatConfigService.message$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ senderUserId, text } = {} as ChatMessage) => {
        if (!this.userMessages.has(senderUserId)) return;

        const isSelected = this.selectedContact.userId === senderUserId;
        this.userMessages.get(senderUserId).push({
          message: text,
          messageDate: new Date(),
          side: ChatMessageSide.Receiver,
          isRead: isSelected,
          readDate: isSelected ? new Date() : null,
        });
        this.scrollToEnd();
      });
  }

  ngAfterViewInit() {
    this.sendOnEnter =
      (
        this.configState.getSetting('Volo.Chat.Messaging.SendMessageOnEnter') || ''
      ).toLowerCase() !== 'false';

    this.listenToChatBoxScroll();
    this.listenToNewMessages();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getConversation(scrollToEnd: boolean) {
    this.allMessagesLoaded = false;
    this.conversationService
      .getConversationByInput({
        skipCount: 0,
        maxResultCount: 50,
        targetUserId: this.selectedContact.userId,
      })
      .subscribe(res => {
        this.userMessages.set(this.selectedContact.userId, res.messages.reverse());
        if (scrollToEnd) this.scrollToEnd();
        if (this.selectedContact.unreadMessageCount) this.markConversationAsRead();
      });
  }

  send() {
    if ((!this.message?.trim() && !this.imageMessage?.trim()) || this.loading) return;

    let _message: string = '';

    _message =
      (this.message == null ? '' : this.message.trim()) +
      (this.imageMessage == null ? '' : this.imageMessage.trim()) +
      (this.message1 == null ? '' : this.message1.trim());

    this.unreadMessageCount = 0;
    this.loading = true;

    this.conversationService
      .sendMessageByInput({ message: _message, targetUserId: this.selectedContact.userId })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.chatContactsComponent.changeLastMessageOfSelectedContact(_message);

        if (!this.userMessages.has(this.selectedContact.userId)) {
          this.userMessages.set(this.selectedContact.userId, []);
        }

        this.userMessages.get(this.selectedContact.userId).push({
          message: _message,
          isRead: true,
          readDate: new Date(),
          messageDate: new Date(),
          side: ChatMessageSide.Sender,
        });

        if (this.sendAsSMS) {
          this.sendSMS(_message);
        }

        this.message = '';
        this.message1 = '';
        this.fileUpdated = true;
        this.stringValue = null;
        this.fileValue = null;
        this.imageMessage = null;

        this.scrollToEnd();
      });
  }

  markConversationAsRead() {
    this.conversationService
      .markConversationAsReadByInput({ targetUserId: this.selectedContact.userId })
      .subscribe(() => {
        this.chatContactsComponent.markSelectedContactAsRead();
        this.selectedContact.unreadMessageCount = 0;
        setTimeout(() => (this.unreadMessageCount = 0), 5000);
      });
  }

  sendWithEnter(event: KeyboardEvent) {
    if (this.sendOnEnter && event.key === 'Enter') {
      event.preventDefault();
      this.send();
    }
  }

  onSelectContact(contact: ChatContactDto) {
    this.selectedContact = contact;
    this.unreadMessageCount = contact.unreadMessageCount;
    this.scrollToEnd();

    if (this.userMessages.has(contact.userId) || !contact.lastMessage) {
      if (contact.unreadMessageCount) this.markConversationAsRead();
      return;
    }
    this.getConversation(true);
  }

  getDateInLocalTime(date: string | Date): Date {
    if (date instanceof Date) {
      return date;
    }

    let newDate: Date = new Date(date + 'Z');
    return newDate;
  }

  getDateFormat(date: string | Date): string {
    date = new Date(date);
    const messageDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();

    if (messageDay === today) return 'shortTime';

    return 'short';
  }

  scrollToEnd() {
    setTimeout(() => {
      const { offsetTop } = (document.querySelector('.unread-message-count-badge-wrapper') ||
        {}) as HTMLDivElement;

      this.chatBoxRef?.nativeElement.scrollTo({
        top: offsetTop ? offsetTop - 60 : this.chatBoxRef.nativeElement.scrollHeight,
      });
    }, 0);
  }

  onScroll = (event: Event) => {
    if (
      this.allMessagesLoaded ||
      this.pagingLoading ||
      !this.selectedContact.lastMessage ||
      this.chatBoxRef?.nativeElement.scrollTop > 250 ||
      this.selectedContactMessages.length % 50 !== 0
    ) {
      event.preventDefault();
      return;
    }

    this.pagingLoading = true;
    this.conversationService
      .getConversationByInput({
        skipCount: this.selectedContactMessages.length,
        maxResultCount: 50,
        targetUserId: this.selectedContact.userId,
      })
      .pipe(finalize(() => (this.pagingLoading = false)))
      .subscribe(res => {
        if (!res.messages.length) {
          this.allMessagesLoaded = true;
          return;
        }
        this.userMessages.get(this.selectedContact.userId).unshift(...res.messages.reverse());
      });
  };

  startConversation() {
    this.clickedStartMessage = true;
  }

  sendSMS(message: string) {
    let currentUser = this.configState.getOne('currentUser');
    let smsNotification: SmsNotificationsMultipleCreateDto = {
      users: [this.selectedContact.userId],
      roles: [],
      phoneNumbers: [],
      message: `FG chat,\n${currentUser.name}\nSays:\n"${message}"`,
      description: 'SMS from chat',
    };
    this.notificationService.createMultipleOptions(smsNotification).subscribe();
  }

  onFileSelected(files: File[]) {
    this.fileUpdated = true;

    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum file size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles,
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      this.uploadFile(files[0]);
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  uploadFile(file: File): void {
    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    this.loadingFile = true;
    const formData = new FormData();
    formData.append('fileList', file);

    this.fileDescriptorService
      .uploadAttachMentsFolder('Chat_Images', this.selectedContact.userId, formData)
      .subscribe(
        (res: FileInfoDto[]) => {
          if (res[0]?.id && res[0]?.fileAttachmentUrl) {
            this.stringValue = res[0].id;
            this.fileValue = res[0].name;

            let _imgMessage: ChatImageDto = new ChatImageDto();

            _imgMessage.id = res[0].id;
            _imgMessage.src = res[0]?.fileAttachmentUrl;
            _imgMessage.name = res[0].name;

            this.imageMessage = '||img||' + JSON.stringify(_imgMessage) + '||img||';

            if (this.message == null) {
              this.message = ' ';
            }
          }

          this.loadingFile = false;
        },
        error => {
          this.loadingFile = false;
        },
      );
  }

  downloadDocument() {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(this.stringValue)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(this.stringValue, token)),
        finalize(() => this.loadingService.hideOverlay()),
      )
      .subscribe(result => {
        downloadBlob(result, this.fileValue);
      });
  }

  onFileRemoved(): void {
    this.fileUpdated = true;

    this.stringValue = null;
    this.fileValue = null;

    if (this.message != null) {
      this.message = this.message.replace(this.imageMessage, '');
    }

    this.imageMessage = null;
  }

  validateImage(message: string): boolean {
    if (message == null) {
      return false;
    }

    if (message.indexOf('||img||') != -1) {
      return true;
    }

    return false;
  }

  getImageProp(message: string, prop: string): string {
    let _return: string = '';

    if (message.indexOf('||img||') != -1) {
      let _messages: string[] = message.split('||img||');

      _messages.forEach(_message => {
        if (_message != '' && _message.indexOf('{"id"') != -1) {
          let _imgMessage: ChatImageDto = JSON.parse(_message);

          switch (prop) {
            case 'id': {
              _return = _imgMessage.id;
              break;
            }
            case 'src': {
              _return = _imgMessage.src;
              break;
            }
            case 'name': {
              _return = _imgMessage.name;
              break;
            }
            default: {
              break;
            }
          }
        }
      });
    }

    return _return;
  }

  viewErrorzoomImage(message: string) {
    if (message.indexOf('||img||') != -1) {
      let _messages: string[] = message.split('||img||');

      _messages.forEach(_message => {
        if (_message != '' && _message.indexOf('{"id"') != -1) {
          let _imgMessage: ChatImageDto = JSON.parse(_message);

          const dialogData = {
            title: _imgMessage.name,
            detail: _imgMessage,
          };

          const dialogRef = this.dialogService.open(ChatImageViewerComponent, {
            data: dialogData,
            disableClose: true,
            width: '900px',
          });
        }
      });
    }
  }

  separateMessages(message: string): string[] {
    let lsitMessages: string[] = [];

    let _messages: string[] = message.split('||img||');

    _messages.forEach(_message => {
      if (_message.trim() != '') {
        if (_message.indexOf('{"id"') != -1) {
          _message = '||img||' + _message + '||img||';
        }

        lsitMessages.push(_message);
      }
    });

    return lsitMessages;
  }
}
