<flg-generic-modal>
  <ng-container modalTitle>
    <div class="modal-title-container">
      Validation Results
      <span>
        <button class="btn p-0" (click)="onClose()">
          <mat-icon aria-hidden="false" aria-label="Close" fontIcon="close"></mat-icon>
        </button>
      </span>
    </div>
  </ng-container>

  <div modalBody>
    <div class="d-flex align-items-center mb-2">
      <span class="overall-status-label">Overall Status:</span>
      <app-column-stylizer
        class="overall-status-value"
        [columnValue]="getOverallStatus()"
        [typeColumn]="'text'"
        [columnRules]="automaticQaRules"
      ></app-column-stylizer>
    </div>

    <ng-container *ngIf="data.isSingleFile">
      <ngx-datatable
        class="flg-grid-table"
        [rows]="flattenedSingleFileResults"
        [headerHeight]="45"
        [footerHeight]="50"
        [rowHeight]="45"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="File Name" prop="fileName" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fileName || '' }}" [matTooltipShowDelay]="2000">
              {{ row.fileName || '' }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tolerance Code" prop="toleranceCode" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.toleranceCode }}" [matTooltipShowDelay]="2000">
              {{ row.toleranceCode }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" prop="result" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer
              [columnValue]="row.result"
              [typeColumn]="'text'"
              [columnRules]="automaticQaRules"
            ></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Expected"
          prop="fieldValue"
          [sortable]="false"
          [minWidth]="250"
          [width]="250"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fieldValue }}" [matTooltipShowDelay]="2000">
              {{ row.fieldValue }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Actual"
          prop="fileValue"
          [sortable]="false"
          [minWidth]="250"
          [width]="250"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fileValue || 'N/A' }}" [matTooltipShowDelay]="2000">
              {{ row.fileValue || 'N/A' }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tolerance" prop="tolerance" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ getToleranceDisplay(row) }}" [matTooltipShowDelay]="2000">
              {{ getToleranceDisplay(row) }}
            </span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-container>

    <ng-container *ngIf="!data.isSingleFile">
      <mat-label class="d-block validations-modal-subtitle mt-2 mb-2"
        >Deliverable Results</mat-label
      >
      <ngx-datatable
        class="flg-grid-table"
        [rows]="deliverableResults"
        [headerHeight]="45"
        [footerHeight]="50"
        [rowHeight]="45"
        [scrollbarH]="true"
      >
        <ngx-datatable-column
          name="Tolerance Code"
          prop="toleranceCode"
          [sortable]="false"
          [width]="250"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.toleranceCode }}" [matTooltipShowDelay]="2000">
              {{ row.toleranceCode }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" prop="result" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer
              [columnValue]="row.result"
              [typeColumn]="'text'"
              [columnRules]="automaticQaRules"
            ></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Expected" prop="fieldValue" [sortable]="false" [width]="350">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fieldValue }}" [matTooltipShowDelay]="2000">
              {{ row.fieldValue }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actual" prop="fileValue" [sortable]="false" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fileValue || 'N/A' }}" [matTooltipShowDelay]="2000">
              {{ row.fileValue || 'N/A' }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tolerance" prop="tolerance" [sortable]="false" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ getToleranceDisplay(row) }}" [matTooltipShowDelay]="2000">
              {{ getToleranceDisplay(row) }}
            </span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <mat-label class="d-block validations-modal-subtitle mt-3 mb-2">File Results</mat-label>

      <ngx-datatable
        class="flg-grid-table"
        [rows]="flattenedFileResults"
        [headerHeight]="45"
        [footerHeight]="50"
        [rowHeight]="45"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="File Name" prop="fileName" [sortable]="false" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fileName || '' }}" [matTooltipShowDelay]="1000">
              {{ row.fileName || '' }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Tolerance Code"
          prop="toleranceCode"
          [sortable]="false"
          [width]="250"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.toleranceCode }}" [matTooltipShowDelay]="1000">
              {{ row.toleranceCode }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" prop="result" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer
              [columnValue]="row.result"
              [typeColumn]="'text'"
              [columnRules]="automaticQaRules"
            ></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Expected" prop="fieldValue" [sortable]="false" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fieldValue }}" [matTooltipShowDelay]="2000">
              {{ row.fieldValue }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actual" prop="fileValue" [sortable]="false" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.fileValue || 'N/A' }}" [matTooltipShowDelay]="2000">
              {{ row.fileValue || 'N/A' }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tolerance" prop="tolerance" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ getToleranceDisplay(row) }}" [matTooltipShowDelay]="2000">
              {{ getToleranceDisplay(row) }}
            </span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-container>
  </div>
</flg-generic-modal>
