<app-search-filter (search)="onSearch($event)"></app-search-filter>

<mat-selection-list
  class="deliverablelist"
  #deliverableslist
  (selectionChange)="selectionChange($event)"
>
  <mat-list-option
    *ngFor="let data of filteredOptions; let i = index"
    checkboxPosition="before"
    [value]="data"
    [name]="'selector' + i"
    class="mt-1 mb-1"
    [selected]="data.selected"
  >
    <div class="deliverable-item">
      <div class="name-column">
        <span class="name" [class.mb-1]="data.productDescription">{{ data.productName }}</span>
        <span
          class="description"
          *ngIf="data.productDescription"
          matTooltip="{{ data.productDescription }}"
          [matTooltipShowDelay]="1000"
          >{{ data.productDescription }}</span
        >
      </div>
      <div class="price-column" (click)="$event.preventDefault()">
        <div *ngIf="!data.editingPrice" class="d-flex align-items-center">
          <span class="deliverable-price">
            <span class="currency-culture">$</span>
            <span class="price">
              {{ data.standardPrice | number: '1.2-2' }}
            </span>
          </span>
          <button
            *ngIf="data.selected"
            mat-icon-button
            (click)="quantityUpdated($event); data.editingPrice = true"
          >
            <mat-icon class="mat-36">edit</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="data.editingPrice">
          <div class="d-flex align-items-center">
            <mat-form-field
              class="compressed-form-field-density package-quantity bg-white me-1 w-100-px"
              subscriptSizing="dynamic"
            >
              <input
                class="w-33"
                matInput
                type="number"
                min="1"
                (click)="quantityUpdated($event)"
                [(ngModel)]="data.standardPrice"
              />
            </mat-form-field>
            <button mat-icon-button (click)="quantityUpdated($event); updatePrice(data)">
              <mat-icon class="mat-36">save</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
      <div class="quantity-column">
        <mat-form-field class="compressed-form-field-density" subscriptSizing="dynamic">
          <!-- We don't have the adder component that the ticket needs. See https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/10349 -->
          <input
            matInput
            type="number"
            [(ngModel)]="data.quantity"
            min="1"
            #quantityInput
            (input)="onQuantityChange(data)"
            (click)="quantityUpdated($event)"
            (blur)="onQuantityBlur(data)"
            [name]="data.deliverableId + i"
          />
        </mat-form-field>
      </div>
      <div class="button-column" (click)="addAttributes($event, data, data.selected)">
        <button
          class="button-text"
          mat-button
          type="button"
          [disabled]="!data.selected"
          [ngClass]="{
            'text-danger': data.incompleteConfiguration || data.requiredConfigurationIncomplete,
          }"
          color="primary"
          (click)="addAttributes($event, data, data.selected)"
        >
          Add Attributes
        </button>
      </div>
    </div>
  </mat-list-option>
</mat-selection-list>
<small class="text-warning" *ngIf="incompleteConfiguration && !missingRequiredConfiguration">{{
  'missionsService::IncompleteConfiguration' | abpLocalization
}}</small>
<br *ngIf="incompleteConfiguration && !missingRequiredConfiguration" />
<small class="text-danger" *ngIf="missingRequiredConfiguration">{{
  'missionsService::RequiredConfigurationMissing' | abpLocalization
}}</small>
<div class="mt-3 mb-3 w-100">
  <button
    class="common-button"
    [disabled]="
      this.filteredOptions.length === this.model.productsbyIndustry.length ||
      this.valuesFound.length > 0
    "
    mat-button
    type="button"
    color="primary"
    (click)="nextPage()"
  >
    See More
  </button>
</div>
