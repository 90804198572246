import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetGridViewsInput,
  GridViewAssignationDto,
  GridViewsCreateDto,
  GridViewsDto,
  GridViewsExcelDownloadDto,
  GridViewsUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class GridViewsService {
  apiName = 'missionsService';

  create = (input: GridViewsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/grid-views',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  update = (input: GridViewsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewsDto>(
      {
        method: 'PUT',
        url: '/api/missions-service/grid-views',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  disable = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/grid-views/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  enable = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/missions-service/grid-views/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  remove = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/grid-views/${id}/remove`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/grid-views/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  assignToUser = (assignation: GridViewAssignationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewAssignationDto>(
      {
        method: 'POST',
        url: `/api/missions-service/grid-views/user/assignation`,
        body: assignation,
      },
      { apiName: this.apiName, ...config },
    );

  removeAssignation = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewsDto>(
      {
        method: 'DELETE',
        url: `/api/missions-service/grid-views/user/assignation/${userId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAssignation = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GridViewAssignationDto>(
      {
        method: 'GET',
        url: `/api/missions-service/grid-views/user/assignation/${userId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/grid-views/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: GridViewsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/grid-views/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetGridViewsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GridViewsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/grid-views',
        params: {
          filterText: input.filterText,
          name: input.name,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
          userId: input.userId,
          isAdmin: input.isAdmin,
          private: input.private,
          viewType: input.viewType,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getAll = (userId: string, isAdmin: boolean, viewType: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<GridViewsDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/grid-views/all/user/${userId}/admin/${isAdmin}/${viewType}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
