<div class="new-order">
  <div class="d-flex align-items-center mb-3">
    <span
      class="material-symbols-outlined go-back"
      [routerLink]="'/orders/list'"
      matTooltip="Go back to the Orders Grid"
      [matTooltipShowDelay]="2000"
    >
      arrow_back
    </span>
    <app-fg-breadcrumb
      [breadcrumbs]="[
        { text: 'Orders', link: '/orders/list' },
        { text: 'New Order', link: '/orders/new' },
      ]"
    ></app-fg-breadcrumb>
  </div>

  <h2>New Order</h2>

  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-4">
        <mat-stepper
          (animationDone)="handleAnimationDone()"
          (selectionChange)="onStepChange($event)"
        >
          <mat-step>
            <ng-template matStepLabel
              ><span class="step-title">Client Information</span></ng-template
            >
            <app-first-step
              [form]="formStepOne"
              [customerId]="orderModel?.customerId"
              [customerContactFirstName]="orderModel?.customerContactFirstName"
              [customerContactLastName]="orderModel?.customerContactLastName"
              [copiedAdditionalContacts]="orderModel?.additionalContacts"
              (discardChange)="discardCreation()"
              (contactsUpdatedChange)="onContactsUpdatedChange()"
              (customAttributesChange)="onCustomAttributesChange($event)"
              (nextStep)="onFirstStepNext()"
            ></app-first-step>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel><span class="step-title">Order Request</span></ng-template>
            <app-second-step
              [form]="formStepTwo"
              [model]="orderModel"
              [SLAOptions]="SLAOptions"
              (discardChange)="discardCreation()"
              [contactsUpdated]="contactsUpdated"
              #secondStep
            ></app-second-step>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel><span class="step-title">Deliverables</span></ng-template>
            <ng-template matStepContent>
              <app-third-step
                [form]="formStepThree"
                [model]="orderModel"
                (discardChange)="discardCreation()"
                (kmlFields)="updateKmlData($event)"
                (kmlFieldsPkg)="updateKmlDataPkg($event)"
                (attributesDetails)="updateAttDetails($event)"
                #thirdStep
              ></app-third-step>
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel><span class="step-title">Confirmation</span></ng-template>
            <ng-template matStepContent>
              <app-fourth-step
                [form]="formStepOne"
                [model]="orderModel"
                [kmlData]="kmlData"
                [kmlDataPkg]="kmlDataPkg"
                [attributesDetails]="attributesDetails"
                (discardChange)="discardCreation()"
                (saveChange)="saveAll()"
              ></app-fourth-step>
            </ng-template>
          </mat-step>

          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
