import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CustomerActionOrderConfiguredDto,
  CustomerActionOrderCreateDto,
  CustomerActionOrderDto,
  CustomerActionOrderExcelDownloadDto,
  CustomerActionOrderUpdateDto,
  GetCustomerActionOrdersInput,
} from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerActionOrderService {
  apiName = 'CoreService';

  create = (input: CustomerActionOrderCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerActionOrderDto>(
      {
        method: 'POST',
        url: '/api/core-service/customer-action-orders',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/core-service/customer-action-orders/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerActionOrderDto>(
      {
        method: 'GET',
        url: `/api/core-service/customer-action-orders/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/core-service/customer-action-orders/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetCustomerActionOrdersInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerActionOrderDto>>(
      {
        method: 'GET',
        url: '/api/core-service/customer-action-orders',
        params: {
          filterText: input.filterText,
          statusId: input.statusId,
          customerId: input.customerId,
          statusName: input.statusName,
          actionId: input.actionId,
          actionName: input.actionName,
          roleId: input.roleId,
          roleName: input.roleName,
          orderMin: input.orderMin,
          orderMax: input.orderMax,
          isPaginated: input.isPaginated,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (
    input: CustomerActionOrderExcelDownloadDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/core-service/customer-action-orders/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: CustomerActionOrderUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerActionOrderDto>(
      {
        method: 'PUT',
        url: `/api/core-service/customer-action-orders/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  filterByRoles = (customerId: string, input: Array<string>, config?: Partial<Rest.Config>) => {
    let data = {
      customerId: customerId,
      roles: input,
    };

    return this.restService.request<any, CustomerActionOrderConfiguredDto[]>(
      {
        method: 'POST',
        url: '/api/core-service/customer-action-orders/filter/role',
        body: data,
      },
      { apiName: this.apiName, ...config },
    );
  };

  filterCustomerOrdersByRoles = (input: Array<string>, config?: Partial<Rest.Config>) => {
    let data = {
      roles: input,
    };

    return this.restService.request<any, CustomerActionOrderConfiguredDto[]>(
      {
        method: 'POST',
        url: '/api/core-service/customer-action-orders/filter/only/role',
        body: data,
      },
      { apiName: this.apiName, ...config },
    );
  };

  constructor(private restService: RestService) {}
}
