<ol class="breadcrumb">
  <li
    *ngFor="
      let folder of navigatedFolders;
      let last = last;
      let first = first;
      trackBy: trackByIdAndName
    "
    (click)="onClick(folder)"
    class="breadcrumb-item"
    [class.root]="first"
    [class.active]="last && !first"
    [class.pe-none]="last"
    aria-current="page"
  >
    <span class="breadcrumb-item-text">
      <ng-container *ngIf="first">
        <span>{{ rootBreadcrumbName | abpLocalization }}</span>
        <em *ngIf="currentRootDetails" class="ms-1">
          ({{ currentRootDetails.itemsCount }}
          <span *ngIf="currentRootDetails.itemsCount > 1">
            {{ 'FileManagement::Items' | abpLocalization }}
          </span>
          <span *ngIf="currentRootDetails.itemsCount <= 1">
            {{ 'FileManagement::Item' | abpLocalization }}
          </span>
          | {{ currentRootDetails.size | fileSize }})
        </em>
      </ng-container>
      <span *ngIf="!first">{{ folder.name }} </span>
    </span>
  </li>
</ol>
