<form [formGroup]="formPackage" class="compressed-form-field-density">
  <div [ngClass]="{ 'package-card': !package.selected, 'package-selected': package.selected }">
    <div class="package-title">
      <div *ngIf="!package.selected" class="package-subtitle mb-2">{{ package.packageName }}</div>
      <div *ngIf="package.selected" class="package-settings mb-2">
        <div class="package-subtitle-selected me-2">{{ package.packageName }}</div>
        <div class="d-flex align-items-baseline">
          <div class="icon-button">
            <mat-icon
              class="icon3"
              [ngClass]="{ 'text-danger': incompleteConfiguration }"
              (click)="editAttributes(true)"
              >settings</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="deliverable-container">
      <div class="package-deliverable" *ngFor="let dv of this.package.deliverables">
        <div class="icon">
          <mat-icon class="done">done</mat-icon>
        </div>
        <div class="list-item-text">
          <div class="label-deliverable-name">
            {{ dv.productName }}
            <span *ngIf="dv.quantity > 0"> x {{ dv.originalquantity }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="package-price">
        <div class="package-price-label d-flex">
          <ng-container *ngIf="!editingPrice">
            ${{ package.packagePrice | number: '1.2-2' }}
            <mat-icon *ngIf="package.selected" (click)="editingPrice = true" class="ms-1"
              >edit</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="editingPrice">
            <div class="d-flex align-items-center">
              <mat-form-field class="package-quantity bg-white me-1" subscriptSizing="dynamic">
                <input matInput type="number" min="1" formControlName="price" />
              </mat-form-field>
              <mat-icon (click)="updatePrice()" class="ms-1">save</mat-icon>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="d-flex align-items-center" *ngIf="package.selected">
        <mat-form-field class="package-quantity bg-white me-1" subscriptSizing="dynamic">
          <input
            matInput
            type="number"
            min="1"
            formControlName="quantity"
            #quantityInput
            (input)="onQuantityChange($event)"
            (blur)="onQuantityBlur()"
          />
        </mat-form-field>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      *ngIf="!package.selected"
      (click)="selectPackage()"
    >
      Select Package
    </button>

    <div *ngIf="package.selected && !editingPrice" class="div-selected-buttons">
      <div class="div-selected-buttons-sub">
        <div class="icon-button">
          <button
            class="button-text"
            mat-button
            type="button"
            color="primary"
            (click)="unselectPackage()"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<small class="text-warning" *ngIf="incompleteConfiguration && !package.incompletePackage">{{
  'missionsService::IncompleteConfiguration' | abpLocalization
}}</small>
<br *ngIf="incompleteConfiguration && !package.incompletePackage" />
<small class="text-danger" *ngIf="package.incompletePackage">{{
  'missionsService::RequiredConfigurationMissing' | abpLocalization
}}</small>
