import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersGridRefreshService {
  private refresh$: Subject<boolean> = new Subject();
  private refreshMission$: Subject<boolean> = new Subject();

  /**
   * Provides an observable to listen upon
   * subscribe to this to get trigger notifications for grid updates
   * @returns Observable<boolean>
   */
  listenForGridChanges(): Observable<boolean> {
    return this.refresh$.asObservable();
  }

  /**
   * Provides an observable to listen upon
   * subscribe to this to get trigger notifications for mission updates
   * @returns Observable<boolean>
   */
  listonForMissionChanges(): Observable<boolean> {
    return this.refreshMission$.asObservable();
  }

  /**
   * Triggers a whole orders grid refresh
   */
  triggerGridRefresh(): void {
    this.refresh$.next(true);
  }

  /**
   * Triggers a refresh of the currently active mission
   */
  triggerMissionRefresh(): void {
    this.refreshMission$.next(true);
  }
}
