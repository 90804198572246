import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild, 
  ElementRef
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import {
  ConfigStateService,
  CurrentUserDto,
  EnvironmentService,
  PagedResultDto,
} from '@abp/ng.core';

import {
  CustomerContactsCreateDto,
  CustomerContactsDto,
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import {
  CustomerContactsService,
  CustomersService,
} from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { OrderFormContactModel } from '../../model/order-form-contact.model';
import { OrderContactComponent } from '../../order-contact/order-contact.component';
import { ConfirmDialogComponent } from '../../../common/confirm-dialog/confirm.dialog.component';
import {
  type AddressesCreateDto,
  ContactsCreateDto,
  ContactsDto,
  ContactsUpdateDto,
  Control,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  AddressesService,
  ContactsService,
  MissionsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/shared/enum-state.enum';
import { NewCustomerFormComponent } from '../../new-customer-form/new-customer-form.component';
import {
  BillingInformation,
  CreateCustomer,
  CustomerType,
} from '../../model/order-form-create-customer.model';
import { AccountService, RegisterDto } from '@volo/abp.ng.account/public';
import { ContactTypesService } from '../../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import type { GetContactTypeInput } from '../../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ToasterService } from '@abp/ng.theme.shared';
import { IdentityUserService, IdentityUserUpdateDto } from '@volo/abp.ng.identity/proxy';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';
import { getPendingControls } from '@flyguys/components';

const requiredControlsNames = {
  customerDescription: 'Requesting Company Name',
  customerContactFirstName: 'Project Manager First Name',
  customerContactLastName: 'Project Manager Last Name',
  customerContactEmail: 'Project Manager Email',
};

@Component({
  selector: 'app-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
})
export class FirstStepComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() customerId: string;
  @Input() copiedAdditionalContacts: OrderFormContactModel[];
  @Input() customerContactFirstName: string;
  @Input() customerContactLastName: string;

  @Output() discardChange: EventEmitter<boolean> = new EventEmitter();
  @Output() contactsUpdatedChange = new EventEmitter<boolean>();

  @ViewChild("cotactInput") myContactInput: ElementRef;

  dataCustomers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };
  customerselected: CustomersDto;
  filterCustomers = {} as GetCustomerInput;
  filteredCustomers: Observable<string[]>;

  disableAddContactBtn: boolean = true;

  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { name: 'Actions' },
  ];

  billingContactCode = 'BILLING';
  mainContactCode = 'MAIN';

  private subscriptions = new Subscription();

  loaders: Map<string, boolean> = new Map();

  get loadInProgress() {
    return [...this.loaders.entries()].find(([_, state]) => state === true);
  }

  controls: Control<string>[] = [];
  showControls: boolean = false;
  values = [];

  get additionalContacts(): FormControl {
    return this.form.get('additionalContacts') as FormControl;
  }

  projectManagerValueSelected: string;

  constructor(
    public readonly accountService: AccountService,
    public readonly customerService: CustomersService,
    public readonly customerContactService: CustomerContactsService,
    public readonly contactsService: ContactsService,
    public readonly addressService: AddressesService,
    public readonly missionsService: MissionsService,
    public readonly contactTypeService: ContactTypesService,
    public readonly customerContactsService: CustomerContactsService,
    public readonly identityService: IdentityUserService,
    private fb: FormBuilder,
    public dialogService: MatDialog,
    private toaster: ToasterService,
    private environment: EnvironmentService,
    private configState: ConfigStateService
  ) {
    this.loaders.set('customerDescription', false);
    this.loaders.set('subclientName', false);
  }

  ngOnInit(): void {
    this.form.get('projectManagerSearch').disable();

    this.disableProjectManagerFields();
    this.disableAdditionalContactsFields();
    this.form.get('subclientName').disable();

    this.filteredCustomers = this.form.controls.customerDescription.valueChanges.pipe(
      tap(() => {
        this.cleanProjectManagerModelAndFields();
        // Also reset the autocomplete options
        this.contacts = [];
        this.customerContactsList = [];
      }),
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.loaders.set('customerDescription', true)),
      switchMap(() =>
        this.generateSuggestionsCustomers().pipe(
          finalize(() => this.loaders.set('customerDescription', false))
        )
      )
    );

    this.initSubClientSearch();

    if (!this.form.get('additionalContacts')) {
      this.form.addControl('additionalContacts', new FormControl([]));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerId']) {
      const newCustomerId = changes['customerId'].currentValue;
      if (newCustomerId) {
        this.handleNewCustomerId(newCustomerId);
      }
    }

    if (changes['copiedAdditionalContacts']) {
      const contacts = changes['copiedAdditionalContacts'].currentValue;
      if (contacts) {
        this.form.get('additionalContacts').setValue(contacts);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  verifyInput(value: string) {
    if (value == '') {
      this.showControls = false;
    }
  }

  getCustomer(event: MatAutocompleteSelectedEvent) {
    if (this.dataCustomers?.items.length > 0) {
      const selectedCustomer = this.dataCustomers.items.find(
        s => s.name.trim() === event.option.value.trim()
      );

      if (selectedCustomer) {
        this.customerselected = selectedCustomer;
        this.customerId = selectedCustomer.id;

        this.form.get('customerId').setValue(selectedCustomer.id);

        this.getContacts(selectedCustomer.id);
        this.enableProjectManagerFields();
        this.form.get('projectManagerSearch').enable();
        this.form.get('projectManagerSearch').setValue('');
        this.form.get('subclientName').enable();
      }
    }
  }

  handleAddContact(): void {
    let contact = new OrderFormContactModel();

    const dialogRef = this.dialogService.open(OrderContactComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: {
        contact,
        addingContact: true,
        title: 'Add New Contact',
        actions: {
          confirm: 'Add',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const contactCreateDto: ContactsCreateDto = {
            contactTypeId: data.contactFromId,
            contactTypeDescription: data.contactFromDescription,
            firstName: data.name,
            lastName: data.lastname,
            phone: data.number,
            email: data.email,
            levelCommunicationId: data.levelCommunicationId,
            levelCommunicationDescription: data.levelCommunicationDescription,
            shareData: data.shareData,
            contactMethodId: data.preferredContactMethodId,
            contactMethodDescription: data.preferredContactMethodDescription,
            state: enumState.Enabled,
            userId: data.userId,
          };

          this.contactsService.create(contactCreateDto).subscribe({
            next: (createdContact: ContactsDto) => {
              const contactId = createdContact.id;

              const customerContactCreateDto: CustomerContactsCreateDto = {
                contactId: contactId,
                customerId: this.customerId,
                state: enumState.Enabled,
              };

              this.customerContactsService.create(customerContactCreateDto).subscribe({
                next: (createdCustomerContact: CustomerContactsDto) => {
                  if (createdCustomerContact) {
                    this.getContacts(this.customerId);
                    data.contactId = contactId;
                    data.userId = createdContact.userId;
                    const contacts = [...this.additionalContacts.value, ...[data]];
                    this.additionalContacts.setValue(contacts);
                    this.toaster.success(
                      `Contact '${data.name} ${data.lastname}' created successfully.`
                    );
                  }
                },
                error: err => console.log(err),
              });
            },
            error: err => console.log(err),
          });
        }
      })
    );
  }

  handleRemoveContact(row: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((removeContact: Boolean) => {
        if (removeContact) {
          const contacts = this.additionalContacts.value;
          const indexToRemove = contacts.indexOf(row);

          if (indexToRemove !== -1) {
            contacts.splice(indexToRemove, 1);
            this.additionalContacts.setValue(contacts);
          }
        }
      })
    );
  }

  handleEditContact(contact: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(OrderContactComponent, {
      width: '900px',
      data: {
        contact,
        addingContact: false,
        title: 'Edit Contact',
        actions: {
          confirm: 'Edit',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          // Edit the contact in the database
          const contactsUpdateDto: ContactsUpdateDto = {
            contactTypeId: data.contactFromId,
            contactTypeDescription: data.contactFromDescription,
            firstName: data.name,
            lastName: data.lastname,
            phone: data.number,
            email: data.email,
            levelCommunicationId: data.levelCommunicationId,
            levelCommunicationDescription: data.levelCommunicationDescription,
            contactMethodId: data.preferredContactMethodId,
            contactMethodDescription: data.preferredContactMethodDescription,
            state: enumState.Enabled,
          };

          this.contactsService.update(data.contactId, contactsUpdateDto).subscribe({
            next: (updatedContact: ContactsDto) => {
              this.toaster.success(
                `Contact '${updatedContact.firstName} ${updatedContact.lastName}' updated successfully.`
              );

              // Edit the contacts grid
              const contacts = this.additionalContacts.value;
              const indexToEdit = contacts.findIndex(
                contact => contact.contactId === updatedContact.id
              );

              if (indexToEdit !== -1) {
                const updatedContacts = [...contacts];
                updatedContacts[indexToEdit] = {
                  ...contacts[indexToEdit],
                  contactFromId: data.contactFromId,
                  contactFromDescription: data.contactFromDescription,
                  email: data.email,
                  name: data.name,
                  lastname: data.lastname,
                  number: data.number,
                  shareData: data.shareData,
                };
                this.additionalContacts.setValue(updatedContacts);
              }

              this.getContacts(this.customerId);
              this.contactsUpdatedChange.emit();
            },
            error: err => console.log('Error updating contact:', err),
          });
        }
      })
    );
  }

  private generateSuggestionsCustomers(): Observable<string[]> {
    this.filterCustomers.name = this.form.controls.customerDescription.value;

    return this.customerService.getList(this.filterCustomers).pipe(
      map((data: PagedResultDto<CustomersDto>) => {
        const suggestions: string[] = [];
        if (data?.totalCount !== 0) {
          this.dataCustomers = data;
          for (let s of this.dataCustomers.items) {
            suggestions.push(s.name.trim());
          }
        }

        return suggestions;
      }),
      catchError(error => {
        console.log('Error on getting customers: ' + JSON.stringify(error));

        return of([]);
      })
    );
  }

  discard() {
    this.discardChange.emit(true);
  }

  mapDataToSendEmail(data: CreateCustomer): string {
    let customerInviterName = '';
    if (data.customerType === CustomerType.Personal) {
      customerInviterName = data.personal.firstName + ' ' + data.personal.lastName;
    } else {
      customerInviterName =
        data.company.mainContact.firstName + ' ' + data.company.mainContact.lastName;
    }

    return customerInviterName;
  }

  handleAddCustomer(): void {
    const dialogRef = this.dialogService.open(NewCustomerFormComponent, {
      disableClose: true,
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data || !data.billingInformation) {
        this.handleCustomerCreationError();
        return;
      }

      let registerData;
      const registerDto = this.mapToRegisterDto(data);
      this.accountService.register(registerDto).subscribe({
        next: registerResponse => {
          registerData = registerResponse;

          let customerInviter = this.mapDataToSendEmail(data);
          let currentUser = this.configState.getOne('currentUser') as CurrentUserDto;
          let appNameWithParams = 'MVC' + ':' + customerInviter + ':' + currentUser.userName;

          const updateUserData = this.getDataUpdateUser(data, registerData);
          this.identityService.update(registerData.id, updateUserData).subscribe({
            next: (_) => {
              this.missionsService.createCustomerAdminUser(registerData.id).subscribe({
                next: () => {
                  this.accountService
                    .sendPasswordResetCode({
                      email: registerDto.emailAddress,
                      appName: appNameWithParams,
                      returnUrl: this.environment.getEnvironment().apis.CustomerApp.url,
                    })
                    .subscribe(() => {
                      this.createCustomerResources(data, registerData.id, registerData.userName);
                    });
                },
                error: error => {
                  console.log('Error creating customer admin user', error);
                },
              });
            },
            error: err => console.log('Error creating customer admin user', err),
          });
        },
        error: err => {
          console.log('Registration failed:', err);
          this.createCustomerResources(data, '', '');
        },
      });
    });
  }

  private getDataUpdateUser(data: CreateCustomer, registerData: any): IdentityUserUpdateDto {
    const userFirstName =
      data.customerType === CustomerType.Personal
        ? data.personal.firstName
        : data.company.mainContact.firstName;
    const userLastName =
      data.customerType === CustomerType.Personal
        ? data.personal.lastName
        : data.company.mainContact.lastName;

    return {
      shouldChangePasswordOnNextLogin: false,
      userName: registerData.userName,
      email: registerData.email,
      isActive: registerData.isActive,
      lockoutEnabled: registerData.lockoutEnabled,
      roleNames: [FormHelpers.CustomerRoles.USER],
      name: userFirstName,
      surname: userLastName,
      organizationUnitIds: [],
      extraProperties: { enableSMSReception: true },
    };
  }

  private createCustomerResources(
    data: CreateCustomer,
    userId: string,
    descriptionUser: string
  ): void {
    const contactTypeInput = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetContactTypeInput;

    this.contactTypeService.getList(contactTypeInput).subscribe({
      next: res => {
        const mainContactType = res.items.find(item => item.code === this.mainContactCode);

        let mainContactTypeId = '';

        if (mainContactType) {
          mainContactTypeId = mainContactType.id;
        }

        const addressDto = this.mapToAddressCreateDto(data.billingInformation);
        const mainContactDto = this.mapToMainContactDto(
          data,
          userId,
          descriptionUser,
          mainContactTypeId
        );

        const createAddress$ =
          addressDto?.streetAddress?.length > 0 && addressDto?.countryName?.length > 0
            ? this.addressService.create(addressDto)
            : of(null);

        const createMainContact$ = this.contactsService.create(mainContactDto);

        forkJoin([createAddress$, createMainContact$]).subscribe({
          next: ([addressResponse, mainContactResponse]) => {
            const updatedBillingInfo = {
              ...data.billingInformation,
              addressId: addressResponse ? addressResponse.id : null,
            };

            const createCustomerData: CreateCustomer = {
              ...data,
              mainContactId: mainContactResponse.id,
              billingInformation: updatedBillingInfo,
            };

            this.customerService.customCreate(createCustomerData).subscribe({
              next: (res: CustomersDto) => {
                if (res) {
                  this.toaster.success(`Customer '${res.name}' created successfully.`);

                  this.form.controls.customerDescription.setValue(res.name);
            
                  this.filterCustomers.name = res.name;
            
                  this.customerService.getList(this.filterCustomers).subscribe({
                    next: (results) => {
                      const suggestions: string[] = [];
                      if (results?.totalCount !== 0) {
                        this.dataCustomers = results;
                        for (let s of this.dataCustomers.items) {
                          suggestions.push(s.name.trim());
                        }
            
                        if (this.dataCustomers?.items.length > 0) {
                          const selectedCustomer = this.dataCustomers.items.find(
                            s => s.name.trim() === res.name.trim()
                          );
                    
                          if (selectedCustomer) {
                            this.customerselected = selectedCustomer;
                            this.customerId = selectedCustomer.id;
                    
                            this.form.get('customerId').setValue(selectedCustomer.id);
                    
                            this.getContacts(selectedCustomer.id);
                            this.enableProjectManagerFields();
                            this.form.get('projectManagerSearch').enable();
                            this.form.get('projectManagerSearch').setValue('');
                            this.form.get('subclientName').enable();

                            this.myContactInput.nativeElement.focus();
                          }
                        }  
                      }
                    }
                  });                  
                }
              },
              error: err => {
                console.error(err);
                this.handleCustomerCreationError();
              },
            });
          },
          error: err => {
            console.error(err);
            this.handleCustomerCreationError();
          },
        });
      },
      error: err => console.log(err),
    });
  }

  private mapToRegisterDto(data: CreateCustomer): RegisterDto {
    let emailAddress;

    if (data.customerType === CustomerType.Personal) {
      emailAddress = data.personal.email;
    } else {
      emailAddress = data.company.mainContact.email;
    }

    return {
      userName: emailAddress,
      emailAddress: emailAddress,
      password: 'Pass' + crypto.randomUUID().toString(),
      appName: 'Angular',
    };
  }

  private mapToAddressCreateDto(bi: BillingInformation): AddressesCreateDto {
    return {
      streetAddress: bi.address,
      countryId: bi.countryId,
      countryName: bi.countryName,
      stateId: bi.stateId,
      stateName: bi.stateName,
      city: bi.city,
      zipCode: bi.zipCode,
      state: enumState.Enabled,
    };
  }

  private mapToBillingContactDto(
    bi: BillingInformation,
    billingContactTypeId: string
  ): ContactsCreateDto {
    return {
      contactTypeId: billingContactTypeId,
      firstName: bi.billingFirstName,
      lastName: bi.billingLastName,
      phone: bi.phoneNumber,
      email: bi.email,
      shareData: false,
      state: enumState.Enabled,
    };
  }

  private mapToMainContactDto(
    data: CreateCustomer,
    userId: string,
    descriptionUser: string,
    mainContactTypeId: string
  ): ContactsCreateDto {
    const dto: ContactsCreateDto =
      data.customerType === CustomerType.Personal
        ? {
            contactTypeId: mainContactTypeId,
            firstName: data.personal.firstName,
            lastName: data.personal.lastName,
            phone: data.personal.primaryPhoneNumber,
            email: data.personal.email,
            shareData: false,
            state: enumState.Enabled,
          }
        : {
            contactTypeId: mainContactTypeId,
            firstName: data.company.mainContact.firstName,
            lastName: data.company.mainContact.lastName,
            phone: data.company.mainContact.primaryPhoneNumber,
            email: data.company.mainContact.email,
            shareData: false,
            state: enumState.Enabled,
          };

    dto.userId = userId;
    dto.descriptionUser = descriptionUser;

    return dto;
  }

  private handleCustomerCreationError(): Observable<null> {
    this.toaster.info('No customer was created.');
    return of(null);
  }

  get shouldDisplayError(): boolean {
    return this.form.get('customerDescription').value && !this.customerId;
  }

  customerContactsList: ContactsDto[] = [];
  contacts: ContactsDto[] = [];

  private getContacts(customerId) {
    this.customerContactService.getCustomerContacts(customerId).subscribe({
      next: (response: PagedResultDto<CustomerContactsDto>) => {
        const customerContactsList = response.items;

        const contactIds = customerContactsList.map(contact => contact.contactId);

        this.contactsService.getContactsByListOfIds(contactIds).subscribe({
          next: (data: ContactsDto[]) => (this.contacts = data),
          error: err => console.error('Error fetching contacts:', err),
        });
      },
      error: err => console.log(err),
    });

    this.customerContactsList = this.contacts;

    this.form.get('projectManagerSearch').valueChanges.subscribe(val => {
      if (typeof val === 'string' && this.projectManagerValueSelected != val) {
        this.projectManagerValueSelected = val;
        this.enableProjectManagerFields();
        this.enableAdditionalContactsFields();
        this.cleanProjectManagerModelAndFields();

        this.customerContactsList = this.contacts.filter(
          contact =>
            (contact.firstName && contact.firstName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.lastName && contact.lastName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.phone && contact.phone.toLowerCase().includes(val.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(val.toLowerCase())) ||
            (((contact.firstName === null ? '' : contact.firstName).toLowerCase() + ' ' + (contact.lastName === null ? '' : contact.lastName).toLowerCase()).includes(val.toLowerCase()))
        );
      }
    });

    this.form.get('additionalContactsSearch').valueChanges.subscribe(val => {
      if (typeof val === 'string') {
        this.customerContactsList = this.contacts.filter(
          contact =>
            (contact.firstName && contact.firstName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.lastName && contact.lastName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.phone && contact.phone.toLowerCase().includes(val.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(val.toLowerCase())) ||
            (((contact.firstName === null ? '' : contact.firstName).toLowerCase() + ' ' + (contact.lastName === null ? '' : contact.lastName).toLowerCase()).includes(val.toLowerCase()))
        );
      }
    });
  }

  openAutocompletePanel(autocompleteTrigger: MatAutocompleteTrigger) {
    if (!autocompleteTrigger.panelOpen) {
      // TODO can be improved ... for now it is a way to achieve opening the panel with suggestions on the first focus
      setTimeout(() => {
        this.customerContactsList = this.contacts;
        autocompleteTrigger.openPanel();
      }, 500);
    }
  }

  onContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    this.form
      .get('projectManagerSearch')
      .setValue(selectedContact.firstName + ' ' + selectedContact.lastName);

    this.form.get('customerContactId').setValue(selectedContact.id);
    this.form.get('customerContactFirstName').setValue(selectedContact.firstName);
    this.form.get('customerContactLastName').setValue(selectedContact.lastName);
    this.form.get('customerContactEmail').setValue(selectedContact.email);
    this.form.get('customerContactNumber').setValue(selectedContact.phone);

    this.disableProjectManagerFields();
  }

  private enableProjectManagerFields() {
    this.form.get('customerContactId').enable();
    this.form.get('customerContactFirstName').enable();
    this.form.get('customerContactLastName').enable();
    this.form.get('customerContactEmail').enable();
    this.form.get('customerContactNumber').enable();
  }

  private enableAdditionalContactsFields() {
    this.form.get('additionalContactsSearch').enable();
    this.disableAddContactBtn = false;
    this.additionalContacts.setValue([]);
  }

  private disableProjectManagerFields() {
    this.form.get('customerContactId').disable();
    this.form.get('customerContactFirstName').disable();
    this.form.get('customerContactLastName').disable();
    this.form.get('customerContactEmail').disable();
    this.form.get('customerContactNumber').disable();
  }

  private disableAdditionalContactsFields() {
    this.form.get('additionalContactsSearch').disable();
    this.disableAddContactBtn = true;
  }

  private cleanProjectManagerModelAndFields() {
    this.form.get('customerContactId').setValue('');
    this.form.get('customerContactFirstName').setValue('');
    this.form.get('customerContactLastName').setValue('');
    this.form.get('customerContactEmail').setValue('');
    this.form.get('customerContactNumber').setValue('');
  }

  onAddContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    // Check if the contact already exists in the grid
    if (this.additionalContacts.value.some(contact => contact.contactId === selectedContact.id)) {
      this.toaster.warn('This contact is already added.');
      this.form.get('additionalContactsSearch').setValue('');
      return;
    }

    const contactToAdd: OrderFormContactModel = {
      contactId: selectedContact.id || '',
      contactFromId: selectedContact.contactTypeId || '',
      contactFromDescription: selectedContact.contactTypeDescription || '',
      email: selectedContact.email || '',
      name: selectedContact.firstName,
      lastname: selectedContact.lastName,
      number: selectedContact.phone || '',
      shareData: false,
      preferredContactMethodId: selectedContact.contactMethodId || '',
      preferredContactMethodDescription: selectedContact.contactMethodDescription || '',
      levelCommunicationId: selectedContact.levelCommunicationId || '',
      levelCommunicationDescription: selectedContact.levelCommunicationDescription || '',
      customerContactDescription: '',
      customerId: '',
      userId: selectedContact.userId || '',
    };

    const contacts: OrderFormContactModel[] = [...this.additionalContacts.value, contactToAdd];
    this.additionalContacts.setValue(contacts);

    this.form.get('additionalContactsSearch').setValue('');
    this.toaster.success(
      `Contact '${contactToAdd.name} ${contactToAdd.lastname}'added successfully.`
    );
  }

  subClientsList: CustomersDto[] = [];
  customers: CustomersDto[] = [];
  searchTerms = new Subject<string>();

  initSubClientSearch() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.searchSubClients(term)),
        catchError(error => {
          console.error(error);
          return of([]);
        })
      )
      .subscribe((data: PagedResultDto<CustomersDto>) => {
        // Filter out the selected customer to avoid subclient to be the same as the customer
        this.subClientsList = data.items.filter(customer => customer.id !== this.customerId);
      });
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  searchSubClients(filterText: string): Observable<PagedResultDto<CustomersDto>> {
    const input: GetCustomerInput = {
      filterText: filterText,
      name: filterText,
      skipCount: 0,
      maxResultCount: 10,
      isPaginated: true,
      parentId: '',
      state: enumState.Enabled,
    };

    return this.customerService.getList(input);
  }

  onSubClientSelected(event: MatAutocompleteSelectedEvent) {
    const selectedSubClient: CustomersDto = event.option.value;

    this.form.get('subcustomerId').setValue(selectedSubClient.id);
    this.form.get('subclientName').setValue(selectedSubClient.name);
  }

  private handleNewCustomerId(newCustomerId: string) {
    this.form.get('customerId').setValue(newCustomerId);
    this.form.get('projectManagerSearch').enable();
    this.form.get('additionalContactsSearch').enable();
    this.disableAddContactBtn = false;

    this.getContacts(newCustomerId);

    // We need to temporarily store the values in temporary variables because the changes subscription will clear these values when the 'projectManagerSearch' field is updated
    const customerContactId = this.form.get('customerContactId').getRawValue();
    const customerContactFirstName = this.form.get('customerContactFirstName').getRawValue();
    const customerContactLastName = this.form.get('customerContactLastName').getRawValue();
    const customerContactEmail = this.form.get('customerContactEmail').getRawValue();
    const customerContactNumber = this.form.get('customerContactNumber').getRawValue();

    const currentValue = this.form.get('projectManagerSearch').value;
    if (!currentValue) {
      this.form
        .get('projectManagerSearch')
        .setValue(this.customerContactFirstName + ' ' + this.customerContactLastName);

      this.form.get('customerContactId').setValue(customerContactId);
      this.form.get('customerContactFirstName').setValue(customerContactFirstName);
      this.form.get('customerContactLastName').setValue(customerContactLastName);
      this.form.get('customerContactEmail').setValue(customerContactEmail);
      this.form.get('customerContactNumber').setValue(customerContactNumber);

      this.disableProjectManagerFields();

      this.form.get('subclientName').enable();
    }
  }

  onInputBlur(inputValue: string | CustomersDto): void {
    if (typeof inputValue === 'string') {
      const matchingCustomer = this.subClientsList.find(
        customer => customer.name.trim().toLowerCase() === inputValue.trim().toLowerCase()
      );

      if (matchingCustomer) {
        this.form.get('subcustomerId').setValue(matchingCustomer.id);
        this.form.get('subclientName').setValue(matchingCustomer.name);
      } else {
        // By passing a null ID we will create a new customer on the DB
        this.form.get('subcustomerId').setValue(null);
        this.form.get('subclientName').setValue(inputValue);
      }
    } else if (typeof inputValue === 'object' && inputValue !== null) {
      this.form.get('subcustomerId').setValue(inputValue.id);
      this.form.get('subclientName').setValue(inputValue.name);
    }
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    return getPendingControls(this.form, requiredControlsNames);
  }
}
