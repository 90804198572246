import { Component, Input, OnInit } from '@angular/core';
import { DeliverablesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { FinancialDataDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { MissionFlowDto } from '../../models/mission-flow-dto';

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.scss'],
})
export class FinancialsComponent implements OnInit {
  @Input() mission: MissionFlowDto;
  @Input() missionId: string;
  @Input() captureId: string;

  deliverables: { [id: string]: string } = {};
  financialDataDto: FinancialDataDto;

  pilotSourcingCommunicationSubscription: Subscription;

  constructor(
    private delivService: DeliverablesService,
    private missionService: MissionFlowService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
  ) {}

  ngOnInit(): void {
    this.delivService.getList({ maxResultCount: 100 }).subscribe(data => {
      this.deliverables = data.items?.reduce(
        (result, r) => {
          result[r.id] = r.name;
          return result;
        },
        {} as { [id: string]: string },
      );
    });

    this.loadData();

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe(() => {
        this.loadData();
      });
  }

  loadData() {
    this.missionService
      .getFinancialsData(this.missionId)
      .subscribe(r => (this.financialDataDto = r));
  }
}
