import { Component, Input, OnInit } from '@angular/core';
import { OrderFormDeliverableModel } from '../../model/order-form-deliverable.model';
import { MatDialog } from '@angular/material/dialog';
import { DeliverableAttributesComponent } from '../../../../shared/deliverable-attributes/deliverable-attributes.component';
import { OrderDetailSummaryModel, OrderFormModel } from '../../model/order-form.model';
import { DeliverableAttributesListComponent } from 'projects/flyguys/src/app/shared/deliverable-attributes-list/deliverable-attributes-list.component';
import { ItemSummaryTypeEnum } from '../../model/item-summary-type.enum';
import {
  AttributesInfo,
  KmlDataInfoPkg,
} from 'projects/flyguys/src/app/shared/package/package.component';

@Component({
  selector: 'app-orders-detail-table',
  templateUrl: './order-details-list.component.html',
  styleUrls: ['./order-details-list.component.scss'],
})
export class OrderDetailsListComponent implements OnInit {
  @Input() isFourthStep: boolean = false;
  @Input() isKML: boolean = false;
  @Input() orderDeliverableDetail: OrderFormDeliverableModel[] = [];
  @Input() orderTotal: number = 0;
  columns: OrderFormDeliverableModel[] = [];

  @Input() model: OrderFormModel;
  @Input() showSummaryTable: boolean;
  @Input() kmlDataPkg: KmlDataInfoPkg[] = [];
  @Input() attributesDetailed: AttributesInfo[] = [];

  constructor(private dialogService: MatDialog) {}

  ngOnInit(): void {}

  viewAttributes(row: OrderFormDeliverableModel): void {
    const dialogData = {
      title: 'View Deliverable Attributes',
      deliverableData: row,
      readonly: true,
    };

    const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
      data: dialogData,
      disableClose: true,
      width: '1150px',
    });
  }

  viewAttributesSummary(row: OrderDetailSummaryModel): void {
    if (row.type == ItemSummaryTypeEnum.Package) {
      row.deliverables.forEach(x => {
        const kmlData = this.kmlDataPkg.filter(kml => kml.packageId == x.packageId);
        if (kmlData) {
          if (x.sameConfiguration) {
            const kmlByProd = kmlData.find(t => t.productId == x.productId);
            if (kmlByProd && kmlByProd.kmlField) {
              const fieldsWithoutKml = x.fields.filter(
                f => !f.typeCode.toLowerCase().includes('kml'.toLowerCase()),
              );
              x.fields = [...fieldsWithoutKml, ...kmlByProd.kmlField];
            }
          } else {
            const kmlByProd = kmlData.filter(t => t.productId == x.productId);
            let newKmlFields = [];
            kmlByProd.forEach(kmlByPr => {
              if (kmlByPr.kmlField) {
                newKmlFields = [...newKmlFields, ...kmlByPr.kmlField];
              }
            });
            const fieldsWithoutKml = x.fields.filter(
              f => !f.typeCode.toLowerCase().includes('kml'.toLowerCase()),
            );
            x.fields = [...fieldsWithoutKml, ...newKmlFields];
          }
        }
      });
      const dialogData = {
        title: 'View Deliverable Attributes',
        deliverableList: row.deliverables,
        quantity: row.quantity,
        isEdition: true,
        readonly: true,
        isFromSummary: true,
      };

      this.dialogService.open(DeliverableAttributesListComponent, {
        data: dialogData,
        disableClose: true,
      });
    } else {
      if (this.isKML) {
        row.deliverables.forEach(rd => {
          const matchingDeliverable = this.orderDeliverableDetail.find(
            x => x.deliverableId === rd.deliverableId,
          );
          if (matchingDeliverable) {
            const kmlField = matchingDeliverable.fields.filter(mat =>
              mat.typeCode.toLowerCase().includes('kml'.toLowerCase()),
            );
            rd.fields = rd.fields.filter(
              field => !field.typeCode.toLowerCase().includes('kml'.toLowerCase()),
            );
            rd.fields.push(...kmlField);
          }
        });
      }
      const dialogData = {
        title: 'View Deliverable Attributes',
        deliverableData: row.deliverables[0],
        isEdition: true,
        readonly: true,
        fromMissionSummary: this.isFourthStep,
      };

      const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
        data: dialogData,
        disableClose: true,
        width: '1150px',
      });
    }
  }
}
