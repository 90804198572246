import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MissionChangeLogDto } from '../../../models/mission-change-log-dto';

@Component({
  selector: 'app-timeline-event',
  templateUrl: './timeline-event.component.html',
  styleUrls: ['./timeline-event.component.scss'],
})
export class TimelineEventComponent {
  @Input() log!: MissionChangeLogDto;
  @Input() selected: boolean = false;
  @Output() logSelected: EventEmitter<MissionChangeLogDto> = new EventEmitter();

  /**
   * Handles event clicking.
   */
  handleClick() {
    this.logSelected.emit(this.log);
  }
}
