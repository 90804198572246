<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="false"
  [zIndex]="111"
>
  <p style="color: #bc5a2e">Loading Notifications...</p>
</ngx-spinner>

<mat-sidenav-container class="filters-container bg-white" [hasBackdrop]="false">
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      *ngIf="filterConfig"
      [filterConfig]="filterConfig"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
      filterTitle="Filter Notifications"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="d-flex">
      <h3>Notifications Logs</h3>
    </div>
    <div class="white-background">
      <form [formGroup]="form" (submit)="onGlobalSearch()">
        <div class="d-flex mb-3 pt-2">
          <mat-form-field class="d-flex w-80 pe-2" subscriptSizing="dynamic">
            <mat-label>Search</mat-label>
            <input
              matInput
              placeholder="Search"
              formControlName="searchInput"
              (enter)="onGlobalSearch()"
            />
            <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onGlobalSearch()"
              >search</mat-icon
            >
          </mat-form-field>

          <mat-form-field
            class="d-flex mission-visibility"
            subscriptSizing="dynamic"
            *ngIf="!missionId"
          >
            <mat-label>Grid View</mat-label>
            <mat-select [(value)]="selectedAssignedFilter" name="assignedFilter">
              <mat-select-trigger>
                <span class="example-additional-selection">
                  {{ getDisplayValue(selectedAssignedFilter) }}
                </span>
              </mat-select-trigger>
              <label class="view-label mb-1 ms-1 mt-1">Default views</label>
              <mat-option
                *ngFor="let dp of lstAssignedFilter; let first = first"
                [value]="dp.value"
                title="{{ dp.description }}"
                (click)="handleAssignedSelect(dp.value, true)"
                [ngClass]="{ 'mt-1': first }"
              >
                <span class="d-flex">
                  <span class="mt-1">{{ dp.description }}</span>
                </span>
              </mat-option>
              <hr class="mt-2 mb-2" *ngIf="existingViews?.length > 0" />
              <label class="view-label mb-1 ms-1" *ngIf="existingViews?.length > 0"
                >Custom views</label
              >
              <mat-option
                *ngFor="let view of existingViews; let first = first"
                [value]="view.id"
                title="{{ view.name }}"
                (click)="handleOnViewSelected(view.id)"
                [ngClass]="{
                  'mt-1': first,
                  'view-selected': viewSelected && viewSelected.id == view.id,
                }"
                (mouseenter)="handleOnHover(view, true, $event)"
                (mouseleave)="handleOnHover(view, false, $event)"
              >
                <span class="d-flex align-items-center justify-content-between star-item">
                  <span>{{ view.name }}</span>
                  <span
                    *ngIf="
                      view.displayStar && (!predefinedView || predefinedView.gridViewId != view.id)
                    "
                    class="material-symbols-outlined item-star"
                    (click)="handleOnAssignView($event, view.id)"
                    [matTooltip]="'Set as predefined'"
                  >
                    star
                  </span>
                  <span
                    class="material-icons item-selected-star"
                    *ngIf="predefinedView && predefinedView.gridViewId == view.id"
                    (click)="handleOnUnassignView($event, view.id)"
                    [matTooltip]="'Remove from predefined'"
                  >
                    star
                  </span>
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span
            class="material-symbols-outlined d-flex add-view-button"
            [matTooltip]="'Add new view'"
            (click)="handleOnCreateCustomView()"
            *ngIf="!missionId"
          >
            splitscreen_vertical_add
          </span>
        </div>
      </form>

      <div class="d-flex align-items-center py-0 mb-3">
        <span class="fg-subtitle me-2"> I want to see Notifications with: </span>

        <!-- Display applied filters -->
        <div class="d-flex align-items-center flex-wrap">
          <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
            <ng-container *ngIf="condition.existingValues.length">
              <app-filter-chip
                [label]="condition.columnDisplayName"
                [value]="getFilterDisplayValue(condition)"
                (removeEvent)="removeFilter(condition.column)"
              ></app-filter-chip>
              <span *ngIf="!isLast" class="comma-separator">, </span>
            </ng-container>
          </ng-container>
        </div>

        <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
          add_circle
        </span>

        <span
          class="d-inline-block main-color fw-medium cursor-pointer"
          (click)="removeAllFilters()"
          >Clear All</span
        >
      </div>
    </div>

    <div class="grid" *ngIf="data && data.items">
      <ngx-datatable
        [rows]="data.items"
        [headerHeight]="60"
        [footerHeight]="55"
        [rowHeight]="50"
        [offset]="pageNumber"
        [count]="data.totalCount"
        [list]="list"
        [limit]="10"
        [externalPaging]="true"
        [reorderable]="false"
        [swapColumns]="false"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [trackByProp]="'userId'"
        default
        #dataTable
        class="flg-grid-table"
      >
        <ngx-datatable-column
          name="{{ 'missionsService::SentDateTime' | abpLocalization }}"
          prop="Date"
          [width]="230"
          [resizeable]="true"
          *ngIf="showedColumns.creationDates && allowedByView('creationDates')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.date + 'z' | date: 'MM/dd/yyyy | hh:mm a' }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::NotificationName' | abpLocalization }}"
          prop="Date"
          [width]="230"
          [resizeable]="true"
          *ngIf="showedColumns.notificationName && allowedByView('notificationName')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.notificationName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'NotificationService::Audience' | abpLocalization }}"
          prop="UserId"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
          *ngIf="showedColumns.emails && allowedByView('emails')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge-gray badge bg-light">{{ row.userId }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'NotificationService::Trigger' | abpLocalization }}"
          prop="TriggerType"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
          *ngIf="showedColumns.triggerTypes && allowedByView('triggerTypes')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ notificationTriggerTypeDictionary[row.triggerType] }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'NotificationService::Category' | abpLocalization }}"
          prop="CategoryType"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
          *ngIf="showedColumns.categoryTypes && allowedByView('categoryTypes')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ notificationCategoryTypeDictionary[row.categoryType] }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'NotificationService::Tags' | abpLocalization }}"
          prop="Tags"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
          *ngIf="showedColumns.tags && allowedByView('tags')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.tags }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'NotificationService::NotificationType' | abpLocalization }}"
          prop="Type"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
          *ngIf="showedColumns.types && allowedByView('types')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ notificationTypeDictionary[row.type] }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::Status' | abpLocalization }}"
          prop="Status"
          [width]="80"
          [resizeable]="true"
          *ngIf="showedColumns.statuses && allowedByView('statuses')"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ notificationStatusDictionary[row.status] }}
          </ng-template>
        </ngx-datatable-column>
        <!-- Actions section -->
        <ngx-datatable-column
          name="Actions"
          [sortable]="false"
          [frozenRight]="true"
          [width]="80"
          [resizeable]="false"
          [headerClass]="'actions-column text-center'"
          [cellClass]="'text-center'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <i class="bi bi-eye-fill" role="button" (click)="view(row)"></i>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              (clientMaxResultCountChange)="onPaginationChange($event)"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<abp-modal [options]="{ size: 'lg', centered: true }" [(visible)]="isModalOpen" *ngIf="selected">
  <ng-template #abpBody>
    <h3 class="mt-3 bold fs-5">Notification Details</h3>
    <p class="d-flex align-items-center" *ngIf="selected.smsNotification?.message">
      {{ selected.smsNotification?.message }}
    </p>
    <p class="d-flex align-items-center" *ngIf="selected.webNotification?.message">
      {{ selected.webNotification?.message }}
    </p>
    <p class="d-flex align-items-center" *ngIf="selected.hubNotifications?.length > 0">
      {{ selected.hubNotifications[0].message }}
    </p>
    <p
      class="description-text"
      *ngIf="selected.emailNotification?.body"
      [innerHTML]="selected.emailNotification?.body"
    ></p>
    <div class="mb-3 d-flex align-items-center">
      <button
        mat-flat-button
        type="button"
        class="outline-button me-2"
        (click)="isModalOpen = false"
      >
        Ok
      </button>
    </div>
  </ng-template>
</abp-modal>
