import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ABPInterceptor } from './abp-interceptor';
import { ActionFrameworkInterceptor } from './action-framework-interceptor';
import { HttpErrorInterceptor } from './http-error-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ABPInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ActionFrameworkInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
