import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GetIndustryInput,
  GetPriorityInput,
  IndustriesDto,
  PrioritiesDto,
  SLADto,
} from '../../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  IndustriesService,
  PrioritiesService,
} from '../../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { forkJoin, Observable, Subscription, tap } from 'rxjs';
import { ABP, PagedResultDto } from '@abp/ng.core';
import { MissionDetailsForm } from '../models/mission-details-form.model';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { DatePipe } from '@angular/common';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import {
  CalendarControl,
  CheckBoxControl,
  Control,
  DropDownListControl,
  enumState,
  InputControl,
  MissionSLADto,
  TextAreaControl,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  GetValuesCustomerAttributesInput,
  ValuesCustomerAttributeCreateDto,
  ValuesCustomerAttributeUpdateDto,
  ValuesCustomerAttributeWithNavigationPropertiesDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import {
  CustomerAttributeWithNavigationPropertiesDto,
  GetCustomerAttributesInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/relationals';
import { ValuesCustomerAttributeService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals/values-customer-attribute.service';
import { CustomerAttributeService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/relationals';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mission-details-modal',
  templateUrl: './mission-details-modal.component.html',
  styleUrls: ['./mission-details-modal.component.scss'],
})
export class MissionDetailsModalComponent implements OnInit, OnDestroy {
  formMissionDetails: FormGroup;

  SLAOptions: PagedResultDto<SLADto> = {
    items: [],
    totalCount: 0,
  };
  filterPriorities = {} as GetPriorityInput;

  dataIndustries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterIndustries = { isPaginated: false } as GetIndustryInput;

  private subscriptions = new Subscription();
  slaGenerated: MissionSLADto[];
  slaSelected: SLADto;
  minDate: Date;

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  filtersValuesCustomerAttribute = {} as GetValuesCustomerAttributesInput;
  filtersCustomerAttribute = {} as GetCustomerAttributesInput;

  valuesCustomerAttributes: PagedResultDto<ValuesCustomerAttributeWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };

  customerAttributes: PagedResultDto<CustomerAttributeWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };
  formAddCutomerAttribute: FormGroup = this.fb.group({});

  controls: Control<string>[] = [];

  constructor(
    public dialogRef: MatDialogRef<MissionDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MissionDetailsForm,
    public readonly industriesService: IndustriesService,
    public readonly priorityService: PrioritiesService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private slaService: SlaService,
    public readonly valuesCustomerAttributeService: ValuesCustomerAttributeService,
    public readonly customerAttributeService: CustomerAttributeService,
    private spinner: NgxSpinnerService,
  ) {
    this.minDate = new Date();

    this.formMissionDetails = this.fb.group({
      missionName: [
        data.missionName || '',
        [Validators.required, this.notOnlyWhitespace(), Validators.maxLength(385)],
      ],
      description: [data.description || '', [Validators.required, this.notOnlyWhitespace()]],
      priorityId: [data.priorityId || '', Validators.required],
      industryId: [data.industryId || '', Validators.required],
      captureDate: [this.getDateFromString(data.fixedCustomerRequestedCaptureDate) || ''],
      captureTime: [data.fixedCustomerRequestedCaptureTime || ''],
      captureDateMustId: [data.customerRequestedCaptureMustBe ?? undefined],
      missionAssetOrder: [data.missionAssetOrder || ''],
      manualAirspaceWaiverRequired: [data.manualAirspaceWaiverRequired],
    });
  }

  ngOnInit(): void {
    this.getCustomerAttribute();
    const sub = forkJoin([
      this.slaService.get(this.data.customerId),
      this.industriesService.getList(this.filterIndustries),
    ]).subscribe({
      next: ([slas, industries]) => {
        this.SLAOptions = slas;
        this.dataIndustries = industries;
        if (this.SLAOptions.totalCount > 0) {
          this.slaSelected = this.SLAOptions.items.find(d => d.id == this.data.priorityId);
          if (this.slaSelected.isCustom) {
            this.slaService.getMissionSLAByMissionId(this.data.missionId).subscribe(d => {
              this.slaGenerated = d.items;
            });
          }
        }
      },
      error: error => console.log(`Error on Mission Details Modal component: ${error}`),
    });
    this.subscriptions.add(sub);
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  saveMissionDetails(): void {
    if (this.formMissionDetails.valid) {
      this.spinner.show();
      this.submitFormAddCutomerAttribute(() => {
        this.preSaveMisionDetails();
      });
    }
  }

  preSaveMisionDetails() {
    const formValue = this.formMissionDetails?.getRawValue();

    const priorityItem = this.SLAOptions.items.find(x => x.id === formValue.priorityId);
    const industryItem = this.dataIndustries.items.find(x => x.id === formValue.industryId);

    const updatedMissionDetails: MissionDetailsForm = {
      missionId: this.data.missionId,
      missionName: formValue.missionName,
      description: formValue.description,
      priorityId: formValue.priorityId,
      orderPriority: priorityItem?.name,
      industryId: formValue.industryId,
      orderIndustry: industryItem?.name,
      customerRequestedCaptureDate: this.datePipe.transform(
        formValue.captureDate,
        'MM/dd/yyyy',
        'UTC',
      ),
      customerRequestedCaptureMustBe: formValue.captureDateMustId,
      customerRequestedCaptureTime: formValue.captureTime,
      fixedCustomerRequestedCaptureDate: this.datePipe.transform(
        formValue.captureDate,
        'MM/dd/yyyy',
      ),
      fixedCustomerRequestedCaptureTime: this.getFixedHourFormat(formValue.captureTime),
      missionAssetOrder: formValue.missionAssetOrder,
      customerId: this.data.missionId,
      sla: this.slaGenerated,
      manualAirspaceWaiverRequired: formValue.manualAirspaceWaiverRequired,
    };

    this.dialogRef.close(updatedMissionDetails);
    this.spinner.hide();
  }

  notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getDateFromString(formatedDate: string): Date {
    if (!formatedDate) return undefined;

    const segments = formatedDate.split('/');

    const year = parseInt(segments[2], 10);
    const day = parseInt(segments[1], 10);
    const month = parseInt(segments[0], 10) - 1;

    return new Date(year, month, day);
  }

  private getFixedHourFormat(fixedHour: string) {
    if (
      !fixedHour ||
      (!fixedHour.toLowerCase().includes('am') && !fixedHour.toLowerCase().includes('pm'))
    )
      return fixedHour;

    const [time, period] = fixedHour.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour);

    if (period === 'PM') {
      formattedHour += 12;
    }

    return `${formattedHour}:${minute}`;
  }

  changeSla(sla) {
    this.slaSelected = this.SLAOptions.items.find(s => s.id == sla);
    this.getCalculateSla();
  }

  getCalculateSla() {
    if (this.slaSelected.isCustom) {
      this.slaService
        .calculateSLAByMissionId(this.data.missionId, this.slaSelected.id)
        .subscribe(d => {
          if (d.totalCount > 0) this.slaGenerated = d.items;
        });
    } else {
      this.slaGenerated = [];
    }
  }

  checkboxChange(value: any, key: string): void {
    const control = this.controls.find(co => co.key == key);
    control.valueCheckBox = value.checked;
    control.value = value.checked;
    this.formAddCutomerAttribute.value[key] = value.checked;
  }

  handleSelectDate(event: MatDatepickerInputEvent<Date>, key: string) {
    const control = this.controls.find(co => co.key == key);
    const dateString = this.dateToLocalIsoString(event.value);
    control.value = dateString;
    this.formAddCutomerAttribute.value[key] = dateString;
  }

  submitFormAddCutomerAttribute(preSaveMisionDetails: any) {
    if (this.controls?.length < 1) {
      preSaveMisionDetails();
      return;
    }

    if (this.formAddCutomerAttribute.invalid) return;

    const requests: Observable<any>[] = [];

    this.controls.forEach(control => {
      let controlValue = this.formAddCutomerAttribute.value[control.key];
      controlValue = String(controlValue);
      if (control.type == 'checkbox') {
        controlValue = control.valueCheckBox;
        if (controlValue == '') {
          controlValue = 'false';
        } else {
          controlValue = String(controlValue);
        }
      }

      control.value = controlValue;

      let _valuesCustomerAttributeId: string =
        this.valuesCustomerAttributes.items.find(
          va =>
            va.missions.id == this.data.missionId &&
            va.valuesCustomerAttribute.customerAttributeId == control.key,
        )?.valuesCustomerAttribute.id || '';

      let _customerAttributeDescription: string = this.customerAttributes.items.find(
        ca => ca.customerAttribute.id == control.key,
      )?.customerAttribute.displayName;

      if (_valuesCustomerAttributeId.trim() == '') {
        let valuesCustomerAttributeCreateDto = {} as ValuesCustomerAttributeCreateDto;
        valuesCustomerAttributeCreateDto.missionDescription = this.data.missionName;
        valuesCustomerAttributeCreateDto.missionsId = this.data.missionId;
        valuesCustomerAttributeCreateDto.customerAttributeId = control.key;
        valuesCustomerAttributeCreateDto.customerAttributeDescription =
          _customerAttributeDescription;
        valuesCustomerAttributeCreateDto.value = controlValue;
        valuesCustomerAttributeCreateDto.state = enumState.Enabled;

        const request = this.valuesCustomerAttributeService
          .create(valuesCustomerAttributeCreateDto)
          .pipe();

        requests.push(request);
      } else {
        let valuesCustomerAttributeUpdateDto = {} as ValuesCustomerAttributeUpdateDto;

        valuesCustomerAttributeUpdateDto.missionDescription = this.data.missionName;
        valuesCustomerAttributeUpdateDto.missionsId = this.data.missionId;
        valuesCustomerAttributeUpdateDto.customerAttributeId = control.key;
        valuesCustomerAttributeUpdateDto.customerAttributeDescription =
          _customerAttributeDescription;
        valuesCustomerAttributeUpdateDto.value = controlValue;
        valuesCustomerAttributeUpdateDto.state = enumState.Enabled;
        let _concurrencyStamp: string = this.valuesCustomerAttributes.items.find(
          va =>
            va.missions.id == this.data.missionId &&
            va.valuesCustomerAttribute.customerAttributeId == control.key,
        )?.valuesCustomerAttribute.concurrencyStamp;
        valuesCustomerAttributeUpdateDto.concurrencyStamp = _concurrencyStamp;

        const request = this.valuesCustomerAttributeService
          .update(_valuesCustomerAttributeId, valuesCustomerAttributeUpdateDto)
          .pipe();

        requests.push(request);
      }
    });

    forkJoin(requests)
      .pipe(
        tap(() => {
          preSaveMisionDetails();
        }),
      )
      .subscribe();
  }

  private getCustomerAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const customerAttributeFilter = {
      state: enumState.Enabled,
      customersId: this.data.customerId,
    } as GetCustomerAttributesInput;

    this.customerAttributeService
      .getList({
        ...query,
        ...customerAttributeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.customerAttributes = res;
        this.generateFormCustomerAttributeV1();
      });
  }

  private generateFormCustomerAttributeV1(): void {
    const query = {} as ABP.PageQueryParams;
    let _value: string = '';

    const valuesCustomerAttributesFilter = {
      state: enumState.Enabled,
      missionsId: this.data.missionId,
    } as GetValuesCustomerAttributesInput;

    this.valuesCustomerAttributeService
      .getList({
        ...query,
        ...valuesCustomerAttributesFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.formAddCutomerAttribute = this.fb.group({});
        this.valuesCustomerAttributes = res;

        let _values: Array<{ key: string; value: string }> = [];
        this.controls = [];

        this.customerAttributes.items.forEach(element => {
          _value = this.valuesCustomerAttributes.items.find(
            v => v.valuesCustomerAttribute.customerAttributeId == element.customerAttribute.id,
          )?.valuesCustomerAttribute.value;

          if (!_value) {
            _value = '';
          }

          switch (element.customerAttribute.controlTypeDescription.toUpperCase()) {
            case 'CHECKBOX': {
              let control: Control<string> = new CheckBoxControl();

              control.key = element.customerAttribute.id;
              control.label = element.customerAttribute.displayName;
              control.required = element.customerAttribute.isRequired ? true : false;
              control.type = 'checkbox';

              let _valueCheckBox: boolean;

              if (_value == 'false') {
                _valueCheckBox = false;
              } else {
                _valueCheckBox = true;
              }

              control.valueCheckBox = _value == '' ? false : _valueCheckBox;

              this.controls.push(control);

              this.formAddCutomerAttribute.addControl(
                control.key,
                control.required
                  ? new FormControl(control.valueCheckBox || '', Validators.required)
                  : new FormControl(control.valueCheckBox || ''),
              );
              break;
            }
            case 'DROPDOWNLIST': {
              let control: Control<string> = new DropDownListControl();

              let _valuesList = element.customerAttribute.listValues;
              let _aValueslist = _valuesList.split(',');

              _aValueslist.forEach(item => {
                _values.push({ key: item, value: item });
              });

              control.key = element.customerAttribute.id;
              control.label = element.customerAttribute.displayName;
              control.required = element.customerAttribute.isRequired ? true : false;
              control.options = _values;
              control.value = _value;

              this.controls.push(control);
              this.formAddCutomerAttribute.addControl(
                control.key,
                control.required
                  ? new FormControl(control.value || '', Validators.required)
                  : new FormControl(control.value || ''),
              );
              break;
            }
            case 'CALENDAR': {
              let control: Control<string> = new CalendarControl();
              control.key = element.customerAttribute.id;
              control.label = element.customerAttribute.displayName;
              control.required = element.customerAttribute.isRequired ? true : false;
              control.value = _value;
              control.type = 'CALENDAR';

              this.controls.push(control);
              this.formAddCutomerAttribute.addControl(
                control.key,
                control.required
                  ? new FormControl(control.value || '', Validators.required)
                  : new FormControl(control.value || ''),
              );
              break;
            }
            case 'INPUT':
            case 'NUMBER':
            case 'TEXT': {
              let control: Control<string> = new InputControl();

              control.key = element.customerAttribute.id;
              control.label = element.customerAttribute.displayName;
              control.required = element.customerAttribute.isRequired ? true : false;
              control.type =
                element.customerAttribute.customerAttributeTypeDescription.toUpperCase() == 'TEXT'
                  ? 'text'
                  : 'number';
              if (element.customerAttribute.maxTextLength != 0) {
                control.maxLength = element.customerAttribute.maxTextLength;
              } else {
                control.maxLength = null;
              }
              control.value = _value;

              this.controls.push(control);
              this.formAddCutomerAttribute.addControl(
                control.key,
                control.required
                  ? new FormControl(control.value || '', Validators.required)
                  : new FormControl(control.value || ''),
              );
              break;
            }
            case 'TEXT AREA': {
              let control: Control<string> = new TextAreaControl();

              control.key = element.customerAttribute.id;
              control.label = element.customerAttribute.displayName;
              control.required = element.customerAttribute.isRequired ? true : false;
              if (element.customerAttribute.maxTextLength != 0) {
                control.maxLength = element.customerAttribute.maxTextLength;
              } else {
                control.maxLength = null;
              }
              control.value = _value;

              this.controls.push(control);
              this.formAddCutomerAttribute.addControl(
                control.key,
                control.required
                  ? new FormControl(control.value || '', Validators.required)
                  : new FormControl(control.value || ''),
              );
              break;
            }
          }
        });
      });
  }

  IsValid(): boolean {
    return this.formAddCutomerAttribute?.valid;
  }

  dateToLocalIsoString(date: Date): string {
    const pad = (n: number, width = 2) => n.toString().padStart(width, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
}
