import { Component, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-tooltip-with-rich-text',
  templateUrl: './tooltip-with-rich-text.component.html',
  styleUrls: ['./tooltip-with-rich-text.component.scss'],
})
export class TooltipWithRichTextComponent {
  sanitizedHtml: SafeHtml;

  @Input() set htmlContent(value: string) {
    this.sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, value);
  }

  constructor(
    private sanitizer: DomSanitizer,
    public elementRef: ElementRef,
  ) {}
}
