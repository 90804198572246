import { OrderFormContactModel } from './order-form-contact.model';
import { OrderFormDeliverableModel } from './order-form-deliverable.model';
import { OrderFormPackageModel } from './order-form-package-model';
import { OrderFormSiteModel } from './order-form-site.model';
import {
  BulkUploadsBUDto,
  Control,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { FormGroup } from '@angular/forms';
import { OrderFormDetailsModel } from './order-form-detail.model';
import { ItemSummaryTypeEnum } from './item-summary-type.enum';
import { ValuesCustomerAttributeCreateDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';

export class OrderFormModel {
  customerId: string = '';
  customerDescription: string = '';
  customerContactId: string = '';
  customerContactFirstName: string = '';
  customerContactLastName: string = '';
  customerContactEmail: string = '';
  customerContactNumber: string = '';
  subcustomerId: string = '';
  subclientName: string = '';
  additionalContacts: OrderFormContactModel[] = [];
  orderSites: OrderFormSiteModel[] = [];
  priorityId: string = '';
  priorityDescription: string = '';
  industryId: string = '';
  industryDescription: string = '';
  packagesbyCustumer: OrderFormPackageModel[] = [];
  genericPackages: OrderFormPackageModel[] = [];
  productsbyIndustry: OrderFormDeliverableModel[] = [];
  additionalDeliverables: OrderFormDeliverableModel[] = [];
  orderDetail: OrderFormDetailsModel = null;
  orderDeliverableDetail: OrderFormDeliverableModel[] = [];
  portfolioName: string = '';
  packageId: string = '';
  productDescription: string = '';
  controls: Control<string>[] = [];
  formAddCutomerAttribute: FormGroup;
  orderDetailSummaryModel: Array<OrderDetailSummaryModel> = [];
  missionAssetOrder?: string = '';
  isBulkImport: boolean = false;
  bulkData: BulkUploadsBUDto = {
    id: null,
    fileId: null,
    fileName: null,
    bulkUploadDetails: [],
  } as BulkUploadsBUDto;
  portfolioId: string = null;
  portfolioExists: boolean = false;
  customAttributes?: ValuesCustomerAttributeCreateDto[];

  calculateOrderDetailTotal() {
    let packagesTotal = 0;
    let productsTotal = 0;
    let total = 0;
    if (this.orderDetail) {
      this.orderDetail.packagesSelected.forEach((p: OrderFormPackageModel) => {
        packagesTotal += p.packageSubtotal;
      });
      this.orderDetail.productsSelected.forEach((pr: OrderFormDeliverableModel) => {
        productsTotal += pr.subTotal;
      });
      total = packagesTotal + productsTotal;
      this.orderDetail.total = Math.abs(Number(total.toFixed(2)));
    }
  }

  buildDetails() {
    this.orderDetailSummaryModel = [];
    this.orderDeliverableDetail = [];
    this.orderDetail.packagesSelected.forEach((p: OrderFormPackageModel) => {
      p.deliverables.forEach((dv: OrderFormDeliverableModel) => {
        this.orderDeliverableDetail.push(this.buildDeliverableModel(dv, Number(p.packagePrice)));
      });

      const summaryModel: OrderDetailSummaryModel = {
        itemName: p.packageName,
        price: Number(p.packagePrice),
        quantity: p.quantity,
        total: p.packageSubtotal,
        type: ItemSummaryTypeEnum.Package,
        typeDescription: 'Package',
        deliverables: p.deliverables,
      };

      this.orderDetailSummaryModel.push(summaryModel);
    });

    this.orderDetail.productsSelected.forEach((pr: OrderFormDeliverableModel) => {
      this.orderDeliverableDetail.push(this.buildDeliverableModel(pr));

      const summaryModel: OrderDetailSummaryModel = {
        itemName: pr.productName,
        price: pr.standardPrice,
        quantity: pr.quantity,
        total: pr.subTotal,
        type: ItemSummaryTypeEnum.Product,
        typeDescription: 'Product',
        deliverables: [pr],
      };

      this.orderDetailSummaryModel.push(summaryModel);
    });
    this.calculateOrderDetailTotal();
  }

  buildDeliverableModel(dl: OrderFormDeliverableModel, packagePrice: Number = 0) {
    return {
      deliverableId: dl.deliverableId,
      deliverableName: dl.deliverableName,
      productId: dl.productId,
      productName: dl.productName,
      productDescription: dl.productDescription,
      packageId: dl.packageId,
      packageName: dl.packageName,
      standardPrice: dl.standardPrice,
      actualPrice: Math.abs(Number(dl.actualPrice.toFixed(2))),
      quantity: dl.quantity,
      subTotal: dl.subTotal,
      industryDescription: dl.industryDescription,
      industryMultiplexor: dl.industryMultiplexor,
      fields: dl.fields.map(fd => ({
        id: fd.id,
        placeholder: fd.placeholder,
        numericValue: fd.numericValue,
        stringValue: fd.stringValue,
        boolValue: fd.boolValue,
        fileValue: fd.fileValue,
        defaultValue: fd.defaultValue,
        isRequired: fd.isRequired,
        listValues: fd.listValues,
        maxTextLength: fd.maxTextLength,
        typeDescription: fd.typeDescription,
        typeCode: fd.typeCode,
      })),
      sameConfiguration: dl.sameConfiguration,
      detailedAttributes: dl.detailedAttributes,
      incompleteConfiguration: dl.incompleteConfiguration,
      packagePrice: packagePrice,
    };
  }
}

export interface OrderDetailSummaryModel {
  itemName: string;
  quantity: number;
  typeDescription: string;
  type: string;
  price: number;
  total: number;
  deliverables: OrderFormDeliverableModel[];
}
