<form skipValidation [formGroup]="search">
  <mat-form-field class="w-100">
    <mat-label>Customer</mat-label>
    <input
      type="text"
      placeholder="Enter a Customer Name..."
      matInput
      formControlName="term"
      [matAutocomplete]="auto"
    />

    <mat-error *ngIf="search.get('term').hasError('required')">This field is required</mat-error>

    <mat-error *ngIf="search.get('term').hasError('notFound')">Customer not found</mat-error>

    <mat-progress-spinner
      *ngIf="loading"
      matSuffix
      [mode]="'indeterminate'"
      [diameter]="20"
    ></mat-progress-spinner>

    <mat-icon
      matSuffix
      (click)="clear()"
      *ngIf="search.get('term').value && !loading"
      class="cursor-pointer"
      >close</mat-icon
    >
  </mat-form-field>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="formatCustomer"
    (optionSelected)="handleCustomerSelection($event)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</form>
