import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { missionStatus } from 'projects/missions-service/src/lib/proxy/missions-service/shared';
import { MissionsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { MissionMetrics } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit, OnChanges {
  @Input() mission: MissionFlowDto;
  @Input() missionId: string;
  @Input() statusName: string;
  days: string;
  showTable: boolean = true;
  showDays = false;
  missionMetrics: MissionMetrics;

  constructor(
    private missionService: MissionsService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.refreshData();
  }

  getEnumKeyByEnumValue(enumObj: any, enumValue: string): string | undefined {
    const keys = Object.keys(enumObj).filter(x => enumObj[x] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }

  refreshData() {
    this.missionService.getMissionMetrics(this.missionId).subscribe(res => {
      this.missionMetrics = res;
      const statusName = this.getEnumKeyByEnumValue(missionStatus, this.statusName);
      const statusesToHide: missionStatus[] = [
        missionStatus.Cancelled,
        missionStatus.DEAD,
        missionStatus.ClientInvoiced,
      ];

      if (!statusesToHide.includes(missionStatus[statusName as keyof typeof missionStatus])) {
        this.showDays = true;
      } else {
        this.showDays = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.statusName || changes.mission) {
      this.refreshData();
      this.showTable = false;

      setTimeout(() => {
        this.showTable = true;
      }, 1000);
    }
  }
  
  getDaysSinceRequestDescription(): string {
    const daysText = this.localizationService.instant('missionsService::Days');
    return `${this.missionMetrics?.daysSinceRequest || 0} ${daysText}`;
  }

  gettimeOnCurrentStatusDescription(): string {
    const daysText = this.localizationService.instant('missionsService::Days');
    return `${this.missionMetrics?.timeOnCurrentStatus || 0} ${daysText}`;
  }

  getReflyDescription(): string {
    let reflyDesc = this.localizationService.instant('missionsService::Refly');
    if (this.missionMetrics?.reflys > 1) {
      reflyDesc = this.localizationService.instant('missionsService::Reflys');
    }
    return `${this.missionMetrics?.reflys || 0} ${reflyDesc}`;
  }
}
