import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { ABP, PagedResultDto } from '@abp/ng.core';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import {
  ContactMethodsDto,
  GetContactMethodInput,
  GetLevelCommunicationInput,
  LevelCommunicationsDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  ContactMethodsService,
  LevelCommunicationsService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { ContactTypesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  ContactTypesDto,
  GetContactTypeInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { MatRadioChange } from '@angular/material/radio';
import { Observable, debounceTime, distinctUntilChanged, of, map, catchError } from 'rxjs';
import { getPendingControls } from '@flyguys/components';

const requiredControlsNames = {
  email: 'Email',
  name: 'First Name',
  lastname: 'Last Name',
};

@Component({
  selector: 'app-order-contact-dialog',
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss'],
})
export class OrderContactComponent implements OnInit {
  formOrderContact: FormGroup;
  ordercontactModel: OrderFormContactModel;

  levelCommunications: PagedResultDto<LevelCommunicationsDto> = {
    items: [],
    totalCount: 0,
  };

  contactMethods: PagedResultDto<ContactMethodsDto> = {
    items: [],
    totalCount: 0,
  };

  contactTypes: PagedResultDto<ContactTypesDto> = {
    items: [],
    totalCount: 0,
  };

  displayContactMethodAndLevelOfCoordination: boolean = false;

  showHintEditContactEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    public readonly customerService: CustomersService,
    public readonly contactTypesService: ContactTypesService,
    public dialogRef: MatDialogRef<OrderContactComponent>,
    @Inject(MAT_DIALOG_DATA) public modalConfig: any,
    public readonly levelCommunicationsService: LevelCommunicationsService,
    public readonly contactMethodsService: ContactMethodsService,
  ) {
    this.ordercontactModel = modalConfig.contact;
    this.displayContactMethodAndLevelOfCoordination =
      modalConfig.displayContactMethodAndLevelOfCoordination;

    const isEditMode = !modalConfig.addingContact;

    const emailValidators = [Validators.email];
    const emailAsyncValidators = isEditMode ? [] : [this.emailExistValidator()];

    this.formOrderContact = this.fb.group({
      contactFromId: [this.ordercontactModel.contactFromId],
      email: [this.ordercontactModel.email, emailValidators, emailAsyncValidators],
      name: [this.ordercontactModel.name, Validators.required],
      lastname: [this.ordercontactModel.lastname, Validators.required],
      number: [this.ordercontactModel.number],
      preferredContactMethodId: [this.ordercontactModel.preferredContactMethodId],
      levelCommunicationId: [this.ordercontactModel.levelCommunicationId],
    });

    this.showHintEditContactEmail = isEditMode;

    if (isEditMode) {
      this.handleEditMode();
    }
  }

  ngOnInit(): void {
    this.getLevelCommunication();
    this.getContactsMethods();
    this.getContactsType();
    this.checkEmailFieldToDisable();
    this.updateFormStatusAfterAsyncValidatorsComplete();
  }

  private checkEmailFieldToDisable() {
    if (this.ordercontactModel?.userId) {
      this.formOrderContact.get('email').disable();
    } else {
      this.formOrderContact.get('email').enable();
    }
  }

  private getContactsType() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetContactTypeInput;

    this.contactTypesService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactTypes = res;
      });
  }

  private getLevelCommunication() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetLevelCommunicationInput;

    this.levelCommunicationsService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levelCommunications = res;
      });
  }

  private getContactsMethods() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactMethodsService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactMethods = res;
      });
  }

  onPreferredContactMethodChange(event: MatRadioChange) {
    this.ordercontactModel.preferredContactMethodId = event.value;
    const selectedMethod = this.contactMethods.items.find(
      x => x.id === this.ordercontactModel.preferredContactMethodId,
    );
    this.ordercontactModel.preferredContactMethodDescription = selectedMethod?.description;
  }

  onLevelCoordinationChange(event: MatRadioChange) {
    this.ordercontactModel.levelCommunicationId = event.value;
    const selectedLevel = this.levelCommunications.items.find(
      x => x.id === this.ordercontactModel.levelCommunicationId,
    );
    this.ordercontactModel.levelCommunicationDescription = selectedLevel?.description;
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveContact(): void {
    if (this.formOrderContact.valid) {
      const formValue = this.formOrderContact.getRawValue();

      const orderFormContactModel = new OrderFormContactModel();

      orderFormContactModel.contactId = this.ordercontactModel.contactId;
      orderFormContactModel.contactFromId = formValue.contactFromId;
      orderFormContactModel.email = formValue.email;
      orderFormContactModel.name = formValue.name;
      orderFormContactModel.lastname = formValue.lastname;
      orderFormContactModel.number = formValue.number;
      orderFormContactModel.preferredContactMethodId = formValue.preferredContactMethodId;
      orderFormContactModel.levelCommunicationId = formValue.levelCommunicationId;

      const selectedContactType = this.contactTypes.items.find(
        type => type.id === formValue.contactFromId,
      );
      orderFormContactModel.contactFromDescription = selectedContactType?.description || '';

      const selectedPreferredContactMethod = this.contactMethods.items.find(
        method => method.id === formValue.preferredContactMethodId,
      );
      orderFormContactModel.preferredContactMethodDescription =
        selectedPreferredContactMethod?.description || '';

      const selectedLevelCommunication = this.levelCommunications.items.find(
        level => level.id === formValue.levelCommunicationId,
      );
      orderFormContactModel.levelCommunicationDescription =
        selectedLevelCommunication?.description || '';

      this.dialogRef.close(orderFormContactModel);
    } else {
    }
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.trim() === '') {
        return of(null);
      }
      return this.customerService.checkEmailExists(control.value.trim()).pipe(
        map(exists => (exists ? { emailExists: true } : null)),
        catchError(() => of(null)),
      );
    };
  }

  private handleEditMode() {
    const emailControl = this.formOrderContact.get('email');
    const initialEmail = emailControl.value;

    emailControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(newValue => {
        if (newValue !== initialEmail) {
          emailControl.setAsyncValidators([this.emailExistValidator()]);
        } else {
          emailControl.setAsyncValidators(null);
        }
        emailControl.updateValueAndValidity();
      });
  }

  private updateFormStatusAfterAsyncValidatorsComplete() {
    this.formOrderContact.statusChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.formOrderContact.updateValueAndValidity();
      });
  }

  IsValid(): boolean {
    if (this.formOrderContact.pending) {
      return false;
    }
    return this.formOrderContact.valid;
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    return getPendingControls(this.formOrderContact, requiredControlsNames);
  }
}
