import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FlyguysMapComponent, FlyguysMapDimension, FlyguysMapMarker } from '@flyguys/map';
import { OrderRequestModel } from '../../models/order-request-model';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { MatDialog } from '@angular/material/dialog';
import { MissionDetailsModalComponent } from './mission-details-modal/mission-details-modal.component';
import { MissionDetailsForm } from './models/mission-details-form.model';
import { LocationModalComponent } from './location-modal/location-modal.component';
import { LocationForm } from './models/location-form.model';
import { DeliverableAspectsModalComponent } from './deliverable-aspects-modal/deliverable-aspects-modal.component';
import { DeliverableAspectsFormModel } from './models/deliverable-aspects-form.model';
import { DatePipe } from '@angular/common';
import { AttachmentsNotesModalComponent } from './attachments-notes-modal/attachments-notes-modal.component';
import { AttachmentsNotesFormModel } from './models/attachments-notes-form-model';
import {
  ActionOrderConfiguredDto,
  CountriesDto,
  GetCountryInput,
  GetStateInput,
  StatesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ConfigStateService, PagedResultDto } from '@abp/ng.core';
import {
  ActionOrderService,
  CountriesService,
  StatesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { SiteLocationContactsComponent } from './site-location-contacts/site-location-contacts/site-location-contacts.component';
import { OrderFormSiteFileModel } from '../../../components/orders/model/order-form-site-file.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { OrderReferenceDocumentationDto } from '../../models/order-reference-documentation-dto';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { CustomerRequestedDataDto } from '../../models/customer-requested-data-dto';
import { finalize, switchMap } from 'rxjs/operators';
import { downloadBlob } from '@abp/ng.core';
import { format } from 'date-fns';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  CustomerLocationCreateDto,
  LocationWithAddressDto,
  MissionLocationInformationDto,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerLocationService } from '../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics/customer-location.service';
import { MissionLocationContactService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/mission-location-contact.service';
import { MissionsService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { Observable, Subscription, concat } from 'rxjs';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { ShowSlaMilestonesComponent } from 'projects/missions-service/src/lib/basics/missions/components/show-sla-milestones/show-sla-milestones.component';
import { OrdersGridRefreshService } from '../../../services/orders-grid-refresh.service';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss'],
  providers: [DatePipe],
})
export class OrderRequestComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() missionStatusId: string = '';
  @Input() missionId: string;
  @Input() missionStatusCode: string;
  @Output() newUpdate = new EventEmitter<OrderRequestModel>();

  kmlFiles: OrderFormSiteFileModel[] = [];

  public mapMarkers: FlyguysMapMarker[] = [];
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  public orderRequest: OrderRequestModel;

  emptyGuid = '00000000-0000-0000-0000-000000000000';

  // Actions visibility variables
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;
  public readonly ORDER_REQUEST_EDIT_DETAILS = 'order_request_edit_details';
  public readonly ORDER_REQUEST_EDIT_LOCATION = 'order_request_edit_location';
  public readonly ORDER_REQUEST_EDIT_SITE_CONTACT_INFORMATION =
    'order_request_edit_site_contact_information';
  public readonly ORDER_REQUEST_EDIT_DELIVERABLE_ASPECTS = 'order_request_edit_deliverable_aspects';
  public readonly ORDER_REQUEST_EDIT_ATTACHMENTS_NOTES = 'order_request_edit_attachments_notes';
  allowOrderRequestEditDetails: boolean = false;
  allowOrderRequestEditLocation: boolean = false;
  allowOrderRequestEditSiteContactInformation: boolean = false;
  allowOrderRequestEditDeliverableAspects: boolean = false;
  allowOrderRequestEditAttachmentsAndNotes: boolean = false;
  currentToken: string;
  // Actions visibility variables

  missionData: MissionFlowDto;

  filterCountries = {} as GetCountryInput;
  countries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  states: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  hasKml: boolean = false;
  subscription = new Subscription();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private missionFlowService: MissionFlowService,
    private customerLocationService: CustomerLocationService,
    private missionLocationContacts: MissionLocationContactService,
    private dialog: MatDialog,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService,
    private oAuthService: OAuthService,
    private spinner: NgxSpinnerService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly fileDescriptorService: FileDescriptorService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private toaster: ToasterService,
    private missionService: MissionsService,
    private notificationBroadcastService: NotificationBroadcastService,
    private ordersRefreshService: OrdersGridRefreshService,
  ) {
    this.currentToken = this.oAuthService.getAccessToken();
  }

  ngOnInit(): void {
    this.getCountries();
    this.getStates();

    this.getActionConfiguration();

    this.getMissionData();
    this.notifStatusChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.missionStatusCode && !changes.missionStatusCode?.firstChange) {
      this.refreshData();
    }
  }

  ngAfterViewInit(): void {
    this.fetchOrderRequestData(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getMissionData() {
    this.missionFlowService.getMissionData(this.missionId).subscribe({
      next: response => {
        if (response.customerRequestedCaptureDate) {
          const dateString = `${response.customerRequestedCaptureDate.split('T')[0]} ${
            response.customerRequestedCaptureTime || '00:00'
          }`;
          response.captureDateTime = new Date(new Date(dateString).toLocalISOString());
        }

        this.missionData = response;
      },
      error: err => console.log(err),
    });
  }

  getCustomerCaptureDateTime(): string {
    if (this.missionData?.captureDateTime)
      return `${format(this.missionData?.captureDateTime, 'MM/dd/yyyy')} | ${
        this.missionData?.fixedCustomerRequestedCaptureTime ||
        this.missionData?.captureTime ||
        '--:--'
      }`;

    return '';
  }

  getFixedCustomerCaptureDateTime(): string {
    if (this.missionData?.fixedCustomerRequestedCaptureDate)
      return `${this.missionData?.fixedCustomerRequestedCaptureDate} | ${
        this.missionData?.fixedCustomerRequestedCaptureTime || '--:--'
      }`;

    return this.getCustomerCaptureDateTime();
  }

  formatTime(time: string): string {
    if (!time) return '--:--';

    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : hours;
    const amOrPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }

  getCaptureMustBeDescription(captureMustBeEnum: CaptureMustBeEnum): string {
    if (captureMustBeEnum === undefined) {
      return 'N\\A';
    }

    // Before removing 'Flexible', the capture must 'Flexible' has an ID of 2. For missions with that ID, we'll show 'Flexible'.
    if ((captureMustBeEnum as unknown as number) === 2) {
      return 'Flexible';
    }

    return this.dataCaptureMust.find(x => x.value === captureMustBeEnum)?.description || 'N\\A';
  }

  onEditOrderDetailsClick(): void {
    const dialogRef = this.dialog.open(MissionDetailsModalComponent, {
      width: '700px',
      disableClose: true,
      data: {
        missionId: this.missionId,
        missionName: this.orderRequest.orderDetails.missionName,
        description: this.orderRequest.orderDetails.description,
        priorityId: this.orderRequest.orderDetails.priorityId,
        orderPriority: this.orderRequest.orderDetails.orderPriority,
        industryId: this.orderRequest.orderDetails.industryId,
        orderIndustry: this.orderRequest.orderDetails.orderIndustry,
        customerRequestedCaptureDate: this.missionData.captureDateTime,
        customerRequestedCaptureTime: this.missionData.captureDateTime
          ? format(this.missionData.captureDateTime, 'HH:mm')
          : '',
        fixedCustomerRequestedCaptureDate: this.missionData.fixedCustomerRequestedCaptureDate,
        fixedCustomerRequestedCaptureTime: this.missionData.fixedCustomerRequestedCaptureTime,
        customerRequestedCaptureMustBe: this.missionData.customerRequestedCaptureMustBe,
        missionAssetOrder: this.orderRequest.orderDetails.missionAssetOrder,
        customerId: this.missionData.customerId,
        manualAirspaceWaiverRequired: this.missionData.manualAirspaceWaiverRequired,
      },
    });

    dialogRef.afterClosed().subscribe((updatedMissionDetails: MissionDetailsForm) => {
      if (updatedMissionDetails) {
        this.pilotSourcingCommunicationService.callReloadFunction({
          updatedMissionDetails,
        });
        updatedMissionDetails.missionId = this.missionId;

        let customerRequestedData: CustomerRequestedDataDto = {
          customerRequestedCaptureDate: updatedMissionDetails.customerRequestedCaptureDate,
          customerRequestedCaptureMustBe: updatedMissionDetails.customerRequestedCaptureMustBe,
          customerRequestedCaptureTime:
            updatedMissionDetails.customerRequestedCaptureTime || '00:00',
          fixedCustomerRequestedCaptureDate:
            updatedMissionDetails.fixedCustomerRequestedCaptureDate,
          fixedCustomerRequestedCaptureTime:
            updatedMissionDetails.fixedCustomerRequestedCaptureTime || '00:00',
          missionId: this.missionId,
        };

        this.updateMissionDetailsAndCustomerRequestedData(
          updatedMissionDetails,
          customerRequestedData,
        );
        localStorage.setItem('missionData', JSON.stringify(updatedMissionDetails));
      }
    });
  }

  updateMissionDetailsAndCustomerRequestedData(
    updatedMissionDetails: MissionDetailsForm,
    customerRequestedData: CustomerRequestedDataDto,
  ) {
    this.missionFlowService.updateMissionDetails(updatedMissionDetails).subscribe({
      next: _ => {
        this.missionFlowService.updateCustomerRequestedData(customerRequestedData).subscribe({
          next: _ => {
            this.refreshData();
            this.pilotSourcingCommunicationService.callReloadFunction({
              missionName: updatedMissionDetails.missionName,
            });
          },
          error: err => console.log(err),
        });
      },
      error: err => console.log(err),
    });
  }

  refreshData() {
    this.fetchOrderRequestData(true, false);
    this.getMissionData();
  }

  reloadParentInfo(data = null) {
    if (data) this.pilotSourcingCommunicationService.callReloadFunction(data);
    else
      this.pilotSourcingCommunicationService.callReloadFunction({ reloadComplementarioInfo: true });
  }

  onEditDeliverableAspectsClick(): void {
    const dialogRef = this.dialog.open(DeliverableAspectsModalComponent, {
      width: '700px',
      disableClose: true,
      data: {
        dueDate: this.orderRequest.deliverableAspects.deliverableDueDate,
        allAtSameTime: this.orderRequest.deliverableAspects.deliverableAllSameTime,
        uploadingInstructions: this.orderRequest.deliverableAspects.uploadingDataInstructions,
        missionId: this.missionId,
        deliverableAspectsId: this.orderRequest.deliverableAspects.deliverableAspectId,
        additionalNotes: this.orderRequest.attachmentsAndNotes.additionalNotes,
        deliverableNotes: this.orderRequest.deliverableAspects.deliverableNotes,
        fixedDueDate: this.getDate(),
        missionStatusCode: this.missionStatusCode,
        uploadDataLocation: this.orderRequest.deliverableAspects.uploadDataLocation,
        externalLinkName: this.orderRequest.deliverableAspects.externalLinkName,
        externalLinkUrl: this.orderRequest.deliverableAspects.externalLinkUrl,
      },
    });

    dialogRef.afterClosed().subscribe((updatedDeliverableAspects: DeliverableAspectsFormModel) => {
      if (updatedDeliverableAspects) {
        this.missionFlowService
          .updateMissionDeliverableAspects(updatedDeliverableAspects)
          .subscribe({
            next: response => {
              this.fetchOrderRequestData(true, false);
              this.reloadParentInfo();
            },
            error: err => console.log(err),
          });
      }
    });
  }

  onEditLocationClick(): void {
    const originalLocationId = this.orderRequest.location.locationId;
    const dialogRef = this.dialog.open(LocationModalComponent, {
      width: '1350px',
      disableClose: true,
      data: {
        missionId: this.orderRequest.missionId,
        locationId: originalLocationId,
        siteName: this.orderRequest.location.siteName,
        customerId: this.orderRequest.customerId,
        location: {
          city: this.orderRequest.location.city,
          stateId: this.orderRequest.location.stateId,
          state: this.orderRequest.location.state,
          countryId: this.orderRequest.location.countryId,
          country: this.orderRequest.location.country,
          address: this.orderRequest.location.address,
          zip: this.orderRequest.location.zipCode,
          lng: this.orderRequest.location.longitude,
          lat: this.orderRequest.location.latitude,
        },
        airspaceClassificationId: this.orderRequest.location.airspaceClassificationId,
        airspaceClassification: this.orderRequest.location.airspaceClassification,
        kmlFilePath: this.orderRequest.location.kmlFilePath,
        kmlFileName: this.orderRequest.location.kmlFileName,
        kmlFileId: this.orderRequest.location.kmlFileId,
        states: this.states,
        countries: this.countries,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((updatedLocation: LocationForm) => {
      if (updatedLocation) {
        if (updatedLocation.locationId == originalLocationId) {
          const obs = [];

          // Same LocationId, update airspace classification
          obs.push(this.updateAirspaceClassification(updatedLocation));
          obs.push(
            this.updateMissionLocationInformation(
              this.missionId,
              updatedLocation.kmlFileId,
              updatedLocation.kmlFilePath,
            ),
          );

          concat(...obs)
            .pipe(
              finalize(() => {
                this.fetchOrderRequestData(true, false);
              }),
            )
            .subscribe({
              next: () => {},
              error: err => console.error('Error updating mission information', err),
            });
        } else {
          // Remove Mission Site Locations if the location is updated
          this.missionLocationContacts.deleteSitesFromMission(this.missionId).subscribe({
            next: () => {
              this.toaster.success(
                'Location Updated. Site contacts from the old location were removed.',
              );

              if (updatedLocation.locationId) {
                const obs = [];

                // Different and non-empty LocationId, update locationId reference
                obs.push(
                  this.missionFlowService.updateLocationReference(
                    this.orderRequest.missionId,
                    updatedLocation.locationId,
                  ),
                );
                obs.push(
                  this.updateMissionLocationInformation(
                    this.missionId,
                    updatedLocation.kmlFileId,
                    updatedLocation.kmlFilePath,
                  ),
                );
                obs.push(this.updateAirspaceClassification(updatedLocation));

                concat(...obs)
                  .pipe(
                    finalize(() => {
                      this.fetchOrderRequestData(true, false);
                    }),
                  )
                  .subscribe({
                    next: (res: any) => {},
                    error: err => console.error('Error on updating airspace classification: ', err),
                  });
              } else {
                let createdLocationId = '';

                // Empty LocationId, create new location and assign to mission
                const newLocationAndAddress: LocationWithAddressDto =
                  this.mapFromFormToLocationWithAddressDto(updatedLocation);

                const obs = [];

                obs.push(this.missionFlowService.createLocation(newLocationAndAddress));
                obs.push(
                  this.updateMissionLocationInformation(
                    this.missionId,
                    updatedLocation.kmlFileId,
                    updatedLocation.kmlFilePath,
                  ),
                );

                concat(...obs)
                  .pipe(
                    finalize(() => {
                      this.fetchOrderRequestData(true, false);

                      if (!createdLocationId) return;

                      // Also create the record on the CustomerLocation table
                      const customerLocationDto: CustomerLocationCreateDto = {
                        customerId: this.orderRequest.customerId,
                        locationId: createdLocationId,
                      };

                      this.customerLocationService.create(customerLocationDto).subscribe({
                        next: () => {
                          this.ordersRefreshService.triggerMissionRefresh();
                        },
                        error: err => console.error('Error on creating customer location: ', err),
                      });
                    }),
                  )
                  .subscribe({
                    next: res => {
                      if (typeof res === 'string') createdLocationId = res;
                    },
                    error: err => console.error('Error on creating or updating location: ', err),
                  });
              }
            },
            error: err => console.error('Error on deleting mission site locations: ', err),
          });
        }

        this.reloadParentInfo({
          airspaceClassificationId: updatedLocation.airspaceClassificationId,
        });
        localStorage.setItem('missionDataLocation', JSON.stringify(updatedLocation));
      }
    });
  }

  getDate(): string {
    if (this.orderRequest.deliverableAspects.fixedDeliverableDueDate) {
      const date = new Date(this.orderRequest.deliverableAspects.fixedDeliverableDueDate);

      return format(date, 'MM/dd/yyyy');
    }

    return '';
  }

  private updateMissionLocationInformation(
    missionId: string,
    fileId: string,
    fileUrl: string,
  ): Observable<void> {
    return this.missionService.updateMissionLocationInformation(<MissionLocationInformationDto>{
      missionId: missionId,
      kmlFileId: fileId,
      kmlFilePath: fileUrl,
    });
  }

  private updateAirspaceClassification(
    updatedLocation: LocationForm,
  ): Observable<CustomerRequestedDataDto> {
    return this.missionFlowService.updateLocationAirspaceClassification(updatedLocation);
  }

  onEditAttachmentsAndNotesClick(): void {
    const dialogRef = this.dialog.open(AttachmentsNotesModalComponent, {
      width: '700px',
      disableClose: true,
      data: {
        missionId: this.missionId,
        additionalNotes: this.orderRequest.attachmentsAndNotes.additionalNotes,
        filesAttachments: this.orderRequest.attachmentsAndNotes.referenceDocumentation,
      },
    });

    dialogRef.afterClosed().subscribe((updatedNotesAndAttachment: AttachmentsNotesFormModel) => {
      if (updatedNotesAndAttachment) {
        if (updatedNotesAndAttachment.saveData) {
          this.missionFlowService.updateNotesAndAttachment(updatedNotesAndAttachment).subscribe({
            next: response => this.fetchOrderRequestData(true, false),
            error: err => console.log(err),
          });
        } else {
          updatedNotesAndAttachment.filesAttachments?.forEach(
            (fileModel: OrderReferenceDocumentationDto) => {
              if (fileModel.uploaded) {
                this.removeFile(fileModel);
                const index = updatedNotesAndAttachment.filesAttachments.indexOf(fileModel);
                if (index >= 0) {
                  updatedNotesAndAttachment.filesAttachments.splice(index, 1);
                }
              }
            },
          );
        }
      }
    });
  }

  removeFile(file: OrderReferenceDocumentationDto): void {
    this.fileDescriptorService.delete(file.fileId);
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {}

  fetchOrderRequestData(reloadMap: boolean, isInitialCall: boolean = true): void {
    this.spinner.show();
    this.missionFlowService.getOrderRequestData(this.missionId).subscribe({
      next: data => {
        this.orderRequest = { ...data };
        this.newUpdate.emit(this.orderRequest);

        this.orderRequest.orderDetails.descriptionToShow =
          this.orderRequest.orderDetails.description.replace(/\n/g, '<br/>');

        if (this.orderRequest?.deliverableAspects) {
          this.orderRequest.deliverableAspects.uploadingDataInstructionsToShow =
            this.orderRequest.deliverableAspects.uploadingDataInstructions?.replace(/\n/g, '<br/>');
          this.orderRequest.deliverableAspects.deliverableNotesToShow =
            this.orderRequest.deliverableAspects.deliverableNotes?.replace(/\n/g, '<br/>');
        }

        if (this.orderRequest?.attachmentsAndNotes)
          this.orderRequest.attachmentsAndNotes.additionalNotesToShow =
            this.orderRequest.attachmentsAndNotes.additionalNotes?.replace(/\n/g, '<br/>');

        // Fix to avoid null attachments
        const docs = this.orderRequest.attachmentsAndNotes.referenceDocumentation;
        if (docs.length === 1 && docs[0] === null) {
          this.orderRequest.attachmentsAndNotes.referenceDocumentation = [];
        }

        // This branch is to set updated values into the selected grid item (if any)
        if (!isInitialCall) {
          this.ordersRefreshService.triggerMissionRefresh();
        }

        if (reloadMap) {
          if (this.orderRequest.location.kmlFilePath) {
            this.fileDescriptorService.get(this.orderRequest.location.kmlFileId).subscribe({
              next: res => {
                this.orderRequest.location.kmlFileName = res.name;
                this.spinner.hide();
              },
            });

            setTimeout(() => {
              this.map.loadKMLLayer(this.orderRequest.location.kmlFilePath);
              this.updateMapMarker();
              this.hasKml = true;
              this.spinner.hide();
            }, 500);
          } else {
            setTimeout(() => {
              this.removeKmlFile();
              this.hasKml = false;
              this.spinner.hide();
            }, 500);
          }
        } else {
          this.spinner.hide();
        }
      },
      error: error => {
        console.error('Error fetching orderRequest data:', error);
        this.toaster.error('There was an error while fetching the mission.');
        this.spinner.hide();
      },
    });
  }

  updateMapMarker() {
    const currentLat = this.orderRequest.location.latitude;
    const currentLng = this.orderRequest.location.longitude;

    if (currentLat.length != 0 && currentLng.length != 0) {
      this.map.clearMarkers();
      const newMarker: FlyguysMapMarker = {
        id: 1,
        lat: parseFloat(currentLat),
        lng: parseFloat(currentLng),
      };
      this.map.addMarker(newMarker);
    }
  }

  removeKmlFile(): void {
    setTimeout(() => {
      this.hasKml = false;
      this.map.removeKMLLayer();
      this.map.kmlLayerToggle;
      this.updateMapMarker();
    }, 500);
  }

  downloadKml() {
    const kmlFile: OrderReferenceDocumentationDto = {
      fileId: this.orderRequest.location.kmlFileId,
      fileName: this.orderRequest.location.kmlFileName,
      filePath: this.orderRequest.location.kmlFilePath,
    };
    this.downloadDocument(kmlFile);
  }

  downloadDocument(file: OrderReferenceDocumentationDto) {
    this.spinner.show();

    this.fileDescriptorService
      .getDownloadToken(file.fileId)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(file.fileId, token)),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(result => {
        downloadBlob(result, file.fileName);
      });
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  private configureActionsVisibility(): void {
    if (!this.missionStatusId) return;

    this.allowOrderRequestEditDetails = !!this.currentActionConfiguration.find(
      t => t.code == this.ORDER_REQUEST_EDIT_DETAILS && t.statusId == this.missionStatusId,
    );

    this.allowOrderRequestEditLocation = !!this.currentActionConfiguration.find(
      t => t.code == this.ORDER_REQUEST_EDIT_LOCATION && t.statusId == this.missionStatusId,
    );

    this.allowOrderRequestEditSiteContactInformation = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.ORDER_REQUEST_EDIT_SITE_CONTACT_INFORMATION &&
        t.statusId == this.missionStatusId,
    );

    this.allowOrderRequestEditDeliverableAspects = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.ORDER_REQUEST_EDIT_DELIVERABLE_ASPECTS && t.statusId == this.missionStatusId,
    );

    this.allowOrderRequestEditAttachmentsAndNotes = !!this.currentActionConfiguration.find(
      t =>
        t.code == this.ORDER_REQUEST_EDIT_ATTACHMENTS_NOTES && t.statusId == this.missionStatusId,
    );
  }

  protected readonly parseFloat = parseFloat;

  openSiteLocation(): void {
    this.dialog.open(SiteLocationContactsComponent, {
      width: '1350px',
      disableClose: true,
      data: {
        missionId: this.orderRequest.missionId,
        locationId: this.orderRequest.location.locationId,
        customerId: this.orderRequest.customerId,
      },
    });
  }

  private convertToGpsCoordinates(latitude: string, longitude: string) {
    return `${this.parseFloat(latitude)},${this.parseFloat(longitude)}`;
  }

  private mapFromFormToLocationWithAddressDto(
    updatedLocation: LocationForm,
  ): LocationWithAddressDto {
    return {
      siteName: updatedLocation.siteName,
      city: updatedLocation.city,
      stateId: updatedLocation.stateId,
      stateName: updatedLocation.state,
      countryId: updatedLocation.countryId,
      countryName: updatedLocation.country,
      streetAddress: updatedLocation.address,
      zipCode: updatedLocation.zipCode,
      longitude: updatedLocation.longitude.toString(),
      latitude: updatedLocation.latitude.toString(),
      locationId: this.emptyGuid,
      customerId: this.orderRequest.customerId,
      missionId: this.orderRequest.missionId,
      gpsCoordinates: this.convertToGpsCoordinates(
        updatedLocation.latitude,
        updatedLocation.longitude,
      ),
      airSpaceClassificationId: updatedLocation.airspaceClassificationId,
      airSpaceClassificationDescription: updatedLocation.airspaceClassification,
    };
  }

  getCountries() {
    const subscountries = this.countriesService.getList(this.filterCountries).subscribe(
      (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount !== 0) {
          this.countries = data;
        }
      },
      error => {
        console.log('Error on getting Countries: ' + JSON.stringify(error));
      },
    );
  }

  getStates() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;
    const subsstates = this.statesService.getList(request).subscribe(
      (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount !== 0) {
          this.states = data;
        }
      },
      error => {
        console.log('Error on getting States: ' + JSON.stringify(error));
      },
    );
  }

  openPopupSlaMilestones(misionId: string, slaName: string) {
    const dialogRef = this.dialog.open(ShowSlaMilestonesComponent, {
      panelClass: 'modal-base',
      width: '900px',
    });
    dialogRef.componentInstance.Initialize(this.missionId, slaName);
  }

  notifStatusChange() {
    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            this.refreshData();
          }
        }
      }),
    );
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    this.toaster.success('Link copied!');
  }
}
