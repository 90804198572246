import { Component, Input, OnChanges } from '@angular/core';
import { MissionChangeLogDto } from '../../../models/mission-change-log-dto';

@Component({
  selector: 'app-timeline-detail',
  templateUrl: './timeline-detail.component.html',
  styleUrls: ['./timeline-detail.component.scss'],
})
export class TimelineDetailComponent implements OnChanges {
  @Input() detail!: MissionChangeLogDto;
  @Input() infoItems!: any;

  ngOnChanges(): void {
    if (!this.detail.displayEventName.toLowerCase().includes('recalculate')) {
      this.infoItems = JSON.parse(this.detail.displayEventInformation);
    } else {
      this.infoItems = null;
    }
  }
}
