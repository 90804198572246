<header
  class="header-area bg-white"
  stickyClass="sticky-box-shadow"
  ngStickyNav
  [class.component-dark-theme]="themeService.isDark()"
  [ngClass]="{ active: isToggled }"
>
  <div class="d-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <div class="burger-menu" (click)="toggle()" [ngClass]="{ active: isToggled }">
        <span class="material-symbols-outlined"> menu </span>
      </div>
    </div>
    <ul class="header-right-side d-flex align-items-center t-mt-0 t-mb-0">
      <li *abpPermission="'Chat.Messaging'">
        <button
          mat-icon-button
          class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
          [class.notification-btn]="chatConfigService.unreadMessagesCount > 0"
          routerLink="/chat"
        >
          <span class="material-symbols-outlined"> chat_bubble </span>
        </button>
      </li>

      <ng-container *ngIf="isLogged()">
        <li>
          <button
            mat-icon-button
            [matMenuTriggerFor]="notesDrawer.menu"
            class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
            [ngClass]="{ 'notification-btn': notes.length > 0 && !notesDrawer.cleared }"
          >
            <span class="material-symbols-outlined"> description </span>
          </button>

          <flg-drawer
            #notesDrawer
            [notifications]="notes"
            [itemTemplate]="noteNotificationItem"
            [mode]="noteNotificationsMode"
            title="Notes Notifications"
            viewAllUrl="orders/list"
          >
          </flg-drawer>
        </li>

        <li>
          <button
            mat-icon-button
            [matMenuTriggerFor]="notificationsDrawer.menu"
            class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
            [ngClass]="{
              'notification-btn': notifications.length > 0 && !notificationsDrawer.cleared,
            }"
          >
            <span class="material-symbols-outlined"> notifications </span>
          </button>

          <flg-drawer
            #notificationsDrawer
            [notifications]="notifications"
            [itemTemplate]="notificationItem"
            [mode]="notificationsMode"
            (clearedNotification)="markAsRead($event)"
            title="Notifications"
          >
          </flg-drawer>
        </li>
      </ng-container>

      <li class="vr"></li>

      <li *ngIf="isLogged(); else notLoggedIn">
        <a [matMenuTriggerFor]="profileMenu">
          <span class="material-symbols-outlined me-1"> account_circle </span>
          <span class="username-tag">{{ currentUser.userName }}</span>
        </a>
        <mat-menu class="common-menu mat-elevation-z2" #profileMenu="matMenu" xPosition="before">
          <ul class="common-menu common-menu--body">
            <li (click)="logout()">
              <span class="material-symbols-outlined"> logout </span>
              Logout
            </li>
          </ul>
        </mat-menu>
      </li>

      <ng-template #notLoggedIn>
        <li>
          <a routerLink="/account">
            <mat-icon class="t-me-5">vpn_key</mat-icon>
            <span>Please Log In</span>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>
</header>

<ng-template let-notification #noteNotificationItem>
  <span class="title">
    Job ID: {{ notification.jobId || 'N/A' }} - {{ notification.missionName }}
  </span>
  <span class="body">{{ notification.subject }}</span>
</ng-template>

<ng-template let-notification #notificationItem let-this="this">
  <span class="d-flex">
    {{ notification.message }}

    <button
      class="bg-transparent border-none p-0"
      (click)="this.handleOnRemoveNotification(notification, $event)"
    >
      <span class="material-symbols-outlined"> close </span>
    </button>
  </span>
</ng-template>
