<div class="container-fluid" *ngIf="missionFilter">
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">Loading Folders...</p>
  </ngx-spinner>

  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
    [name]="'capture'"
  >
    <p style="color: #bc5a2e">Removing capture...</p>
  </ngx-spinner>
  <div class="row" *ngIf="selectedCapture">
    <div class="align-items-center">
      <div class="d-flex flex-wrap justify-content-between align-items-center mt-3">
        <div class="d-flex gap-1 justify-content-start align-items-center file-management-path">
          <span (click)="goBack()" role="button">{{
            'missionsService::Deliverables' | abpLocalization
          }}</span>
          <span>/</span>
          <span class="file-management-path file-management-path__deliverable-name">
            {{ deliverable.deliverableName }} -
            {{ deliverable.productName || deliverable.packageName }}</span
          >
          <span>/</span>
          <abp-file-management-breadcrumb
            [missionFilter]="missionFilter"
            [navigatedFolders]="navigator.navigatedFolderPath$ | async"
            (breadcrumbClick)="navigateToFolder($event)"
          ></abp-file-management-breadcrumb>
        </div>
        <abp-file-management-file-upload
          *ngIf="missionFilter.rootFolder"
          [rootId]="missionFilter.rootFolder.id"
          [missionId]="selectedCapture.missionId"
          [captureId]="selectedCapture.id"
          [deliverableId]="deliverable.deliverableId"
          [orderDetailId]="deliverable.orderDetailId"
        ></abp-file-management-file-upload>
      </div>
      <div *ngIf="deliverableAspectsInstructions" class="ms-3">
        <h3>Uploading Data Instructions</h3>
        {{ deliverableAspectsInstructions }}
      </div>
      <div class="d-flex align-baseline mt-2">
        <mat-form-field
          [floatLabel]="'always'"
          *ngIf="captureList"
          class="mt-1 compressed-form-field-density"
        >
          <mat-label>Capture Version</mat-label>
          <mat-select
            [(ngModel)]="missionFilter.captureId"
            [placeholder]="'Select a Capture'"
            (selectionChange)="onChangeCapture(missionFilter.captureId)"
          >
            <mat-option *ngFor="let item of captureList" [value]="item.id">
              Capture {{ item.captureNumber }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="deliverable-file-action-group">
          <button
            type="button"
            class="deliverable-file-action"
            (click)="downloadSelectedFiles()"
            title="Download files"
            matTooltip="Download Files"
          >
            <span class="material-symbols-outlined"> download </span>
          </button>

          <button
            *ngIf="isDeliverableStatusAwaiting(deliverable?.status)"
            type="button"
            class="deliverable-file-action"
            (click)="showMoveModal()"
            matTooltip="Move Files"
          >
            <span class="material-symbols-outlined"> drive_file_move </span>
          </button>

          <abp-file-management-buttons
            class="deliverable-file-action"
            *ngIf="missionFilter.rootFolder"
            [rootId]="missionFilter.rootFolder.id"
            [missionId]="missionId"
            [captureId]="captureId"
            [deliverableId]="deliverableId"
            [missionFilter]="missionFilter"
            [onlyViewDownload]="onlyViewDownload"
          >
          </abp-file-management-buttons>

          <button
            type="button"
            class="deliverable-file-action"
            (click)="paralledFileUploadService.openProgressViewer()"
            matTooltip="View File Upload Progress"
          >
            <span class="material-symbols-outlined"> arrow_upload_progress </span>
          </button>

          <app-file-management-switcher
            [showMapIcon]="showMapIcon"
            [displayMap]="displayMap"
            (toggleMap)="toggleMap($event)"
          ></app-file-management-switcher>
        </div>
      </div>
      <div class="d-flex align-baseline mt-2">
        <p class="top-attribute">
          <span class="primary">Capture Date:</span>
          <span class="secondary">{{
            selectedCapture.fixedDate || (captureDate + 'Z' | date: 'MM/dd/yyyy')
          }}</span>
        </p>

        <p class="top-attribute">
          <span class="primary">Pilot Name:</span>
          <span class="secondary">{{ selectedCapture.capturePilot || '-' }}</span>
        </p>

        <p class="top-attribute">
          <span class="primary">Capture Time: </span>
          <span class="secondary">{{
            selectedCapture?.fixedTime || (captureDate + 'Z' | date: 'hh:mm a')
          }}</span>
        </p>
      </div>

      <div class="d-flex mt-2">
        <app-file-management-info-block [title]="'Deliverable Attributes'">
          <div class="d-flex flex-wrap">
            <dl class="attribute-item">
              <dt class="attribute-name">Deliverable Status</dt>
              <dd class="attribute-definition">
                <span
                  [ngClass]="getDeliverableStatusClass(selectedCapture.captureDeliverableStatus)"
                  class="me-2"
                >
                  {{ getDeliverableStatusText(selectedCapture.captureDeliverableStatus) }}
                </span>

                <lib-action-form
                  *ngIf="
                    canReject(selectedCapture.captureDeliverableStatus) &&
                    selectedCapture &&
                    deliverable
                  "
                  [row]="{
                    missionId: selectedCapture.missionId,
                    deliverableId: deliverable.deliverableId,
                    orderDetailId: deliverable.orderDetailId,
                    title: missionName,
                    altitude: alt_value || '-',
                    gimbal: gr_value || '-',
                    overlap: ovlp_value || '-',
                    originAuthToken: currentToken,
                    creatorId: currentUserId,
                    selectedCapture: selectedCapture.id,
                  }"
                  [gridAction]="action"
                  [isMobile]="false"
                  [config]="{ modalWidth: '900px', showCloseOnModal: true }"
                  class="single-action single-action--wide"
                >
                  <button
                    type="button"
                    class="table-outline-button ms-1"
                    [disabled]="disableActions"
                  >
                    Reject
                  </button>
                </lib-action-form>
                <lib-action-form
                  *ngIf="
                    canAccept(selectedCapture.captureDeliverableStatus) &&
                    selectedCapture &&
                    deliverable
                  "
                  [row]="{
                    title: acceptTitle,
                    question: acceptQuestion,
                    missionId: selectedCapture.missionId,
                    deliverableId: deliverable.deliverableId,
                    orderDetailId: deliverable.orderDetailId,
                    creatorId: currentUserId,
                    selectedCapture: selectedCapture.id,
                  }"
                  [gridAction]="acceptAction"
                  [isMobile]="false"
                  [config]="{ modalWidth: '900px', showCloseOnModal: true }"
                  class="single-action single-action--wide"
                >
                  <button
                    type="button"
                    class="table-outline-button table-outline-button--inverted ms-1"
                    [disabled]="disableActions"
                  >
                    Accept
                  </button>
                </lib-action-form>
                <button
                  type="button"
                  class="table-outline-button table-outline-button--inverted ms-1"
                  (click)="updateDeliverableToAwait()"
                  [disabled]="disableActions"
                  *ngIf="canReset(selectedCapture.captureDeliverableStatus)"
                >
                  Awaiting
                </button>
              </dd>
            </dl>

            <dl class="attribute-item">
              <dt class="attribute-name">Automated QAQC</dt>
              <dd class="attribute-definition">
                <span [ngClass]="validationClass">
                  {{ validationText }}
                </span>

                <span
                  class="material-symbols-outlined cursor-pointer ms-2"
                  (click)="openValidationResultsDialog()"
                >
                  visibility
                </span>
              </dd>
            </dl>

            <dl class="attribute-item" *ngFor="let attr of attributeList">
              <dt class="attribute-name">{{ attr.attributeName }}</dt>
              <dd class="attribute-definition" *ngIf="!attr.fileLink">
                <p>{{ attr.value }}</p>
              </dd>
              <dd class="attribute-definition" *ngIf="attr.fileLink">
                <a
                  *ngIf="attr.fileLink"
                  [download]="attr.fileName"
                  (click)="downloadFromBlobStorage(attr.fileLink, attr.fileName)"
                  >{{ attr.fileName || '--' }}</a
                >
              </dd>
            </dl>
          </div>
        </app-file-management-info-block>
      </div>

      <ng-container *ngIf="note">
        <div class="d-flex mt-2">
          <app-file-management-info-block [title]="note.subject">
            <p class="note-text">
              {{ note.body }}
            </p>
          </app-file-management-info-block>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedCapture.captureDeliverableStatus == rejectedCaptureStatus">
        <div class="d-flex mt-2">
          <app-file-management-info-block [title]="'QA/QC Notes'">
            <p class="note-text">
              {{ selectedCapture?.captureDeliverableNote }}
            </p>
          </app-file-management-info-block>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="!displayMap">
    <div class="col-12 col-md-4 col-lg-3 col" style="display: none">
      <div class="mt-3 mb-4">
        <!-- <abp-file-management-buttons
          *ngIf="missionFilter.rootFolder && isEditable"
          [rootId]="missionFilter.rootFolder.id"
          [missionId]="missionId"
          [captureId]="captureId"
          [deliverableId]="deliverableId"
        >
        </abp-file-management-buttons> -->
        <abp-file-management-directory-tree
          *ngIf="contentReady"
          [isEditable]="isEditable"
          [validateCapture]="true"
          [missionFilter]="missionFilter"
          [hideMainRootFolder]="true"
          [onlyViewDownload]="onlyViewDownload"
        >
        </abp-file-management-directory-tree>
      </div>
    </div>

    <div class="w-100">
      <div class="mt-3">
        <h3>Deliverable Files</h3>
        <abp-file-management-folder-panel
          *ngIf="contentReady"
          [optionsFilter]="optionsFilter"
          (contentUpdate)="currentContent = $event"
          [hideDirectoryView]="true"
          [filesSelected]="filesSelected"
          (filesSelectedChange)="updateFilesToDownload($event)"
          (selectAllChange)="handleOnSelectAllChange($event)"
          [missionFilter]="missionFilter"
          [showOptions]="true"
          [onlyViewDownload]="onlyViewDownload"
          [deliverable]="deliverable"
        ></abp-file-management-folder-panel>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="displayMap" [class.d-none]="loadingMap">
    <div class="col-lg-12">
      <h3>Map</h3>
      <flg-map
        [usesDarkMode]="false"
        [usesKML]="true"
        [dimensions]="{
          height: '500px',
          width: '100%',
        }"
        [contextMenuItems]="contextMenuItems"
      ></flg-map>
    </div>
  </div>

  <abp-modal [(visible)]="isMoveFolderModalOpen" [busy]="isMoveFolderModalBusy">
    <ng-template #abpHeader>
      <h3>Move Folder/Files</h3>
    </ng-template>

    <ng-template #abpBody>
      <h4>
        <span
          class="move-modal-path"
          *ngFor="let path of modalPath; index as i"
          (click)="navigateToPath(i)"
        >
          <span *ngIf="i === 0">
            <b>{{ path?.path }} > </b>
          </span>
          <span *ngIf="i !== 0"> {{ path?.path }} > </span>
        </span>
      </h4>
      <ul class="mt-3 list-group list-group-flush">
        <li
          *ngFor="let item of moveModalHistory[moveModalHistory.length - 1]"
          class="list-group-item"
          (click)="goToContent(item)"
        >
          <div>
            <img
              *ngIf="item.isDirectory"
              src="assets/images/logo/folder-logo.png"
              class="img-fluid"
            />
            <span class="ms-2">
              {{ item.deliverableName ?? item.name }}
            </span>
          </div>
        </li>
      </ul>
    </ng-template>

    <ng-template #abpFooter>
      <button type="button" class="btn btn-secondary" abpClose>Close</button>
      <button (click)="moveSelectedItems()" type="button" class="btn btn-secondary">Move</button>
    </ng-template>
  </abp-modal>

  <app-responsive-grid
    class="d-none"
    gridName="RejectDeliverable"
    (action)="setAction($event)"
  ></app-responsive-grid>

  <app-responsive-grid
    class="d-none"
    gridName="AcceptDeliverable"
    (action)="setAcceptAction($event)"
  ></app-responsive-grid>
</div>
