import { Component, EventEmitter, Output, Input, ViewChild, OnInit } from '@angular/core';
import { OrderFormSiteFileModel } from '../../orders/model/order-form-site-file.model';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { forkJoin, map, switchMap } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  FilesInfo,
  FilesInfoPkg,
} from '../../../shared/deliverable-attributes/deliverable-attributes.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() fileRemoved: EventEmitter<File> = new EventEmitter<File>();
  @Input() allowMultiple: boolean = true;
  @Input() allowedExtensions: string = '';
  @Input() selectedFiles: File[] = [];
  @Input() showFilesAttached: Boolean = true;
  @Input() disabled: boolean = false;
  @Input() includeBorder: boolean = true;
  @Input() initialFiles: OrderFormSiteFileModel[] = [];
  @ViewChild('fileInput') fileInput: any;
  @Input() seeIconButton: boolean = false;
  @Input() isKML: boolean = false;
  @Input() filesInfo: FilesInfo[] = [];
  @Input() filesInfoPkg: FilesInfoPkg[] = [];
  @Input() index: number;
  @Input() delivIndex: number;

  constructor(
    private fileDescriptorService: FileDescriptorService,
    private toaster: ToasterService,
  ) {}
  ngOnInit() {
    if (this.initialFiles.length > 0) {
      this.downloadAndEmitInitialFiles();
    }
    if (this.isKML) {
      if (this.filesInfo.length > 0) {
        let infoItems = this.filesInfo?.find(x => x.index == this.index);
        if (infoItems) {
          this.selectedFiles = infoItems?.selectedFiles;
        }
      }
      if (this.filesInfoPkg.length > 0) {
        const infoItems = this.filesInfoPkg?.find(
          x => x.deliverableIndex == this.delivIndex && x.index == this.index,
        );
        if (infoItems) {
          this.selectedFiles = infoItems.selectedFiles;
        }
      }
    }
  }

  private downloadAndEmitInitialFiles() {
    const fileDetails$ = this.initialFiles.map(file => {
      return this.fileDescriptorService.get(file.fileId).pipe(
        switchMap(fileDetail => {
          return this.fileDescriptorService.getDownloadToken(file.fileId).pipe(
            switchMap(({ token }) => this.fileDescriptorService.downloadFile(file.fileId, token)),
            map(fileBlob => ({
              fileBlob,
              fileDetail,
            })),
          );
        }),
      );
    });

    forkJoin(fileDetails$).subscribe(results => {
      const files: File[] = results.map(result => {
        const { fileBlob, fileDetail } = result;
        return new File([fileBlob], fileDetail.name, { type: fileBlob.type });
      });

      this.selectedFiles = files;
      this.filesSelected.emit(this.selectedFiles);
    });
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (!this.allowMultiple) {
      this.selectedFiles = [];
    }

    let filesAdded = false;

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileExists = this.selectedFiles?.some(
        selectedFile =>
          selectedFile.name === file.name &&
          selectedFile.size === file.size &&
          selectedFile.type === file.type,
      );
      if (!fileExists) {
        if (
          this.allowedExtensions.length === 0 ||
          this.allowedExtensions.includes('.' + fileExtension)
        ) {
          this.selectedFiles.push(file);
          filesAdded = true;
        } else {
          console.error(`File ${file.name} has an invalid extension.`);
          this.toaster.error(`File ${file.name} has an invalid extension.`);
        }
      }
    }

    if (filesAdded) {
      this.filesSelected.emit(this.selectedFiles);
    }

    this.fileInput.nativeElement.value = '';
    this.selectedFiles = [...this.selectedFiles];
  }

  removeFile(file: File) {
    const index = this.selectedFiles.indexOf(file);
    if (index >= 0) {
      this.selectedFiles.splice(index, 1);
      const fileModel = this.initialFiles.find(f => f.name.trim() === file.name.trim());
      if (fileModel) {
        const fileModelIndex = this.initialFiles.indexOf(fileModel);
        if (fileModelIndex >= 0) {
          this.initialFiles.splice(fileModelIndex, 1);
        }
      }
      this.fileRemoved.emit(file);
    }
  }

  removeAllSelectedFiles() {
    this.selectedFiles = [];
  }
}
