import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PagedResultDto } from '@abp/ng.core';

import { ShipmentCarrierDto } from './models/shipment-carrier.dto';
import { AddShipmentDto } from './models/add-shipment.dto';
import { ShipmentCreationDto } from './models/shipment-creation.dto';
import { ShipmentCarrierService } from './services/shipment-carrier.service';

@Component({
  selector: 'app-add-shipment-modal',
  templateUrl: './add-shipment-modal.component.html',
  styleUrls: ['./add-shipment-modal.component.scss'],
})
export class AddShipmentModalComponent implements OnInit {
  form: FormGroup;
  carriers: ShipmentCarrierDto[] = [];
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AddShipmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddShipmentDto,
    private formBuilder: FormBuilder,
    private shipmentCarrierService: ShipmentCarrierService,
  ) {
    this.form = this.formBuilder.group({
      trackingNumber: ['', Validators.required],
      carrierId: ['', Validators.required],
      estimatedArrivalDate: [''],
      note: [''],
    });
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentCarrierService.getList({ maxResultCount: 1000 }).subscribe({
      next: (result: PagedResultDto<ShipmentCarrierDto>) => {
        this.carriers = result.items.sort((a, b) => a.name.localeCompare(b.name));
        this.loading = false;
      },
      error: error => {
        console.error('Error fetching carriers:', error);
        this.loading = false;
      },
    });
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onClickSave(): void {
    if (this.form.valid) {
      const estimatedArrivalDate = this.form.get('estimatedArrivalDate').value;

      const shipment: ShipmentCreationDto = {
        missionId: this.data.missionId,
        trackingNumber: this.form.get('trackingNumber').value,
        carrierId: this.form.get('carrierId').value,
        estimatedArrivalDate: estimatedArrivalDate ? estimatedArrivalDate.toISOString() : null,
        note: this.form.get('note').value,
      };

      this.dialogRef.close(shipment);
    }
  }
}
