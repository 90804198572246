import { Pipe, PipeTransform } from '@angular/core';
import { OrderFormDeliverableFieldModel } from '../components/orders/model/order-form-deliverable-field.model';
import { KmlDifferentConfigData } from '../shared/deliverable-attributes-list/deliverable-attributes-list.component';

@Pipe({
  name: 'kmlFieldPipe',
})
export class KmlFieldPipe implements PipeTransform {
  transform(
    kmlData: KmlDifferentConfigData[],
    delvIndex: number,
    i: number,
  ): OrderFormDeliverableFieldModel[] {
    if (kmlData.length > 0) {
      let kmlDataInfo: OrderFormDeliverableFieldModel[] = [];
      let delV = kmlData.filter(x => x.delIndex == delvIndex);
      if (delV) {
        delV.forEach(x => {
          let data = x.data.find(y => y.index == i);
          kmlDataInfo = data.kmlData;
        });
      }
      return kmlDataInfo;
    }
  }
}
