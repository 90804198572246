import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ShipmentCarrierDto } from '../models/shipment-carrier.dto';
import { GetShipmentCarriersInput } from '../models/get-shipment-carriers-input.dto';

@Injectable({
  providedIn: 'root',
})
export class ShipmentCarrierService {
  apiName = 'missionsService';

  getList = (input: GetShipmentCarriersInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ShipmentCarrierDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/shipment-carriers',
        params: {
          filterText: input.filterText,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
