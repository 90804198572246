import { NgModule } from '@angular/core';
import { PageModule } from '@abp/ng.components/page';
import { SharedModule } from '../../shared/shared.module';

import { NotificationListRoutingModule } from './notification-list-routing.module';
import { NotificationListComponent } from './notification-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TablePageModule } from '../table-page/table-page.module';
import { CommonModule } from '@angular/common';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { NotificationsLogsComponent } from './notifications-logs/notifications-logs.component';
import { NotificationManagerComponent } from './notification-manager/notification-manager.component';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationsLogsComponent,
    NotificationManagerComponent,
  ],
  imports: [
    SharedModule,
    NotificationListRoutingModule,
    PageModule,
    MatTableModule,
    MatPaginatorModule,
    TablePageModule,
    CommonModule,
    ThemeSharedModule,
    MatMenuModule,
    FormsModule,
  ],
  exports: [NotificationsLogsComponent],
})
export class NotificationListModule {}
