import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomerAttributeCreateDto, CustomerAttributeDto, CustomerAttributeExcelDownloadDto, CustomerAttributeUpdateDto, CustomerAttributeWithNavigationPropertiesDto, GetCustomerAttributesInput } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerAttributeService {
  apiName = 'customersService';
  

  create = (input: CustomerAttributeCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAttributeDto>({
      method: 'POST',
      url: '/api/customers-service/customer-attributes',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/customers-service/customer-attributes/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAttributeDto>({
      method: 'GET',
      url: `/api/customers-service/customer-attributes/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getCustomersLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/customers-service/customer-attributes/customers-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/customers-service/customer-attributes/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetCustomerAttributesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerAttributeWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/customers-service/customer-attributes',
      params: { filterText: input.filterText, customerDescription: input.customerDescription, name: input.name, displayName: input.displayName, customerAttributeTypeId: input.customerAttributeTypeId, customerAttributeTypeDescription: input.customerAttributeTypeDescription, controlTypeId: input.controlTypeId, controlTypeDescription: input.controlTypeDescription, isRequired: input.isRequired, maxTextLengthMin: input.maxTextLengthMin, maxTextLengthMax: input.maxTextLengthMax, customersId: input.customersId, listValues: input.listValues, defaultValue: input.defaultValue, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: CustomerAttributeExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/customers-service/customer-attributes/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAttributeWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/customers-service/customer-attributes/with-navigation-properties/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: CustomerAttributeUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerAttributeDto>({
      method: 'PUT',
      url: `/api/customers-service/customer-attributes/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
