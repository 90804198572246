import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FileDescriptorService, FileInfoDto } from '@volo/abp.ng.file-management/proxy';
import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import { finalize, switchMap } from 'rxjs';
import { downloadBlob } from '@abp/ng.core';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { DeliverableAttribute } from './deliverable-attribute.interface';
import { AttributeTypesEnum } from '../../pilot-sourcing/models/attribute-types.enum';
import { FilesInfoPkg } from '../deliverable-attributes/deliverable-attributes.component';
import { KmlDataInfo } from '../deliverable-list/deliverables-list.component';
import { KmlDataInfoPkg } from '../package/package.component';

@Component({
  selector: 'app-deliverable-attributes-list',
  templateUrl: './deliverable-attributes-list.component.html',
  styleUrls: ['./deliverable-attributes-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliverableAttributesListComponent implements OnInit {
  public readonlyMode: boolean;
  maxFileSizeFiles: number = 200 * 1024 * 1024;
  filesInfo: FilesInfoPkg[] = [];
  selectedFiles: File[] = [];
  fieldUpload: OrderFormDeliverableFieldModel[] = [];
  deliverableAttribute: DeliverableAttribute;
  deliverableKmlData: DeliverableAttribute[] = [];
  KmlDataInfoPackages: KmlDataInfoPackages[] = [];
  kmlDataInfoPackagesDif: KmlDifferentConfigData[] = [];
  kmlData: OrderFormDeliverableFieldModel[] = [];
  kmlInfoUplaod: KmlDataInfoPkg[] = [];
  kmlFieldRemoved: KmlDataInfoPkg[] = [];
  kmlFieldDataRemoved: OrderFormDeliverableFieldModel[] = [];
  kmlFieldDataFilter: OrderFormDeliverableFieldModel[] = [];
  sameConfig: boolean = false;
  numberAfterCtrl: string;
  finish: boolean = false;
  disabled: boolean = false;

  deliverableAttributes: Array<DeliverableAttribute> = [];
  readonly DEFAULT_MAX_LENGTH = 100;

  public get attributeTypes(): typeof AttributeTypesEnum {
    return AttributeTypesEnum;
  }

  constructor(
    public dialogRef: MatDialogRef<DeliverableAttributesListComponent>,
    public loadingService: LoadingOverlayService,
    public readonly fileDescriptorService: FileDescriptorService,
    public dialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      deliverableList: OrderFormDeliverableModel[];
      quatity: number;
      isEdition: boolean;
      readonly: boolean;
      kmlDataInfo: KmlDataInfo[];
      pckgeKmlInfo: KmlDataInfoPkg[];
      isFromSummary: boolean;
    },
  ) {
    this.readonlyMode = data.readonly;
  }

  ngOnInit() {
    if (this.data.isEdition && this.data.isFromSummary){
      this.deliverableListFilter(this.data.deliverableList);
      this.dataFieldsFilter(this.data.deliverableList);
    }
    this.createInitialModel();
  }

  private dataFieldsFilter(data: OrderFormDeliverableModel[]) {
    if (data.length > 0) {
      data.forEach(d => {
        const uniqueValues = new Set<string>();

        const kmlDataFields = d.fields.filter(f => {
          if (f.typeCode.toLowerCase().includes('KML'.toLowerCase())) {
            if (!uniqueValues.has(f.stringValue)) {
              uniqueValues.add(f.stringValue);
              return true;
            }
            return false;
          }
          return true;
        });
        d.fields = kmlDataFields;
      });
    }
  }
  
  private deliverableListFilter(data: OrderFormDeliverableModel[]) {
    data.forEach(d => {
      if (!d.sameConfiguration) {
        this.sameConfig = d.sameConfiguration;
        const kmlDataFilter = d.fields.filter(
          df => !df.typeCode.toLowerCase().includes('kml'.toLowerCase()),
        );
        if (kmlDataFilter.length > 0) {
          const firstKmlData = kmlDataFilter[0];
          d.fields = [
            ...d.fields.filter(df => !df.typeCode.toLowerCase().includes('kml'.toLowerCase())),
            firstKmlData,
          ];
        }
        this.kmlFieldDataFilter = [...kmlDataFilter];
      }
    });
  }

  private createInitialModel(): void {
    this.data.deliverableList.forEach((deliverable, index) => {
      let deliverableAttribute = <DeliverableAttribute>{
        productId: deliverable.productId,
        deliverableName: deliverable.deliverableName,
        quantity: deliverable.quantity,
        sameConfiguration: this.data.isEdition ? deliverable.sameConfiguration : true,
        fields: deliverable.fields.map(x => {
          let attr = { ...x };
          attr.fieldControlName = `ctrl-${attr.id}-${deliverable.productId}`;

          if (this.data.isEdition) {
            attr.stringValue = x.stringValue;

            if (attr.typeCode == AttributeTypesEnum.Bool)
              attr.boolValue = attr.stringValue == true.toString();

            if (attr.typeCode == AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.stringValue);
          } else {
            if (attr.defaultValue) {
              if (attr.typeCode == AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue == true.toString();
              else if (attr.typeCode == AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else attr.stringValue = x.defaultValue;
            }
          }

          return attr;
        }),
      };

      deliverableAttribute.detailedAttributes = [];

      for (let i = 0; i < deliverableAttribute.quantity; i++) {
        if (this.data.isEdition) {
          if (deliverable.detailedAttributes && deliverable.detailedAttributes.length > i) {
            let fields = [...deliverable.detailedAttributes[i]];
            let fieldsCopy = fields.map((field, subIndex) => ({
              ...field,
              fieldControlName: `ctrl-${index}-${i}-${field.id}-${deliverable.productId}`,
            }));
            deliverableAttribute.detailedAttributes.push(fieldsCopy);
          } else {
            let newArray = deliverableAttribute.fields.map((x, subIndex) => {
              let attr = { ...x };
              attr.fieldControlName = `ctrl-${index}-${i}-${x.id}-${deliverable.productId}`;

              if (attr.defaultValue) {
                if (attr.typeCode == AttributeTypesEnum.Bool)
                  attr.boolValue = attr.defaultValue == true.toString();
                else if (attr.typeCode == AttributeTypesEnum.Number)
                  attr.numericValue = new Number(attr.defaultValue);
                else attr.stringValue = x.defaultValue;
              }

              return attr;
            });

            deliverableAttribute.detailedAttributes.push(newArray);
          }
        } else {
          let newArray = deliverableAttribute.fields.map((x, subIndex) => {
            let attr = { ...x };
            attr.fieldControlName = `ctrl-${index}-${i}-${x.id}-${deliverable.productId}`;
            attr.stringValue = '';

            if (attr.defaultValue) {
              if (attr.typeCode == AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue == true.toString();
              else if (attr.typeCode == AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else attr.stringValue = x.defaultValue;
            }
            return attr;
          });

          deliverableAttribute.detailedAttributes.push(newArray);
        }
      }
      this.deliverableAttributes.push(deliverableAttribute);

      if (
        this.data.isFromSummary &&
        this.kmlFieldDataFilter &&
        this.kmlFieldDataFilter.length > 0 &&
        !this.sameConfig
      ) {
        const kmlInfoPkg: KmlDataInfoPackages[] = [];
        let indexDa = 0;
        this.kmlFieldDataFilter.forEach(kmlF => {
          const regex = /ctrl-(\d+)/;
          const match = kmlF.fieldControlName.match(regex);
          if (match && match[1]) {
            this.numberAfterCtrl = match[1];
          }
        });

        deliverableAttribute.detailedAttributes.forEach(da => {
          const kmlFields = this.kmlFieldDataFilter.filter(x =>
            x.fieldControlName.includes(`ctrl-${this.numberAfterCtrl}-${indexDa}`),
          );
          const dataKml: KmlDataInfoPackages = {
            index: indexDa,
            kmlData: kmlFields,
          };
          const existingKmlDel = this.kmlDataInfoPackagesDif.find(item => item.delIndex === index);
          if (existingKmlDel) {
            existingKmlDel.data.push(dataKml);
          } else {
            kmlInfoPkg.push(dataKml);
            const kmlDel: KmlDifferentConfigData = {
              delIndex: index,
              data: kmlInfoPkg,
            };
            this.kmlDataInfoPackagesDif.push(kmlDel);
          }
          indexDa++;
        });
      }
    });

    if (this.data.isEdition && !this.data.isFromSummary) {
      this.disabled = false;
      let index = 0;
      this.deliverableAttributes.forEach(x => {
        if (x.sameConfiguration) {
          const boolVar = this.data?.pckgeKmlInfo.find(pck => pck.productId == x.productId);
          if (boolVar) {
            const uniqueKmlFields = boolVar.kmlField.filter(
              (field, index, self) =>
                index ===
                self.findIndex(
                  f => f.stringValue === field.stringValue && f.fileValue === field.fileValue,
                ),
            );
            if (uniqueKmlFields) {
              boolVar.kmlField = uniqueKmlFields;
            }
            const kml = boolVar.kmlField;
            const data: KmlDataInfoPackages = {
              index: index,
              kmlData: kml,
            };
            this.KmlDataInfoPackages.push(data);
          } else {
            const emptyData: KmlDataInfoPackages = {
              index: index,
              kmlData: null,
            };
            this.KmlDataInfoPackages.push(emptyData);
          }
          if (x.fields) {
            const kmlFields = x.fields.filter(field => field.typeCode.includes('KML'));
            if (kmlFields.length > 1) {
              const firstKmlField = kmlFields[0];
              x.fields = x.fields.filter(field => !field.typeCode.includes('KML'));
              x.fields.push(firstKmlField);
            }
          }
        } else {
          const pkgFilter = this.data.pckgeKmlInfo.filter(pck => pck.productId == x.productId);

          if (pkgFilter) {
            let count = 0;
            pkgFilter.forEach(u => {
              const existingData = this.kmlDataInfoPackagesDif.findIndex(
                kml => kml.delIndex == index,
              );
              if (existingData !== -1) {
                const data: KmlDataInfoPackages = {
                  index: count,
                  kmlData: u.kmlField,
                };
                this.kmlDataInfoPackagesDif[existingData].data.push(data);
              } else {
                let dataArray: KmlDataInfoPackages[] = [];
                const data: KmlDataInfoPackages = {
                  index: count,
                  kmlData: u.kmlField,
                };
                dataArray.push(data);
                const kmlDataInfoPkg: KmlDifferentConfigData = {
                  data: dataArray,
                  delIndex: index,
                };
                this.kmlDataInfoPackagesDif.push(kmlDataInfoPkg);
              }
              count++;
            });
          }
        }
        index++;
      });
    }
    if (this.data.isEdition && this.data.isFromSummary) {
      let index = 0;
      this.disabled = true;
      this.deliverableAttributes.forEach(dl => {
        if (dl.sameConfiguration) {
          const fieldFilter = dl.fields.filter(f => f.typeCode.includes('KML'));
          const withOutKml = dl.fields.filter(f => !f.typeCode.includes('KML'));
          if (fieldFilter.length > 0) {
            dl.fields = [...withOutKml, fieldFilter[0]];

            const kml = fieldFilter;
            const data: KmlDataInfoPackages = {
              index: index,
              kmlData: kml,
            };
            this.KmlDataInfoPackages.push(data);
          }
        }
        index++;
      });
    }
    this.disabled = false;
    this.finish = true;
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverableList(): void {
    this.deliverableAttributes.forEach(deliverable => {
      let boolAttribute = deliverable.fields.filter(x => x.typeCode == AttributeTypesEnum.Bool);
      boolAttribute.forEach(attr => {
        attr.stringValue = attr.boolValue.toString();
      });

      let numericAttributes = deliverable.fields.filter(
        x => x.typeCode == AttributeTypesEnum.Number,
      );
      numericAttributes.forEach(attr => {
        if (attr.numericValue !== null && attr.numericValue !== undefined)
          attr.stringValue = attr.numericValue.toString();
        else attr.stringValue = '0';
      });

      deliverable.detailedAttributes.forEach(fields => {
        let boolAttribute = fields.filter(x => x.typeCode == AttributeTypesEnum.Bool);
        boolAttribute.forEach(attr => {
          attr.stringValue = attr.boolValue.toString();
        });

        let numericAttribute = fields.filter(x => x.typeCode == AttributeTypesEnum.Number);
        numericAttribute.forEach(attr => {
          if (attr.numericValue !== null && attr.numericValue !== undefined)
            attr.stringValue = attr.numericValue.toString();
          else attr.stringValue = '0';
        });
      });
    });

    this.dialogRef.close(this.deliverableAttributes);
    this.dialogRef.close({
      attributesData: this.deliverableAttributes,
      fieldUpload: this.fieldUpload,
      kmlFieldData: this.kmlData,
      kmlInfoUplaod: this.kmlInfoUplaod,
      kmlFieldRemoved: this.kmlFieldRemoved,
    });
  }

  public isControlInvalid(form: NgForm, controlName: string): boolean {
    return form?.controls[controlName]?.invalid ?? false;
  }

  public thereAreMissingFiles(): boolean {
    let missingValues = false;
    for (let dv of this.deliverableAttributes) {
      if (dv.sameConfiguration) {
        missingValues = !!dv.fields.find(
          x =>
            (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
            x.isRequired &&
            !x.stringValue,
        );

        if (missingValues) break;
      } else {
        for (let fields of dv.detailedAttributes) {
          missingValues = !!fields.find(
            x =>
              (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
              x.isRequired &&
              !x.stringValue,
          );

          if (missingValues) break;
        }

        if (missingValues) break;
      }
    }

    return missingValues;
  }

  onFileSelected(
    files: File[],
    field: OrderFormDeliverableFieldModel,
    fieldindex: number,
    dv: DeliverableAttribute,
    deliverableIndex: number,
  ) {
    if (field.typeCode == 'KML') {
      let newFileInfo: FilesInfoPkg = {
        deliverableIndex: deliverableIndex,
        index: fieldindex,
        selectedFiles: files,
      };
      this.filesInfo.push(newFileInfo);
    }
    this.selectedFiles = files;
    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles,
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      if (field.typeCode.toLowerCase() == 'KML'.toLocaleLowerCase()) {
        this.uploadKML(files, field, dv);
      } else {
        this.uploadFile(files[0], field);
      }
    }
  }

  uploadKML(files: File[], field: OrderFormDeliverableFieldModel, dv: DeliverableAttribute): void {
    if (!files) {
      console.error('Error Uploading file.No file selected');
      return;
    }
    this.fieldUpload = [];
    field.loadingFile = true;
    const formData = new FormData();

    files.forEach(file => {
      formData.append('fileList', file);
    });

    this.fileDescriptorService
      .uploadAttachMentsFolder(
        'Deliverables_Attributes',
        'Attribute_' + field.fieldControlName,
        formData,
      )
      .subscribe(
        (res: FileInfoDto[]) => {
          res.forEach((fileInfo: FileInfoDto) => {
            if (fileInfo?.id && fileInfo?.fileAttachmentUrl) {
              const newFieldCopy = { ...field };
              newFieldCopy.stringValue = fileInfo.id;
              newFieldCopy.fileValue = fileInfo.name;
              newFieldCopy.loadingFile = false;
              this.fieldUpload.push(newFieldCopy);
            }
          });
          field.loadingFile = false;
        },
        error => {
          field.loadingFile = false;
        },
      );
    let data: KmlDataInfoPkg = {
      productId: dv.productId,
      deliverableId: dv.deliverableId,
      kmlField: this.fieldUpload,
    };
    this.kmlInfoUplaod.push(data);
  }

  uploadFile(file: File, field: OrderFormDeliverableFieldModel): void {
    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    field.loadingFile = true;
    const formData = new FormData();
    formData.append('fileList', file);

    this.fileDescriptorService
      .uploadAttachMentsFolder(
        'Deliverables_Attributes',
        'Attribute_' + field.fieldControlName,
        formData,
      )
      .subscribe(
        (res: FileInfoDto[]) => {
          if (res[0]?.id && res[0]?.fileAttachmentUrl) {
            field.stringValue = res[0].id;
            field.fileValue = res[0].name;
          }

          field.loadingFile = false;
        },
        error => {
          field.loadingFile = false;
        },
      );
  }

  downloadDocument(field: OrderFormDeliverableFieldModel) {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(field.stringValue)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(field.stringValue, token)),
        finalize(() => this.loadingService.hideOverlay()),
      )
      .subscribe(result => {
        downloadBlob(result, field.fileValue);
      });
  }

  onFileRemoved(field: OrderFormDeliverableFieldModel, dv: DeliverableAttribute, file: File): void {
    this.kmlFieldDataRemoved.push(field);
    field.stringValue = '';
    field.fileValue = '';
    let fieldR: KmlDataInfoPkg = {
      productId: dv.productId,
      kmlField: this.kmlFieldDataRemoved,
    };
    this.kmlFieldRemoved.push(fieldR);
    if (this.kmlInfoUplaod.length > 0) {
      this.kmlInfoUplaod.forEach(x => {
        let kmlFile = x.kmlField.filter(fil => fil.fileValue !== file.name);
        if (kmlFile) {
          x.kmlField = [...kmlFile];
        }
      });
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  getFilesInfo(delvIndex: number, index: number) {
    if (this.filesInfo.length > 0) {
      let files = this.filesInfo.filter(x => x.deliverableIndex == delvIndex && index == x.index);
      return files;
    }
  }
}

export interface KmlDataInfoPackages {
  index: number;
  productId?: string;
  kmlData: OrderFormDeliverableFieldModel[];
}

export interface KmlDifferentConfigData {
  delIndex: number;
  data: KmlDataInfoPackages[];
}
