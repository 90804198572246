import { mapEnumToOptions } from '@abp/ng.core';

export enum enumNotificationType {
  EmailMessage,
  HubNotification,
  SmsNotification,
  WebNotification,
}

export const notificationTypeDisplayNames: Record<enumNotificationType, string> = {
  [enumNotificationType.EmailMessage]: 'Email Message',
  [enumNotificationType.HubNotification]: 'Hub Notification',
  [enumNotificationType.SmsNotification]: 'Sms Notification',
  [enumNotificationType.WebNotification]: 'Web Notification',
};

export const EnumNotificationStatusOptions = mapEnumToOptions(enumNotificationType);
