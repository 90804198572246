import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '@abp/ng.theme.shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToasterService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && req.url.includes('generate-reconciliation-data-qb')) {
          this.toastr.error(
            'Error: Access denied. Please review and update your QuickBooks configuration to ensure proper authorization.',
            '403 Forbidden',
          );
          return of(null);
        }
        return throwError(() => error);
      }),
    );
  }
}
