import { ABP, ConfigStateService, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationsDto } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';
import { PaginationSortingAndGlobalSearch } from '../../../shared/grid-filters/models/pagination-sorting-and-global-search';
import { NotificationFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import { NotificationFilterColumns } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters-columns';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { combineLatest, Observable, switchMap } from 'rxjs';
import {
  ConfigurationAttributeTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  enumState,
  GridViewsCreateDto,
  GridViewsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ColumnAction } from '../../columns/components/column-actions/column-actions.component';
import { enumNotificationCategoryType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-category-type';
import { enumNotificationTriggerType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-trigger-type';
import { enumNotificationType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-type';
import { enumNotificationStatus } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { MissionsGridSettingsForLocalStorage } from 'projects/missions-service/src/lib/basics/missions/components/models/mission-grid-settings-for-local-storage';
import { NotificationSettingsForLocalStorage } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-settings-for-local-storage';
import {
  assignedFilterDisplayNames,
  assignedFilterEnum,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/assignedFilter.enum';
import { GridViewsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { NotificationViewFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-view-filters';
import { CustomViewDataModel } from 'projects/missions-service/src/lib/basics/missions/components/models/custom-view-data.model';
import { CustomViewModalComponent } from 'projects/missions-service/src/lib/basics/missions/components/custom-view-modal/custom-view-modal.component';
import { CustomViewNotificationModalComponent } from 'projects/missions-service/src/lib/basics/missions/components/custom-view-notification-modal-component/custom-view-notification-modal-component.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnStatus } from '../../columns/components/column-displayer/column-displayer.component';
import { SelectionType } from '@swimlane/ngx-datatable';
import { NgxDatatableColumnStatePersistance } from 'projects/flyguys/src/app/directives/ngx-datatable-column-state-persistance/ngx-datatable-column-state-persistance.directive';
import { TablePaginatorComponent } from '../../table-page/components/table-paginator/table-paginator.component';

@Component({
  selector: 'app-notifications-logs',
  templateUrl: './notifications-logs.component.html',
  styleUrls: ['./notifications-logs.component.scss'],
})
export class NotificationsLogsComponent implements OnInit, OnDestroy {
  data: PagedResultDto<NotificationsDto> = {
    items: [],
    totalCount: 0,
  };

  isModalBusy = false;
  isModalOpen = false;

  currentUserId: string;

  clientMaxResultCount = 10;
  valuesMaxResultCount = [];
  pageNumber = 0;
  action: any;
  MaxResultCount = 10;
  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';
  selectedAssignedFilter: number = 0;
  viewConfigSelected: NotificationViewFilters;
  selected: NotificationsDto;
  @Input() missionId?: string;

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 10,
  };

  notificationGridFilters: NotificationFilters = {};
  filterConfig: FilterConfig = NotificationFilterColumns;

  notificationCategoryTypeDictionary: { [key: number]: string } = {
    [enumNotificationCategoryType.QAQCAlerts]: 'QA/QC Alerts',
    [enumNotificationCategoryType.CustomerUpdates]: 'Customer Updates',
  };

  notificationTriggerTypeDictionary: { [key: number]: string } = {
    [enumNotificationTriggerType.StatusChange]: 'Status Change',
  };

  notificationTypeDictionary: { [key: number]: string } = {
    [enumNotificationType.EmailMessage]: 'Email Message',
    [enumNotificationType.HubNotification]: 'Hub Notification',
    [enumNotificationType.SmsNotification]: 'SMS Notification',
    [enumNotificationType.WebNotification]: 'Web Notification',
  };

  notificationStatusDictionary: { [key: number]: string } = {
    [enumNotificationStatus.Sent]: 'Sent',
    [enumNotificationStatus.Incomplete]: 'Incomplete',
    [enumNotificationStatus.Failed]: 'Failed',
    [enumNotificationStatus.Queued]: 'Queued',
  };

  form: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  viewSelected: GridViewsDto;

  lstAssignedFilter = Object.keys(assignedFilterEnum)
    .filter(key => key == 'Everything')
    .map(key => ({
      value: assignedFilterEnum[key],
      description: assignedFilterDisplayNames[assignedFilterEnum[key]],
    }));

  existingViews: Array<GridViewsDto>;
  isAdminUser: boolean = false;

  constructor(
    public readonly list: ListService,
    private spinner: NgxSpinnerService,
    private service: NotificationsService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    private stateService: ConfigStateService,
    public readonly gridViewsService: GridViewsService,
    private dialogService: MatDialog,
    private snackBar: MatSnackBar,
  ) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.currentUserId = this.stateService.getDeep('currentUser.id');
    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];

    this.isAdminUser = currentRoles.includes('admin');

    combineLatest([this.getViewList(this.currentUserId, this.isAdminUser)]).subscribe({
      next: ([viewList]) => {
        this.callApiWithFilters();

        this.existingViews = viewList;

        this.spinner.hide();
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
        this.spinner.hide();
      },
    });
  }

  handleOnViewSelected(id: string) {
    let completeView = this.existingViews.find(x => x.id == id);

    if (!completeView) return;

    this.viewSelected = completeView;

    let filtersObj: NotificationViewFilters = JSON.parse(completeView.viewDefinition);

    this.notificationGridFilters = { ...filtersObj };

    this.paginationSortingAndGlobalSearch.maxResultCount = filtersObj.maxResultCount;
    this.paginationSortingAndGlobalSearch.filter = filtersObj.filter;

    this.viewConfigSelected = filtersObj;

    this.applyViewFilters(filtersObj);

    this.handleFilterFromView();
  }

  handleOnHover(view: GridViewsDto, hover: boolean, event: any) {
    view.displayStar = hover;
  }

  handleOnCreateCustomView(): void {
    const currentFilters: NotificationViewFilters = {
      ...this.notificationGridFilters,
      sorting: this.paginationSortingAndGlobalSearch.sorting,
      maxResultCount: this.paginationSortingAndGlobalSearch.maxResultCount,
    };

    const modalData: CustomViewDataModel = {
      data: currentFilters,
      isEdition: false,
    };

    const dialogRef = this.dialogService.open(CustomViewNotificationModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: modalData,
    });

    dialogRef.afterClosed().subscribe((result: NotificationViewFilters) => {
      if (!result) return;

      let viewDefinition = { ...result, ['viewName']: undefined, ['privateView']: undefined };

      let creationViewModel: GridViewsCreateDto = {
        name: result.viewName,
        private: !this.isAdminUser || !!result.privateView,
        viewDefinition: JSON.stringify(viewDefinition),
        userId: this.currentUserId,
        viewType: 1,
      };

      this.gridViewsService.create(creationViewModel).subscribe({
        next: (res: GridViewsDto) => {
          this.existingViews.push(res);

          this.snackBar.open('New view added.', 'Close', {
            duration: 3000,
          });
        },
        error: err => console.error('error saving view', err),
      });
    });
  }

  showedColumns = {
    statuses: true,
    types: true,
    triggerTypes: true,
    categoryTypes: true,
    emails: true,
    tags: true,
    creationDates: true,
    notificationName: true,
  };

  allowedByView(column: string): boolean {
    if (!this.viewSelected) return true;

    return this.viewConfigSelected?.columns?.includes(column);
  }

  columnsStatus() {
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'statuses',
        softColumnName: 'Statuses',
        defaultChecked: this.showedColumns.statuses,
      },
      {
        columnName: 'types',
        softColumnName: 'Types',
        defaultChecked: this.showedColumns.types,
      },
      {
        columnName: 'triggerTypes',
        softColumnName: 'Trigger Types',
        defaultChecked: this.showedColumns.triggerTypes,
      },
      {
        columnName: 'categoryTypes',
        softColumnName: 'Category Types',
        defaultChecked: this.showedColumns.categoryTypes,
      },
      {
        columnName: 'emails',
        softColumnName: 'Emails',
        defaultChecked: this.showedColumns.emails,
      },
      {
        columnName: 'tags',
        softColumnName: 'Tags',
        defaultChecked: this.showedColumns.tags,
      },
      {
        columnName: 'creationDates',
        softColumnName: 'Creation Dates',
        defaultChecked: this.showedColumns.creationDates,
      },
    ];

    return columnsStatus;
  }

  handleFilterFromView() {
    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  private applyViewFilters(viewFilters: NotificationViewFilters) {
    for (let condition of this.filterConfig.conditions) {
      condition.existingValues = viewFilters[condition.column] || [];
    }

    this.form.get('searchInput')?.setValue(viewFilters.filter);
  }

  private getViewList(userId: string, isAdmin: boolean): Observable<Array<GridViewsDto>> {
    return this.gridViewsService.getAll(userId, isAdmin, 1);
  }

  getDisplayValue(item: any) {
    if (this.viewSelected) {
      return this.viewSelected.name;
    }

    let selected = this.lstAssignedFilter.find(z => z.value == item);

    return selected?.description || '-';
  }

  onGlobalSearch() {
    this.paginationSortingAndGlobalSearch.filter = this.form.get('searchInput')?.value.trim();

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  private storeFilterSettings() {
    const filterSettings: NotificationSettingsForLocalStorage = {
      notificationFilters: this.notificationGridFilters,
      paginationSortingAndGlobalSearch: this.paginationSortingAndGlobalSearch,
    };
    localStorage.setItem(
      `filterSettings_notifications_${this.currentUserId}`,
      JSON.stringify(filterSettings),
    );
  }

  callApiWithFilters() {
    this.spinner.show();
    this.service
      .getLogsList(
        this.notificationGridFilters,
        this.missionId,
        this.paginationSortingAndGlobalSearch,
      )
      .subscribe({
        next: (data: PagedResultDto<NotificationsDto>) => {
          this.data = data;
          this.spinner.hide();
        },
        error: error => {
          console.log(error);
          this.spinner.hide();
        },
      });
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.notificationGridFilters[updatedCondition.column] = updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;

      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.callApiWithFilters();
    }
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    // Reset filters
    for (let condition of this.filterConfig.conditions) {
      this.notificationGridFilters[condition.column as keyof NotificationFilters] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  view(notification: NotificationsDto) {
    this.selected = notification;
    this.isModalOpen = true;
  }
}
