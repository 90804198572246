<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11">
      <form [formGroup]="form" skipValidation>
        <header class="header-area bg-white border-bottom-radius t-mb-25">
          <div class="d-flex align-items-center justify-content-between">
            <div class="header-left-side d-flex align-items-center">
              <h3 class="mt-4 mb-4">Deliverables</h3>
            </div>
            <ul class="header-right-side d-flex align-items-center t-mt-0 t-mb-0">
              <li>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="cartMenu"
                  class="btn p-0 border-none rounded-circle position-relative"
                  [class.notification-btn]="
                    this.model.orderDetail.packagesSelected.length > 0 ||
                    this.model.orderDetail.productsSelected.length > 0
                  "
                  [disabled]="
                    this.model.orderDetail.packagesSelected.length === 0 &&
                    this.model.orderDetail.productsSelected.length === 0
                  "
                >
                  <mat-icon class="cart-icon">shopping_cart</mat-icon>
                </button>
                <mat-menu
                  #cartMenu="matMenu"
                  yPosition="below"
                  xPosition="before"
                  [class]="'menu-dropdown mat-elevation-z2'"
                >
                  <ng-template matMenuContent>
                    <app-orders-detail [orderDetails]="this.model.orderDetail"></app-orders-detail>
                  </ng-template>
                </mat-menu>
              </li>
            </ul>
          </div>
        </header>

        <div class="deliverables-subtitle">
          Select the package that best suits your needs, then if you wish, you can include some
          add-ons.
        </div>

        <mat-form-field class="w-50 justify-content-end mt-3">
          <mat-label>Order Industry</mat-label>
          <mat-select
            name="orderIndustry"
            [(ngModel)]="model.industryId"
            (ngModelChange)="
              setDescriptionIndustry(
                model.industryId,
                dataIndustries.items,
                'industryDescription',
                'name'
              )
            "
            (selectionChange)="industrySelectionChange()"
            formControlName="industryid"
          >
            <mat-option
              *ngFor="let di of dataIndustries.items"
              [value]="di.id"
              title="{{ di.name }}"
            >
              {{ di.name }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              form.get('industryid').hasError('required') ||
              form.get('industryid').hasError('whitespace')
            "
          >
            Please enter a value
          </mat-error>
        </mat-form-field>

        <div *ngIf="model.packagesbyCustumer.length > 0">
          <h6 class="mt-3 mb-3">
            <span class="deliverables-filtertitle">Frequently Requested Packages</span>
          </h6>

          <mat-divider class="mt-2 mb-3"></mat-divider>

          <div class="d-flex w-100 mt-3 flex-wrap">
            <div *ngFor="let pk of model.packagesbyCustumer; let i = index">
              <div class="w-100 mt-2 mb-4 pe-2 mt-3">
                <app-package #package [package]="pk" [model]="model"></app-package>
              </div>
              <ng-container *ngIf="(i + 1) % 4 === 0 || i + 1 === model.packagesbyCustumer.length">
                <div class="w-100"></div>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="filteredPackages.length > 0">
          <h6 class="mt-3">
            <span class="deliverables-filtertitle">Generic Packages</span>
          </h6>

          <mat-divider></mat-divider>

          <div class="d-flex w-100 flex-wrap">
            <div class="mt-2 mb-4 pe-2 mt-3" *ngFor="let gpk of filteredPackages; let i = index">
              <app-package
                #package
                [package]="gpk"
                [model]="model"
                [genericPackage]="true"
              ></app-package>
            </div>
          </div>
        </div>

        <ng-container *ngIf="model.productsbyIndustry.length > 0">
          <h6 class="mt-2">
            <span class="deliverables-filtertitle">Add Products</span>
          </h6>

          <mat-divider class="mt-2 mb-3"></mat-divider>

          <app-deliverable-list
            #deliverableList
            *ngIf="model.productsbyIndustry.length > 0"
            [model]="model"
          ></app-deliverable-list>
        </ng-container>

        <flg-pending-controls
          [touched]="form.touched"
          [controls]="getPendingControls()"
        ></flg-pending-controls>

        <div class="d-flex justify-content-end mt-3">
          <button
            mat-flat-button
            class="outline-button mb-3 me-auto"
            matStepperPrevious
            type="button"
          >
            <span class="button-text">Back</span>
          </button>
          <button mat-flat-button class="plain-button me-2" type="button" (click)="discard()">
            Discard
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button"
            [disabled]="!isStepValid() || invalidPackageConfiguration()"
            (click)="checkIndustryDescription()"
            matStepperNext
            type="button"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
