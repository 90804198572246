<div class="info-block">
  <div class="info-block-actions">
    <h2>{{ title }}</h2>

    <span
      class="material-symbols-outlined expand-collapse"
      matTooltip="{{ expanded ? 'Collapse' : 'Expand' }}"
      (click)="expanded = !expanded"
    >
      {{ expanded ? 'collapse_content' : 'expand_content' }}
    </span>
  </div>

  <div class="info-block-body" [class.collapsed]="!expanded">
    <ng-content></ng-content>
  </div>
</div>
