import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-detail-info',
  templateUrl: './timeline-detail-info.component.html',
  styleUrls: ['./timeline-detail-info.component.scss'],
})
export class TimelineDetailInfoComponent {
  @Input() info!: any;
}
