import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetNotificationInput,
  NotificationsCreateDto,
  NotificationsDto,
  NotificationsExcelDownloadDto,
  NotificationsUpdateDto,
  SmsNotificationInput,
  SmsNotificationsMultipleCreateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { NotificationFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters';
import { PaginationSortingAndGlobalSearch } from 'projects/flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  apiName = 'notificationsService';

  create = (input: NotificationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotificationsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/sms',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  createMultipleOptions = (
    input: SmsNotificationsMultipleCreateDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, NotificationsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/sms/multiple',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/notification-service/sms/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: SmsNotificationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<NotificationsDto>>(
      {
        method: 'GET',
        url: '/api/notification-service/sms',
        params: {
          filterText: input.filterText,
          userId: input.userId,
          notificationId: input.notificationId,
          message: input.message,
          sender: input.sender,
          status: input.status,
          description: input.description,
          recipients: input.recipients,
          resultInformation: input.resultInformation,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getManagerList = (
    filters: NotificationFilters,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<NotificationsDto>>(
      {
        method: 'POST',
        url: '/api/notification-service/notification/list',
        body: {
          ...filters,
          paginationSortingAndSearch,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: NotificationsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/notification-service/sms/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
