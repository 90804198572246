import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomerNotificationsCreateDto, CustomerNotificationsDto, CustomerNotificationsExcelDownloadDto, CustomerNotificationsUpdateDto, GetCustomerNotificationInput } from '../../relationals/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerNotificationsService {
  apiName = 'customersService';
  

  create = (input: CustomerNotificationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerNotificationsDto>({
      method: 'POST',
      url: '/api/customers-service/customer-notification',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/customers-service/customer-notification/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerNotificationsDto>({
      method: 'GET',
      url: `/api/customers-service/customer-notification/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/customers-service/customer-notification/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetCustomerNotificationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerNotificationsDto>>({
      method: 'GET',
      url: '/api/customers-service/customer-notification',
      params: { filterText: input.filterText, customerNotificationTypeId: input.customerNotificationTypeId, notificationId: input.notificationId, customerId: input.customerId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: CustomerNotificationsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/customers-service/customer-notification/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: CustomerNotificationsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerNotificationsDto>({
      method: 'PUT',
      url: `/api/customers-service/customer-notification/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
