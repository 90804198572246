<mat-menu class="drawer mat-elevation-z2" xPosition="before">
  <ng-template matMenuContent>
    <header class="drawer drawer--header">
      <h4 class="m-0 p-2" title>
        {{ title }}
        <button [disabled]="cleared" class="bg-transparent border-none p-0">
          <span
            class="material-symbols-outlined"
            [matTooltip]="cleared ? '' : 'Mark as Read'"
            (click)="markAsRead()"
          >
            mark_chat_read
          </span>
        </button>
      </h4>
    </header>

    <ul class="drawer drawer--body">
      <ng-container *ngIf="notifications.length > 0; else elseTemplate">
        <li
          *ngFor="let notification of notifications | slice: 0 : 5; let i = index"
          [@fadeInAnimation]="'in'"
          [routerLink]="notification.path"
        >
          <div class="drawer drawer--item">
            <span class="material-symbols-outlined"> description </span>
            <p>
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: notification }"
              >
              </ng-container>
              <time>
                {{ notification.dateInLocalTime | date: notification.dateFormat }}
              </time>
            </p>
          </div>
        </li>
      </ng-container>
      <ng-template #elseTemplate>
        <li [@fadeInAnimation]="'in'">
          <div class="drawer drawer--item">
            <span class="material-symbols-outlined"> description </span>
            <p>There are no notifications to show</p>
          </div>
        </li>
      </ng-template>
    </ul>

    <div class="drawer drawer--button">
      <button
        mat-flat-button
        class="no-outline-button"
        [routerLink]="viewAllUrl ? viewAllUrl : '/notifications'"
      >
        View All
      </button>
    </div>
  </ng-template>
</mat-menu>
