<div class="container">
  <div class="days">
    {{
      dayDueDates !== null && dayDueDates !== undefined && showDays ? dayDueDates + ' days' : '--'
    }}
  </div>
  <div class="triangle">
    <span
      class="material-symbols-outlined"
      *ngIf="showTriangle && dayDueDates !== null && dayDueDates !== undefined && showDays"
      [ngClass]="getIconClass()"
    >
      warning
    </span>
  </div>
</div>
