import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, firstValueFrom } from 'rxjs';

import { OrderFormModel } from '../../model/order-form.model';
import { ConfirmDialogComponent } from '../../../common/confirm-dialog/confirm.dialog.component';
import { OrderFormContactModel } from '../../model/order-form-contact.model';
import { OrderContactComponent } from '../../order-contact/order-contact.component';
import { MissionBulkDTO } from '../../dto/order-bulk-dto';
import { OrderBulkStates } from '../../../columns/rules/lookup-rules';
import { MissionBulkOverviewComponent } from '../../mission-bulk-overview/mission-bulk-overview.component';
import {
  BulkUploadDetailsCreateDto,
  BulkUploadDetailsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { GoogleMapsService } from '@flyguys/services';
import { SlaService } from '../../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { OrderFormDeliverableFieldModel } from '../../model/order-form-deliverable-field.model';
import { KmlDataInfo } from 'projects/flyguys/src/app/shared/deliverable-list/deliverables-list.component';
import {
  AttributesInfo,
  KmlDataInfoPkg,
} from 'projects/flyguys/src/app/shared/package/package.component';

@Component({
  selector: 'app-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrls: ['./fourth-step.component.scss'],
})
export class FourthStepComponent implements OnInit {
  // @TODO This should be its own form, not a generic one
  @Input() form: FormGroup;

  // @TODO This model is redundant as the previous form should handle
  // serialization
  @Input() model: OrderFormModel;
  @Input() kmlData: KmlDataInfo[] = [];
  @Input() kmlDataPkg: KmlDataInfoPkg[] = [];
  @Input() attributesDetails: AttributesInfo[] = [];

  @Output() discardChange: EventEmitter<boolean> = new EventEmitter();
  @Output() saveChange: EventEmitter<boolean> = new EventEmitter();

  kmlDataPkgFourStep: KmlDataInfoPkg[] = [];
  attributesDetailed: AttributesInfo[] = [];
  orderBulkStates = OrderBulkStates;
  updateData: boolean = false;
  hasKmlData: boolean = true;
  isFourthStep: boolean = true;

  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { name: 'Actions' },
  ];

  private subscriptions = new Subscription();

  constructor(
    private dialogService: MatDialog,
    private readonly _googleMapService: GoogleMapsService,
    private slaService: SlaService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.kmlDataPkg) {
      this.kmlDataPkgFourStep = changes.kmlDataPkg.currentValue;
      this.cdr.detectChanges();
    }

    if (changes.attributesDetails) {
      this.attributesDetailed = changes.attributesDetails.currentValue;
      this.cdr.detectChanges();
    }
  }

  ngOnInit() {
    this.validateAndFetchPriorityDescription();
  }

  private validateAndFetchPriorityDescription() {
    if (!this.model.priorityDescription && this.model.priorityId) {
      this.slaService.get(this.model.customerId).subscribe({
        next: response => {
          const slaList = response.items;
          const sla = slaList.find(s => s.id == this.model.priorityId);
          this.model.priorityDescription = sla.name;
        },
        error: err => console.log(err),
      });
    }
    this.updateKmlData();
    if (this.attributesDetails) this.attributesDetailed = this.attributesDetails;
    if (this.kmlDataPkg) this.kmlDataPkgFourStep = this.kmlDataPkg;
  }

  private updateKmlData() {
    this.model.orderDeliverableDetail.forEach(x => {
      this.hasKmlData = x.fields.some(field => field.typeCode === 'KML');
      this.kmlData?.forEach(kml => {
        if (kml.deliverableId == x.deliverableId) {
          x.fields = x.fields.filter(f => f.typeCode.toLocaleLowerCase() !== 'kml');
          x.fields.push(...kml.kmlField);
        }
      });
    });
    this.model.orderDeliverableDetail = [...this.model.orderDeliverableDetail];
    this.updateData = true;
  }

  handleAddContact(): void {
    let contact = new OrderFormContactModel();

    const dialogRef = this.dialogService.open(OrderContactComponent, {
      disableClose: true,
      width: '900px',
      data: {
        contact,
        title: 'Add Contact',
        addingContact: true,
        actions: {
          confirm: 'Add',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const contacts = [...this.model.additionalContacts, ...[data]];
          this.model.additionalContacts = contacts;
        }
      }),
    );
  }

  handleRemoveContact(row: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((removeContact: Boolean) => {
        if (removeContact) {
          const indexToRemove = this.model.additionalContacts.indexOf(row);

          if (indexToRemove !== -1) {
            this.model.additionalContacts.splice(indexToRemove, 1);
          }

          this.model.additionalContacts = [...this.model.additionalContacts];
        }
      }),
    );
  }

  handleEditContact(contact: OrderFormContactModel) {
    const dialogRef = this.dialogService.open(OrderContactComponent, {
      width: '900px',
      data: {
        contact,
        title: 'Edit Contact',
        addingContact: false,
        actions: {
          confirm: 'Edit',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const indexToEdit = this.model.additionalContacts.indexOf(data);
          if (indexToEdit !== -1) {
            this.model.additionalContacts[indexToEdit] = {
              ...this.model.additionalContacts[indexToEdit],
              contactFromId: data.contactFromId,
              contactFromDescription: data.contactFromDescription,
              email: data.email,
              name: data.name,
              lastname: data.lastname,
              number: data.number,
              shareData: data.shareData,
            };
          }

          this.model.additionalContacts = [...this.model.additionalContacts];
        }
      }),
    );
  }

  discard() {
    this.discardChange.emit(true);
  }

  save() {
    this.saveChange.emit(true);
  }

  async viewBulkDetail(row: BulkUploadDetailsDto): Promise<void> {
    const dialogData = {
      title: 'Mission detail',
      mission: row,
      readonly: true,
    };

    if (
      !row.locationLatitude ||
      (!row.locationLongitude &&
        (row.locationZipCode ||
          row.locationCountry ||
          row.locationState ||
          row.locationCity ||
          row.locationStreet))
    ) {
      const address = `${row.locationZipCode} ${row.locationCountry} ${row.locationState} ${row.locationCity} ${row.locationStreet}`;

      const places = await firstValueFrom(this._googleMapService.getPlaces(address));
      const location = (
        await firstValueFrom(this._googleMapService.getGeocodeData(places.predictions[0].place_id))
      ).results[0].geometry.location;

      row.locationLatitude = location.lat.toString();
      row.locationLongitude = location.lng.toString();
    } else {
      const latLng = `${row.locationLatitude},${row.locationLongitude}`;

      const address = (await firstValueFrom(this._googleMapService.getReverseGeoCode(latLng)))
        .results[0].address_components;
      row.locationStreet = `${address.find(x => x.types.find(type => type == 'street_number')).long_name} ${address.find(x => x.types.find(type => type == 'route')).long_name}`;
      row.locationCountry = address.find(x => x.types.find(type => type == 'country')).long_name;
      row.locationState = address.find(x =>
        x.types.find(type => type == 'administrative_area_level_1'),
      ).long_name;
      row.locationCity = address.find(x => x.types.find(type => type == 'locality')).long_name;
      row.locationZipCode = address.find(x =>
        x.types.find(type => type == 'postal_code'),
      ).long_name;
    }

    const dialogRef = this.dialogService.open(MissionBulkOverviewComponent, {
      data: dialogData,
      disableClose: true,
      width: '1150px',
    });
  }
}
