<ng-container *ngIf="!onlyViewDownload">
  <!-- <abp-button
    class="d-grid"
    [buttonId]="buttonId"
    iconClass="fa fa-upload me-2"
    buttonClass="btn btn-primary mb-2"
    [disabled]="!canUpload()"
    >{{ 'FileManagement::UploadFiles' | abpLocalization }}</abp-button
  > -->
  <!-- <abp-button
    class="d-grid"
    *abpPermission="directoryCreatePermission"
    iconClass="fa fa-folder-plus me-2"
    buttonClass="btn btn-light btn-outline-primary mb-4"
    (click)="handleOnCreate(currentSelectedNode)"
    [disabled]="!canCreate()"
    >{{ 'FileManagement::CreateFolder' | abpLocalization }}
  </abp-button> -->
  <div class="d-flex align-items-center">
    <button
      *abpPermission="directoryCreatePermission"
      type="button"
      class="border-0 bg-transparent"
      (click)="handleOnCreate(currentSelectedNode)"
      [disabled]="!canCreate()"
      matTooltip="{{ 'FileManagement::CreateFolder' | abpLocalization }}"
    >
      <span class="material-symbols-outlined"> create_new_folder </span>
    </button>
  </div>
</ng-container>

<abp-create-folder-modal
  [(visible)]="folderCreateModal"
  [missionId]="missionFilter?.missionId"
  [deliverableId]="missionFilter?.deliverableId"
  [captureId]="missionFilter?.captureId"
  [orderDetailId]="missionFilter?.orderDetailId"
  [rootId]="rootId"
>
</abp-create-folder-modal>
