import {
  ABP,
  downloadBlob,
  ListService,
  PagedResultDto,
  PermissionService,
  TrackByService,
} from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type {
  GetMissionFinancialExpensesInput,
  MissionFinancialExpenseDto,
} from '../../../proxy/missions-service/relational/models';
import { MissionFinancialExpenseService } from '../../../proxy/missions-service/controllers/relational/mission-financial-expense.service';
import { ExpenseTypeService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/expense-type.service';
import { ColumnAction } from 'projects/flyguys/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnStatus } from 'projects/flyguys/src/app/components/columns/components/column-displayer/column-displayer.component';
import { UomService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/uom.service';
import { Subscription } from 'rxjs';
import { PilotSourcingCommunicationService } from 'projects/flyguys/src/app/pilot-sourcing/pilot-sourcing-communication-service';
import { format } from 'date-fns';
import { CapturePilotService } from '../../../proxy/missions-service/controllers/relationals/capture-pilot.service';
import { GetPilotByMissionResponse } from '../../../proxy/missions-service/relationals';
@Component({
  selector: 'lib-mission-financial-expense',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './mission-financial-expense.component.html',
  styles: [],
  styleUrls: ['./mission-financial-expense.component.scss'],
})
export class MissionFinancialExpenseComponent implements OnInit, OnDestroy {
  data: PagedResultDto<MissionFinancialExpenseDto> = {
    items: [],
    totalCount: 0,
  };

  @Input() missionId: string;
  @Input() captureId: string;

  filters = {} as GetMissionFinancialExpensesInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionFinancialExpenseDto;

  expenseTypes: { [id: string]: string } = {};
  uomTypes: { [id: string]: string } = {};
  action: any;

  clientMaxResultCount = 10;
  pageNumber = 0;

  showedColumns = {
    actions: true,
    expenseType: true,
    description: true,
    units: true,
    uom: true,
    itemCost: true,
    totalItemCost: true,
    expenseDate: true,
    vendor: true,
    paymentStatus: true,
    reconciliationStatusDescription: true,
  };

  pilotSourcingCommunicationSubscription: Subscription;

  pilots: GetPilotByMissionResponse[];

  loadingReconcile = false;

  defaultPaymentStatusId = '3030d792-362f-4079-b257-b43cb32888c0'
  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionFinancialExpenseService,
    private confirmation: ConfirmationService,
    private expenseTypeService: ExpenseTypeService,
    private readonly capturePilotService: CapturePilotService,
    private uomService: UomService,
    private fb: FormBuilder,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    public permissionService: PermissionService,
  ) {}

  ngOnInit() {
    this.loadData();

    this.expenseTypeService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.expenseTypes = r.items?.reduce(
        (result, dto) => {
          result[dto.id] = dto.description;
          return result;
        },
        {} as { [id: string]: string },
      );
    });

    this.uomService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.uomTypes = r.items?.reduce(
        (result, dto) => {
          result[dto.id] = dto.description;
          return result;
        },
        {} as { [id: string]: string },
      );
    });

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe(() => {
        this.loadData();
      });
  }

  ngOnDestroy(): void {
    this.pilotSourcingCommunicationSubscription.unsubscribe();
  }

  reloadPilotSourcing() {
    this.pilotSourcingCommunicationService.callReloadFunction();
  }

  loadData() {
    this.filters.missionId = this.missionId;
    this.capturePilotService
      .getPilotsByMissionId({ missionId: this.missionId })
      .subscribe(result => {
        this.pilots = result;
      });

    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<MissionFinancialExpenseDto>) => {
      this.data = list;

      this.data.items = this.data.items.map(r => {
        if (r.expenseDate)
          r.expenseLocalDate = format(new Date(r.expenseDate + 'Z'), 'yyyy/MM/dd HH:mm');

        return r;
      });
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  setAction(act: any) {
    this.action = act;
  }

  clearFilters() {
    this.filters = {} as GetMissionFinancialExpensesInput;
  }

  buildForm() {
    const { expenseTypeId, description, units, uomId, itemCost, totalItemCost, expenseDate } =
      this.selected || {};

    this.form = this.fb.group({
      expenseTypeId: [expenseTypeId ?? null, []],
      description: [description ?? null, [Validators.required, Validators.maxLength(100)]],
      units: [units ?? '0', []],
      uomId: [uomId ?? null, []],
      itemCost: [itemCost ?? '0', []],
      totalItemCost: [totalItemCost ?? '0', []],
      expenseDate: [expenseDate ? new Date(expenseDate) : null, []],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.id, {
          ...this.form.value,
          concurrencyStamp: this.selected.concurrencyStamp,
        })
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm()),
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: MissionFinancialExpenseDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: MissionFinancialExpenseDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id)),
      )
      .subscribe(r => this.reloadPilotSourcing());
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter }),
        ),
        finalize(() => (this.isExportToExcelBusy = false)),
      )
      .subscribe(result => {
        downloadBlob(result, 'MissionFinancialExpense.xlsx');
      });
  }

  columnActions(record: MissionFinancialExpenseDto) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'missionsService.MissionFinancialExpenses.Edit && General.Common.Edit',
        action: { callAction: () => this.update(record) },
      },
      {
        actionIcon: 'bi bi-trash',
        abpPermission: 'missionsService.MissionFinancialExpenses.Delete && General.Common.Delete',
        action: { callAction: () => this.delete(record) },
      },
    ];

    return columnActions;
  }

  toggler = (column: string, checked: boolean) => (this.showedColumns[column] = checked);

  columnsStatus() {
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission:
          'missionsService.MissionFinancialExpenses.ShowColumn.Actions && General.Common.ShowColumn.Actions',
      },
      {
        columnName: 'expenseType',
        softColumnName: 'Expense Type',
        defaultChecked: this.showedColumns.expenseType,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.ExpenseType',
      },
      {
        columnName: 'description',
        softColumnName: 'Description',
        defaultChecked: this.showedColumns.description,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.Description',
      },
      {
        columnName: 'units',
        softColumnName: 'Units',
        defaultChecked: this.showedColumns.units,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.Units',
      },
      {
        columnName: 'uom',
        softColumnName: 'Uom',
        defaultChecked: this.showedColumns.uom,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.Uom',
      },
      {
        columnName: 'itemCost',
        softColumnName: 'Item Cost',
        defaultChecked: this.showedColumns.itemCost,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.ItemCost',
      },
      {
        columnName: 'totalItemCost',
        softColumnName: 'Total Item Cost',
        defaultChecked: this.showedColumns.totalItemCost,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.TotalItemCost',
      },
      {
        columnName: 'expenseDate',
        softColumnName: 'Expense Date',
        defaultChecked: this.showedColumns.expenseDate,
        abpPermission: 'missionsService.MissionFinancialExpenses.ShowColumn.ExpenseDate',
      },
    ];

    return columnsStatus;
  }

  reconcileMission() {
    this.loadingReconcile = true;
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);

    var endDateFormat = format(endDate, 'yyyy-MM-dd') + 'T00:00:00';
    var startDateFormat = format(startDate, 'yyyy-MM-dd') + 'T00:00:00';
    this.service
      .generateDataqb({
        startDate: startDateFormat,
        endDate: endDateFormat,
        missionId: this.missionId,
      })
      .subscribe({
        next: response => {
          this.loadData();
        },
        error: erro => {},
        complete: () => {
          this.loadingReconcile = false;
        },
      });
  }
}
