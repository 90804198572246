import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  private readonly PORTFOLIO_TAB_INDEX = 1;
  tabSelected: number;

  constructor(
    public themeService: CustomizerSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    let currentUrl = this.activatedRoute.snapshot.url.join().split(',');

    if (currentUrl.includes('portfolio')) {
      this.tabSelected = this.PORTFOLIO_TAB_INDEX;
    }
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
