import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-management-switcher',
  templateUrl: './file-management-switcher.component.html',
  styleUrls: ['./file-management-switcher.component.scss']
})
export class FileManagementSwitcherComponent {
  @Input() showMapIcon: boolean = false;
  @Input() displayMap: boolean = false;
  @Output() toggleMap: EventEmitter<boolean>;

  constructor() {
    this.toggleMap = new EventEmitter(false);
  }

  /**
   * Handles the switching of the map
   * @param state boolean
   */
  handleToggleMap(state: boolean) {
    this.toggleMap.emit(state);
  }
}
