import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-event-group',
  templateUrl: './timeline-event-group.component.html',
  styleUrls: ['./timeline-event-group.component.scss'],
})
export class TimelineEventGroupComponent {
  @Input() name: string = '';
}
