import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { ActionOrdered } from '../../models/action-ordered-dto';
import { missionStatusOptions } from 'projects/missions-service/src/lib/proxy/missions-service/shared/mission-status.enum';
import { ProductsDeliverablesService } from '../../../services/products-deliverables.service';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { ConfigStateService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { MissionStatus } from '../../models/mission-status.enum';
import { OrderResumeDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatMenuTrigger } from '@angular/material/menu';
import { CaptureWithDeliverablesDto } from '../../models/capture-with-deliverables-dto';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { AssignedDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { MatDialog } from '@angular/material/dialog';
import { MissionAssignmentsModalComponent } from 'projects/missions-service/src/lib/basics/mission-assignments-modal/mission-assignments-modal.component';
import { MissionsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CaptureDeliverableStatusEnum } from '../../../models/products-deliverables/capture-deliverable-status-enum';
import { CopyMissionForm } from '../../../../../../missions-service/src/lib/basics/missions/components/models/copy-mission-form';
import { CopyMissionModalComponent } from '../../../../../../missions-service/src/lib/basics/missions/components/copy-mission-modal/copy-mission-modal.component';
import { OrderFormContactModel } from '../../../components/orders/model/order-form-contact.model';
import { OrderRequestModel } from '../../models/order-request-model';
import { AssignPilotModalComponent } from 'projects/missions-service/src/lib/basics/missions/components/assign-pilot-modal/assign-pilot-modal.component';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { ToasterService } from '@abp/ng.theme.shared';
import { TransferFilesPreStartResponseDto } from '../../models/transfer-files-pre-start-response-dto';
import { S3FileDto } from '../../models/s3-files-dto';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import {
  CategoriesService,
  DepartamentsService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { CategoriesDto, DepartamentsDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { TransferFilesStatusResponseDto } from '../../models/transfer-files-status-response-dto';
import { NgxSpinnerService } from 'ngx-spinner';

// @TODO Tech debt https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/9691
// We're missing a router implementation here. Tabs cannot be used as link

@Component({
  selector: 'app-mission-flow',
  templateUrl: './mission-flow.component.html',
  styleUrls: ['./mission-flow.component.scss'],
})
export class MissionFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * DECLARE HERE VALID ACTIONS IN THE ORDER GRID.
   */
  private readonly ALLOWED_ORDER_DETAIL_ACTIONS = [
    'accept_cancellation',
    'accept_mission',
    'add_note',
    'assign_MC',
    'assign_qaqc',
    'cancel_mission',
    'check_in',
    'check_in_mc',
    'complete_upload',
    'confirm_flight',
    'confirm_mission',
    'copy_mission',
    'logistic_coordination',
    'edit_mission',
    'pause_mission',
    'processing_complete',
    'rate_experience',
    'ready_for_sourcing',
    'reject_mission',
    'request_data',
    'request_data_qaqc',
    'request_deliverables',
    'request_reassignment',
    'request_refly',
    'reschedule_mission',
    'resource_pilot',
    'resubmit_mission',
    'resume_mission',
    'source_pilot',
    'submit_mc_pilot_rating',
    'submit_qaqc_rating',
    'submit_to_qaqc',
    'update_pilot_rating',
    'upload_data',
    'mark_as_dead',
    'data_upload_complete',
    'missionCoordinator_awaitingFlight',
    'reassign_pilot_success',
    'reassign_mc',
    'reassign_qaqc',
    'QA_QC_Complete',
    'reschedule:request_refly',
    'mc_resource',
    'mark_mission_as_flown',
    'reschedule_mission_success',
    'assign_sales',
    'no_code',
    'client_invoiced',
    'mc-review-to-logistic-coordination',
    'recalculate_sla',
    'airspace_requested',
    'airspace_denied',
    'airspace_approved',
    'faa_approve',
  ];

  @Input() missionId: string;
  @Input() displayFromPortfolio: boolean;
  @Input() goToPilotSourcingGrid: boolean;
  @Input() currentCaptureId: string;
  @Output() onGoBack = new EventEmitter<void>();

  customerId: string;
  missionData: MissionFlowDto;
  actions: ActionOrdered[] = [];
  public readonly missionStatus = missionStatusOptions;
  missionStatusId: string = '';
  pilot: string = '';
  captureId: string = '';
  location: string = '';

  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  private isProgrammaticChange = false;
  private displayPilotSourcingTable = false;
  private displayCaptures = true;
  private readonly PILOT_SOURCING_PATH = 'pilot-sourcing/missions/';
  public orderResumeAdditional: any;
  public firstAction: ActionOrdered;
  public visibleSecondaryAction: ActionOrdered;
  public reloadingNotes: boolean = false;
  deliverables: DeliverableDto[] = [];
  deliverablesname: string;
  currentUser: any;
  selectedCaptureId: string;
  selectedCapture: CaptureWithDeliverablesDto;
  users: any;

  emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  subscription = new Subscription();

  iconActions: ActionOrdered[];
  listActions: ActionOrdered[] = new Array();
  actionsToLoad: string[];
  extendedMissionDto: OrderResumeDto;
  currentToken: string;

  noteTabActive: boolean;
  timelineTabActive: boolean;

  captureDateLocal: string;
  captureDateLocalTime: string;

  // max number of icons.
  private readonly ICON_ROW_LENGTH = 3;

  // order managed as primary action
  private readonly PRIMARY_ACTION_ORDER = 1;

  // action code for the only visible action
  private readonly VISIBLE_SECONDARY_ACTION_CODE = 'add_note';

  @ViewChild('actionsMenu') actionsMenu: MatMenuTrigger;

  reloadCapturesActions: Subject<any> = new Subject();

  pilotSourcingCommunicationSubscription: Subscription;
  updateDeliverable: number;
  private missionAssignments: AssignedDto[] = [];

  s3FileCount: number = 0;
  portalFileCount: number = 0;

  categories: CategoriesDto[];
  departments: DepartamentsDto[];
  currentUserId: string;

  isSiteTechMission: boolean;

  @ViewChild('afButton')
  afButton: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private missionService: MissionFlowService,
    private cdRef: ChangeDetectorRef,
    private productsDeliverablesService: ProductsDeliverablesService,
    private stateService: ConfigStateService,
    private usersService: IdentityUserService,
    private notificationBroadcastService: NotificationBroadcastService,
    private oAuthService: OAuthService,
    private localizationService: LocalizationService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private missionsService: MissionsService,
    private dialog: MatDialog,
    private missionFlowService: MissionFlowService,
    private slaService: SlaService,
    private toaster: ToasterService,
    private categoryService: CategoriesService,
    private departamentService: DepartamentsService,
    private confirmation: ConfirmationService,
    private spinner: NgxSpinnerService,
  ) {}

  ngAfterViewInit(): void {
    if (this.goToPilotSourcingGrid) {
      this.missionFlowService.getCapturesWithDeliverables(this.missionId).subscribe({
        next: response => {
          try {
            response = response.map(r => {
              const timeRegex = /(\d+):(\d+)/;
              let time = null;
              const match = String(r.captureTime).match(timeRegex);
              if (match) {
                time = match[0] + ':00';
              }
              r.deliverablesAsString = r.deliverables.map(d => d.name)?.join(', ') || '--';
              r.captureDateTime = time
                ? new Date(new Date(`${r.captureDate}T${time}`).toLocalISOString())
                : new Date(r.captureDate);
              r.missionDescription =
                'In the realm of Eldoria, a forgotten prophecy awakens, binding the fates of disparate races as they unite against an ancient darkness threatening to devour their world.';

              return r;
            });
          } catch { }

          const currentCapture = response.find(x => x.captureId === this.currentCaptureId);
          this.handleCaptureId(currentCapture);
          this.intervalId = setInterval(this.navigateToSourcing.bind(this), 1000);
        },
        error: err => console.log(err),
      });
    }
  }

  handleOnFirstAction(actionCode: string, event: PointerEvent) {
    if (event.isTrusted && this.isSiteTechMission && actionCode == 'QA_QC_Complete') {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.spinner.show();

      forkJoin({
        res: this.getTransferFilesStatus(),
        preTransferResponse: this.missionFlowService.preTransferMissionFiles(this.missionId),
        s3Response: this.missionFlowService.listS3Items({
          correlationId: this.missionData.correlationId,
          pageSize: 1,
          pageNumber: 1,
        }),
      }).subscribe({
        next: response => {
          this.spinner.hide();
          const res = response.res;

          if (res.inQueue && !res.inProgress) {
            let message = `The file transfer process is queued. Please wait for It to be successfully done.`;

            this.confirmation.error(message, 'File Transfer Process', {
              hideCancelBtn: true,
              yesText: 'AbpUi::Close',
            });

            return;
          }

          // in progress
          if (res.inProgress && !res.finishDate) {
            let message = `The file transfer process is in progress. Please wait for It to be successfully done.`;

            this.confirmation.error(message, 'File Transfer Process', {
              hideCancelBtn: true,
              yesText: 'AbpUi::Close',
            });

            return;
          }

          // finished with error
          if (
            res.isFinished &&
            (!res.finishDate || res.totalFiles != res.filesAlreadyTransferred)
          ) {
            let message = `The last file transfer process was finished with errors. Please retry it.`;

            this.confirmation.error(message, 'File Transfer Process', {
              hideCancelBtn: true,
              yesText: 'AbpUi::Close',
            });

            return;
          }

          if (response.preTransferResponse.totalFiles != response.s3Response.totalCount) {
            let message =
              'The number of files uploaded to the portal does not match the S3 file count. Please retry it.';

            this.confirmation.error(message, 'File Transfer Process', {
              hideCancelBtn: true,
              yesText: 'AbpUi::Close',
            });

            return;
          }

          this.handleOnManualClick();
        },
        error: error => {
          this.spinner.hide();
          console.error(error);
        },
      });
    }
  }

  copyMissionLink() {
    navigator.clipboard
      .writeText(`${window.location.origin}/${this.PILOT_SOURCING_PATH}${this.missionId}`)
      .then(() => {
        this.toaster.info('Mission URL copied!');
      })
      .catch(err => {
        this.toaster.info('error copying Mission URL!');
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pilotSourcingCommunicationSubscription.unsubscribe();
  }

  ngOnInit() {
    if (!this.displayFromPortfolio) {
      this.missionId = this.route.snapshot.paramMap.get('missionId');

      this.route.paramMap.subscribe({
        next: (params: ParamMap) => {
          const missionId = params.get('missionId');
          if (missionId && missionId !== this.missionId) {
            this.missionId = this.route.snapshot.paramMap.get('missionId');
            this.initMissionFlow();
          }
        },
      });
    }

    this.currentUser = this.stateService.getDeep('currentUser');
    this.currentToken = this.oAuthService.getAccessToken();

    this.initMissionFlow();

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe((obj: any) => {
        if (obj?.name) {
          this.missionData.customerName = obj.name;
          this.extendedMissionDto.customerName = obj.name;
        }

        if (obj?.reloadComplementarioInfo) this.getInfoMission();

        if (obj?.airspaceClassificationId)
          this.missionData.airSpaceClasificationId = obj.airspaceClassificationId;

        if (obj?.missionName) this.missionData.missionName = obj.missionName;
        if (obj?.updatedMissionDetails?.orderPriority)
          this.missionData.priorityDescription = obj?.updatedMissionDetails.orderPriority;
      });

    this.usersService.getList({ maxResultCount: 1000 }).subscribe(r => {
      this.users = r.items?.map(q => {
        return { id: q.id, value: `${q.name || ''} ${q.surname || ''}` };
      });
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            this.getInfoMission();
            this.loadMissionInformation();
            this.reloadCapturesActions.next(true);
          }
        }

        if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalNoteAddedToMission
        ) {
          if (
            notif.itemId &&
            notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForNoteList &&
            this.missionId == notif.itemId
          ) {
            this.reloadAction();
          }
        }

        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventGlobalForMissionAssignationUpdated
        ) {
          if (notif.extraArgument.salesUser)
            this.missionData.salesName = notif.extraArgument.salesUser;
        }
      }),
    );
    this.missionsService.getMissionAssigned(this.missionId).subscribe({
      next: response => {
        this.missionAssignments = response;
      },
      error: error => console.error('Unable to get mission assignments:\n', error),
    });

    this.stateService.getDeep('currentUser.id');

    combineLatest([this.categoryService.getAll(), this.departamentService.getAll()]).subscribe({
      next: ([categories, departments]) => {
        this.categories = categories;
        this.departments = departments;
      },
      error: _ => console.error('Unable to fetch Categories/Departments'),
    });
  }

  handleOnBack() {
    this.onGoBack.emit();
  }

  goToPortfolioPoc(): void {
    this.routing.navigate(['pilot-sourcing/charting/' + this.missionId]);
  }

  showAssigned(): void {
    var missionAssignments: AssignedDto[] = [];

    forkJoin([
      this.missionsService.getMissionAssigned(this.missionId),
      this.usersService.get(this.extendedMissionDto.creatorId),
    ]).subscribe(([assigned, creator]) => {
      missionAssignments.push({
        user: `${creator.name ?? ''} ${creator.surname ?? ''}`,
        role: 'Created By',
        roleId: '',
        userId: creator.id,
      });

      assigned.forEach(element => {
        if (missionAssignments.findIndex(ma => ma.role == element.role) == -1)
          missionAssignments.push(element);
      });

      this.dialog.open(MissionAssignmentsModalComponent, {
        disableClose: true,
        panelClass: 'modal-base',
        width: '900px',
        data: {
          missionAssignments,
          title: '',
          actions: {},
        },
      });
    });
  }

  initMissionFlow() {
    this.getInfoMission();

    this.loadMissionInformation();

    this.getMissionDeliverables();
  }

  reloadAction() {
    setTimeout(() => {
      this.reloadNotes();
    }, 500);
  }

  reloadNotes() {
    this.reloadingNotes = true;
    this.cdRef.detectChanges();
    this.reloadingNotes = false;
    this.cdRef.detectChanges();
  }

  async onTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel === 'Captures' && !this.isProgrammaticChange) {
      this.displayPilotSourcingTable = false;
    } else {
      this.isProgrammaticChange = false;
    }

    this.noteTabActive =
      event.tab.textLabel == this.localizationService.instant(`missionsService::NotesTab`);
    this.timelineTabActive =
      event.tab.textLabel == this.localizationService.instant(`missionsService::TimelineTab`);
  }

  navigateToSourcing() {
    try {
      this.isProgrammaticChange = true;
      this.displayPilotSourcingTable = true;
      this.tabGroup.selectedIndex = 2;
      clearInterval(this.intervalId);
    } catch { }
  }

  intervalId: any;

  handleCaptureId(capture: CaptureWithDeliverablesDto) {
    this.selectedCaptureId = capture.captureId;
    this.selectedCapture = capture;
    this.displayPilotSourcingTable = true;
  }

  getInfoMission() {
    this.missionService.getOrderResume(this.missionId).subscribe({
      next: response => {
        if (response.missionCaptureDetails?.currentCaptureDate) {
          this.captureId = response.missionCaptureDetails?.captureId;
          this.pilot = response.missionOrderDetails?.pilotName;
          this.location = response.missionOrderDetails.locationCity;
          this.captureDateLocal = response.missionCaptureDetails?.fixedCurrentCaptureDate;
          this.captureDateLocalTime = response.missionCaptureDetails?.fixedCurrentCaptureTime;
        }

        this.extendedMissionDto = response;
      },
      error: error => {
        console.log(error);
      },
    });
  }

  formatTime(time: string): string {
    if (!time) return '--:--';

    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : hours;
    const amOrPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }

  getMissionDeliverables() {
    this.productsDeliverablesService.getDeliverablesByMission(this.missionId).subscribe({
      next: response => {
        this.deliverables = response;
        const uniqueDeliverables = [
          ...new Set(response.map(deliverable => deliverable.deliverableName)),
        ];
        this.deliverablesname = uniqueDeliverables.join(', ');
      },
      error: error => console.log(error),
    });
  }

  handleCaptureBreadcrumb() {
    this.selectedCaptureId = null;
    this.selectedCapture = null;
    this.displayPilotSourcingTable = false;
  }

  private loadMissionInformation(): void {
    this.missionService.getMissionData(this.missionId).subscribe({
      next: response => {
        this.missionData = response;
        this.customerId = response.customerId;

        this.isSiteTechMission = this.missionData.isSiteTechCustomer;

        this.loadActionInformation();
      },
      error: err => console.log(err),
    });
  }

  private mapActions(actions: Array<ActionOrdered>): void {
    this.firstAction = actions.find(
      action =>
        action.order == this.PRIMARY_ACTION_ORDER &&
        this.ALLOWED_ORDER_DETAIL_ACTIONS.includes(action.code),
    );

    let filteredActions = actions.filter(
      action =>
        action.order != this.PRIMARY_ACTION_ORDER &&
        this.ALLOWED_ORDER_DETAIL_ACTIONS.includes(action.code),
    );

    // Pick the visible actions from the list
    this.visibleSecondaryAction = filteredActions.find(
      x => x.code === this.VISIBLE_SECONDARY_ACTION_CODE,
    );

    filteredActions = filteredActions.filter(
      (action, index, self) => index === self.findIndex(a => a.code === action.code),
    );

    // Prepare the actions used in the ... menu
    this.listActions = filteredActions.filter(x => x.code !== this.VISIBLE_SECONDARY_ACTION_CODE);

    this.actionsToLoad = filteredActions
      .filter(r => r.isActionFramework)
      .concat(this.firstAction)
      .map(r => r.code);

    if (this.firstAction.code == 'no_code') {
      this.listActions.push(this.firstAction);
    }
  }

  /**
   * Calls directly to the action clicked on the ... menu
   * @param action: any
   */
  handleAction(action: any) {
    action.currentTarget.querySelector('a').click();
    this.actionsMenu.closeMenu();
  }

  private loadActionInformation(): void {
    this.missionService.getActionsPermissions(this.missionData.missionStatusCode).subscribe({
      next: (response: ActionOrdered[]) => {
        // Filter by the allowed general actions
        this.actions = response;

        this.missionStatusId = this.missionData.missionStatusId;

        // Don't show the captures tab when in "Customer Request" or in "Ready for Sourcing"
        this.displayCaptures =
          this.missionData.missionStatusCode !== MissionStatus.CustomerRequest &&
          this.missionData.missionStatusCode !== MissionStatus.ReadyForSourcing;

        this.cdRef.detectChanges();

        // Sort the actions by order to arrange the buttons. And get the first action for the main button
        this.actions.sort((a, b) => a.order - b.order);

        this.mapActions(this.actions);
        this.cdRef.detectChanges();
      },
      error: err => console.log(err),
    });
  }

  public getActionData(actionCode: string) {
    let currentUser = this.stateService.getOne('currentUser');
    switch (actionCode) {
      case 'check_in_mc':
        return {
          ...this.missionData,
          deliverable: this.deliverablesname,
          missionCoordinator: this.missionData.salesName,
          title: this.missionData.missionName,
          pilot: this.extendedMissionDto.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
        };
      case 'airspace_requested':
        return {
          ...this.missionData,
          deliverable: this.deliverablesname,
          airspaceRequestedTitle: this.missionData.missionName,
          pilotName: this.extendedMissionDto.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
        };
      case 'airspace_denied':
        return {
          ...this.missionData,
          deliverable: this.deliverablesname,
          airspaceRequestedTitle: this.missionData.missionName,
          pilotName: this.extendedMissionDto.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
        };
      case 'airspace_approved':
        return {
          ...this.missionData,
          deliverable: this.deliverablesname,
          airspaceRequestedTitle: this.missionData.missionName,
          pilotName: this.extendedMissionDto.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
        };
      case 'request_refly':
      case 'request_data': {
        return {
          id: this.missionData.missionId,
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          customer: this.missionData.customerName || '-',
          missionSummary: this.extendedMissionDto.missionSummary || '-',
          pilot: this.extendedMissionDto.pilotName || '-',
          location: this.extendedMissionDto.missionOrderDetails.locationAddress || '-',
          originAuthToken: this.currentToken,
          lastScopeOfWork: this.missionData.lastScopeOfWork,
          creatorId: this.currentUser.id,
          captureTime: this.captureDateLocalTime || '-',
        };
      }
      case 'reassign_mc':
        return {
          id: this.missionData.missionId,
          reassignTitle: this.missionData.missionName,
          captureDate: this.captureDateLocal || '-',
          customer: this.missionData.customerName || '-',
          reassignSummary: this.extendedMissionDto.missionSummary,
          pilot: this.extendedMissionDto.pilotName || '-',
          location: this.extendedMissionDto.missionOrderDetails.locationAddress || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          creatorId: this.currentUser.id,
          captureTime: this.captureDateLocalTime || '-',
        };

      case this.QaQcCompleteActionCode:
      case this.ClientInvoicedActionCode:
        return {
          ...this.extendedMissionDto,
          id: this.extendedMissionDto.missionName,
          missionId: this.missionId,
          creatorId: this.currentUser.id,
          pilot: this.extendedMissionDto.pilotName || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          salesName: this.missionData.salesName || '-',
          customer: this.missionData.customerName || '-',
          customerName: this.missionData.customerName || '-',
          qaqcManagerId: `${this.missionData.qaqcUserId}/${this.missionData.qaqcUsername}/${this.missionData.qaqcRoleId}`,
          missionCoordinator: this.missionData.missionCoordinatorName ?? '-',
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
          deliverable: this.deliverablesname || '-',
          qaqcName: this.missionData.qaqcName || '-',
          confirmationTitle: 'Warning!',
          confirmationDescription: this.getMessageForConfirmationAction(actionCode),
        };

      default: {
        return {
          ...this.extendedMissionDto,
          id: this.extendedMissionDto.missionName,
          missionId: this.missionId,
          creatorId: this.currentUser.id,
          creatorName: this.currentUser.name,
          pilot: this.extendedMissionDto.pilotName || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          pilotSuccessId:
            this.extendedMissionDto.missionOrderDetails.pilotSuccessUserId &&
              this.extendedMissionDto.missionOrderDetails.pilotSuccessUserId !=
              '00000000-0000-0000-0000-000000000000'
              ? this.extendedMissionDto.missionOrderDetails.pilotSuccessUserId
              : currentUser.id,
          salesName: this.missionData.salesName || '-',
          salesUserId: this.missionData.salesUserId ?? currentUser.id,
          customer: this.missionData.customerName || '-',
          customerName: this.missionData.customerName || '-',
          qaqcManagerId: `${this.missionData.qaqcUserId}/${this.missionData.qaqcUsername}/${this.missionData.qaqcRoleId}`,
          missionCoordinator: this.missionData.missionCoordinatorName ?? '-',
          missionCoordinatorUserId: this.missionData.missionCoordinatorUserId ?? currentUser.id,
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
          deliverable: this.deliverablesname || '-',
          qaqcName: this.missionData.qaqcName || '-',
          qaqcUserId: this.missionData.qaqcUserId ?? currentUser.id,
          airSpaceClasificationId: this.missionData.airSpaceClasificationId || this.emptyGuid,
          missionAssignments: this.missionAssignments,
          priorityDescription: this.missionData.priorityDescription || '--',
        };
      }
    }
  }

  public showNextStepHelper(): boolean {
    return (
      this.firstAction &&
      (this.firstAction?.description?.length > 0 || this.firstAction?.helperText?.length > 0)
    );
  }

  QaQcCompleteActionCode: string = 'QA_QC_Complete';
  ClientInvoicedActionCode: string = 'client_invoiced';

  checkIfThrowsConfirmationAction(code: string): boolean {
    switch (code) {
      case this.QaQcCompleteActionCode:
        return this.checkIfAllDeliverablesAreEitherAcceptedOrRejected();
      case this.ClientInvoicedActionCode:
        return this.missionData.preventClientInvoiced;
      default:
        return false;
    }
  }

  private checkIfAllDeliverablesAreEitherAcceptedOrRejected(): boolean {
    for (let deliverable of this.deliverables as DeliverableDto[]) {
      let status: number = parseInt(deliverable.status);
      if (isNaN(status) || status === CaptureDeliverableStatusEnum.NotReviewed) {
        return true;
      }
    }
    return false;
  }

  handleCopyAction() {
    const captureTimeAsShownInHeader =
      this.extendedMissionDto?.missionCaptureDetails?.fixedCurrentCaptureTime ||
      this.extendedMissionDto?.missionCaptureDetails?.currentCaptureTime ||
      '';

    const copyModal: CopyMissionForm = {
      missionId: this.missionId,
      missionName: this.missionData.missionName,
      captureDate: this.missionData.captureDate || '',
      captureTime: captureTimeAsShownInHeader,
      jobId: this.missionData.jobId?.toString() || '',
      captureDateMust: this.missionData.customerRequestedCaptureMustBe,
    };

    const dialogRef = this.dialog.open(CopyMissionModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: copyModal,
    });

    dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => { });
  }

  getMessageForConfirmationAction(actionCode: string): string {
    switch (actionCode) {
      case this.QaQcCompleteActionCode:
        const allDeliverablesAcceptedOrRejected =
          this.checkIfAllDeliverablesAreEitherAcceptedOrRejected();

        if (!allDeliverablesAcceptedOrRejected) {
          return 'At least one or more deliverables are not accepted, are you sure you want to mark QA QC Complete?';
        }
      case this.ClientInvoicedActionCode:
        return 'Pilot payment date is missing, are you sure you want to mark as client invoiced?';
      default:
        return '';
    }
  }

  handleAssignPilotAction() {
    const missionInfoModal = {
      missionsInfo: [
        {
          missionId: this.missionId,
          jobId: this.missionData.jobId || '',
          missionName: this.missionData.missionName,
          captureDate:
            this.parseCaptureDate(this.missionData.fixedCustomerRequestedCaptureDate) || '',
          captureTime: this.missionData.fixedCustomerRequestedCaptureTime || '',
          pilot: this.pilot || '',
          location: this.location || '',
          customerName: this.missionData.customerName || '',
          captureId: this.captureId || '',
        },
      ],
    };

    const dialogRef = this.dialog.open(AssignPilotModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: missionInfoModal,
    });

    dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => { });
  }

  private parseCaptureDate(dateString: string): string | '' {
    if (!dateString) {
      return '';
    }

    const dateParts = dateString.split('/');

    if (dateParts.length !== 3) {
      return '';
    }

    const formattedDate = `${dateParts[2]}-${dateParts[0].padStart(2, '0')}-${dateParts[1].padStart(
      2,
      '0',
    )}T00:00:00`;
    const captureDate = new Date(formattedDate);

    if (isNaN(captureDate.getTime())) {
      return '';
    }

    return formattedDate;
  }

  public newUpdateFromOrderRequest(data: OrderRequestModel) {
    if (this.missionData)
      this.missionData.timeZone = data?.location?.timeZone;
  }

  private getTransferFilesStatus(): Observable<TransferFilesStatusResponseDto> {
    return this.missionFlowService.getTransferFilesStatus(this.missionId);
  }

  private handleOnManualClick() {
    this.afButton.nativeElement.click();
  }
}
