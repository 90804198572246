import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[fix-action-width]',
  standalone: true,
  exportAs: 'ngxDatatableActionWidthDirective',
})
//NgxDatatable doesn't expose unique identifiers per row, because of this
//we need to track down the parent of the action and add a class with
//our style fixes.
export class NgxDatatableActionWidthFix implements AfterViewInit {
  constructor(private ref: ElementRef) {}
  private readonly className = 'action-width-fix';

  ngAfterViewInit(): void {
    this.ref.nativeElement?.parentElement?.classList?.add(this.className);
  }
}
