import { SubscriptionService, trackBy } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChatConfigService, ChatMessage } from '@volo/abp.ng.chat/config';
import { ChatMessageSide } from '../enums/chat-message-side';
import { ChatContactDto } from '../models/chat-contact-dto';
import { ContactService } from '../services/contact.service';
import { format } from 'date-fns';
import { ChatImageDto } from '../models';
import { ChatImageViewerComponent } from './chat-image-viewer/chat-image-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'abp-chat-contacts',
  templateUrl: 'chat-contacts.component.html',
  styleUrls: ['./chat-contacts.scss'],
  providers: [SubscriptionService],
})
export class ChatContactsComponent implements OnInit, OnChanges {
  @Input() startConversation: boolean;

  @Output()
  selected = new EventEmitter<ChatContactDto>();
  allContacts: ChatContactDto[] = [];
  selectedContact = {} as ChatContactDto;
  filter = '';

  getContactName = getContactName;
  trackByUserId = trackBy<ChatContactDto>('userId');

  get contacts(): ChatContactDto[] {
    return this.allContacts
      .filter(contact => contact.lastMessage)
      .sort((a, b) =>
        new Date(a.lastMessageDate).valueOf() > new Date(b.lastMessageDate).valueOf() ? -1 : 1,
      );
  }

  get otherContacts(): ChatContactDto[] {
    return this.allContacts.filter(contact => !contact.lastMessage);
  }

  constructor(
    private contactService: ContactService,
    private chatConfigService: ChatConfigService,
    private subscription: SubscriptionService,
    public dialogService: MatDialog,
  ) {}

  private listenToNewMessages() {
    this.subscription.addOne(this.chatConfigService.message$, (message = {} as ChatMessage) => {
      const index = this.allContacts.findIndex(cont => cont.userId === message.senderUserId);

      const contact = {
        userId: message.senderUserId,
        username: message.senderUsername,
        name: message.senderName,
        surname: message.senderSurname,
        lastMessage: message.text,
        unreadMessageCount: 1,
        lastMessageDate: new Date(),
        lastMessageSide: ChatMessageSide.Receiver,
      };

      if (index > -1) {
        this.allContacts[index] = {
          ...contact,
          unreadMessageCount:
            this.selectedContact.userId === this.allContacts[index].userId
              ? 0
              : this.allContacts[index].unreadMessageCount + 1,
        };
      } else if (!this.filter) {
        this.allContacts.push(contact);
      }
    });
  }

  ngOnInit() {
    this.get(() => {
      this.selectContact(this.allContacts.length ? this.allContacts[0] : ({} as ChatContactDto));
      this.listenToNewMessages();
    });
  }

  ngOnChanges() {
    if (this.startConversation) {
      this.get(() => {}, true);
    }
  }

  get(onSuccess?: Function, includeOtherContacts?: boolean) {
    const includeOtherContactsParam =
      typeof includeOtherContacts === 'boolean' ? includeOtherContacts : !!this.filter;
    this.contactService
      .getContactsByInput({ includeOtherContacts: includeOtherContactsParam, filter: this.filter })
      .subscribe(res => {
        this.allContacts = res;

        if (onSuccess) onSuccess();
      });
  }

  selectContact(contact: ChatContactDto) {
    this.selectedContact = contact;
    this.selected.emit(contact);
  }

  changeLastMessageOfSelectedContact(message: string) {
    this.selectedContact.lastMessage = message;
    this.selectedContact.lastMessageDate = new Date();

    const index = this.allContacts.findIndex(
      contact => contact.userId === this.selectedContact.userId,
    );

    this.allContacts[index] = this.selectedContact;
  }

  markSelectedContactAsRead() {
    this.selectedContact.unreadMessageCount = 0;
  }

  getDateInLocalTime(date: string | Date): string {
    if (!date) return '-';

    if (date instanceof Date) {
      return format(date, 'MMMM d, yyyy');
    }

    let newDate: Date = new Date(date + 'Z');
    return format(new Date(newDate), 'MMMM d, yyyy');
  }

  validateImage(message: string): boolean {
    if (message.indexOf('||img||') != -1) {
      return true;
    }

    return false;
  }

  getImageProp(message: string, prop: string): string {
    let _return: string = '';

    if (message.indexOf('||img||') != -1) {
      let _messages: string[] = message.split('||img||');

      _messages.forEach(_message => {
        if (_message != '' && _message.indexOf('{"id"') != -1) {
          let _imgMessage: ChatImageDto = JSON.parse(_message);

          switch (prop) {
            case 'id': {
              _return = _imgMessage.id;
              break;
            }
            case 'src': {
              _return = _imgMessage.src;
              break;
            }
            case 'name': {
              _return = _imgMessage.name;
              break;
            }
            default: {
              break;
            }
          }
        }
      });
    }

    return _return;
  }

  viewErrorzoomImage(message: string) {
    if (message.indexOf('||img||') != -1) {
      let _messages: string[] = message.split('||img||');

      _messages.forEach(_message => {
        if (_message != '' && _message.indexOf('{"id"') != -1) {
          let _imgMessage: ChatImageDto = JSON.parse(_message);

          const dialogData = {
            title: _imgMessage.name,
            detail: _imgMessage,
          };

          const dialogRef = this.dialogService.open(ChatImageViewerComponent, {
            data: dialogData,
            disableClose: true,
            width: '900px',
          });
        }
      });
    }
  }

  separateMessages(message: string): string {
    if (message) {
      let lsitMessages: string[] = [];

      let _messages: string[] = message.split('||img||');

      _messages.forEach(_message => {
        if (_message.trim() != '') {
          if (_message.indexOf('{"id"') != -1) {
            _message = '||img||' + _message + '||img||';
          }

          lsitMessages.push(_message);
        }
      });

      return lsitMessages.length > 0 ? lsitMessages[0] : message;
    }

    return '';
  }
}

export function getContactName({ name, surname, username }: ChatContactDto) {
  if (!name && !surname) return username;

  return `${name || ''} ${surname || ''}`;
}
