import { DirectoryContentDto } from '@volo/abp.ng.file-management/proxy';
import { ExifDataResultsDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';

export class FilePreviewModel {
  constructor(fileName, imageUrl, videoUrl, fileUrl, thumbnailUrl, directoryContent) {
    this.fileName = fileName;
    this.imageUrl = imageUrl;
    this.videoUrl = videoUrl;
    this.videoInProgress = this.videoUrl == 'InProgress';
    this.fileUrl = fileUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.directoryContent = directoryContent;
    this.validationResults = directoryContent?.validationResults;

    this.previewSupported = this.isPreviewEnabled();
  }

  private isPreviewEnabled() {
    return (
      this.isImagePreviewValid() || this.isVideoPreviewValid() || this.isFileContentPreviewValid()
    );
  }

  private isImagePreviewValid() {
    return this.imageUrl !== null && this.imageUrl.length > 0;
  }

  private IsPreviewUrlEmpty(previewUrl: string) {
    return previewUrl == null || previewUrl.length == 0;
  }

  private isVideoPreviewValid() {
    return this.videoUrl !== null && this.videoUrl.length > 0;
  }

  private isFileContentPreviewValid() {
    return (
      this.fileUrl !== null &&
      this.fileUrl.length > 0 &&
      this.filesContentPreviewExtensions.includes(this.GetFileExtension())
    );
  }

  public GetFileExtension() {
    if (this._fileExtension != null && this._fileExtension.length > 0) return this._fileExtension;

    let re = /(?:\.([^.]+))?$/;
    this._fileExtension = re.exec(this.fileName)[1].toLowerCase().replace('.', '');
    return this._fileExtension;
  }

  public GetMediaThumbnail() {
    if (this.GetFileExtension() == 'pdf') return 'assets/images/media/pdfFilePreview.png';

    if (!this.IsPreviewUrlEmpty(this.videoUrl))
      return this.thumbnailUrl || 'assets/images/media/reproductor.jpg';

    if (!this.IsPreviewUrlEmpty(this.imageUrl)) return this.thumbnailUrl || this.imageUrl;
  }

  public HasPreviewURL() {
    return (
      !this.IsPreviewUrlEmpty(this.imageUrl) ||
      !this.IsPreviewUrlEmpty(this.videoUrl) ||
      !this.IsPreviewUrlEmpty(this.fileUrl)
    );
  }

  public IsPreviewReady() {
    return (
      this.isImagePreviewValid() ||
      (this.isVideoPreviewValid() && !this.videoInProgress) ||
      this.isFileContentPreviewValid()
    );
  }

  directoryContent: DirectoryContentDto;
  fileName: string;
  imageUrl: string;
  videoUrl: string;
  fileUrl: string;
  videoInProgress: boolean;
  previewSupported: boolean;
  thumbnailUrl: string;
  validationResults: ExifDataResultsDto[];

  private _fileExtension: string;
  filesContentPreviewExtensions = ['pdf'];
}
