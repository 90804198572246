import { mapEnumToOptions } from '@abp/ng.core';

export enum enumNotificationTriggerType {
  StatusChange,
}

export const notificationTriggerDisplayNames: Record<enumNotificationTriggerType, string> = {
  [enumNotificationTriggerType.StatusChange]: 'Status Change',
};

export const EnumNotificationStatusOptions = mapEnumToOptions(enumNotificationTriggerType);
