import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AssignedDto,
  CaptureDeliverablesAttributesDto,
  MissionLocationInformationDto,
  MissionMetrics,
  MissionsCreateDto,
  MissionsDto,
  MissionsExcelDownloadDto,
  MissionsUpdateDto,
  UpdateDeliverableStatusDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { MissionsGridFilters } from '../../../../basics/missions/components/models/missions-grid-filters';
import { StatusAndAssignedPermissions } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import { PaginationSortingAndGlobalSearch } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { PilotSuccessDto } from '../../relationals';
import { CopyMissionForm } from 'projects/missions-service/src/lib/basics/missions/components/models/copy-mission-form';
import { OrderRequestDTO } from '../../../../../../../flyguys/src/app/components/orders/dto/order-request.dto';
import {
  MissionCostItemDescriptionDto,
  MissionsPaymentsDto,
  MissionsPaymentsFilterDto,
} from 'projects/missions-service/src/lib/basics/accounting/models/models';
import { MarginReferenceTableDto } from 'projects/customers-service/src/lib/proxy/customers-service/relationals';

@Injectable({
  providedIn: 'root',
})
export class MissionsService {
  apiName = 'missionsService';
  identityApiName = 'AbpIdentity';

  create = (input: MissionsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getMissionsGridData = (
    filters: MissionsGridFilters,
    statusAndAssignedPermissions: StatusAndAssignedPermissions,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<MissionsDto>>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/list',
        body: {
          ...filters,
          statusAndAssignedPermissions,
          paginationSortingAndSearch,
        },
        reportProgress: false,
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: MissionsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/mission/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: MissionsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getAttributeValues = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<CaptureDeliverablesAttributesDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/attributes/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}

  getUserByRole = (missionId: string, roleName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/get-user-byRole/${missionId}`,
        params: { roleName: roleName },
      },
      { apiName: this.apiName, ...config },
    );

  getUsersByRoleNames = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotSuccessDto>>(
      {
        method: 'GET',
        url: `/identityservice/api/users/users-by-role-names/${input}`,
      },
      { apiName: this.identityApiName, ...config },
    );

  createCustomerAdminUser = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>(
      {
        method: 'POST',
        url: `/identityservice/api/users/create-customer-admin-user`,
        body: { userId: userId },
      },
      { apiName: this.identityApiName, ...config },
    );

  getMissionAssigned = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssignedDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/assigned/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  updateMissionLocationInformation = (
    input: MissionLocationInformationDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission/location`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getMissionForCopy = (input: CopyMissionForm, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderRequestDTO>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/get-mission-for-copy',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  updateDeliverableStatus = (input: UpdateDeliverableStatusDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UpdateDeliverableStatusDto>(
      {
        method: 'POST',
        url: `/api/missions-service/mission/deliverable/status/update`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getPortfolioMissionsData = (
    portfolioId: string,
    filters: MissionsGridFilters,
    statusAndAssignedPermissions: StatusAndAssignedPermissions,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<MissionsDto>>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/portfolio/' + portfolioId,
        body: {
          ...filters,
          statusAndAssignedPermissions,
          paginationSortingAndSearch,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getMissionsPaymentsGridData = (
    filters: MissionsPaymentsFilterDto,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<MissionsPaymentsDto>>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/GetMissionsPayments',
        body: {
          ...filters,
          paginationSortingAndSearch,
        },
        reportProgress: false,
      },
      { apiName: this.apiName, ...config },
    );

  getMissionSummaryByPilot = (missionId: string, pilotId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionCostItemDescriptionDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/GetMissionSummaryByPilot/${missionId}/${pilotId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getMissionMetrics = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionMetrics>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/GetMissionMetrics/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getMarginReferenceTable = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MarginReferenceTableDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/GetMarginReferenceTable/${id}`,
      },
      { apiName: this.apiName, ...config },
    );
}
