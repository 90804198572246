import { ComponentRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TooltipWithRichTextComponent } from './tooltip-with-rich-text/tooltip-with-rich-text.component';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[appTooltip]',
  exportAs: 'appTooltip',
})
export class TooltipWithRichTextDirective {
  @Input('appTooltip') htmlText: string;

  private _overlayRef: OverlayRef;
  private isTooltipVisible = false;
  private isMouseOverHost = false;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) {}

  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 20,
        },
      ])
      .withFlexibleDimensions(false)
      .withPush(false);

    this._overlayRef = this._overlay.create({
      positionStrategy,
      hasBackdrop: false,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
    });
  }

  @HostListener('mouseenter')
  onHostMouseEnter() {
    this.isMouseOverHost = true;
    this.show();
  }

  @HostListener('mouseleave')
  onHostMouseLeave() {
    this.isMouseOverHost = false;
    this.hide();
  }

  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipWithRichTextComponent> = this._overlayRef.attach(
        new ComponentPortal(TooltipWithRichTextComponent),
      );
      tooltipRef.instance.htmlContent = this.htmlText;

      const tooltipElement = tooltipRef.location.nativeElement;
      tooltipElement.addEventListener('mouseenter', this.onTooltipMouseEnter.bind(this));
      tooltipElement.addEventListener('mouseleave', this.onTooltipMouseLeave.bind(this));
    }
  }

  hide() {
    setTimeout(() => {
      if (!this.isMouseOverHost && !this.isTooltipVisible) {
        this.closeToolTip();
      }
    }, 100);
  }

  private onTooltipMouseEnter() {
    this.isTooltipVisible = true;
  }

  private onTooltipMouseLeave() {
    this.isTooltipVisible = false;
    this.hide();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this.isTooltipVisible = false;
    }
  }
}
