import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { GridComponent } from './components/grid/grid.component';
import { environment } from '../environments/environment.prod';

import { RedirectGuardComponent } from './guards/redirect.component';
import { AuthGuard, EnvironmentService, PermissionGuard } from '@abp/ng.core';

import { NotFoundComponent } from './components/tagus/not-found/not-found.component';
import { NewOrderFormComponent } from './components/orders/new-order-form/new-order-form.component';
import { MissionFlowComponent } from './pilot-sourcing/components/mission-flow/mission-flow.component';
import { PilotFinderComponent } from './pilot-sourcing/components/pilot-finder/pilot-finder.component';
import { PilotInfoComponent } from './pilot-sourcing/components/pilot-info/pilot-info.component';
import { FinancialsComponent } from './pilot-sourcing/components/financials/financials.component';
import { InternalServerErrorComponent } from './components/tagus/internal-server-error/internal-server-error.component';
import { ForbiddenComponent } from './components/tagus/forbidden/forbidden.component';
import {
  identityCreateFormPropContributors,
  identityEditFormPropContributors,
} from './shared/contributors/form-prop-contributors';
import {
  auditLoggingEntityPropContributors,
  identityEntityPropContributors,
} from './shared/contributors/entity-prop-contributors';
import { AccessPortalGuard } from './guards/access-portal.guard';
import { AccessDeniedComponent } from './components/tagus/access-denied/access-denied.component';
import { NoInternetComponent } from './components/tagus/no-internet/no-internet/no-internet.component';
import { OrderEditGuard } from './guards/order-edit.guard';
import { ImpersonateActionService } from './shared/contributors/user-actions-prop-contributor';
import { MissionBulkUploadComponent } from './components/orders/mission-bulk-upload/mission-bulk-upload.component';
import { canAccessLower } from './guards/lower-env.guard';
import { MissionPocNgxChartsComponent } from './pilot-sourcing/components/mission-flow/mission-poc-ngx-charts.component';
import { PilotWorkbenchComponent } from './pilot-sourcing/components/pilot-workbench/pilot-workbench.component';

const getElsaUrlResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const { apis } = inject(EnvironmentService).getEnvironment();
  return apis['elsa']?.url || environment.apis.elsa.url;
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  { path: '500', component: InternalServerErrorComponent, pathMatch: 'full' },
  { path: 'no-internet', component: NoInternetComponent, pathMatch: 'full' },
  { path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full' },
  { path: 'access-denied', component: AccessDeniedComponent, pathMatch: 'full' },
  {
    path: 'home',
    pathMatch: 'full',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'notifications',
    canActivate: [AccessPortalGuard],
    loadChildren: () =>
      import('./components/notification-list/notification-list.module').then(
        m => m.NotificationListModule,
      ),
  },
  {
    path: 'account',
    canActivate: [AccessPortalGuard],
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'saas',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('projects/audit-logging/src/lib/audit-logging.module').then(m =>
        m.AuditLoggingModule.forLazy({
          entityPropContributors: auditLoggingEntityPropContributors,
        }),
      ),
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'product-service',
    loadChildren: () => import('product-service').then(m => m.ProductServiceModule),
  },
  {
    path: 'core-service',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('core-service').then(m => m.CoreServiceModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('text-template-management').then(m => m.TextTemplateManagementModule.forLazy()),
    canActivate: [PermissionGuard, AccessPortalGuard],
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'file-management',
    canActivate: [AccessPortalGuard],
    loadChildren: () =>
      import('projects/file-management/src/lib').then(m => m.FileManagementModule.forLazy()),
  },
  {
    path: 'grid',
    canActivate: [AccessPortalGuard],
    component: GridComponent,
  },
  {
    path: 'financials',
    canActivate: [AccessPortalGuard],
    component: FinancialsComponent,
  },
  {
    path: 'chat',
    canActivate: [AccessPortalGuard],
    loadChildren: () =>
      import('projects/chat/src/lib/chat.module').then(m => m.ChatModule.forLazy()),
  },
  {
    path: 'missions-service',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('missions-service').then(m => m.MissionsServiceModule.forLazy()),
  },
  {
    path: 'pilots-service',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('pilots-service').then(m => m.PilotsServiceModule.forLazy()),
  },
  {
    path: 'customers-service',
    canActivate: [AccessPortalGuard],
    loadChildren: () => import('customers-service').then(m => m.CustomersServiceModule.forLazy()),
  },
  {
    path: 'notifications-service',
    canActivate: [AccessPortalGuard],
    loadChildren: () =>
      import('notifications-service').then(m => m.NotificationsServiceModule.forLazy()),
  },
  {
    path: 'elsa',
    canActivate: [AuthGuard, PermissionGuard, AccessPortalGuard],
    component: RedirectGuardComponent,
    resolve: {
      externalUrl: getElsaUrlResolver,
    },
  },
  {
    path: 'orders',
    loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard, AccessPortalGuard],
  },
  {
    path: 'orders/bulk-import-requests',
    canActivate: [AuthGuard, AccessPortalGuard],
    canDeactivate: [OrderEditGuard],
    component: MissionBulkUploadComponent,
  },
  {
    path: 'orders/bulk-import-requests',
    canActivate: [AuthGuard, AccessPortalGuard],
    canDeactivate: [OrderEditGuard],
    component: MissionBulkUploadComponent,
  },
  {
    path: 'orders/bulk-import-requests',
    canActivate: [AuthGuard, AccessPortalGuard],
    canDeactivate: [OrderEditGuard],
    component: MissionBulkUploadComponent,
  },
  {
    path: 'orders/new',
    pathMatch: 'full',
    canActivate: [AccessPortalGuard],
    canDeactivate: [OrderEditGuard],
    component: NewOrderFormComponent,
  },
  {
    path: 'pilot-sourcing/missions/:missionId',
    canActivate: [AccessPortalGuard],
    component: MissionFlowComponent,
  },
  {
    path: 'pilot-sourcing/charting/:missionId',
    canActivate: [AccessPortalGuard],
    component: MissionPocNgxChartsComponent,
  },
  {
    path: 'pilot-sourcing/pilot-finder',
    canActivate: [AccessPortalGuard],
    component: PilotFinderComponent,
  },
  {
    path: 'pilot-sourcing/pilot-workbench',
    canActivate: [AccessPortalGuard],
    component: PilotWorkbenchComponent,
  },
  {
    path: 'pilot-sourcing/pilot-info/:id',
    canActivate: [AccessPortalGuard],
    component: PilotInfoComponent,
  },
  {
    path: 'sandbox',
    pathMatch: 'full',
    canActivate: [canAccessLower],
    loadChildren: () => import('./sandbox/sandbox.module').then(m => m.SandboxModule.forLazy()),
  },
  {
    path: 'health',
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./health/health.module').then(m => m.HealthModule.forLazy()),
    data: {
      requiredPolicy: 'General.ShowMenu.Administration',
    },
  },
  {
    path: 'accounting',
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./health/health.module').then(m => m.HealthModule.forLazy()),
    data: {
      requiredPolicy: 'missionsService.ShowMenu.Accounting',
    },
  },
  {
    path: 'accounting/pilot-payment',
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./health/health.module').then(m => m.HealthModule.forLazy()),
    data: {
      requiredPolicy: 'missionsService.ShowMenu.Accounting',
    },
  },
  {
    path: 'accounting',
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPolicy: 'missionsService.Accounting.ShowMenu',
    },
    loadChildren: () =>
      import('../../../missions-service/src/lib/basics/accounting/accounting.module').then(
        m => m.AccountingModule,
      ),
  },
  {
    path: 'accounting/pilot-payment',
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPolicy: 'missionsService.Accounting.ShowMenu',
    },
    loadChildren: () =>
      import('../../../missions-service/src/lib/basics/accounting/accounting.module').then(
        m => m.AccountingModule,
      ),
  },
  {
    path: 'rule-designer',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./components/rule-designer/rule-designer.module').then(m => m.RuleDesignerModule),
  },
  {
    path: 'webhooks',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./components/webhooks/webhooks.module').then(m => m.WebhooksModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(public impersonateActionService: ImpersonateActionService) {
    const identity = routes.find(x => x.path == 'identity');
    identity.loadChildren = () =>
      import('projects/identity/src/lib/identity.module').then(m =>
        m.IdentityModule.forLazy({
          createFormPropContributors: identityCreateFormPropContributors,
          editFormPropContributors: identityEditFormPropContributors,
          entityPropContributors: identityEntityPropContributors,
          entityActionContributors: impersonateActionService.identityEntityActionContributors,
        }),
      );
  }
}
