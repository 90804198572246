<div class="file-upload-frame" [ngClass]="{ 'border-1': includeBorder }">
  <div>
    <input
      type="file"
      (change)="onFileSelected($event)"
      #fileInput
      hidden
      [multiple]="allowMultiple"
      [accept]="allowedExtensions"
      [disabled]="disabled"
    />
    <button
      mat-raised-button
      (click)="fileInput.click()"
      class="custom-select-files {{ disabled ? 'disabled' : '' }} {{ isKML ? 'kml-button' : '' }}"
      [disabled]="disabled"
      *ngIf="!seeIconButton"
      [textContent]="isKML ? 'Upload KML File' : 'Choose Files'"
    ></button>
    <button
      mat-icon-button
      class="btn p-0 border-none rounded-circle position-relative"
      *ngIf="seeIconButton"
      (click)="fileInput.click()"
    >
      <i class="fa fa-paperclip"></i>
    </button>
  </div>
  <div class="right-container" *ngIf="showFilesAttached && !isKML">
    <div *ngIf="selectedFiles?.length === 0" class="no-files-chosen">Select Files</div>
    <div *ngIf="selectedFiles?.length > 0" class="files-container">
      <mat-chip-list>
        <mat-chip
          *ngFor="let file of selectedFiles"
          [removable]="true"
          (removed)="removeFile(file)"
          class="me-2 mt-2"
        >
          {{ file.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div *ngIf="showFilesAttached && isKML">
    <div *ngIf="selectedFiles?.length === 0" class="no-files-chosen">No files chosen</div>
    <div *ngIf="selectedFiles?.length > 0" class="files-container">
      <mat-chip-list>
        <mat-chip
          *ngFor="let file of selectedFiles"
          [removable]="true"
          (removed)="removeFile(file)"
          class="me-2 mt-2"
        >
          {{ file.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
