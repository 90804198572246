<ng-container *ngIf="showMapIcon">
  <div class="file-manager-switcher">
    <button
      class="file-manager-switcher file-manager-switcher__action"
      [class.file-manager-switcher__action--selected]="!displayMap"
      (click)="handleToggleMap(true)"
    >
      <span class="material-symbols-outlined icon"> folder </span>
      <span class="label">Folder View</span>
    </button>

    <button
      class="file-manager-switcher file-manager-switcher__action"
      [class.file-manager-switcher__action--selected]="displayMap"
      (click)="handleToggleMap(true)"
    >
      <span class="material-symbols-outlined icon"> map </span>
      <span class="label">Map View</span>
    </button>
  </div>
</ng-container>
