import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ExifDataResultsDto,
  ValidationResultDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import {
  AutomaticQaStatus,
  AutomaticQaStatusOptions,
} from '../../../models/automatic-qa-status.enum';
import { ToleranceTypeEnum } from '../../../../../../../missions-service/src/lib/proxy/missions-service/basics/tolerance-type.enum';
import { ColumnColoringRules } from '../../../../components/columns/components/column-stylizer/column-stylizer.component';
import { LocalizationService } from '@abp/ng.core';

export interface DialogData {
  results: ExifDataResultsDto[];
  isSingleFile: boolean;
}

@Component({
  selector: 'app-validations-results-modal',
  templateUrl: './validations-results-modal.component.html',
  styleUrls: ['./validations-results-modal.component.scss'],
})
export class ValidationsResultsModalComponent implements OnInit, AfterViewInit {
  filteredResults: ExifDataResultsDto[];
  deliverableResults: ValidationResultDto[] = [];
  fileResults: ExifDataResultsDto[] = [];
  automaticQaRules: ColumnColoringRules[] = [];
  flattenedFileResults: any[] = [];
  totalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 10;

  flattenedSingleFileResults: any[] = [];

  private readonly IMAGE_QUANTITY_VALIDATOR_CODE = 'quantity';
  private readonly DATA_EXISTS_VALIDATOR_CODE = 'data exists validator';

  constructor(
    public dialogRef: MatDialogRef<ValidationsResultsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localizationService: LocalizationService,
  ) {
    this.processResults(data.results);
  }

  ngOnInit() {
    this.buildAutomaticQaStatusRules();
    if (!this.data.isSingleFile) {
      this.flattenFileResults();
    }
  }

  ngAfterViewInit(): void {
    // This is a hack for ngx-datatable, which cannot detect when its container gets
    // resized. By adding a delay and trigger a global resize, the table will
    // have its proper width.
    setTimeout(_ => window.dispatchEvent(new Event('resize')), 500);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  processResults(results: ExifDataResultsDto[]): void {
    if (this.data.isSingleFile) {
      this.filteredResults = this.filterResults(results);
      this.flattenSingleFileResults();
    } else {
      this.deliverableResults = this.extractDeliverableResults(results);
      this.fileResults = this.filterFileResults(results);
      this.flattenFileResults();
    }
  }

  flattenFileResults(): void {
    this.flattenedFileResults = this.fileResults.reduce((acc, file) => {
      const fileResults = file.validationResults.map(result => ({
        fileName: file.fileName || '',
        ...result,
      }));
      return acc.concat(fileResults);
    }, [] as any[]);
    this.totalElements = this.flattenedFileResults.length;
  }

  filterResults(results: ExifDataResultsDto[]): ExifDataResultsDto[] {
    return results.map(file => ({
      ...file,
      validationResults: file.validationResults.filter(
        result => !this.shouldFilterOut(result.toleranceCode),
      ),
    }));
  }

  extractDeliverableResults(results: ExifDataResultsDto[]): ValidationResultDto[] {
    const allDeliverableResults = results.reduce((acc, file) => {
      const deliverableResults = file.validationResults.filter(result =>
        this.shouldFilterOut(result.toleranceCode),
      );
      return acc.concat(deliverableResults);
    }, [] as ValidationResultDto[]);

    const quantityResult = this.selectMostRelevantResult(
      allDeliverableResults.filter(
        r => r.toleranceCode.toLowerCase().trim() === this.IMAGE_QUANTITY_VALIDATOR_CODE,
      ),
    );

    const dataExistsResult = this.selectMostRelevantResult(
      allDeliverableResults.filter(
        r => r.toleranceCode.toLowerCase().trim() === this.DATA_EXISTS_VALIDATOR_CODE,
      ),
    );

    return [quantityResult, dataExistsResult].filter(r => r !== null) as ValidationResultDto[];
  }

  private selectMostRelevantResult(results: ValidationResultDto[]): ValidationResultDto | null {
    if (results.length === 0) return null;

    const passResults = results.filter(r => r.result === AutomaticQaStatus.Pass);

    if (passResults.length > 0) {
      // Sort by actual value (descending) and take the first one
      return passResults.sort((a, b) => {
        const aValue = parseFloat(a.fileValue || '0');
        const bValue = parseFloat(b.fileValue || '0');
        return bValue - aValue;
      })[0];
    } else {
      // If no passing results, just return the last result
      return results[results.length - 1];
    }
  }

  filterFileResults(results: ExifDataResultsDto[]): ExifDataResultsDto[] {
    return results.map(file => ({
      ...file,
      validationResults: file.validationResults.filter(
        result => !this.shouldFilterOut(result.toleranceCode),
      ),
    }));
  }

  private shouldFilterOut(toleranceCode: string): boolean {
    const lowerCaseTrimmedCode = toleranceCode.toLowerCase().trim();
    return (
      lowerCaseTrimmedCode === this.IMAGE_QUANTITY_VALIDATOR_CODE ||
      lowerCaseTrimmedCode === this.DATA_EXISTS_VALIDATOR_CODE
    );
  }

  getOverallStatus(): AutomaticQaStatus {
    const allResults = this.data.isSingleFile
      ? this.filteredResults.reduce(
          (acc, file) => acc.concat(file.validationResults),
          [] as ValidationResultDto[],
        )
      : [
          ...this.deliverableResults,
          ...this.fileResults.reduce(
            (acc, file) => acc.concat(file.validationResults),
            [] as ValidationResultDto[],
          ),
        ];

    if (allResults.length === 0) {
      return AutomaticQaStatus.NotTested;
    }

    if (allResults.some(item => item.result === AutomaticQaStatus.Fail)) {
      return AutomaticQaStatus.Fail;
    } else if (allResults.every(item => item.result === AutomaticQaStatus.Pass)) {
      return AutomaticQaStatus.Pass;
    } else {
      return AutomaticQaStatus.NotTested;
    }
  }

  getToleranceDisplay(result: ValidationResultDto): string {
    return `${result.toleranceValue}${result.toleranceType === ToleranceTypeEnum.Percentage ? '%' : ''}`;
  }

  private buildAutomaticQaStatusRules() {
    AutomaticQaStatusOptions.forEach(option => {
      const newRule: ColumnColoringRules = {
        columnValue: option.value,
        softValue: this.localizationService.instant(`missionsService::${option.key}`),
        valueClass: `badge badge-automatic-qa-${option.key.toLowerCase()}`,
      };
      this.automaticQaRules.push(newRule);
    });
  }

  flattenSingleFileResults(): void {
    this.flattenedSingleFileResults = this.filteredResults.reduce((acc, file) => {
      const fileResults = file.validationResults.map(result => ({
        fileName: file.fileName || '',
        ...result,
      }));
      return acc.concat(fileResults);
    }, [] as any[]);
  }
}
