<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11">
      <form [formGroup]="form" skipValidation>
        <h4 class="mt-4 mb-4">Client Information</h4>

        <div class="d-flex justify-content-end mt-3">
          <h6 class="mb-3 me-auto">Requesting Customer</h6>
          <button
            mat-stroked-button
            class="outline-button"
            type="button"
            color="primary"
            (click)="handleAddCustomer()"
          >
            <mat-icon aria-hidden="false" aria-label="Add New Contact" fontIcon="add"></mat-icon>
            Create Customer
          </button>
        </div>

        <mat-form-field class="w-100">
          <mat-label>Requesting Company Name</mat-label>
          <input
            #inputCompanyName
            type="text"
            placeholder="Requesting Company Name*"
            matInput
            formControlName="customerDescription"
            [matAutocomplete]="autCustomer"
            (change)="verifyInput($event.target.value)"
          />
          <mat-progress-spinner
            *ngIf="loaders.get('customerDescription')"
            matSuffix
            [mode]="'indeterminate'"
            [diameter]="20"
          ></mat-progress-spinner>
          <mat-autocomplete #autCustomer="matAutocomplete" (optionSelected)="getCustomer($event)">
            <mat-option
              *ngFor="let option of filteredCustomers | async"
              [value]="option"
              title="{{ option }}"
              class="autocomplete-dropdown"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="shouldDisplayError" class="t-mb-20">
          Please enter a valid customer
        </mat-error>

        <mat-form-field class="w-100">
          <input
            #cotactInput
            #autoTrigger="matAutocompleteTrigger"
            type="text"
            placeholder="Search Contact"
            matInput
            formControlName="projectManagerSearch"
            [matAutocomplete]="autoContact"
            (focus)="openAutocompletePanel(autoTrigger)"
          />

          <mat-autocomplete
            #autoContact="matAutocomplete"
            (optionSelected)="onContactSelected($event)"
            class="site-autocomplete"
          >
            <mat-option *ngFor="let contact of customerContactsList" [value]="contact">
              <div class="contact-info-container">
                <span class="location-name">{{
                  contact.firstName.trim() + ' ' + contact.lastName.trim()
                }}</span>
                <div class="contact-details">
                  <div class="location-address">{{ contact.email }}</div>
                  <div class="location-address">{{ contact.phone }}</div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="w-50 pe-2">
          <mat-label>Project Manager First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="customerContactFirstName" />
          <mat-error
            *ngIf="
              form.get('customerContactFirstName').hasError('required') ||
              form.get('customerContactFirstName').hasError('whitespace')
            "
          >
            Please enter a value
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-50">
          <mat-label>Project Manager Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="customerContactLastName" />
          <mat-error
            *ngIf="
              form.get('customerContactLastName').hasError('required') ||
              form.get('customerContactLastName').hasError('whitespace')
            "
          >
            Please enter a value
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-50 pe-2">
          <mat-label>Project Manager Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="Ex. email@domain.com"
            formControlName="customerContactEmail"
          />

          <mat-error
            *ngIf="
              form.get('customerContactEmail').hasError('email') &&
              !form.get('customerContactEmail').hasError('required')
            "
          >
            Please enter a valid email
          </mat-error>
          <mat-error
            *ngIf="
              form.get('customerContactEmail').hasError('required') ||
              form.get('customerContactEmail').hasError('whitespace')
            "
          >
            Please enter a value
          </mat-error>
        </mat-form-field>

        <flg-phone-input
          [control]="form.get('customerContactNumber')"
          [label]="'Project Manager Phone Number'"
        ></flg-phone-input>

        <mat-form-field class="w-100">
          <mat-label>Sub Client Name</mat-label>
          <input
            #autoTrigger="matAutocompleteTrigger"
            type="text"
            [placeholder]="'missionsService::CreateOrder:SubClient' | abpLocalization"
            matInput
            formControlName="subclientName"
            [matAutocomplete]="autSubscostumer"
            (input)="search($event.target.value)"
            (blur)="onInputBlur($event.target.value)"
          />

          <mat-autocomplete
            #autSubscostumer="matAutocomplete"
            (optionSelected)="onSubClientSelected($event)"
            class="site-autocomplete"
          >
            <mat-option *ngFor="let customer of subClientsList" [value]="customer">
              <div class="contact-info-container">
                {{ customer.name }}
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="form.hasError('customerSubclientSame')">
          Sub Client Name can't be the same as the Requesting Company Name.
        </mat-error>

        <div [hidden]="!showControls">
          <h6 class="mt-3 mb-3">{{ 'missionsService::CustomerDefinedFields' | abpLocalization }}</h6>

          <form [formGroup]="formAddCutomerAttribute" skipValidation>
            <div class="row">
              <div
                [ngClass]="{
                  'col-lg-6 col-md-6 col-sm-12': control.controlType !== 'TEXTAREA',
                  'col-lg-12 col-md-12 col-sm-12': control.controlType === 'TEXTAREA',
                }"
                *ngFor="let control of controls"
                class="p-1"
              >
                <ng-container [ngSwitch]="control.controlType">
                  <!-- Checkbox Control -->
                  <ng-container *ngSwitchCase="'CHECKBOX'" class="w-100">
                    <mat-checkbox
                      matInput
                      [id]="control.key"
                      [formControlName]="control.key"
                      [checked]="control.valueCheckBox"
                      (change)="checkboxChange($event, control.key)"
                      class="w-100"
                    >
                      {{ control.label }} {{ control.required ? '*' : '' }}
                    </mat-checkbox>
                  </ng-container>
    
                  <!-- Textarea Control -->
                  <ng-container *ngSwitchCase="'TEXTAREA'">
                    <mat-form-field class="w-100">
                      <mat-label>{{ control.label }} {{ control.required ? '*' : '' }}</mat-label>
                      <textarea
                        matInput
                        [id]="control.key"
                        [formControlName]="control.key"
                        [maxlength]="control.maxLength"
                        rows="5"
                        [value]="control.value"
                        class="w-100"
                      ></textarea>
                    </mat-form-field>
                  </ng-container>
    
                  <!-- Dropdown List Control -->
                  <ng-container *ngSwitchCase="'DROPDOWNLIST'">
                    <mat-form-field class="w-100">
                      <mat-label>{{ control.label }} {{ control.required ? '*' : '' }}</mat-label>
                      <mat-select [formControlName]="control.key" class="w-100">
                        <mat-option *ngFor="let opt of control.options" [value]="opt.key">
                          {{ opt.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
    
                  <!-- Calendar Control -->
                  <ng-container *ngSwitchCase="'CALENDAR'">
                    <mat-form-field class="w-100" [floatLabel]="'always'">
                      <mat-label>{{ control.label }} {{ control.required ? '*' : '' }}</mat-label>
                      <input
                        matInput
                        [id]="control.key"
                        [matDatepicker]="picker"
                        [(ngModel)]="control.value"
                        [formControlName]="control.key"
                        placeholder="MM/DD/YYYY"
                        (dateInput)="handleSelectDate($event, control.key)"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
    
                  <!-- Input Control -->
                  <ng-container *ngSwitchDefault>
                    <mat-form-field class="w-100">
                      <mat-label>{{ control.label }} {{ control.required ? '*' : '' }}</mat-label>
                      <input
                        matInput
                        [type]="control.type"
                        [id]="control.key"
                        [formControlName]="control.key"
                        [maxlength]="control.maxLength"
                        [value]="control.value"
                        class="w-100"
                      />
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </form>
        </div>

        <div class="container mt-3 px-0">
          <div class="row">
            <div class="col-12">
              <h6 class="mb-2">Additional Contacts</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="label-order d-block mb-3">
                <span *ngIf="disableAddContactBtn" class="warning">
                  You must select a customer first
                </span>
                <span *ngIf="!disableAddContactBtn">
                  Search for the contacts you want to add or create a new one if your search is not
                  successful.
                </span>
              </label>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <mat-form-field class="w-100">
                <input
                  #autoTriggerAddContacts="matAutocompleteTrigger"
                  type="text"
                  placeholder="Search Contact"
                  matInput
                  formControlName="additionalContactsSearch"
                  [matAutocomplete]="autoAddContacts"
                  (focus)="openAutocompletePanel(autoTriggerAddContacts)"
                  (change)="verifyInput($event.target.value)"
                />
                <mat-autocomplete
                  #autoAddContacts="matAutocomplete"
                  (optionSelected)="onAddContactSelected($event)"
                  class="site-autocomplete"
                >
                  <mat-option *ngFor="let contact of customerContactsList" [value]="contact">
                    <div class="contact-info-container">
                      <span class="location-name">
                        {{ contact.firstName.trim() + ' ' + contact.lastName.trim() }}
                      </span>
                      <div class="contact-details">
                        <div class="location-address">{{ contact.email }}</div>
                        <div class="location-address">{{ contact.phone }}</div>
                      </div>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-auto h-56px">
              <button
                mat-stroked-button
                class="outline-button"
                type="button"
                color="primary"
                [disabled]="disableAddContactBtn"
                (click)="handleAddContact()"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Add New Contact"
                  fontIcon="add"
                ></mat-icon>
                Add New Contact
              </button>
            </div>
          </div>
        </div>

        <app-order-contact-table
          [contacts]="additionalContacts?.value"
          [columns]="columns"
          [showLevelOfCoordinationColumn]="false"
          [showContactMethodColumn]="false"
          (removecontact)="handleRemoveContact($event)"
          (editcontact)="handleEditContact($event)"
        ></app-order-contact-table>

        <flg-pending-controls
          [touched]="form.touched"
          [controls]="getPendingControls()"
        ></flg-pending-controls>

        <div class="d-flex justify-content-end mt-3">
          <button mat-flat-button class="plain-button me-2" type="button" (click)="discard()">
            Discard
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button"
            type="button"
            [disabled]="form.invalid || formAddCutomerAttribute?.invalid"
            [ngClass]="{ 'step-disabled-button': form.invalid || formAddCutomerAttribute?.invalid }"
            (click)="onNextButtonClick()"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
