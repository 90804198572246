<ng-container *ngIf="detail">
  <header class="mb-4">
    <h4 class="title mb-2 d-flex">
      {{ 'missionsService::' + detail.displayEventName | abpLocalization }}
      <span class="content"
        >{{ detail.date + 'Z' | date: 'H:mm' }} | {{ detail.date + 'Z' | date: 'MM/dd/yyyy' }}</span
      >
    </h4>

    <p>
      {{ 'missionsService::CompletedBy' | abpLocalization }}:
      <span>{{ detail.eventUserName || '-' }}</span>
    </p>
  </header>

  <app-timeline-detail-info
    *ngFor="let info of infoItems | keyvalue"
    [info]="info"
  ></app-timeline-detail-info>
</ng-container>
